import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  useSetState,
  getDataFromDropdown,
  toastifyError,
  getDropdownValue,
  getExperience,
  addCommasToCurrency,
  removeCommasFromCurrency,
} from 'utils/functions.utils';
import {
  Models,
  Navbutton,
  Assets,
  Input,
  Tag,
  Dropdown,
  PrimaryButton,
  FileUpload,
} from 'utils/imports.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './edit_candidate.scss';
import {
  candidate_source,
  education_level,
  gender,
  getNoticePeriod,
  notice_period,
  reasons_for_job_change,
  remote_work_preference,
  willing_to_relocate,
} from '../../utils/constant.utils';
import { getCandidate } from 'utils/redux.utils';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

const EditCandidate = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();
  const inputFileRef: any = useRef();
  //Redux
  const appState = useSelector((state: any) => state.app_state);
  const candidateState: any = useSelector((state: any) => state.candidate);
  const user = useSelector((state: any) => state.user.auth);
  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    time_zone: '',
    name: '',
    last_name: '',
    first_name: '',
    contact: '',
    willing_to_relocate: '',
    email: '',
    gender: '',
    location: '',
    position_applied_for: '',
    current_compensation: '',
    role: [],
    limit: 20,
    skip: 0,
    RecquisitionData: [],
    img: '',
    img_error: false,
    cityList: [],
    stateList: [],
    candidateSource: '',
    salary_current: '',
    salary_expected: '',
    referral_email: '',
    referral_name: '',
    candidate_data: {}
  });

  //Hooks
  useEffect(() => {
    getCandidates();
    getRecquisitionList(state.skip);
    getCountriesList();
  }, [props.candidateId]);
  // Network req

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  const getCandidates = async () => {
    try {
      const body = {
        candidate_id: props.candidateId,
      };
      const res: any = await Models.candidateModel.getCandidate(body);
      setState({
        first_name: res.data?.resume?.first_name,
        last_name: res.data?.resume?.last_name,
        phone_number: res.data?.resume?.phone,
        email: res.data?.resume?.email,
        position_applied_for: getDropdownData([
          res.data?.requisition?.job_title,
        ]),
        location: res.data?.resume?.address,
        current_compensation: res.data?.resume?.current_salary,
        gender: getDropdownValue(res.data?.resume?.gender),
        willing_to_relocate: getDropdownValue(
          res.data?.resume?.willing_to_relocate,
        ),
        img: res.data?.resume?.profile_picture,
        relevant_experience: getDropdownValue(
          res.data?.resume?.relevant_experience,
        ),
        total_experience: getDropdownValue(res.data?.resume?.total_experience),
        reason_for_job_change: getDropdownValue(
          res.data?.resume?.reason_for_job_change,
        ),
        notice_period: getNoticePeriod(res.data?.resume?.notice_period),
        remote_work_preference: getDropdownValue(
          res.data?.resume?.remote_work_preference,
        ),
        // education: getDropdownValue(res.data?.resume?.education),
        salary_current: addCommasToCurrency(res.data?.resume?.current_salary),
        salary_expected: addCommasToCurrency(res.data?.resume?.expected_salary),
        expected_salary: res.data?.resume?.expected_salary,
        current_salary: res.data?.resume?.current_salary,
        candidateState: getDropdownValue(res.data?.resume?.source),
        candidate_data: res.data
      });
      if (res.data?.referral_name) {
        setState({ referral_name: res.data.referral_name });
      }
      if (res.data?.referral_email) {
        setState({ referral_email: res.data.referral_email });
      }
      if (res.data?.resume?.country) {
        setState({ country: getDropdownValue(res.data.resume.country) });
        setState({country_code: 'IN'})
      }
      getStatesListByCountry('IN');
      getCityListByStates('IN', 'TN');
      if (res.data?.resume?.state) {
        setState({ state: getDropdownValue(res.data.resume.state) });
      }
      if (res.data?.resume?.city) {
        setState({ city: getDropdownValue(res.data.resume.city) });
      }
    } catch (err) {
      toastifyError(err);
    }
  };

  const getRecquisitionList = async (skip: number) => {
    let data: any = [];
    try {
      const res: any = await Models.jobRecquisition.getNewRecquisition({
        limit: state.limit,
        skip,
      });
      res.data?.docs?.map((item: any) => {
        return data.push({ label: item.job_title, value: item._id });
      });
      setState({ RecquisitionData: data });
    } catch (error) {
      toastifyError(error);
    }
  };
  const getCountriesList = async () => {
    try {
      const res: any = await Models.country.getAllCountries();
      let countryList: any = [];
      res?.map((country: any) =>
        countryList.push({
          label: country.name,
          value: country.iso2,
        }),
      );
      setState({ countryList });
    } catch (error) {}
  };

  const getStatesListByCountry = async (isoCode: string) => {
    try {
      const res: any = await Models.country.getStatesOfCountry(isoCode);
      let stateList: any = [];
      res?.map((item: any) =>
        stateList.push({
          label: item.name,
          value: item.name,
        }),
      );
      setState({ stateList, allStates: res });
    } catch (error) {}
  };

  const getCityListByStates = async (country: string, state_code: string) => {
    let index = _.findIndex(state.allStates, { name: state_code });
    let stateIso = state.allStates[index].iso2;
    try {
      const res: any = await Models.country.getCitiesByCountry(
        country,
        stateIso,
      );
      let cityList: any = [];
      res?.map((item: any) =>
        cityList.push({
          label: item.name,
          value: item.name,
        }),
      );
      setState({ cityList });
    } catch (error) {}
  };

  const editCandidate = async () => {
    try {
      let body: any = {
        resume_id: state.candidate_data.resume?._id,
        // gender: state.gender?.value,
        // address: state.location,
        // willing_to_relocate: state.willing_to_relocate?.value,
        // remote_work_preference: state.remote_work_preference?.value,
        // country: state.country?.label,
        // state: state.state?.label,
        // city: state.city?.label,
        // relevant_experience: state.relevant_experience?.value,
        // total_experience: state.total_experience?.value,
        // reason_for_job_change: state.reason_for_job_change?.value,
        // notice_period: state.notice_period?.value,
        // source: state.candidateState?.value,
      };
      if (state.current_salary > 0) {
        body.current_salary = parseInt(state.current_salary);
      } else {
        body.current_salary = 0;
      }
      if (state.expected_salary > 0) {
        body.expected_salary = parseInt(state.expected_salary);
      } else {
        body.expected_salary = 0;
      }
      if (state.email.length !== null) {
        body.email = state.email;
      }
      if (state.first_name.length !== null) {
        body.first_name = state.first_name;
      }
      if (state.last_name.length !== null) {
        body.last_name = state.last_name;
      }
      if (!_.isEmpty(state.willing_to_relocate)) {
        body.willing_to_relocate = state.willing_to_relocate?.value;
      }
      if (!_.isEmpty(state.remote_work_preference)) {
        body.remote_work_preference = state.remote_work_preference?.value;
      }
      if (!_.isEmpty(state.country)) {
        body.country = state.country?.label;
      }
      if (!_.isEmpty(state.city)) {
        body.city = state.city?.label;
      }
      if (!_.isEmpty(state.state)) {
        body.state = state.state?.label;
      }
      if (!_.isEmpty(state.gender)) {
        body.gender = state.gender?.value;
      }
      if (!_.isEmpty(state.relevant_experience)) {
        body.relevant_experience = state.relevant_experience?.value;
      }
      if (!_.isEmpty(state.total_experience)) {
        body.total_experience = state.total_experience?.value;
      }
      if (!_.isEmpty(state.reason_for_job_change)) {
        body.reason_for_job_change = state.reason_for_job_change?.value;
      }
      if (!_.isEmpty(state.notice_period)) {
        body.notice_period = state.notice_period?.value;
      }
      if (!_.isEmpty(state.candidateState)) {
        body.source = state.candidateState?.value;
      }
      if (state.img !== null) {
        body.profile_picture= state.img
      }
      if (state.location !== null) {
        body.address= state.location
      }
      const res: any = await Models.resume.editResume(body);
      // // dispatch
      let candidateData = candidateState.candidate;
      candidateData.resume = res?.data;
      getCandidate(candidateData);
      if (state.referral_name !== '' || state.referral_email !== '') {
        changeReferral();
      }
      setState({ isModalOpen: false });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const moveCandidate = async (new_requisition: string) => {
    try {
      const body = {
        candidate_id: props.candidateId,
        new_requisition,
      };
      const res: any = await Models.candidateModel.moveCandidate(body);
      navigate(`/requisition?id=${new_requisition}`);
      // dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //Logic
  const uploadImg = (img) => {
    let type = img.split('.').pop();
    if (type == 'jpg' || type == 'jpeg' || type == 'png') {
      setState({ img, img_error: false });
    } else {
      setState({ img_error: true });
      toastifyError('Upload only image format');
      setTimeout(() => {
        setState({ img_error: false });
      }, 4000);
    }
  };

  const handleChangeState = (value: any) => {
    let index: any = _.findIndex(state.allStates, {
      name: value?.label,
    });
    getCityListByStates(state.country_code, state.allStates[index].iso2);
    setState({ state: value });
  };

  const changeReferral = async () => {
    try {
      let domain = state.referral_email.split('@').pop();
      if (user.org.domain == domain) {
        let body: any = {
          candidate_id: candidateState?.candidate?._id,
        };
        if (state.referral_email !== '') {
          body.referral_email = state.referral_email;
        }
        if (state.referral_name !== '') {
          body.referral_name = state.referral_name;
        }
        const res: any = await Models.candidateModel.addReferral(body);
      } else {
        toastifyError(
          'Only Users from same organization can be added as referral',
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'editcandidate_modal_wrapper',
        }}>
        <div className="editcandidate_container">
          <div className="editcandidate_wrapper">
            <div className="editcandidate_header">
              <div className="editcandidate_heading">Candidate Info</div>
              <Navbutton
                icon={Assets.black_close}
                onClick={() => {
                  setState({ isModalOpen: false });
                }}
              />
            </div>
            <div className="editcandidate_photo_button">
              <div className="editcandidate_image">
                {state.img?.length > 0 ? (
                  <img className="candidate_upload_img" src={state.img} />
                ) : (
                  <img
                    src={Assets.profile_placeholder}
                    className="candidate_upload_img"
                  />
                )}
              </div>
              <div className="editcandidate_upload_button">
                <FileUpload
                  ref={inputFileRef}
                  multiple={true}
                  accept_types={'.jpg, .png,.jpeg'}
                  onChange={(img: any) => {
                    uploadImg(img);
                  }}>
                  <PrimaryButton
                    onClick={() => inputFileRef.current.openUpload()}
                    text={'Upload Picture'}
                    icon={Assets.upload}
                    className="editcandidate_upload_button"
                  />
                </FileUpload>
              </div>
            </div>
            <div className="editcandidate_name_container">
              <div className="editcandidate_name_wrapper">
                <div className="editcandidate_input_heading">First Name</div>
                <div className="editcandidate_input_container">
                  <Input
                    onChange={(first_name: String) => {
                      setState({
                        first_name,
                      });
                    }}
                    type="text"
                    value={state.first_name}
                    name="first_name"
                  />
                </div>
              </div>
              <div className="editcandidate_name_wrapper">
                <div className="editcandidate_input_heading">Last Name</div>
                <div className="editcandidate_input_container">
                  <Input
                    onChange={(last_name: String) => {
                      setState({
                        last_name,
                      });
                    }}
                    type="text"
                    value={state.last_name}
                    name="last_name"
                  />
                </div>
              </div>
            </div>
            <div className="editcandidate_location_container">
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">Phone</div>
                <div className="editcandidate_input_container">
                  <Input
                    onChange={(phone_number) => {
                      setState({ phone_number });
                    }}
                    type="text"
                    value={state.phone_number}
                    name="phone_number"
                  />
                </div>
              </div>
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">Email</div>
                <div className="editcandidate_input_container">
                  <Input
                    onChange={(email) => {
                      setState({ email });
                    }}
                    type="text"
                    value={state.email}
                    name="email"
                  />
                </div>
              </div>
            </div>
            <div className="editcandidate_location_container">
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">Address</div>
                <div className="editcandidate_input_container">
                  <Input
                    onChange={(location) => {
                      setState({ location });
                    }}
                    type="text"
                    value={state.location}
                    name="location"
                  />
                </div>
              </div>
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">Country</div>
                <div className="editcandidate_input_container">
                  <Dropdown
                    data={state.countryList}
                    setActive={(value: any) => {
                      getStatesListByCountry(value?.value);
                      setState({
                        country: value,
                        country_code: value?.value,
                      });
                    }}
                    value={state.country}
                    name={'country'}
                    error={state.errorArray}
                  />
                </div>
              </div>
            </div>
            <div className="editcandidate_location_container">
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">State</div>
                <div className="editcandidate_dropdown_container">
                  <Dropdown
                    data={state.stateList}
                    setActive={(value: any) => {
                      getCityListByStates(state.country_code, value.value);
                      setState({ state: value });
                    }}
                    value={state.state}
                    name={'state'}
                    error={state.errorArray}
                  />
                </div>
              </div>
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">City</div>
                <div className="editcandidate_input_container">
                  <Dropdown
                    data={state.cityList}
                    setActive={(value: any) => {
                      setState({ city: value });
                    }}
                    value={state.city}
                    name={'city'}
                    error={state.errorArray}
                  />
                </div>
              </div>
            </div>
            <div className="editcandidate_position_container">
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">Gender</div>
                <div className="editcandidate_dropdown_container">
                  <Dropdown
                    value={state.gender}
                    onChange={(gender) => {
                      setState({ gender });
                    }}
                    data={getDropdownData(gender)}
                  />
                </div>
              </div>
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">
                  Remote work preference
                </div>
                <div className="editcandidate_input_container">
                  <Dropdown
                    onChange={(remote_work_preference) => {
                      setState({ remote_work_preference });
                    }}
                    data={getDropdownData(remote_work_preference)}
                    value={state.remote_work_preference}
                  />
                </div>
              </div>
            </div>
            <div className="editcandidate_location_container">
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">
                  Current salary
                </div>
                <div className="editcandidate_dropdown_container">
                  <Input
                    onChange={(current_salary) => {
                      let salary_current = addCommasToCurrency(
                        current_salary.replaceAll(',', ''),
                      );
                      setState({
                        current_salary:
                          removeCommasFromCurrency(salary_current),
                        salary_current,
                      });
                    }}
                    type="text"
                    value={state.salary_current}
                    name="current_salary"
                  />
                </div>
              </div>
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">
                  Expected salary
                </div>
                <div className="editcandidate_input_container">
                  <Input
                    onChange={(expected_salary) => {
                      let salary_expected = addCommasToCurrency(
                        expected_salary.replaceAll(',', ''),
                      );
                      setState({
                        expected_salary:
                          removeCommasFromCurrency(salary_expected),
                        salary_expected,
                      });
                    }}
                    type="text"
                    value={state.salary_expected}
                    name="expected_salary"
                  />
                </div>
              </div>
            </div>
            <div className="editcandidate_location_container">
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">
                  Relevant Experience
                </div>
                <div className="editcandidate_dropdown_container">
                  <Dropdown
                    value={state.relevant_experience}
                    onChange={(relevant_experience) => {
                      setState({ relevant_experience });
                    }}
                    data={getDropdownData(getExperience())}
                  />
                </div>
              </div>
              <div className="editcandidate_compensation_wrapper">
                <div className="editcandidate_input_heading">
                  Total Experience
                </div>
                <div className="editcandidate_input_container">
                  <Dropdown
                    value={state.total_experience}
                    onChange={(total_experience) => {
                      setState({ total_experience });
                    }}
                    data={getDropdownData(getExperience())}
                  />
                </div>
              </div>
            </div>
            <div className="editcandidate_location_container">
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">Notice Period</div>
                <div className="editcandidate_dropdown_container">
                  <Dropdown
                    value={state.notice_period}
                    onChange={(notice_period) => {
                      setState({ notice_period });
                    }}
                    data={notice_period}
                  />
                </div>
              </div>
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">
                  Willingness to relocate
                </div>
                <div className="editcandidate_input_container">
                  <Dropdown
                    onChange={(willing_to_relocate) => {
                      setState({ willing_to_relocate });
                    }}
                    data={getDropdownData(willing_to_relocate)}
                    value={state.willing_to_relocate}
                  />
                </div>
              </div>
            </div>
            <div className="editcandidate_location_container">
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">
                  Reasons for job change
                </div>
                <div className="editcandidate_dropdown_container">
                  <Dropdown
                    value={state.reason_for_job_change}
                    onChange={(reason_for_job_change) => {
                      setState({ reason_for_job_change });
                    }}
                    data={getDropdownData(reasons_for_job_change)}
                  />
                </div>
              </div>
              {/* <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">
                  Remote work preference
                </div>
                <div className="editcandidate_input_container">
                  <Dropdown
                    onChange={(remote_work_preference) => {
                      setState({ remote_work_preference });
                    }}
                    data={getDropdownData(remote_work_preference)}
                    value={state.remote_work_preference}
                  />
                </div>
              </div> */}
              <div className="editcandidate_gender_wrapper">
                <div className="editcandidate_input_heading">Source</div>
                <div className="editcandidate_input_container">
                  <Dropdown
                    onChange={(candidateState) => {
                      setState({ candidateState });
                    }}
                    data={getDropdownData(candidate_source)}
                    value={state.candidateState}
                  />
                </div>
              </div>
            </div>
            {state.candidateState?.value == 'Referral' && (
              <div className="editcandidate_location_container">
                <div className="editcandidate_gender_wrapper">
                  <div className="editcandidate_input_heading">
                    Referral Name
                  </div>
                  <div className="editcandidate_input_container">
                    <Input
                      onChange={(referral_name) => {
                        setState({ referral_name });
                      }}
                      type="text"
                      value={state.referral_name}
                      name="referral_name"
                    />
                  </div>
                </div>
                <div className="editcandidate_gender_wrapper">
                  <div className="editcandidate_input_heading">
                    Referral Email
                  </div>
                  <div className="editcandidate_input_container">
                    <Input
                      onChange={(referral_email) => {
                        setState({ referral_email });
                      }}
                      type="text"
                      value={state.referral_email}
                      name="referral_email"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="editcandidate_location_container"></div>
            <div className="editcandidate_button_container">
              <PrimaryButton
                onClick={() => editCandidate()}
                text={'Save'}
                className="editcandidate_save_button"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default EditCandidate;
