import React, { useEffect } from 'react';
import {
  Functions,
  PrimaryButton,
  Assets,
  Models,
  Loader,
} from 'utils/imports.utils';
import './job_application_landing.screen.scss';
import { useNavigate } from 'react-router-dom';
import { getUrlType, toastifyError, useQuery, useSetState } from 'utils/functions.utils';
import { getRecquisitionById } from 'utils/redux.utils';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import ReactPlayer from 'react-player'
import moment from 'moment';

const JobApplicationLanding = (props: any) => {
  const requisition = useSelector((state: any) => state.recquisition);

  const [state, setState] = useSetState({
    share: false,
    job_title: '',
    job_type: '',
    address: '',
    job_role_type: '',
    job_description: '',
    display_close_date: true,
    display_company_profile: true,
    display_hiring_manager_profile: true,
    display_recruiter_profile: true,
    display_salary: true,
    display_work_location: true,
    job_close_date: '',
    recruiter: [],
    hiring_team: [],
    header_media:'',
    salary_max:'',
    salary_min:''
  });
  // Redux

  //Hooks
  useEffect(() => {
    recquisitionById()
  }, []);

  const navigate = useNavigate();
  const query = useQuery();
  let req_id: any = query?.get('req_id');
  let source: any = query?.get('utm_source');

  // Network

  const recquisitionById = async () => {
    try {
      setState({ loading: true });
      const body = {
        external_id: req_id,
      };
      const response: any = await Models.jobRecquisition.getRecquisitionById(
        body,
      );
      await getJobPostByReq(response.data._id)
      // dispatch
      setState({
        // job_title: response.data.job_title,
        // job_type: response.data.job_type,
        // address: response.data.work_location?.address,
        // job_role_type: response.data.job_role_type,
        // job_description: response.data.job_description,
        requisitionData: response.data
      });
      setState({ loading: false });
    } catch (error) {
      toastifyError(error);
    }
  };

  const getJobPostByReq = async (id:string) => {
    try {
      const response: any = await Models.postJob.getJobPostByReq({req_id:id});
      if (response.data) {
        setState({
          job_title: response.data.requisition.job_title,
          job_type: response.data.requisition.job_type,
          address: response.data.requisition.work_location?.address,
          job_role_type: response.data.requisition.job_role_type,
          job_description: response.data.description,
          display_close_date: response.data.display_close_date,
          display_company_profile: response.data.display_company_profile,
          display_hiring_manager_profile:
            response.data.display_hiring_manager_profile,
          display_recruiter_profile: response.data.display_recruiter_profile,
          display_salary: response.data.display_salary,
          display_work_location: response.data.display_work_location,
          job_close_date: response.data.job_close_date,
          hiring_team: response.data.requisition.hiring_team,
          recruiter: response.data.requisition.recruiter,
          salary_max: response.data.requisition.salary_max,
          salary_min: response.data.requisition.salary_min,
          salary_currency: response.data.requisition.salary_currency,
        });
        if (response.data.header_media) {
          setState({ header_media: response.data.header_media });
        }
      } 
      // else {
      //   recquisitionById();
      // }
      console.log('response', response);
      // dispatch
    } catch (error) {
      recquisitionById();
    }
  };

  return (
    <div className="landing_container">
      {state.loading ? (
        <Loader />
      ) : (
        <div className="job_landing_wrapper">
          <div className="landing_header">
            <div className="landing_header_main">{state.job_title}</div>
            <div className="landing_header_sub">
              {state.job_type} | {state.display_work_location && state.address}
            </div>
            <div className="landing_header_sub">{state.job_role_type}</div>
            {state.display_close_date && (
              <div className="landing_header_sub">
                Close date :{' '}
                {moment(state.job_close_date).format('DD MMMM YYYY')}
              </div>
            )}
            {state.display_salary && (
              <div className="landing_header_sub">
                Salary Range :{' '}
                {state.salary_currency === 'USD'
                  ? `$${state.salary_min} - ${state.salary_max}`
                  : `INR ${state.salary_min} - ${state.salary_max}`}
              </div>
            )}
          </div>
          <div className="landing_wrapper">
            <div className="landing_main_container">
              {state.header_media !== '' && (
                <div className="landing_video_container">
                  {getUrlType(state.header_media) === 'image' ? (
                    <img
                      src={state.header_media}
                      height={300}
                      style={{ objectFit: 'contain' }}
                      width="100%"
                    />
                  ) : (
                    <ReactPlayer url={state.header_media} controls />
                  )}
                </div>
              )}
              <div className="landing_content1_container">
                {parse(`${state.job_description}`)}
              </div>
            </div>
            <div className="landing_sub_container">
              <div className="landing_sub_button">
                <PrimaryButton
                  text={'Apply Now'}
                  className={'top_apply_button'}
                  onClick={() =>
                    navigate(`/apply_job?req_id=${req_id}&utm_source=${source}`)
                  }
                />
              </div>
              {state.display_recruiter_profile && (
                <div className="landing_sub_wrapper">
                  <div className="landing_sub_recruiter">Recruiter</div>
                  {state.display_recruiter_profile &&
                    state.recruiter?.map((item: any) => (
                      <>
                        <div className="landing_sub_image">
                          <img
                            className="landing_recruiter_image"
                            src={
                              item.profile_picture || Assets.profile_placeholder
                            }
                          />
                        </div>
                        <div className="landing_recruiter_name">
                          {item.first_name} {item.last_name}
                        </div>
                        <div className="landing_recruiter_position">
                          {item.job_title}
                        </div>
                      </>
                    ))}
                </div>
              )}
              {state.display_hiring_manager_profile && (
                <div className="landing_sub_wrapper">
                  <div className="landing_sub_recruiter">Hiring Manager</div>
                  {state.display_hiring_manager_profile &&
                    state.hiring_team.map((item: any) => (
                      <>
                        <div className="landing_sub_image">
                          <img
                            className="landing_recruiter_image"
                            src={
                              item.profile_picture || Assets.profile_placeholder
                            }
                          />
                        </div>
                        <div className="landing_recruiter_name">
                          {item.first_name} {item.last_name}
                        </div>
                        <div className="landing_recruiter_position">
                          {item.job_title}
                        </div>
                      </>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="landing_footer">
            <PrimaryButton
              text={'Apply Now'}
              onClick={() =>
                navigate(`/apply_job?req_id=${req_id}&utm_source=${source}`)
              }
              className={'bottom_apply_button'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default JobApplicationLanding;
