import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  CandidateInfo,
  PrimaryButton,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './review_offer.screen.scss';

interface IReviewOffer {
  text?: String;
}

const ReviewOffer = (props: IReviewOffer) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};

  const candidateValue = {
    Full_name: 'John Doe',
    Position_Applied_For: 'Software Developer',
    Email: 'john123@gmail.com',
    Location: 'chennai',
    Gender: 'Male',
    Relocation: 'yes',
    Contact: '0987654321',
    Compensation: '$145,000,000',
    Resume_Source: 'Email',
  };
  return (
    <div className="review_offer_container">
      <div className="review_offer_header">
        <div className="review_offer_header_wrapper">
          <div className="review_offer_header_text">Review Offer for Acceptance</div>
        </div>
        <div className="review_offer_header_buttons">
          <PrimaryButton
            text={'Decline'}
            icon={Assets.black_close}
            className={'header_button gray_button'}
          />

          <PrimaryButton
            text={'Approve'}
            icon={Assets.big_tick}
            className={'header_button'}
          />
        </div>
      </div>
      <div className="review_offer_container1">
        <div className="review_offer_details_container">
          <div className="review_offer_subheading">Offer Details</div>
          <div className="review_offer_details_wrapper">
            <div className="review_offer_details_content">
              <div className="review_offer_details_heading">Job Title</div>
              <div className="review_offer_details_value">
                Software Developer
              </div>
            </div>
            <div className="review_offer_details_content">
              <div className="review_offer_details_heading">Job Type</div>
              <div className="review_offer_details_value">Permanant</div>
            </div>
            <div className="review_offer_details_content">
              <div className="review_offer_details_heading">Job Role Type</div>
              <div className="review_offer_details_value">Full Time</div>
            </div>
            <div className="review_offer_details_content">
              <div className="review_offer_details_heading">Salary Range</div>
              <div className="review_offer_details_value">
                $45,000 to $65,000
              </div>
            </div>
            <div className="review_offer_details_content">
              <div className="review_offer_details_heading">Job Location</div>
              <div className="review_offer_details_value">
                Software Engineer
              </div>
            </div>
            <div className="review_offer_details_content">
              <div className="review_offer_details_heading">
                Work Location Address
              </div>
              <div className="review_offer_details_value">
                14 Wall Street, Manhattan, New York City, NY, USA
              </div>
            </div>
            <div className="review_offer_details_content">
              <div className="review_offer_details_heading">Bonus Payout</div>
              <div className="review_offer_details_value">N/A</div>
            </div>
            <div className="review_offer_details_content">
              <div className="review_offer_details_heading">
                Remote Working
              </div>
              <div className="review_offer_details_value">No</div>
            </div>
            <div className="review_offer_details_content">
              <div className="review_offer_details_heading">
                Offered Salary
              </div>
              <div className="review_offer_details_value">$45,000</div>
            </div>
            <div className="review_offer_content_container">
              Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim
              neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet
              nibh. Curabitur auctor leo et libero consectetur gravida. Morbi
              gravida et sem dictum varius. Proin eget viverra sem, non euismod
              est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et
              metus nec mauris condimentum vulputate. Aenean volutpat odio quis
              egestas tempus. Fusce tempor vulputate luctus. Pellentesque
              vulputate viverra ex eget elementum. Aliquam ut feugiat felis.
              Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim
              neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet
              nibh. Curabitur auctor leo et libero consectetur gravida. Morbi
              gravida et sem dictum varius. Proin eget viverra sem, non euismod
              est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et
              metus nec mauris condimentum vulputate. Aenean volutpat odio quis
              egestas tempus. Fusce tempor vulputate luctus. Pellentesque
              vulputate viverra ex eget elementum. Aliquam ut feugiat felis.
              Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim
              neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet
              nibh. Curabitur auctor leo et libero consectetur gravida. Morbi
              gravida et sem dictum varius. Proin eget viverra sem, non euismod
              est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et
              metus nec mauris condimentum vulputate. Aenean volutpat odio quis
              egestas tempus. Fusce tempor vulputate luctus. Pellentesque
              vulputate viverra ex eget elementum. Aliquam ut feugiat felis.
            </div>
          </div>
        </div>
        <div className="review_offer_candidateinfo_container">
          <CandidateInfo
            data={candidateValue}
            className={'candidate_info_class'}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewOffer;
