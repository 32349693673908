import { testDispatch } from '../utils/redux.utils';
import instance from "../utils/axios.utils"

const test = {
  createCustomer: (data:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'customer/create_customer';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
getCustomer: (data:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'customer/get_customer';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editCustomer: (data:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'customer/edit_customer';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

}

export default test