import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  getTimeZones,
    useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  UploadComponent,
  Assets,
  PrimaryButton,
  Input,
  Dropdown,
  Loader,
} from 'utils/imports.utils';
import { getUserDetails, testDispatch } from 'utils/redux.utils';
import './profile.scss';
import { toastify, uploadFile, toastifyError } from 'utils/functions.utils';
import { timeZones } from 'utils/constant.utils';
import _ from 'lodash';

const Profile = (props: any) => {
  // Redux
  const user = useSelector((state: any) => state.user);

  // State
  const [state, setState] = useSetState({
    first_name: '',
    last_name: '',
    role: [],
    time_zone: [],
    profile_picture: '',
    job_title: '',
    timeZone: '',
    laoding: true,
  });

  //Hooks
  useEffect(() => {
    getUser();
  }, []);

  // Network req
  const uploadFiles = async (file) => {
    try {
      const content: any = await Models.auth.uploadFile({
        file_type: file.type,
        file_name: file.name,
        folder: 'profile',
      });
      uploadFile(file, content.signedRequest, content.url, function (res) {
        setState({ profile_picture: res?.url });
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getUser = async () => {
    let userId = localStorage.getItem('user_id');
    try {
      setState({ loading: true });
      const res: any = await Models.auth.viewUser(userId);
      // dispatch
      getUserDetails(res.data);
      setState({
        first_name: res.data?.first_name,
        last_name: res.data?.last_name,
        role: getDropdownData([res.data?.role]),
        time_zone: getDropdownData([res.data?.timezone]),
        profile_picture: res.data?.profile_picture,
        job_title: res.data?.job_title,
        loading: false,
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const saveUserDetails = async () => {
    try {
      if (
        (state.first_name !==''  &&
          state.last_name !==''  &&
          state.time_zone !=='' ,
        state.role !==''  && state.job_title !=='')
      ) {
        // let role: any = [];
        // state.role.map((item: any) => role.push(item.value));
        let userId = localStorage.getItem('user_id');
        let timeZone;
        state.time_zone.map((item: any) => (timeZone = item.value));
        const body = {
          user_id: userId,
          first_name: state.first_name,
          last_name: state.last_name,
          // role: role,
          timezone: timeZone,
          job_title: state.job_title,
          profile_picture: state.profile_picture,
          is_profile_completed: true,
        };
        const res: any = await Models.auth.editUser(body);
        getUserDetails(res.data);
        toastify('Profile details saved');
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const role_data = [
    { value: 'Recruiter', label: 'Recruiter' },
    { value: 'Interviewer', label: 'Interviewer' },
  ];

  if (state.loading) return <Loader />;
  return (
    <div className="profile_container">
      <div className="profile_wrapper">
        <div className="heading">Your profile</div>
        <div className="input_heading avatar_heading">Your Avatar</div>
        <div className="photo_and_button">
          <div className="upload_image">
            {/* <UploadComponent icon={Assets.camera} onClick={() => {}} /> */}
            {/* {state.profile_picture === '' ? ( */}
            <UploadComponent
              icon={Assets.camera}
              onChange={(file) => {
                setState({ file: file[0] });
                uploadFiles(file[0]);
              }}
              accept_types={'.jpg, .jpeg,.png'}
              iconOnClick={() => setState({ profile_picture: '' })}
              image={state.profile_picture}
            />
            {/* // ) : (
            //   <img className="profile_picture" src={state.profile_picture} />
            // )} */}
          </div>
          {/* <div className="upload_and_delete">
            <div className="upload">
            <PrimaryButton text={'Upload'} className={'upload_button'} />
          </div>
            <div className="delete_avatar">
              <PrimaryButton
                onClick={() => setState({ profile_picture: '' })}
                text={'Delete Avatar'}
                className={'delete_avatar_button'}
              />
            </div>
          </div> */}
        </div>
        <div className="full_name">
          <div className="first_name">
            <div className="input_heading">
              First Name
              {/* <span className="required_field">*</span> */}
            </div>
            <div className="name_input">
              <Input
                type={'text'}
                onChange={(first_name: string) => {
                  setState({ first_name });
                }}
                value={state.first_name}
                name={'first_name'}
              />
            </div>
          </div>
          <div className="last_name">
            <div className="input_heading">
              Last Name
              {/* <span className="required_field">*</span> */}
            </div>
            <div className="name_input">
              <Input
                type={'text'}
                onChange={(last_name: string) => {
                  setState({ last_name });
                }}
                value={state.last_name}
                name={'last_name'}
                className={'name_input'}
              />
            </div>
          </div>
        </div>
        <div className="info">
          {/* <div className="input_heading">Role</div>
          <div className="input_heading">
            // Role<span className="required_field">*</span>
          </div>
          <Dropdown
            data={role_data}
            isMulti
            value={state.role}
            setActive={(role: any) => setState({ role })}
            {...props}
          /> */}
          {/* <div className="input_heading">Job Title</div> */}
          <div className="input_heading">
            Job Title
            {/* <span className="required_field">*</span> */}
          </div>
          <div className="job_title_input">
            <Input
              type={'text'}
              onChange={(value: string) => {
                setState({ job_title: value });
              }}
              value={state.job_title}
              name={'job_title'}
            />
          </div>
          <div className="input_heading">
            Location & Time Zone 
            {/* <span className="required_field">*</span> */}
          </div>
          <Dropdown
            data={getTimeZones()}
            setActive={(value: any) => {
              console.log(value);
              setState({ time_zone: [value] });
            }}
            value={state.time_zone}
          />
        </div>
        <div className="buttons">
          {/* <div className="linkedin_button">
            <PrimaryButton
              text={'Connect with Linkedin'}
              className={'social_button'}
              icon={Assets.linked_in}
            />
            <div className='disconnect'>Disconnect</div>
          </div> */}
          {/* <div className="facebook_button">
            <PrimaryButton
              text={'Connect with Facebook'}
               className={'social_button'}
              icon={Assets.facebook}
            />
            <div className='disconnect'>Disconnect</div>
          </div> */}
          <div className="save_and_continue_button">
            <PrimaryButton
              text={'Save and Continue'}
              className={'save_and_continue'}
              onClick={()=>saveUserDetails()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
