import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  getTimeZones,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Navbutton,
  Assets,
  Input,
  Tag,
  Dropdown,
  PrimaryButton,
  TextArea,
} from 'utils/imports.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './notes_modal.component.scss';

const NotesModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();

  //Redux
  // State
  const [state, setState] = useSetState({
    skills: [],
    behaviours: [],
    notes: '',
  });

  //Hooks
  useEffect(() => {
    if (props.edit) {
      setState({
        notes: props.data.feedback,
      });
    }
  }, [props.edit, props.data]);

  // Network req

  useImperativeHandle(ref, () => ({
    openModal() {
      if (!props.edit) {
      }
      setState({ isModalOpen: true, notes: '' });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  //Logic

  const onSubmit = () => {
    props.onSubmit(state.notes);
    setState({ isModalOpen: false });
  };

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'notes_modal_wrapper',
        }}>
        <div className="notes_container">
          <div className="notes_wrapper">
            <div className="notes_header">
              <div className="notes_heading">Notes</div>
              <Navbutton
                icon={Assets.black_close}
                onClick={() => {
                  setState({ isModalOpen: false });
                }}
              />
            </div>
            <div className="notes_modal_notes_wrapper">
              <div className="notes_notes_container">
                <TextArea
                  onChange={(e) => setState({ notes: e.target.value })}
                  name={'notes'}
                  value={state.notes}
                  className="notes_modal_textarea"
                />
              </div>
            </div>
            <div className="notes_modal_submit_button_wrapper">
              <PrimaryButton
                text={'Submit'}
                className="notes_modal_submit_button"
                onClick={() => onSubmit()}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default NotesModal;
