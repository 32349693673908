import { GET_EMAIL_TEMPLATES } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  email_templates: [],
};

const emailTemplateReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATES:
      return {
        ...state,
        email_templates: action.payload,
      };
    default:
      return state;
  }
};

export default emailTemplateReducer;
