import React, { useRef } from 'react';
import { BarChart, LineChart } from 'utils/imports.utils';
import './candidate_status.component.scss';

interface ICandidateStatus {
  heading: string;
  heading_number: Number;
  data?: any;
  label?: any;
}
const CandidateStatus = (props: ICandidateStatus) => {
  const candidateUnattendedChartRef: any = useRef();
  // charts props
  const color = {
    color1: '#1C7BDF',
    color2: '#1C7BDF',
  };
  return (
    <div>
      <div className="candidate_card_container">
        <div className="candidate_card_header">
          <div className="candidate_card_heading">{props.heading}</div>
          <div className="candidate_card_number" style={{paddingRight:'40px'}}>{props.heading_number}</div>
        </div>
        <div className="candidate_card_line_chart">
          {/* <LineChart labels={props.label} colors={color} data={props.data} /> */}
          {props.heading_number > 0 ? (
            <BarChart
              colors={color}
              data={props.data}
              labels={props.label}
              ref={candidateUnattendedChartRef}
              saveCanvas={() =>
                candidateUnattendedChartRef?.current.saveChart()
              }
            />
          ) : (
            <div className="empty_text">No data found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateStatus;
