import _ from 'lodash';
import department from 'models/department.model';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { toastifyError, useQuery, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  Dropdown,
  Input,
  Loader,
} from 'utils/imports.utils';
import './career_page.scss';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { REQUISITION_STATUS, SOURCE } from 'utils/constant.utils';

const CareerPage = (props: any) => {
  // State
  const [state, setState] = useSetState({
    limit: 20,
    departments: [],
    departmentValue: '',
    locations: [],
    locationValue: '',
    jobTitle: '',
    requisitions: {},
    loading: false,
    focus:false
  });

  //Hooks
  useEffect(() => {
    getAllDepartments();
    getAllWorkLocations();
  }, []);

  const debounced = useDebouncedCallback((jobTitle) => {
    setState({ jobTitle });
  }, 200);
  
  let query = useQuery();
  let org = query.get('ref');

  useEffect(() => {
    // fetchRecquisitionLists();
    getJobPosts()
  }, [state.departmentValue, state.locationValue,state.jobTitle]);

  // Network req
  const getAllDepartments = async () => {
    try {
      const res: any = await Models.department.getDepartmentList({
        limit: '',
        skip: 0,
        org,
      });
      let departments: any = [];
      res.data.docs.map((item: any) =>
        departments.push({ label: item.name, value: item._id }),
      );
      setState({ departments });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getAllWorkLocations = async () => {
    try {
      const res: any = await Models.workLocation.getLocationList({
        skip: 0,
        limit: '',
        org,
      });
      let locations: any = [];
      res.data.docs.map((item: any) =>
        locations.push({ label: item.nickname, value: item._id }),
      );
      setState({ locations });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const fetchRecquisitionLists = async () => {
    try {
      setState({ loading: true });
      let body: any = {
        limit: '',
        skip: 0,
        org,
        search: state.jobTitle,
        job_title: state.jobTitle,
      };
      if (state.departmentValue?.value?.length > 0) {
        body.department = state.departmentValue.value;
      }
      if (state.locationValue?.value?.length > 0) {
        body.location = state.locationValue.value;
      }
      if (state.jobTitle.length > 0) {
        body.search = state.jobTitle;
      }
      const res: any = await Models.jobRecquisition.getRecquisitionList(body);
      let reqData = res.data;
      let requisitions: any = {};
      if (res.data.length > 0) {
        res.data.map((item: any) => {
          if (item.department) {
            let filterdata = _.filter(res.data, {
              department: { _id: item.department?._id },
            });
            requisitions[item.department.name] = {
              name: item.department.name,
              reqs: filterdata,
            };
          } else {
            let filterdata = _.filter(res.data, function (item) {
              return !item.department;
            });
            requisitions['Others'] = { name: 'Others', reqs: filterdata };
          }
        });
      }
      setState({ loading: false, requisitions });
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const getJobPosts = async () => {
    try {
      setState({ loading: true });
      let body: any = {
        org,
        search: state.jobTitle,
        job_title: state.jobTitle,
      };
      if (state.departmentValue?.value?.length > 0) {
        body.department = state.departmentValue.value;
      }
      if (state.locationValue?.value?.length > 0) {
        body.location = state.locationValue.value;
      }
      if (state.jobTitle.length > 0) {
        body.search = state.jobTitle;
      }
      const res: any = await Models.postJob.getManyJobPosts(body);
      let requisitions: any = {};
      if (res.data.length > 0) {
        res.data.map((item: any) => {
          if (item.department) {
            let filterdata = _.filter(res.data, {
              department: { _id: item.department?._id },
            });
            requisitions[item.department.name] = {
              name: item.department.name,
              reqs: filterdata,
            };
          } else {
            let filterdata = _.filter(res.data, function (item) {
              return !item.department;
            });
            requisitions['Others'] = { name: 'Others', reqs: filterdata };
          }
        });
      }
      setState({ loading: false, requisitions });
     console.log('jobPosts', res);
    } catch (error: any) {
      toastifyError(error);
    }
  };

  return (
    <div className="career_page_container">
      <div className="career_page_wrapper">
        {/* <div className="career_header_container">
          <div className="career_header_icon">
            <img src={Assets.logo} height="50px" width="50px" />
          </div>
          <div className="career_header_text">ASKHUGO</div>
        </div> */}
        <div className="career_subheading">Open Position</div>
        <div className="career_dropdown_container">
          <div className="career_dropdown_wrapper">
            <div className="career_dropdown_heading">Departments</div>
            <Dropdown
              clear
              setActive={(value) => setState({ departmentValue: value })}
              data={state.departments}
              value={state.departmentValue}
            />
          </div>
          <div className="career_dropdown_wrapper">
            <div className="career_dropdown_heading">Location</div>
            <Dropdown
              clear
              setActive={(value) => setState({ locationValue: value })}
              data={state.locations}
              value={state.locationValue}
            />
          </div>
          <div className="career_dropdown_wrapper">
            <div className="career_dropdown_heading">Search</div>
            <Input
              type={'text'}
              onChange={(jobTitle: string) => {
                debounced(jobTitle)
              }}
              name={'job_title'}
            />
          </div>
        </div>
        <div className="career_body_container">
          {state.loading ? (
            <Loader />
          ) : (
            Object.values(state.requisitions)
              .reverse()
              .map((item: any) => (
                <div className="career_body_wrapper">
                  <div className="career_body_heading">{item?.name}</div>
                  {item.reqs.map((req: any) => (
                    <div className="career_key_and_value_container">
                      <div
                        className="career_key_text"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_CAREER_SITE}/job_application_page?req_id=${req.external_id}&utm_source=${SOURCE.CAREER_SITE}`,
                          )
                        }>
                        {req.job_title}
                      </div>
                      <div className="career_value_text">
                        {req.work_location?.nickname}
                      </div>
                    </div>
                  ))}
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CareerPage;
