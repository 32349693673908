import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CANDIDATE_STATUS,
  candidate_status,
  REQUISITION_STATUS,
} from 'utils/constant.utils';
import { toastifyError, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  Dropdown,
  PrimaryButton,
  CheckBox,
  ViewResumeModal,
} from 'utils/imports.utils';
import { getRecquisitionList, testDispatch } from 'utils/redux.utils';
import './resumelist.component.scss';

const ResumeList = (props: any) => {
  const modalRef: any = useRef();
  // Redux

  // State
  const [state, setState] = useSetState({ req_list: [], req_value: {} });

  //Hooks
  useEffect(() => {
  }, []);

  const navigate = useNavigate();

  // Network req
  

  const createCandidate = async (new_requisition: string) => {
    try {
      const body = {
        resume: props.data._id,
        requisition: new_requisition,
        stage: CANDIDATE_STATUS.APPLIED,
      };
      const res: any = await Models.candidateModel.createCandidate(body);
      // navigate(`/requisition?id=${new_requisition}`);
      // dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //Logic

  return (
    <div className="resumelist_container">
      <div className="resumelist_checkbox_image">
        <div className="resumelist_checkbox" onClick={props.onClick}>
          <CheckBox checked={props.checked} />
        </div>
        <div
          className="resumelist_profile_image"
          onClick={() => navigate(`/candidate?resume_id=${props.data._id}&&req_id=${props.data?.last_requisition?._id}`)}>
          <img
            src={props?.profile || Assets.profile_placeholder}
            className="resume_list_profile_image"
            alt="profile picture"
          />
        </div>
      </div>
      <div className="resumelist_wrapper">
        <div className="resumelist_info">
          <div
            className="resumelist_name_container"
            onClick={() => navigate(`/candidate?resume_id=${props.data._id}&&req_id=${props.data?.last_requisition?._id}`)}>
            <div className="resumelist_heading text_spacing">Full Name</div>
            <div className="resumelist_value text_spacing">{`${props.data?.first_name} ${props.data?.last_name}`}</div>
          </div>
          <div className="resumelist_gender_container">
            <div className="resumelist_heading text_spacing">Gender</div>
            <div className="resumelist_value text_spacing">
              {props.data?.gender}
            </div>
          </div>
          <div className="resumelist_location_container">
            <div className="resumelist_heading text_spacing">Location</div>
            <div className="resumelist_value text_spacing">
              {props.data?.city}
            </div>
          </div>
          <div className="resumelist_dropdown_container">
            <div
              className="resumelist_heading text_spacing"
              style={{ paddingBottom: '5px' }}>
              Tag job requisition
            </div>
            <Dropdown
              data={props.requisitions}
              value={state.req_value}
              setActive={(req_value: any) => {
                setState({ req_value });
                createCandidate(req_value?.value);
              }}
            />
          </div>
        </div>
        {/* <div className="resumelist_add_button_container">
          <a
            href={props.data.resume}
            target="_blank"
            className="resume_search_download_btn"
            download>
            <div className="download_icon_wrapper">
              <img
                src={Assets.download}
                className="resume_download_icon"
                alt={'back icon'}
              />
            </div>
            <div className="download_text">Download</div>
          </a>
          <PrimaryButton
            text={'View Resume'}
            className={'add_button'}
            onClick={() => modalRef?.current?.openModal()}
          />
        </div> */}
        <div className="resumelist_footer_container">
          <div>
            <div className="resumelist_heading text_spacing">
              Last Position Applied For
            </div>
            <div className="resumelist_value text_spacing">
              {props.data?.last_requisition?.job_title}
            </div>
          </div>
          <div className="resumelist_add_button_container">
            <a
              href={props.data.resume}
              target="_blank"
              className="resume_search_download_btn"
              download>
              <div className="download_icon_wrapper">
                <img
                  src={Assets.download}
                  className="resume_download_icon"
                  alt={'back icon'}
                />
              </div>
              <div className="download_text">Download</div>
            </a>
            <PrimaryButton
              text={'View Resume'}
              className={'add_button'}
              onClick={() => modalRef?.current?.openModal()}
            />
          </div>
        </div>
        <ViewResumeModal src={props.data?.resume} ref={modalRef} />
      </div>
    </div>
  );
};

export default ResumeList;
