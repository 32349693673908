import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toastify, toastifyError, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  PrimaryButton,
  Assets,
} from 'utils/imports.utils';
import { setAppRoute, testDispatch } from 'utils/redux.utils';
import './otp.screen.scss';
import OTPInput from 'otp-input-react';
import { useNavigate } from 'react-router-dom';

const OTP = (props: any) => {
  // Redux
  const appState = useSelector((state: any) => state.app_state);
  // State
  const [state, setState] = useSetState({ otp: '' });

  //Hooks
  useEffect(() => {}, []);
  const navigate = useNavigate();

  // Network req
  const verifyOtp = async () => {
    try {
      const body = {
        otp: state.otp,
        email: appState?.email,
      };
      const res: any = await Models.auth.verifyotp(body);
      setAppRoute({ reset_password_hash: res?.data?.reset_password_hash });
      navigate('/reset_password');
      console.log(res);
      //dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const sendOtp = async () => {
    try {
      const body = { email: appState?.email };
      const res: any = await Models.auth.sendOtp(body);
      console.log(res);
      toastify('OTp has been sent to your email id');
      //dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className="otp_container">
      <div className="otp_wrapper">
        <div className="otp_container_logo">
          <img src={Assets.logo} height={100} width={100} alt="sidebar logo" className="sidebar_logo" />
        </div>
        <div className="otp_heading">Verify Your Code</div>
        <div className="sub_heading">
          Please enter the OTP sent to your email ID
        </div>
        <div className="inputs">
          <div className="input_box">
            <OTPInput
              value={state.otp}
              onChange={(otp: any) => setState({ otp })}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              inputClassName="otp_input"
              className="otp_input_wrapper"
            />
          </div>
        </div>
        <div className="verify">
          <PrimaryButton
            text={'Verify'}
            onClick={verifyOtp}
            className={'button'}
          />
        </div>
        <div className="resend_code" onClick={sendOtp}>
          Resend Code
        </div>
      </div>
    </div>
  );
};

export default OTP;
