import _ from 'lodash';
import { Assets } from './imports.utils';

export const sampleConstant = 'sampleConstant';

export const timeZones = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay',
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg',
];

export const req_status = [
  'On hold',
  'Open',
  'Cancelled',
  'Filled',
  // 'Shortlist',
  // 'Offer accepted',
];

export const skills = [
  'Computer Programming and Coding',
  'Object-Oriented Design',
  'Software Testing and Debugging',
];

export const candidate_status = [
  'Applied',
  'Longlist',
  'Shortlist',
  'Request for phone screening',
  'Confirm phone screening',
  'Screening',
  'Request for interview availability',
  'Confirm interview availability',
  'Interviewing',
  'Offered',
  'Offer Accepted',
  'Offer Declined',
  'Offer Withdrawn',
  'No Show',
  'Withdrawn',
  'Joined',
  'Reject',
  'Candidate Withdrawn',
  'Screen Reject',
  'Interview Reject',
  'On hold',
  'Submitted',
  'To be Submitted',
  // 'Offered',
];

export const candidate_stage = [
  'Applied',
  'Screening',
  'Interviewing',
  'Offered',
  'Joined',
  'Reject',
  'Submitted'
];

export const agency_candidate_stage = [
  'Applied',
  'Screening',
  'Interviewing',
  'Offered',
  'Joined',
  'Reject',
  'Submitted'
];

export const job_types = [
  'Permanent',
  'Contract',
  'Contract To Hire',
  'Fixed Term',
  'Internship',
];
export const job_role_type = ['Full Time', 'Part Time', 'Seasonal'];

export const job_location = ['Onsite', 'Offsite'];
export const headcount_type = ['New', ' Replacement'];
export const gender = ['Male', 'Female', 'Undisclosed'];
export const currency = ['AUD', 'BDT', 'CAD', 'CHF', 'CNY', 'EGP', 'EUR', 'GBP', 'HKD', 'INR', 'JOD', 'JPY', 'KWD', 'MXN', 'MYR', 'NGN', 'NPR', 'NZD', 'PHP', 'RUB', 'SAR', 'SGD', 'SEK', 'TRY', 'USD', 'ZAR']
// [
//   {label: 'United States - USD', value: 'USD'},
//   {label: 'Eurozone - EUR', value: 'EUR'},
//   {label: 'Japan - JPY', value: 'JPY'},
//   {label: 'United Kingdom - GBP', value: 'GBP'},
//   {label: 'Australia - AUD', value: 'AUD'},
//   {label: 'Canada - CAD', value: 'CAD'},
//   {label: 'Switzerland - CHF', value: 'CHF'},
//   {label: 'China - CNY', value: 'CNY'},
//   {label: 'Sweden - SEK', value: 'SEK'},
//   {label: 'New Zealand - NZD', value: 'NZD'},
//   {label: 'India - INR', value: 'INR'},
//   {label: 'Brazil - BRL', value: 'BRL'},
//   {label: 'Russia - RUB', value: 'RUB'},
//   {label: 'South Africa - ZAR', value: 'ZAR'},
//   {label: 'Norway - NOK', value: 'NOK'},
//   {label: 'Singapore - SGD', value: 'SGD'},
//   {label: 'Turkey - TRY', value: 'TRY'},
//   {label: 'Mexico - MXN', value: 'MXN'},
//   {label: 'South Korea - KRW', value: 'KRW'},
//   {label: 'Hong Kong - HKD', value: 'HKD'},
//   {label: 'Egypt - EGP', value: 'EGP'},
//   {label: 'Bangladesh - BDT', value: 'BDT'},
//   {label: 'Saudi Arabia - SAR', value: 'SAR'},
//   {label: 'Nigeria - NGN', value: 'NGN'},
//   {label: 'Jordan - JOD', value: 'JOD'},
//   {label: 'Kuwait - KWD', value: 'KWD'},
//   {label: 'Nepal - NPR', value: 'NPR'}
// ];
export const user_role = [
  { label: 'Admin', value: 'admin' },
  { label: 'User', value: 'user' },
];

export const notice_period = [
  { label: 'Serving Notice', value: 10 },
  { label: 'Immediate', value: 0 },
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
  { label: '90 days', value: 90 },
];

export const willing_to_relocate = ['Yes', 'No'];
export const remote_work_preference = [
  'Work from home',
  'Work from office',
  'Hybrid',
];
export const education_level = ['Bachelor', 'Master'];
export const reasons_for_job_change = [
  'No career progression',
  'No learning opportunity',
  'Better Salary',
  'Too far to commute',
  'Organisation restructure',
  'Toxic culture',
];
export const behaviorData = [
  'Energy',
  'Adaptability',
  'Integrity',
  'Tactical behaviour,',
  'Responding sensitively',
  'Loyalty',
  'Judgement,',
  'Presenting',
  'Interviewing',
  'Networking',
  'Vision',
  'Communication skills (verbal)',
  'Communication skills (written)',
  'Group-oriented leadershi',
  'Customer-oriented',
  'Acting innovatively',
  'Delegating',
  'Problem analysis',
  'People-driven management',
  'Independenc',
  'Results-oriented way of working	Sociability',
  'Listening',
  'Perseverance',
  'Determination',
  'Courag',
  'Situational awareness',
  'Attention to detail',
  'Understanding of the environment',
  'Cooperation',
  'Independent learning',
  'Self-knowledge',
  'Entrepreneurship',
  'Leadership',
  'Ability to lear',
  'Responding flexibly',
  'Financial awareness',
  'Persuasivenes',
  'Stress resistance',
  'Showing initiative',
  'Assertivenes',
  'Discipline',
  'Conceptual thinking',
  'Negotiatin',
  'Organisational sensitivity',
  'Confronting',
  'Flexibility',
  'Creative thinking',
  'Planning and organising',
  'Monitoring progress',
  'Critical thinking',
  'Impression',
  'Self-development',
  'Organising',
  'Accuracy',
  'Anticipating',
  'Coaching',
  'Analytical skills',
  'Decisivenes',
  'Ambition',
  'Managing conflic',
];
export const interview_rounds = [
  'Round 1',
  'Round 2',
  'Round 3',
  'Round 4',
  'Round 5',
];
export const overall_feedback = ['On hold','Shortlist', 'Reject'];
export enum CANDIDATE_STATUS {
  APPLIED = 'Applied',
  REQUEST_FOR_SCREENING_AVAILABILITY = 'Request for phone screening',
  CONFIRM_SCREENING_AVAILABILITY = 'Confirm phone screening',
  SCREENING = 'Screening',
  LONGLIST = 'Longlist',
  SHORTLIST = 'Shortlist',
  REQUEST_FOR_INTERVIEW_AVAILABILITY = 'Request for interview availability',
  CONFIRM_INTERVIEW_AVAILABILITY = 'Confirm interview availability',
  OFFERED = 'Offered',
  OFFER_ACCEPTED = 'Offer Accepted',
  OFFER_DECLINED = 'Offer Declined',
  OFFER_WITHDRAWN = 'Offer Withdrawn',
  INTERVIEWING = 'Interviewing',
  NO_SHOW = 'No show',
  WITHDRAWN = 'Withdrawn',
  JOINED = 'Joined',
  REJECT = "Reject",
  CANDIDATE_DECLINED = "Candidate declined",
  INTERVIEW_REJECT = "Interview Reject",
  SCREEN_REJECT = "Screen Reject",
  ON_HOLD = 'On hold',
  SUBMITTED = 'Submitted',
  TO_BE_SUBMITTED = 'To be Submitted',
  }

export enum REQUISITION_STATUS {
  OPEN = 'Open',
  ON_HOLD = 'On hold',
  CANCELLED = 'Cancelled',
  FILLED = 'Filled',
  DRAFT = 'Draft',
  LOST='Lost'
}

export enum MapTypeId {
  Roadmap = 'roadmap',
  Satellite = 'satellite',
  Hybrid = 'hybrid',
  Terrain = 'terrain',
}

export enum InterviewMedium {
  IN_PERSON = 'In person',
  PHONE = 'Phone',
  VIDEO = 'Video call',
}

export enum FeedbackStatus {
  CANDIDATE = 'candidate',
  INTERVIEW = 'interview',
}

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
  IS_JOINED = 'Joined',
  SUPER_ADMIN='super_admin',
}

export enum InterviewStatus {
  ALL = 'All',
  TODAY = 'Today',
  UPCOMING = 'Upcoming',
  COMPLETED = 'Completed',
}

export enum DropdownStatus {
  REMOVE_VALUE = 'remove-value',
}

export enum NetworkStatus {
  FAILED = 'failed',
  SUCCESS = 'success',
}

export const interview_filter = [
  { label: 'All', value: 'all' },
  { label: 'Today', value: 'today' },
  { label: 'Upcoming', value: 'upcoming' },
  // { label: 'Completed', value: 'completed' },
];

export const candidate_filter = [
  { label: 'All', value: 'all' },
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
];

export enum SOURCE {
  FACEBOOK = 'Facebook',
  LINKEDIN = 'LinkedIn',
  COPY_LINK = 'Copy Link',
  REFERRAL = 'Referral',
  CAREER_SITE = 'Career site',
  AGENCY = 'Agency',
}

export enum TYPE {
  FEEDBACK = 'feedback',
  NOTE = 'note',
}

export enum RESUME_PARSER {
  DEFAULT = 'default',
  AFFINDA = 'affinda',
}

export const merge_fields = [
  'first_name',
  'last_name',
  'email',
  'recruiter',
  'job_title',
  'interview_start_time',
  'interview_end_time',
  'interview_date',
  'interview_meeting_link',
  'interview_in_person',
  'interview_phone_number',
  'company_name'
];

export const feminine_coded_words = [
  'agree',
  'affectionate',
  'child',
  'cheer',
  'collab',
  'commit',
  'communal',
  'compassion',
  'connect',
  'considerate',
  'cooperat',
  'co-operat',
  'depend',
  'emotiona',
  'empath',
  'feel',
  'flatterable',
  'gentle',
  'honest',
  'interpersonal',
  'interdependen',
  'interpersona',
  'inter-personal',
  'inter-dependen',
  'inter-persona',
  'kind',
  'kinship',
  'loyal',
  'modesty',
  'nag',
  'nurtur',
  'pleasant',
  'polite',
  'quiet',
  'respon',
  'sensitiv',
  'submissive',
  'support',
  'sympath',
  'tender',
  'together',
  'trust',
  'understand',
  'warm',
  'whin',
  'enthusias',
  'inclusive',
  'yield',
  'share',
  'sharin',
];

export const masculine_coded_words = [
  'active',
  'adventurous',
  'aggress',
  'ambitio',
  'analy',
  'assert',
  'athlet',
  'autonom',
  'battle',
  'boast',
  'challeng',
  'champion',
  'compet',
  'confident',
  'courag',
  'decid',
  'decision',
  'decisive',
  'defend',
  'determin',
  'domina',
  'dominant',
  'driven',
  'fearless',
  'fight',
  'force',
  'greedy',
  'head-strong',
  'headstrong',
  'hierarch',
  'hostil',
  'impulsive',
  'independen',
  'individual',
  'intellect',
  'lead',
  'logic',
  'objective',
  'opinion',
  'outspoken',
  'persist',
  'principle',
  'reckless',
  'self-confiden',
  'self-relian',
  'self-sufficien',
  'selfconfiden',
  'selfrelian',
  'selfsufficien',
  'stubborn',
  'superior',
  'unreasonab',
];

export const hyphenated_coded_words = [
  'co-operat',
  'inter-personal',
  'inter-dependen',
  'inter-persona',
  'self-confiden',
  'self-relian',
  'self-sufficien',
];

export const possible_codings = [
  'strongly feminine-coded',
  'feminine-coded',
  'neutral',
  'masculine-coded',
  'strongly masculine-coded',
];

export const candidate_source = [
  'Naukri',
  'LinkedIn',
  'Job boards',
  'Social Networks',
  'Referral',
  'Others',
  'Copy Link',
  'Agency',
  'Facebook',
  'Career site'
];

export const explanations = {
  'strongly feminine-coded':
  "The description you have written is biased towards attracting male candidates. You will have to tweak your job description to be able to attract a diverse talent pool. For that, you need to remove or modify the below words",
    // 'This job ad uses more words that are subtly coded as feminine than words that are subtly coded as masculine (according to the research). Fortunately, the research suggests this will have only a slight effect on how appealing the job is to men, and will encourage women applicants.',
  'feminine-coded': "The description you have written is biased towards attracting male candidates. You will have to tweak your job description to be able to attract a diverse talent pool. For that, you need to remove or modify the below words",
    // 'This job ad uses more words that are subtly coded as feminine than words that are subtly coded as masculine (according to the research). Fortunately, the research suggests this will have only a slight effect on how appealing the job is to men, and will encourage women applicants.',
  neutral: "Great Going!!. Your job description is well written to attract a diverse talent pool. Awesome job!!",
    // "This job ad uses an equal number of words that are subtly coded as masculine and feminine (according to the research). It probably won't be off-putting to men or women applicants.",
  'masculine-coded':"The description you have written is biased towards attracting male candidates. You will have to tweak your job description to be able to attract a diverse talent pool. For that, you need to remove or modify the below words",
    // 'This job ad uses more words that are subtly coded as masculine than words that are subtly coded as feminine (according to the research). It risks putting women off applying, but will probably encourage men to apply.',
  'strongly masculine-coded': "The description you have written is biased towards attracting male candidates. You will have to tweak your job description to be able to attract a diverse talent pool. For that, you need to remove or modify the below words",
    // 'This job ad uses more words that are subtly coded as masculine than words that are subtly coded as feminine (according to the research). It risks putting women off applying, but will probably encourage men to apply.',
  empty:
    "This job ad doesn't use any words that are subtly coded as masculine or feminine (according to the research). It probably won't be off-putting to men or women applicants.",
};

export const admin_settings_data = [
  {
    name: 'Your Profile',
    route: '/settings/profile',
    inactive_icon: Assets.profile,
    active_icon: Assets.profile_active,
  },
  {
    name: 'Organization',
    route: '/settings/organization',
    inactive_icon: Assets.org_inactive,
    active_icon: Assets.org_active,
  },
  {
    name: 'Change Password',
    route: '/settings/change_password',
    inactive_icon: Assets.password,
    active_icon: Assets.password_active,
  },
  {
    name: 'Manage Users',
    route: '/settings/manage_users',
    inactive_icon: Assets.manageusers,
    active_icon: Assets.manageusers_active,
  },
  {
    name: 'Email Template',
    route: '/settings/email_template',
    inactive_icon: Assets.email,
    active_icon: Assets.email_active,
  },
  // {
  //   name: 'Payment Settings',
  //   route: '/settings/payment_settings',
  //   inactive_icon: Assets.payment_inactive,
  //   active_icon: Assets.payment_active,
  // },
  {
    name: 'Work Locations',
    route: '/settings/work_locations',
    inactive_icon: Assets.map,
    active_icon: Assets.map_active,
  },
  {
    name: 'Departments',
    route: '/settings/departments',
    inactive_icon: Assets.department,
    active_icon: Assets.department_active,
  },
  {
    name: 'Business Unit',
    route: '/settings/business_unit',
    inactive_icon: Assets.business_unit,
    active_icon: Assets.business_unit_active,
  },
  {
    name: 'Staffing Partners',
    route: '/settings/agencies',
    inactive_icon: Assets.buildings,
    active_icon: Assets.buildings_active,
  },
  {
    name: 'Integrations',
    route: '/settings/integrations',
    inactive_icon: Assets.integration,
    active_icon: Assets.integration_active,
  },
  {
    name: 'Career Site',
    route: '/settings/career',
    inactive_icon: Assets.career_active,
    active_icon: Assets.career_inactive,
  },
];

export const agency_settings = [
  {
    name: 'Your Profile',
    route: '/settings/profile',
    inactive_icon: Assets.profile,
    active_icon: Assets.profile_active,
  },
  {
    name: 'Change Password',
    route: '/settings/change_password',
    inactive_icon: Assets.password,
    active_icon: Assets.password_active,
  },
  {
    name: 'Manage Users',
    route: '/settings/manage_users',
    inactive_icon: Assets.manageusers,
    active_icon: Assets.manageusers_active,
  },
  {
    name: 'Email Template',
    route: '/settings/email_template',
    inactive_icon: Assets.email,
    active_icon: Assets.email_active,
  },
  // {
  //   name: 'Payment Settings',
  //   route: '/settings/payment_settings',
  //   inactive_icon: Assets.payment_inactive,
  //   active_icon: Assets.payment_active,
  // },
  {
    name: 'Work Locations',
    route: '/settings/work_locations',
    inactive_icon: Assets.map,
    active_icon: Assets.map_active,
  },

  {
    name: 'Departments',
    route: '/settings/departments',
    inactive_icon: Assets.department,
    active_icon: Assets.department_active,
  },
  {
    name: 'Business Unit',
    route: '/settings/business_unit',
    inactive_icon: Assets.business_unit,
    active_icon: Assets.business_unit_active,
  },
  {
    name: 'Integrations',
    route: '/settings/integrations',
    inactive_icon: Assets.integration,
    active_icon: Assets.integration_active,
  },
  {
    name: 'Career Site',
    route: '/settings/career',
    inactive_icon: Assets.career_active,
    active_icon: Assets.career_inactive,
  },
  {
    name: 'Client',
    route: '/settings/client',
    inactive_icon: Assets.client_inactive,
    active_icon: Assets.client_active,
  },
  {
    name: 'Payment Terms',
    route: '/settings/payment_terms',
    inactive_icon: Assets.payment_terms_inactive,
    active_icon: Assets.payment_terms_active,
  },
];


export const admin_home_data = [
  {
    name: 'Home',
    route: '/home',
    inactive_icon: Assets.house,
    active_icon: Assets.house_active,
  },
  {
    name: 'Requisitions',
    route: '/requisition_board',
    inactive_icon: Assets.requisition,
    active_icon: Assets.requisition_active,
  },
  {
    name: 'Your Task',
    route: '/your_task',
    inactive_icon: Assets.recruitment,
    active_icon: Assets.recruitment_active,
  },
  {
    name: 'Resume Search',
    route: '/resume_search',
    inactive_icon: Assets.search_inactive,
    active_icon: Assets.search_active,
  },
  {
    name: 'Settings',
    route: '/settings/profile',
    inactive_icon: Assets.settings_inactive,
    active_icon: Assets.settings,
  },
];

export const user_settings_data = [
  {
    name: 'Your Profile',
    route: '/settings/profile',
    inactive_icon: Assets.profile,
    active_icon: Assets.profile_active,
  },
  {
    name: 'Change Password',
    route: '/settings/change_password',
    inactive_icon: Assets.password,
    active_icon: Assets.password_active,
  },
  {
    name: 'Integrations',
    route: '/settings/integrations',
    inactive_icon: Assets.integration,
    active_icon: Assets.integration_active,
  },
  {
    name: 'Career Site',
    route: '/settings/career',
    inactive_icon: Assets.career_active,
    active_icon: Assets.career_inactive,
  },
];

export const agency_home_data = [
  
  {
    name: 'Requisitions',
    route: '/requisition_board',
    inactive_icon: Assets.requisition,
    active_icon: Assets.requisition_active,
  }
];
export const super_admin_data=[
  {
    name: 'Clients',
    route: '/client_org',
    inactive_icon: Assets.client_inactive,
    active_icon: Assets.client_active,
  },
]

export const getNoticePeriod = (value: any) => {
  let index = _.findIndex(notice_period, { value });
  return notice_period[index];
};

export enum JOB_SCORE_STATUS {
  HIGH_MATCH = 'High Match',
  AVERAGE_MATCH = 'Average Match',
  LOW_MATCH = 'Low Match',
}
export enum CALENDAR {
  GOOGLE = 'Google',
  OUTLOOK = 'Outlook',
}
export enum JOB_CHANNELS {
  LINKEDIN = 'Linkedin',
  INDEED = 'Indeed',
  COMPANY_SITE = 'Company site',
}

export const email_templates = [
  {
    name: 'Recruiter Screen',
    subject: 'Your availability for recruiter screening',
    body: '<p>Hi {{first_name}},</p><p>Thanks for applying for the {{job_title}} role with us. Please let us know your availability for a quick</p><p>screening conversation.</p><p>Regards,</p><p>{{Recruiter}}</p>',
  },
  {
    name: 'Request for interview availability',
    subject: 'Your availability for a video interview',
    body: '<p>HI {{first_name}},</p><p>I am glad to let you know that you have been shortlisted for a technical interview for the {{job_title}}</p><p>role.We would like to meet you on {{Int_date}} at {{int_time}} IST at our office in {{office_loc}}</p><p>Please let me know your availability for an hour&amp;#39;s conversation.</p><p>Regards,</p><p>{{Recruiter}}</p>',
  },
  {
    name: 'Round 1 Interview confirmation',
    subject: 'Your interview confirmation with AskHugo',
    body: '<p>HI {{first_name}},</p><p>We would like to confirm your video interview for the {{job_title}} role with us.</p><p>Below are the details</p><p>Date: {{interview_date}}</p><p>Time: {{interview_start_time}}</p><p>Interviewers:</p><p>Video link:</p><p>Cheers,</p><p>{{Recruiter}}</p>',
  },
];

export enum REQ_APPROVAL {
  SEQUENTIAL = 'Sequential',
  PARALLEL = 'Parallel',
}


export enum OFFER_TEMPLATE{
  BILLING = 'Billing',
  PLAN = 'Plan',
  INVOICE_SETTING='Invoice Setting'
}

export enum PRODUCT_STRIPE{
  ORG='Org',
  AGENCIES='Agencies'
}

export enum SUBSCRIPTION_PLAN {
  PREMIUM='premium',
  BASIC='basic',
  ENTERPRISE='enterprise',
  SUBSCRIPTION='Subscription',
  TRIAL='trial'
}

export const subscriptionPlan = ['premium','trial','enterprise'];

export enum  SUBSCRIPTION_STATUS{
  PROCESSING='Processing',
  CREATED='Created',
  CANCELLED='Cancelled'
}

export const subscriptionFeatures = [
  // 'BulkUploadResume',
  // 'SingleUploadResume',
  'Staffing partner',
  'CandiateScore',
  'Equest',
  'Chatgpt post job description'
];

export const enterprise_plan = [];

export enum SUBSCRIPTION_FEATURES {
  // BULKUPLOADRESUME = 'BulkUploadResume',
  // SINGLEUPLOADRESUME = 'SingleUploadResume',
  CANDIDATESCORE = 'CandiateScore',
  EQUEST = 'Equest',
  CHATGPT_DESC = 'Chatgpt post job description',
  STAFFING_PARTNER=`Staffing partner`

}

export const REVENUES = {
  'Revenue Actual': [
    { wch: 10 },
    { wch: 20 },
    { wch: 12 },
    { wch: 20 },
    { wch: 12 },
    { wch: 20 },
    { wch: 18 },
    { wch: 18 },
  ],
  'Revenue Potential': [
    { wch: 10 },
    { wch: 20 },
    { wch: 12 },
    { wch: 20 },
    { wch: 12 },
    { wch: 20 },
    { wch: 18 },
    { wch: 18 },
  ],
  'Revenue Pipeline': [
    { wch: 10 },
    { wch: 20 },
    { wch: 12 },
    { wch: 20 },
    { wch: 12 },
    { wch: 20 },
    { wch: 11 },
    { wch: 11 },
    { wch: 18 },
  ],
};
