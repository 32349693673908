import testPic from 'assets/images/dp.jpeg';
import vector from 'assets/icons/vector.svg';
import facebook from 'assets/icons/facebook.svg';
import eye from 'assets/icons/eye.svg';
import camera from 'assets/icons/camera.svg';
import arrow from 'assets/icons/Path.svg';
import tick from 'assets/icons/tick.svg';
import bell from 'assets/icons/bellicon.svg';
import eye_slash from 'assets/icons/eye_slash.svg';
import download from 'assets/icons/download.svg';
import upload from 'assets/icons/upload.svg';
import send from 'assets/icons/send.svg';
import trash from 'assets/icons/trash.svg';
import edit from 'assets/icons/edit.svg';
import search from 'assets/icons/Search.svg';
import plus_with_box from 'assets/icons/plus_with_box.svg';
import settings from 'assets/icons/settings.svg';
import plus from 'assets/icons/plus.svg';
import save from 'assets/icons/save.svg';
import group from 'assets/icons/group.svg';
import add from 'assets/icons/add.svg';
import toggle from 'assets/icons/toggle.svg';
import logo from 'assets/images/logo.png';
import profile from 'assets/icons/Profile.svg';
import profile_active from 'assets/icons/Profile_active.svg';
import manageusers from 'assets/icons/UserPlus.svg';
import manageusers_active from 'assets/icons/UserPlus_active.svg';
import password from 'assets/icons/Password.svg';
import password_active from 'assets/icons/Password_active.svg';
import email from 'assets/icons/EnvelopeSimple.svg';
import email_active from 'assets/icons/EnvelopeSimple_active.svg';
import offer from 'assets/icons/offer.svg';
import offer_active from 'assets/icons/offer_active.svg';
import map from 'assets/icons/map.svg';
import map_active from 'assets/icons/map_active.svg';
import department from 'assets/icons/dept.svg';
import department_active from 'assets/icons/dept_active.svg';
import job from 'assets/icons/job.svg';
import buildings from 'assets/icons/buildings.svg';
import buildings_active from 'assets/icons/buildings_active.svg';
import close from 'assets/icons/close.svg';
import attachment from 'assets/icons/attachment.svg';
import black_close from 'assets/icons/black_close.svg';
import profile_placeholder from 'assets/icons/user_placeholder.svg';
import mail from 'assets/images/mail.svg';
import uploadimg from 'assets/images/upload img.jpg';
import addCertification from 'assets/images/addCertification.png';
import loader from 'assets/videos/loader.gif';
import uploadImage from 'assets/images/upload.svg';
import back from 'assets/icons/back.svg';
import search_white from 'assets/icons/search_white.svg';
import unpublish from 'assets/icons/Unpublish.svg';
import agency_invite from 'assets/icons/agency.svg';
import agency_invite_inactive from 'assets/icons/agency_inactive.svg';
import post_job from 'assets/icons/Post_job.svg';
import share from 'assets/icons/share.svg';
import check_icon from 'assets/icons/check_icon.svg';
import requisition from 'assets/icons/requisition.svg';
import requisition_active from 'assets/icons/requisition_active.svg';
import create from 'assets/icons/create.svg';
import create_active from 'assets/icons/create_active.svg';
import house from 'assets/icons/house.svg';
import house_active from 'assets/icons/house_active.svg';
import plus_black from 'assets/icons/plus_black.svg';
import add_resume from 'assets/images/add_resume.svg';
import right from 'assets/icons/right.svg';
import left from 'assets/icons/left.svg';
import add_green from 'assets/icons/add_green.svg';
import big_tick from 'assets/icons/big_tick.svg';
import search_active from 'assets/icons/search_active.svg';
import search_inactive from 'assets/icons/search-inactive.svg';
import hiring_card from 'assets/images/hiring_card.svg';
import logout from 'assets/icons/logout.svg';
import full_screen from 'assets/icons/full_screen.svg';
import whatsapp from 'assets/icons/whatsapp.svg';
import small_right from 'assets/icons/small_right.svg';
import upload_green from 'assets/icons/upload_green.svg';
import checked from 'assets/icons/checked.svg';
import uncheck from 'assets/icons/uncheck.svg';
import arrow_down_white from 'assets/icons/arrow_down_white.svg';
import settings_inactive from 'assets/icons/settings_inactive.svg';
import mail_black from 'assets/icons/mail_black.svg';
import facebook_black from 'assets/icons/facebook_black.svg';
import trash_white from 'assets/icons/trash_white.svg';
import Linkedin from 'assets/icons/linked_in.svg';
import copy from 'assets/icons/copy.svg';
import instagram from 'assets/icons/instagram.svg';
import close_black from 'assets/icons/close_black.svg';
import recruitment from 'assets/icons/recruitment.svg';
import recruitment_active from 'assets/icons/recruitment_active.svg';
import clock from 'assets/icons/clock.svg';
import male_icon from 'assets/icons/male_icon.svg';
import female_icon from 'assets/icons/female_icon.svg';
import user_placeholder_white from 'assets/icons/user_placeholder_white.svg';
import search_gray from 'assets/icons/search_gray.svg';
import calendar_icon from 'assets/icons/calendar_icon.svg';
import google_icon from 'assets/icons/google_icon.svg';
import business_unit from 'assets/icons/business_unit.svg';
import business_unit_active from 'assets/icons/business_unit_active.svg';
import integration from 'assets/icons/integration.svg';
import integration_active from 'assets/icons/integration_active.svg';
import google from 'assets/icons/google.svg';
import calender_integrated from 'assets/icons/calender_intergrated.svg';
import calender_inactive from 'assets/icons/calender_inactive.svg';
import burger_icon_1 from 'assets/icons/burger_icon_1.svg';
import burger_icon_2 from 'assets/icons/burger_icon_2.svg';
import tick_black from 'assets/icons/tick_black.svg';
import csv from 'assets/icons/csv.svg';
import copy_black from 'assets/icons/copy_black.svg';
import copy_white from 'assets/icons/copy_white.svg';
import filter from 'assets/icons/filter.svg';
import microsoft from 'assets/images/microsoft.png';
import email_integration from 'assets/images/email_integration.png';
import outlook_calendar from 'assets/images/outlook_calendar.png';
import google_calendar from 'assets/images/google_calendar.png';
import career_active from 'assets/images/career_active.png';
import career_inactive from 'assets/images/career_inactive.png';
import support_email from 'assets/images/support_email.png';
import radio_checked from 'assets/icons/radio_checked.svg';
import radio_unchecked from 'assets/icons/radio_unchecked.svg';
import linkedin_icon from 'assets/icons/linkedin_icon.svg';
import facebook_disabled from 'assets/images/facebook_disabled.png';
import copy_disabled from 'assets/images/copy_disabled.png';
import linkedin_disabled from 'assets/images/linkedin_disabled.png';
import resume_upload from 'assets/icons/resume_upload.svg'
import client_active from 'assets/icons/client_active.svg'
import client_inactive from 'assets/icons/client_inactive.svg'
import payment_terms_active from 'assets/icons/payment_terms_active.svg'
import payment_terms_inactive from 'assets/icons/payment_terms_inactive.svg'
import radio_active from 'assets/icons/radio_active.svg'
import radio_inactive from 'assets/icons/radio_inactive.svg'
import pdf_icon from 'assets/icons/pdf_icon.svg'
import wrong_icon from 'assets/icons/wrong_icon.svg'
import doc_icon from 'assets/icons/doc_icon.svg'
import upload_doc from 'assets/images/upload_doc.svg'
import draggable_icon from 'assets/icons/draggable_icon.svg'
import eye_icon from 'assets/icons/eye_icon.svg';
import equest_logo from 'assets/icons/equest_logo.png';
import tick_inactive from 'assets/icons/tick_inactive.svg';
import basic_plan from 'assets/icons/basic_plan.svg';
import enterprise_plan from 'assets/icons/enterprise_plan.svg';
import premium_plan from 'assets/icons/premium_plan.svg';
import tick_active from 'assets/icons/tick_active.svg';
import save_inactive from 'assets/icons/save_inactive.svg';
import copy_white_inactive from 'assets/icons/copy_white_inactive.svg';
import payment_active from 'assets/icons/payment_active.svg'
import payment_inactive from 'assets/icons/payment_inactive.svg'
import sparkle_active from 'assets/icons/sparkle_active.svg';
import sparkle_inactive from 'assets/icons/sparkle_inactive.svg';
import info_icon from 'assets/icons/info_icon.svg';
import org_active from 'assets/icons/org_active.svg'
import org_inactive from 'assets/icons/org_inactive.svg'

const Assets = {
  testPic,
  eye,
  camera,
  vector,
  facebook,
  arrow,
  tick,
  bell,
  eye_slash,
  download,
  upload,
  send,
  trash,
  edit,
  search,
  plus_with_box,
  settings,
  plus,
  save,
  group,
  add,
  toggle,
  logo,
  profile,
  manageusers,
  email,
  password,
  offer,
  map,
  department,
  job,
  buildings,
  close,
  attachment,
  black_close,
  profile_active,
  manageusers_active,
  password_active,
  email_active,
  map_active,
  offer_active,
  department_active,
  buildings_active,
  profile_placeholder,
  mail,
  uploadimg,
  addCertification,
  loader,
  uploadImage,
  back,
  unpublish,
  search_white,
  agency_invite,
  post_job,
  share,
  check_icon,
  requisition,
  requisition_active,
  create,
  create_active,
  house,
  house_active,
  plus_black,
  add_resume,
  right,
  left,
  add_green,
  big_tick,
  search_active,
  search_inactive,
  hiring_card,
  logout,
  full_screen,
  whatsapp,
  small_right,
  upload_green,
  checked,
  uncheck,
  arrow_down_white,
  settings_inactive,
  mail_black,
  facebook_black,
  trash_white,
  Linkedin,
  copy,
  instagram,
  close_black,
  recruitment,
  recruitment_active,
  male_icon,
  female_icon,
  clock,
  user_placeholder_white,
  search_gray,
  calendar_icon,
  integration,
  integration_active,
  business_unit,
  business_unit_active,
  google,
  calender_inactive,
  calender_integrated,
  burger_icon_1,
  burger_icon_2,
  tick_black,
  csv,
  copy_black,
  copy_white,
  filter,
  microsoft,
  email_integration,
  outlook_calendar,
  google_calendar,
  career_active,
  career_inactive,
  support_email,
  radio_checked,
  radio_unchecked,
  linkedin_icon,
  facebook_disabled,
  copy_disabled,
  linkedin_disabled,
  resume_upload,
  client_active,
  client_inactive,
  payment_terms_active,
  payment_terms_inactive,
  radio_active,
  radio_inactive,
  pdf_icon,
  wrong_icon,
  doc_icon,
  upload_doc,
  draggable_icon,
  eye_icon,
  equest_logo,
  tick_active,
  tick_inactive,
  basic_plan,
  premium_plan,
  enterprise_plan,
  save_inactive,
  copy_white_inactive,
  payment_active,
  payment_inactive,
  sparkle_inactive,
  sparkle_active,
  agency_invite_inactive,
  info_icon,
  org_active,
  org_inactive,
  google_icon
};
export default Assets;
