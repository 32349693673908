import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { timeZones } from 'utils/constant.utils';
import {
  getTimeZones,
  toastify,
  toastifyError,
  uploadFile,
    useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  PrimaryButton,
  UploadComponent,
  Assets,
  Dropdown,
  Validation,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './create_profile.screen.scss';
import _ from 'lodash';

const CreateProfile = (props: any) => {
  // Redux
  const appState = useSelector((state: any) => state.app_state);
  const userState = useSelector((state: any) => state.user.auth);

  // State
  const [state, setState] = useSetState({
    first_name: '',
    last_name: '',
    role: '',
    time_zone: '',
    profile_picture: '',
    job_title: '',
    timeZone: [],
    errorArray: [],
  });

  //Hooks
  useEffect(() => {getUserDetail()}, []);

  // Network req
  const uploadFiles = async (file) => {
    try {
      const content: any = await Models.auth.uploadFile({
        file_type: file.type,
        file_name: file.name,
        folder: 'profile',
      });
      uploadFile(file, content.signedRequest, content.url, function (res) {
        setState({ profile_picture: res?.url });
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const editUser = async () => {
    try {
      let userId = localStorage.getItem('user_id');
      const body = {
        user_id: userId,
        first_name: state.first_name,
        last_name: state.last_name,
        timezone: state.time_zone,
        job_title: state.job_title,
        profile_picture: state.profile_picture,
        is_profile_completed: true,
      };
      await Validation.createProfile.validate(body, { abortEarly: false });
      const res: any = await Models.auth.editUser(body);
      
      if(res.data?.org?.subscribed && res.data.is_agency){
        await Models.subscription.updateSubscription({});
      }
      if (!res.data.confirmed && !res.data.invited && !res.data.is_agency) {
        window.location.href = '/confirm_email';
      } else {
        window.location.href = '/home';
      }
      // dispatch
    } catch (error: any) {
      toastify(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const getUserDetail = async () => {
    try {
      let userId = localStorage.getItem('user_id');
      const res: any = await Models.auth.viewUser(userId);
      setState({
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        timezone: res.data.time_zone,
        job_title: res.data.job_title,
        profile_picture: res.data.profile_picture,
      })
      //dispatch
    } catch (error: any) {
      toastify(error);
    }
  };


  //Logic
  

  return (
    <div className="create_profile_container">
      <div className="create_profile_wrapper">
        <div className="create_profile_container_logo">
          <img src={Assets.logo} height={100} width={100} alt="sidebar logo" className="sidebar_logo" />
        </div>
        <div className="header">Complete Your Profile</div>
        <div className="headings">Your Avatar</div>
        <div className="photo_and_button">
          <div className="upload_image">
            <UploadComponent
              icon={Assets.camera}
              onChange={(file) => {
                                setState({ file: file[0] });
                uploadFiles(file[0]);
              }}
              accept_types={'.jpg, .jpeg,.png'}
              iconOnClick={() => setState({ profile_picture: '' })}
              image={state.profile_picture}
            />
            {/* {state.profile_picture === '' ? (
              <UploadComponent
                icon={Assets.camera}
                onChange={(file) => {
                  setState({ file: file[0] });
                  uploadFiles(file[0]);
                }}
              />
            ) : (
              <img className="profile_picture" src={state.profile_picture} />
            )} */}
          </div>
          {/* <div className="upload">
            <PrimaryButton text={'Upload'} className={'upload_button'} />
          </div> */}
        </div>
        <div className="full_name">
          <div className="first_name">
            <div className="headings">
              First Name<span className="required_field">*</span>
            </div>
            <Input
              type={'text'}
              onChange={(first_name: string) => {
                setState({ first_name });
              }}
              value={state.first_name}
              name={'first_name'}
              className={'name_input'}
              error={state.errorArray}
            />
          </div>
          <div className="last_name">
            <div className="headings">
              Last Name<span className="required_field">*</span>
            </div>
            <Input
              type={'text'}
              onChange={(last_name: string) => {
                setState({ last_name });
              }}
              value={state.last_name}
              name={'last_name'}
              className={'name_input'}
              error={state.errorArray}
            />
          </div>
        </div>
        <div className="info">
          {/* <div className="headings">Role</div>
          <div className="headings">
            Role<span className="required_field">*</span>
          </div>
          <Dropdown
            data={role_data}
            isMulti
            setActive={(role: any) => {
              setState({ role });
            }}
            {...props}
          /> */}
          {/* <div className="headings">Job Title</div> */}
          <div className="headings">
            Job Title
            {/* <span className="required_field">*</span> */}
          </div>
          <div className="job_title_input">
            <Input
              type={'text'}
              onChange={(job_title: string) => {
                setState({ job_title });
              }}
              value={state.job_title}
              name={'job_title'}
            />
          </div>
          <div className="headings">
            Location & Time Zone
            {/* <span className="required_field">*</span> */}
          </div>
          <Dropdown
            data={getTimeZones()}
            setActive={(value: any) => {
              setState({ time_zone: value.value });
            }}
            {...props}
          />
        </div>
        <div className="buttons">
          {/* <div className="linkedin_button">
            <PrimaryButton
              text={'Connect with Linkedin'}
              className={'social_btn'}
              icon={Assets.linked_in}
            />
          </div>
          <div className="facebook_button">
            <PrimaryButton
              text={'Connect with Facebook'}
              className={'social_btn'}
              icon={Assets.facebook}
            />
          </div> */}
          <div className="save_and_continue_button">
            <PrimaryButton
              text={'Save and Continue'}
              onClick={editUser}
              className={'save_and_continue'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProfile;
