import React, { useEffect, useRef } from 'react';
import './offer_list.component.scss';
import Assets from 'imports/assets.import';
import PrimaryButton from '../button/primary_Button.ui';
import {
  isoToDateConvert,
  toastifyError,
  unixTimeToDateConvert,
  useSetState,
} from 'utils/functions.utils';
import { Loader, Models, Navbutton } from 'utils/imports.utils';
import _ from 'lodash';
import csvDownload from 'json-to-csv-export';
import { useSelector } from 'react-redux';
import createInvoicePdf from 'components/invoice_pdf/invoice_pdf';

interface IOfferListComponentProps {
  data?: any;
  loading?: boolean;
  titleArray?: any;
  action_btn?: string;
  action_btn_icon?: string;
  onPress?: any;
  type?: string;
  actionArray?: any;
  loadMore?: any;
  hasMore?: boolean;
  download?: boolean;
  show?: string;
  editApproval?: any;
}

export default function OfferListComponent(props: IOfferListComponentProps) {
  const userState = useSelector((state: any) => state.user.auth);
  const [state, setState] = useSetState({
    isModalOpen: false,
  });
  const modalRef: any = useRef();
  useEffect(() => {}, []);

  // const createInvoice = async (query: any) => {
  //   try {
  //     query.is_agency = userState.is_agency ? true : false;
  //     query.bill_date = isoToDateConvert(query.created_at);
  //     if (userState.is_agency) {
  //       query.start_date = unixTimeToDateConvert(
  //         query.subscription_data.current_period_start,
  //       );
  //       query.end_date = unixTimeToDateConvert(
  //         query.subscription_data.current_period_end,
  //       );
  //     }
  //     query.type = 'invoice';
  //     createInvoicePdf(query);
  //   } catch (error: any) {
  //     toastifyError(error);
  //   }
  // };

  if (_.isEmpty(props.data) && !props.loading) {
    return <div className="empty_text">No data found</div>;
  }

  return (
    <div className="list_component_container">
      <div className="list_component_row">
        {props.titleArray.map((item: any, index: number) => (
          <div
            style={{ width: item.width, ...item?.style }}
            className={`${
              index === 0 && 'list_component_block'
            }  list_component_name_wrapper`}
            key={index}>
            <div className="list_component_name list_component_heading">
              {item.label}
            </div>
          </div>
        ))}
        <div className="list_component_action_wrapper">
          {props.download && (
            <div className="csv_download">
              <Navbutton
                icon={Assets.csv}
                onClick={() => csvDownload(props.data)}
                className="csv_download_icon"
              />
            </div>
          )}
        </div>
      </div>
      {props.loading ? (
        <Loader />
      ) : // )
      // : !props.loading && props?.data?.length == 0 ? (
      //   <div className="empty_text">No data found</div>
      props.type === 'invoiceDetails' ? (
        props?.data?.map((item: any) => (
          <div className="list_component_row">
            {props.titleArray.map((titleObj: any, titleIndex: number) => {
              let value = props?.titleArray[titleIndex].value;
              return (
                <div
                  className={`${
                    titleIndex === 0 && 'list_component_block'
                  }  list_component_name_wrapper`}
                  style={{ width: props?.titleArray[titleIndex]?.width }}
                  onClick={() =>
                    value === 'invoice_number'
                      ? window.open(item['url'], '_blank')
                      : {}
                  }>
                  <div
                    className={`${
                      titleIndex === 0 && 'list_component_subtitle'
                    }  list_component_name ${
                      titleIndex === 4 && 'invoice_link'
                    }`}>
                    {value === 'created_at'
                      ? unixTimeToDateConvert(item[value], true)
                      : value === 'plan'
                      ? 'Premium'
                      : value === 'invoice_number'
                      ? `${item[value]}`
                      : item[value]}
                  </div>
                </div>
              );
            })}
          </div>
        ))
      ) : (
        props?.data?.map((item: any) => (
          <div className="list_component_row">
            {props.titleArray.map((titleObj: any, titleIndex: number) => {
              let value = props?.titleArray[titleIndex].value;
              return (
                <div
                  className={`${
                    titleIndex === 0 && 'list_component_block'
                  }  list_component_name_wrapper`}
                  style={{ width: props?.titleArray[titleIndex]?.width }}>
                  <div
                    className={`${
                      titleIndex !== 0 && 'list_component_subtitle'
                    }  list_component_name`}>
                    {value === 'plan'
                      ? `${item[value] === 'trial' ? 'Free' : item[value]} Plan`
                      : item[value]}
                  </div>
                </div>
              );
            })}
          </div>
        ))
      )}
      {props.hasMore && !props.loading && (
        <div className="list_component_load_button_wrapper">
          <PrimaryButton
            text={'Load more'}
            onClick={() => {
              props.loadMore();
            }}
            className="list_component_load_button"
          />
        </div>
      )}
    </div>
  );
}
