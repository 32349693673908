import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  getTimeZones,
  toastify,
  toastifyError,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Navbutton,
  Assets,
  Input,
  Tag,
  Dropdown,
  PrimaryButton,
} from 'utils/imports.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './smtp_form.scss';
import _ from 'lodash';

const SmtpForm = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();

  //Redux
  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    host: '',
    port: '',
    cipher: '',
    email: '',
    password: '',
    ssl: false,
    name: '',
  });

  //Hooks
  useEffect(() => {
    if (!_.isEmpty(props.data)) {
      setState({
        host: props.data.host,
        port: props.data.port,
        cipher: props.data.cipher,
        email: props.data.email,
        password: props.data.password,
        ssl: props.data.ssl,
        name: props.data.name,
      });
    }
  }, [props.data]);

  // Network req

  const createSmtp = async () => {
    try {
      let body: any = {
        password: state.password,
        email: state.email,
        port: state.port,
        host: state.host,
        name: state.name,
      };
      if (state.cipher.length > 0) {
        body.cipher = state.cipher;
      }
      if (state.ssl.length > 0) {
        body.ssl = state.ssl;
      }
      const res: any = await Models.smtp.createSmtp(body);
      setState({ isModalOpen: false });
      toastify('Email integrated');
    } catch (error) {
      toastifyError(error);
    }
  };

  const editSmtp = async () => {
    try {
      let body = {
        smtp_id: props.data._id,
        cipher: state.cipher,
        password: state.password,
        email: state.email,
        port: state.port,
        ssl: state.ssl,
        host: state.host,
        name: state.name,
      };
      const res: any = await Models.smtp.editSmtp(body);
      toastify('Details Saved');
      setState({ isModalOpen: false });
    } catch (error) {
      toastifyError(error);
    }
  };

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  //Logic

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'smtp_modal_wrapper',
        }}>
        <div className="smtp_container">
          <div className="smtp_wrapper">
            <div className="smtp_header">
              <div className="smtp_heading">Email integration</div>
              <Navbutton
                icon={Assets.black_close}
                onClick={() => {
                  setState({ isModalOpen: false });
                }}
              />
            </div>
            <div className="smtp_name_container">
              <div className="smtp_first_name">
                <div className="smtp_input_heading">Host</div>
                <Input
                  type="text"
                  onChange={(host: string) => {
                    setState({ host });
                  }}
                  value={state.host}
                  name={'host'}
                />
              </div>
              <div className="smtp_last_name">
                <div className="smtp_input_heading">Port</div>
                <Input
                  type="text"
                  onChange={(port: string) => {
                    setState({ port });
                  }}
                  value={state.port}
                  name={'port'}
                  {...props}
                />
              </div>
            </div>
            <div className="smtp_location_container">
              <div className="smtp_input_heading">Cipher</div>
              <Input
                type="text"
                onChange={(cipher: string) => {
                  setState({ cipher });
                }}
                value={state.cipher}
                name={'cipher'}
                {...props}
              />
            </div>
            <div className="smtp_role_container">
              <div className="smtp_input_heading">Email</div>
              <div className="smtp_role_wrapper">
                <Input
                  type="text"
                  onChange={(email: string) => {
                    setState({ email });
                  }}
                  value={state.email}
                  name={'email'}
                  {...props}
                />
              </div>
            </div>
            <div className="smtp_job_title_container">
              <div className="smtp_input_heading">Password</div>
              <Input
                onChange={(password: string) => {
                  setState({ password });
                }}
                value={state.password}
                name={'password'}
                type={state.visible ? 'text' : 'password'}
                icon={state.visible ? Assets.eye_slash : Assets.eye}
                iconOnPress={() => setState({ visible: !state.visible })}
                {...props}
              />
            </div>
            <div className="smtp_job_title_container">
              <div className="smtp_input_heading">SSL</div>
              <Input
                type="text"
                onChange={(ssl: string) => {
                  setState({ ssl });
                }}
                value={state.ssl}
                name={'ssl'}
                {...props}
              />
            </div>
            <div className="smtp_job_title_container">
              <div className="smtp_input_heading">Name</div>
              <Input
                type="text"
                onChange={(name: string) => {
                  setState({ name });
                }}
                value={state.name}
                name={'name'}
                {...props}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}>
          <PrimaryButton
            onClick={() => {
              if (!_.isEmpty(props.data)) {
                editSmtp();
              } else {
                createSmtp();
              }
            }}
            text={!_.isEmpty(props.data) ? 'Save' : 'Integrate'}
            className={'button'}
            width={'200px'}
          />
        </div>
      </Modal>
    </div>
  );
});

export default SmtpForm;
