import instance from 'utils/axios.utils';

const paymentTerms = {
  createPaymentTerms: (data:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'payment_terms/create_payment_terms';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getAllPaymentTerms: (body?: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'payment_terms/get_many_payment_terms';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  deletePaymentTermsById: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'payment_terms/delete_payment_terms';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getPaymentTerms: (body?: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'payment_terms/get_payment_terms';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editPaymentTermsById: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'payment_terms/edit_payment_terms';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default paymentTerms;
