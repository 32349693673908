import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { toastify, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  Assets,
  PrimaryButton,
  Validation,
  ClipBoard,
} from 'utils/imports.utils';
import './career_settings.scss';

const CareerSettings = (props: any) => {
  // Redux
  const userState = useSelector((state: any) => state.user.auth);

  // State
  const [state, setState] = useSetState({
    career_site: '',
    org: '',
  });

  //Hooks
  useEffect(() => {
    getUser();
  }, []);

  // Network req
  const getUser = async () => {
    let userId = localStorage.getItem('user_id');
    try {
      const res: any = await Models.auth.viewUser(userId);
      // dispatch
      setState({
        org: res.data.org._id,
        career_site: `${process.env.REACT_APP_CAREER_SITE}/career?ref=${res.data.org._id}`,
      });
    } catch (error: any) {
      console.log(error);
    }
  };
  //Logic

  return (
    <div className="career_settings_container">
      <div className="career_settings_wrapper">
        <div className="heading">Career Site</div>
        <div className="career_settings_input_container">
          <Input
            type={'text'}
            onChange={(career_site) => {
              setState({ career_site });
            }}
            disabled
            value={state.career_site}
            name={'career_site'}
          />
        </div>
        <div className="button">
          <ClipBoard
            text={state.career_site}
            onCopy={() => {
              setState({ copied: true });
              toastify('Link Copied');
            }}
            {...props}>
            <PrimaryButton
              onClick={() => {}}
              text={'Copy Link'}
              className={'button'}
            />
          </ClipBoard>
        </div>
      </div>
    </div>
  );
};

export default CareerSettings;
