import React, { Suspense, useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Main from 'common_components/hoc/main.hoc';
import store from 'store/store';
import { Provider } from 'react-redux';
import Login from 'screens/login/login.screen';
import Signup from 'screens/signup/signup.screen';
import ResetPassword from 'screens/reset_password/reset_password.screen';
import OTP from 'screens/otp/otp.screen';
import ForgetPassword from 'screens/forget_password/forget_password.screen';
import { Test } from 'screens/test/test.screen';
import CreateProfile from 'screens/profile/create_profile.screen';
import Profile from 'screens/profile/profile';
import ChangePassword from 'screens/change_password/change_password';
import OfferTemplate from 'screens/offer_templates/offer_templates.screen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WorkLocations from 'screens/work_locations/work_locations.screen';
import ManageUsers from 'screens/manage_users/manage_users.screen';
import Agencies from 'screens/agencies/agencies_screen';
import EmailTemplate from 'screens/email_template/email_template.screen';
import Departments from 'screens/departments/departments.screen';
import NewJobRecquisition from 'screens/new_job_recquisition/new_job_recquisition.screen';
import ResumeSearch from 'screens/resume_search/resume_search.screen';
import Home from 'screens/home/home.screen';
import EmailConfirmation from 'screens/email_confirmation/email_confirmation.screen';
import RecquisitionBoard from 'screens/recquisition_board/recquisition_board.screen';
import Requisition from './screens/job-requisition/requisition.screen';
import BusinessUnit from 'screens/business_unit/business_unit.screen';
import JobApplication from 'screens/job_application/job_application.screen';
import PostJob from 'screens/post_job/post_job.screen';
import InterviewPage from 'screens/interview_page/interview_page.screen';
import CreateNewOffer from 'screens/create_new_offer/create_new_offer.screen';
import ApproveOffer from 'screens/approve_offer/approve_offer.screen';
import ReviewOffer from 'screens/review_offer/review_offer.screen';
import CandidatePage from 'screens/candidate_page/candidate_page.screen';
import ProtectedRoutes from 'common_components/hoc/protected_routes';
import InterviewSetup from 'screens/interview_page/interview_setup.screen';
import JobApplicationLanding from 'screens/job_application_landing/job_application_landing.screen';
import Recruitment from 'screens/recruitment/recruitment.screen';
import Integrations from 'screens/integrations/integrations.screen';
import CreateRequisition from 'screens/new_job_recquisition/create_requisition.screen';
import CareerPage from 'screens/career_page/career_page';
import CareerSettings from 'screens/career_settings/career_settings.screen';
import Invite from 'screens/invite/invite.screen';
import ErrorScreen from 'screens/error/error.screen';
import Client from 'screens/client_settings/client.screen';
import PaymentTerms from 'screens/payment_terms/payment_terms.screen';
import ApprovalScreen from 'screens/approval/req_approval.screen';
import Organization from 'screens/organization/organization.screen';
import ClientOrg from 'admin_screens/client_organisation/client_organisation.screen';

const token = localStorage.getItem('token');
const agency = localStorage.getItem('agency');

function App() {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Routes>
            {agency ? (
              <>
                <Route
                  path="/requisition_board"
                  element={
                    <Main>
                      <RecquisitionBoard />
                    </Main>
                  }></Route>
                <Route
                  path="/requisition"
                  element={
                    <Main>
                      <Requisition />
                    </Main>
                  }></Route>
                <Route
                  path="/candidate"
                  element={
                    <Main>
                      <CandidatePage />
                    </Main>
                  }></Route>
              </>
            ) : (
              <>
                <Route path="/" element={<Login />}></Route>
                <Route path="/test" element={<Test />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/signup" element={<Signup />}></Route>
                <Route
                  path="/reset_password"
                  element={<ResetPassword />}></Route>
                <Route
                  path="/forget_password"
                  element={<ForgetPassword />}></Route>
                <Route path="/invite" element={<Invite />}></Route>
                <Route path="/otp" element={<OTP />}></Route>
                <Route
                  path="/create_profile"
                  element={<CreateProfile />}></Route>
                <Route
                  path="/job_application_page"
                  element={<JobApplicationLanding />}></Route>
                <Route
                  path="/confirm_email"
                  element={<EmailConfirmation />}></Route>
                <Route
                  path="/settings/profile"
                  element={
                    <Main>
                      <Profile />
                    </Main>
                  }></Route>
                   <Route
                  path="/settings/organization"
                  element={
                    <Main>
                      <Organization />
                    </Main>
                  }></Route>
                <Route
                  path="/dashboard"
                  element={
                    <Main>
                      <Test />
                    </Main>
                  }></Route>
                <Route
                  path="/settings/payment_settings"
                  element={
                    <Main>
                      <OfferTemplate />
                    </Main>
                  }></Route>
                <Route
                  path="/settings/work_locations"
                  element={
                    <Main>
                      <WorkLocations />
                    </Main>
                  }></Route>
                <Route
                  path="/settings/manage_users"
                  element={
                    <Main>
                      <ManageUsers />
                    </Main>
                  }></Route>
                <Route
                  path="/settings/agencies"
                  element={
                    <Main>
                      <Agencies />
                    </Main>
                  }></Route>
                <Route
                  path="/settings/email_template"
                  element={
                    <Main>
                      <EmailTemplate />
                    </Main>
                  }></Route>
                <Route
                  path="/settings/departments"
                  element={
                    <Main>
                      <Departments />
                    </Main>
                  }></Route>
                <Route
                  path="/settings/change_password"
                  element={
                    <Main>
                      <ChangePassword />
                    </Main>
                  }></Route>
                <Route
                  path="/new_job_requisition"
                  element={
                    <Main>
                      <CreateRequisition />
                    </Main>
                  }></Route>
                <Route
                  path="/resume_search"
                  element={
                    <Main>
                      <ResumeSearch />
                    </Main>
                  }></Route>
                <Route
                  path="/your_task"
                  element={
                    <Main>
                      <Home />
                    </Main>
                  }></Route>
                <Route
                  path="/requisition_board"
                  element={
                    <Main>
                      <RecquisitionBoard />
                    </Main>
                  }></Route>
                <Route
                  path="/requisition"
                  element={
                    <Main>
                      <Requisition />
                    </Main>
                  }></Route>
                <Route
                  path="/settings/business_unit"
                  element={
                    <Main>
                      <BusinessUnit />
                    </Main>
                  }></Route>
                {/* <Route element={<ProtectedRoutes />}> */}
                <Route path="/apply_job" element={<JobApplication />} />
                {/* </Route> */}
                <Route
                  path="/post_job"
                  element={
                    <Main>
                      <PostJob />
                    </Main>
                  }></Route>
                <Route
                  path="/interview"
                  element={
                    <Main>
                      <InterviewPage />
                    </Main>
                  }></Route>
                <Route
                  path="/create_new_offer"
                  element={
                    <Main>
                      <CreateNewOffer />
                    </Main>
                  }></Route>
                <Route
                  path="/approve_offer"
                  element={
                    <Main>
                      <ApproveOffer />
                    </Main>
                  }></Route>
                <Route
                  path="/review_offer"
                  element={
                    <Main>
                      <ReviewOffer />
                    </Main>
                  }></Route>
                <Route
                  path="/candidate"
                  element={
                    <Main>
                      <CandidatePage />
                    </Main>
                  }></Route>
                <Route
                  path="/interview_setup"
                  element={
                    <Main>
                      <InterviewSetup />
                    </Main>
                  }></Route>
                <Route
                  path="/home"
                  element={
                    <Main>
                      <Recruitment />
                    </Main>
                  }></Route>
                <Route
                  path="/settings/integrations"
                  element={
                    <Main>
                      <Integrations />
                    </Main>
                  }></Route>
                <Route path="/career" element={<CareerPage />} />
                <Route
                  path="/settings/career"
                  element={
                    <Main>
                      <CareerSettings />
                    </Main>
                  }
                />
                <Route
                  path="/settings/career"
                  element={
                    <Main>
                      <CareerSettings />
                    </Main>
                  }
                />
                  <Route
                  path="/settings/client"
                  element={
                    <Main>
                      <Client />
                    </Main>
                  }
                />
                <Route
                  path="/settings/payment_terms"
                  element={
                    <Main>
                      <PaymentTerms />
                    </Main>
                  }
                />
                <Route path="/client_org" element={    <Main>
                  <ClientOrg />
                  </Main>}></Route>

                <Route
                  path="/public/approval_requisition"
                  element={
                      <ApprovalScreen />
                  }
                />
              </>
            )}
            <Route
              path="*"
              element={
                  <ErrorScreen />
              }></Route>
          </Routes>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
