import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  useSetState,
  getDataFromDropdown,
  useQuery,
  getExperience,
  getDropdownValue,
  gets3FileName,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  Dropdown,
  PrimaryButton,
  Input,
  Certification,
  ToggleHeader,
  Validation,
  FileUpload,
  PdfViewer,
  MultiSelectDropdown,
  UploadComponent,
  Navbutton,
  DatePickerComponent,
  Loader,
} from 'utils/imports.utils';
import { getRecquisitionById, testDispatch } from 'utils/redux.utils';
import './job_application.screen.scss';
import { toastify, uploadFile, toastifyError } from 'utils/functions.utils';
import { useNavigate } from 'react-router-dom';
import {
  education_level,
  gender,
  notice_period,
  reasons_for_job_change,
  remote_work_preference,
  RESUME_PARSER,
  skills,
  SUBSCRIPTION_FEATURES,
  willing_to_relocate,
} from 'utils/constant.utils';
import _ from 'lodash';

const JobApplication = (props: any) => {
  const fileUploadRef: any = useRef();
  const inputFileRef: any = useRef();
  const profileRef: any = useRef();
  const certificateRef: any = useRef();
  // Redux
  const recquisition = useSelector(
    (state: any) => state.recquisition.recquisition_by_id,
  );
  // State
  const [state, setState] = useSetState({
    active: '',
    email: '',
    phone: null,
    work_sample: '',
    first_name: '',
    last_name: '',
    obj: '',
    gender: [],
    address: '',
    total_experience: { label: '', value: '' },
    relevant_experience: { label: '', value: ' ' },
    notice_period: { label: '', value: '' },
    education: [],
    reasons_for_job_change: { label: '', value: '' },
    willingness_to_relocate: '',
    remote_work_preference: '',
    current_salary: '',
    expected_salary: '',
    errorArray: [],
    resume_file: '',
    certification_file: [],
    limit: 1,
    data: [],
    pageNumber: '',
    countryList: [],
    stateList: [],
    skills_value: [],
    profile_picture: '',
    city: { label: '', value: '' },
    cityList: [],
    resume_identifier: '',
    job_description_identifier: '',
    employers: [],
    employerLength: 1,
    job_description_file: '',
    state: { label: '', value: '' },
    country: { label: '', value: '' },
    parsing: false,
    requisitionData: {},
    isMobile: false,
    disabled: false,
    dob: new Date(),
    skills_data: [],
    educationLength: 1,
    applying: false,
    planName: '',
  });
  //Hooks
  useEffect(() => {
    setState({ active: headerData[0] });
    let width = window.screen.width;
    if (width <= 768) {
      setState({ isMobile: true });
    } else {
      setState({ isMobile: false });
    }
    getSkillsData();
  }, []);
  const handleWindowClose = (e) => {
    e.preventDefault();
    return (e.returnValue = '');
  };
  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [window]);
  const query = useQuery();
  const navigate = useNavigate();
  let requisition_id = query.get('req_id');

  useEffect(() => {
    addSampleLink();
    getCountriesList();
  }, [state.limit]);

  // Network req

  const resumeParser = async (file: string) => {
    try {
      setState({ parsing: true });
      let body = {
        url: file,
        type: RESUME_PARSER.AFFINDA,
        job_description: state.job_description_file,
      };
      const res: any = await Models.resume.parseResume(body);
      let skills_value: any = [];
      res.data.parsed_resume.data.skills?.map((skill: any) =>
        skills_value.push({ label: skill.name, value: skill.name }),
      );
      let employers: any[] = [];
      let education: any[] = [];
      if (res.data.parsed_resume.data.workExperience?.length > 0) {
        res.data.parsed_resume.data.workExperience.map((item: any) =>
          employers.push({
            employer: item.organization,
            job_title: item.jobTitle,
            start_date: item.dates?.startDate,
            end_date: item.dates?.endDate,
          }),
        );
        setState({ employers, employerLength: employers.length });
      }
      if (res.data.parsed_resume.data.education?.length > 0) {
        res.data.parsed_resume.data.education.map((item: any) =>
          education.push({
            education_level: item.accreditation.educationLevel,
            organisation: item.organization,
            start_date: item.dates?.startDate,
            end_date: item.dates?.completionDate,
          }),
        );
        setState({ education, educationLength: education.length });
      }
      if (
        res.data.parsed_resume.data.location?.city &&
        res.data.parsed_resume.data.location?.state
      ) {
        setState({
          state: getDropdownValue(res.data.parsed_resume.data.location?.state),
          city: getDropdownValue(res.data.parsed_resume.data.location?.city),
          country: getDropdownValue(
            res.data.parsed_resume.data.location?.country,
          ),
        });
      }
      setState({
        email:
          res.data.parsed_resume.data.emails?.length > 0
            ? res.data.parsed_resume.data.emails[0]
            : '',
        first_name: res.data.parsed_resume.data.name?.first,
        last_name: res.data.parsed_resume.data.name?.last,
        phone:
          res.data.parsed_resume.data.phoneNumbers?.length > 0 &&
          res.data.parsed_resume.data.phoneNumbers[0],
        address: res.data.parsed_resume.data.location?.formatted,
        skills_value,
        total_experience: getDropdownValue(
          res.data.parsed_resume.data.totalYearsExperience,
        ),
        pincode: res.data.parsed_resume.data.location?.postalCode,
        resume_identifier: res.data.parsed_resume.meta.identifier,
        job_description_identifier: res.data.job_description_identifier,
        raw_text: res.data.parsed_resume.data?.rawText,
        parsing: false,
      });
    } catch (error) {
      setState({ parsing: false });
      toastifyError(error);
    }
  };

  const applyJob = async () => {
    try {
      setState({ applying: true });
      let workSampleArray: any = [];
      let source: any;
      if (query.get('utm_source')?.length) {
        source = query.get('utm_source');
      } else {
        source = 'unknown';
      }
      state.data.map((item: any) => {
        if (item.title.length > 0) {
          workSampleArray.push({
            title: item.title,
            link: item.link,
          });
        }
      });
      let skills: any = [];
      state.skills_value.map((item) => skills.push(item.label));
      const body: any = {
        resume: {
          email: state.email,
          phone: state.phone,
          resume: state.resume_file,
          first_name: state.first_name,
          last_name: state.last_name,
          address: state.address,
          total_experience: state.total_experience?.value,
          relevant_experience: state.relevant_experience?.value,
          notice_period: state.notice_period.value,
          education: state.education,
          reason_for_job_change: state.reasons_for_job_change.value,
          pincode: state.pincode,
          state: state.state?.value,
          country: state.country?.label,
          skills,
          city: state.city.value,
          source,
          employers: state.employers,
        },
        requisition_id: state.requisitionData._id,
      };
      if (state.expected_salary !== '') {
        body.resume.expected_salary = parseInt(state.expected_salary);
      }
      if (state.current_salary !== '') {
        body.resume.current_salary = parseInt(state.current_salary);
      }
      if (workSampleArray.length > 0) {
        body.resume.work_samples = workSampleArray;
      }
      if (state.certification_file?.length > 0) {
        body.resume.certifications = state.certification_file;
      }
      if (state.profile_picture?.length > 0) {
        body.resume.profile_picture = state.profile_picture;
      }
      if (state.gender?.value?.length > 0) {
        body.resume.gender = state.gender?.value;
      }
      if (state.remote_work_preference?.label?.length > 0) {
        body.resume.remote_work_preference = state.remote_work_preference.label;
      }
      if (state.willingness_to_relocate?.label?.length > 0) {
        body.resume.willing_to_relocate = state.willingness_to_relocate?.label;
      }
      if (state.resume_identifier !== '') {
        body.resume.resume_identifier = state.resume_identifier;
      }
      if (state.job_description_identifier) {
        body.job_description_identifier = state.job_description_identifier;
      }
      if (state.dob) {
        body.resume.dob = state.dob;
      }
      await Validation.candidateApplyJobSchema.validate(body, {
        abortEarly: false,
      });
      const res: any = await Models.candidateModel.createApplyJob(body);
      toastify(
        `Thank you. You have successfully applied to ${state.requisitionData.job_title} `,
      );
      window.close();
      setState({ applying: false });
      setState({
        email: '',
        phone: null,
        work_sample: '',
        first_name: '',
        last_name: '',
        obj: '',
        gender: [],
        address: '',
        total_experience: { label: '', value: '' },
        relevant_experience: { label: '', value: ' ' },
        notice_period: { label: '', value: '' },
        education: [],
        reasons_for_job_change: { label: '', value: '' },
        willingness_to_relocate: '',
        remote_work_preference: '',
        current_salary: '',
        expected_salary: '',
        errorArray: [],
        resume_file: '',
        certification_file: [],
        limit: 1,
        data: [],
        pageNumber: '',
        countryList: [],
        stateList: [],
        skills_value: [],
        profile_picture: '',
        city: { label: '', value: '' },
        cityList: [],
        resume_identifier: '',
        job_description_identifier: '',
        employers: [],
        employerLength: 1,
        job_description_file: '',
        state: { label: '', value: '' },
        country: { label: '', value: '' },
        parsing: false,
        requisitionData: {},
        disabled: false,
        active: headerData[0]
      });
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
        applying: false,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const getCountriesList = async () => {
    try {
      const res: any = await Models.country.getAllCountries();
      let countryList: any = [];
      res?.map((country: any) =>
        countryList.push({
          label: country.name,
          value: country.iso2,
        }),
      );
      setState({ countryList });
    } catch (error) {}
  };

  const getStatesListByCountry = async (isoCode: string) => {
    try {
      const res: any = await Models.country.getStatesOfCountry(isoCode);
      let stateList: any = [];
      res?.map((item: any) =>
        stateList.push({
          label: item.name,
          value: item.name,
        }),
      );
      setState({ stateList, allStates: res });
    } catch (error) {}
  };

  const getCityListByStates = async (country: string, stateIso: string) => {
    try {
      const res: any = await Models.country.getCitiesByCountry(
        country,
        stateIso,
      );
      let cityList: any = [];
      res?.map((item: any) =>
        cityList.push({
          label: item.name,
          value: item.name,
        }),
      );
      setState({ cityList });
    } catch (error) {}
  };
  //Logic

  const fileHandle = (data) => {
    let fileArray: any = state.certification_file;
    let file_name: any = gets3FileName(data);
    fileArray.push({
      title: file_name,
      link: file_name,
    });
    setState({
      certification_file: fileArray,
    });
  };

  const addSampleLink = () => {
    let data: any = [];
    for (let i = 0; i < state.limit; i++) {
      data.push({
        link: state.data[i]?.link || '',
        title: state.data[i]?.title || '',
      });
    }
    setState({ data });
  };

  const handleChange = (index, value) => {
    let data: any = state.data;
    data[index].link = value;
    data[index].title = value.split('/').pop();
    setState({ data });
  };

  const certificateFileRemove = (index: number) => {
    let data: any = state.certification_file;
    data.splice(index, 1);
    setState({ certification_file: data });
  };

  const uploadResume = (resume_file: string) => {
    let fileName = resume_file.split('.').pop();
    setState({
      resume_file,
    });
    if (fileName === 'pdf' || fileName === 'doc' || fileName === 'docx') {
      resumeParser(resume_file);
    }
  };

  const removeWorkSample = (index: number) => {
    let data: any = state.data;
    data.splice(index, 1);
    setState({ data, limit: state.limit - 1 });
  };

  const uploadFiles = async (file) => {
    try {
      const content: any = await Models.auth.uploadFile({
        file_type: file.type,
        file_name: file.name,
        folder: 'resume_profile',
      });
      uploadFile(file, content.signedRequest, content.url, function (res) {
        setState({ profile_picture: res?.url });
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const recquisitionById = async () => {
    try {
      const body = {
        external_id: requisition_id,
      };
      const response: any = await Models.jobRecquisition.getRecquisitionByExternalId(
        body,
      );

      setState({
        job_description_file: response.data.job_description_file,
        requisitionData: response.data,
      });
     if(response.data?.subscription?.subscribed) {
        setState({ planName: response.data.subscription.plan_name});
      }
      // dispatch
      getRecquisitionById(response.data);
      setState({ loading: false });
    } catch (error) {
      toastifyError(error);
    }
  };

  const handleChangeState = (value: any) => {
    let index: any = _.findIndex(state.allStates, {
      name: value?.label,
    });
    getCityListByStates(state.country_code, state.allStates[index].iso2);
    setState({ state: value });
  };

  const checkDisabled = () => {
    let skills: any = [];
    state.skills_value.map((item) => skills.push(item.label));
    if (
      state.email !== '' &&
      state.phone !== '' &&
      state.resume_file !== '' &&
      state.first_name !== '' &&
      state.last_name !== '' &&
      state.address !== '' &&
      state.total_experience.value !== '' &&
      state.relevant_experience.value !== '' &&
      state.notice_period.value !== '' &&
      state.reasons_for_job_change.value !== '' &&
      state.pincode !== '' &&
      state.state.value !== '' &&
      state.country.label !== '' &&
      skills.length > 0 &&
      state.city.value !== ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  const removeEmployer = (index: number) => {
    let employers: any = state.employers;
    employers.splice(index, 1);
    setState({ employers, employerLength: state.employerLength - 1 });
  };

  const handleSetEmployer = (value: string, index: number, key: string) => {
    let fileArray: any = state.employers;
    fileArray[index][key] = value;
    setState({
      employers: fileArray,
    });
  };

  const getEmployerData = () => {
    let data: any[] = [];
    for (let i = 0; i < state.employerLength; i++) {
      data.push({
        job_title: state.employers[i]?.job_title || '',
        start_date: state.employers[i]?.start_date || '',
        end_date: state.employers[i]?.end_date || '',
        employer: state.employers[i]?.employer || '',
      });
    }
    setState({ employers: data });
  };
  const getSkillsData = async () => {
    try {
      const res: any = await Models.skills.getManySkills();
      setState({ skills_data: res.data.skills });
    } catch (error: any) {
      toastifyError(error);
    }
  };
  const getEducationData = () => {
    let data: any[] = [];
    for (let i = 0; i < state.educationLength; i++) {
      data.push({
        education_level: state.education[i]?.education_level || '',
        start_date: state.education[i]?.start_date || '',
        end_date: state.education[i]?.end_date || '',
        organisation: state.education[i]?.employer || '',
      });
    }
    setState({ education: data });
  };

  const removeEducation = (index: number) => {
    let education: any = state.education;
    education.splice(index, 1);
    setState({ education, educationLength: state.educationLength - 1 });
  };

  const handleSetEducation = (value: string, index: number, key: string) => {
    let fileArray: any = state.education;
    fileArray[index][key] = value;
    setState({
      education: fileArray,
    });
  };

  useEffect(() => {
    getEducationData();
  }, [state.educationLength]);

  useEffect(() => {
    getEmployerData();
    recquisitionById();
  }, [state.employerLength]);

  const headerData = ['Professional Information', 'Personal Information'];
  return (
    <div className="application_container">
      <div className="application_wrapper">
        <div className="application_header">
          <div className="application_header_text">Job Application</div>
          <div className="application_header_buttons">
            {state.active === 'Personal Information' &&
              (state.applying ? (
                <Loader />
              ) : (
                <PrimaryButton
                  onClick={applyJob}
                  disabled={checkDisabled()}
                  text={'Submit'}
                  icon={checkDisabled() ? Assets.tick_black : Assets.tick}
                  className={'header_button'}
                />
              ))}
          </div>
        </div>
        <div className="application_sub_header">
          <ToggleHeader
            data={headerData}
            onChange={(item) => setState({ active: item })}
            active={state.active}
          />
        </div>
        {!state.isMobile ? (
          state.active === 'Professional Information' ? (
            <div className="application_page_1">
              <div className="application_wrapper">
                <div className="application_add_resume_container">
                  {/* {subscriptionPlan(state.planName,SUBSCRIPTION_FEATURES.SINGLEUPLOADRESUME) && ( */}
                    <>
                      <div className="application_add_resume_wrapper">
                        <div className="application_input_heading">
                          Add resume<span className="required_field">*</span>
                        </div>
                        <FileUpload
                          ref={inputFileRef}
                          folder={'resume'}
                          onChange={(resume_file) => uploadResume(resume_file)}
                          accept_types={'.doc, .docx,.pdf'}>
                          <img
                            onClick={() => inputFileRef.current.openUpload()}
                            src={Assets.resume_upload}
                            alt="add resume"
                          />
                        </FileUpload>
                        {state.errorArray.length > 0 && (
                          <div className="input_field_error">
                            {state.errorArray.map((item: any) => {
                              return (
                                item.path === 'resume.resume' && item.errors
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div className="application_sub_heading">
                        Link to Work Samples
                      </div>
                    </>
                  {/* )} */}
                  <div className="application_dropdown_container">
                    <div className="application_input_heading">
                      Work Sample Link
                    </div>
                    {state.data.map((item, index: number) => {
                      return (
                        <Input
                          type="text"
                          error={state.errorArray}
                          value={item.link}
                          name={'resume.work_samples'}
                          icon={Assets.black_close}
                          iconOnPress={() => removeWorkSample(index)}
                          onChange={(work_sample) => {
                            handleChange(index, work_sample);
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="application_add_button">
                    <PrimaryButton
                      onClick={() =>
                        setState({
                          limit: state.limit + 1,
                        })
                      }
                      icon={Assets.add}
                      text="Add Another Link"
                    />
                  </div>
                </div>
                <div className="application_resume_preview_container">
                  {state.parsing ? (
                    <>
                      <div className="application_input_heading">
                        Parsing resume <Loader />
                      </div>
                    </>
                  ) : (
                    state.resume_file.length > 0 && (
                      <div className="application_input_heading">
                        Resume parsed
                      </div>
                    )
                  )}
                  {state.resume_file.length > 0 && (
                    <>
                      <div className="application_input_heading">
                        Resume Preview
                      </div>
                      {state.resume_file.split('.').pop() == 'pdf' ? (
                        <PdfViewer file={state.resume_file} {...props} />
                      ) : (
                        <div className="application_sub_heading">
                          <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${state.resume_file}`}
                            width="98%"
                            height="98%"
                            frameBorder="0">
                            This is an embedded document by AskHugo.
                          </iframe>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="application_page_2">
              <div className="application_form_container">
                <div className="application_profile_container">
                  <UploadComponent
                    icon={Assets.camera}
                    onChange={(file) => {
                      setState({ file: file[0] });
                      uploadFiles(file[0]);
                    }}
                    iconOnClick={() => setState({ profile_picture: '' })}
                    image={state.profile_picture}
                    accept_types={'.jpg, .jpeg,.png'}
                  />
                  {state.errorArray?.length > 0 && (
                    <div className="input_field_error">
                      {state.errorArray?.map((item: any) => {
                        return (
                          item.path === 'resume.profile_picture' && item.errors
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="application_name_container">
                  <div className="application_firstname_container width19">
                    <div className="application_input_heading">
                      Email<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(email: string) => {
                        setState({ email });
                      }}
                      value={state.email}
                      error={state.errorArray}
                      name="resume.email"
                    />
                  </div>
                  <div className="application_firstname_container width19">
                    <div className="application_input_heading">
                      Phone number<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(phone: string) => {
                        setState({ phone });
                      }}
                      value={state.phone}
                      error={state.errorArray}
                      name="resume.phone"
                    />
                  </div>
                  <div className="application_firstname_container width19">
                    <div className="application_input_heading">
                      First Name<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(first_name: string) => {
                        setState({ first_name });
                      }}
                      value={state.first_name}
                      error={state.errorArray}
                      name="resume.first_name"
                    />
                  </div>
                  <div className="application_lastname_container width19">
                    <div className="application_input_heading">
                      Last Name<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(last_name: string) => {
                        setState({ last_name });
                      }}
                      value={state.last_name}
                      name="resume.last_name"
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_gender_container width19">
                    <div className="application_input_heading">Gender</div>
                    <Dropdown
                      onChange={(gender) => {
                        setState({
                          gender,
                        });
                      }}
                      value={state.gender}
                      name="resume.gender"
                      error={state.errorArray}
                      data={getDropdownData(gender)}
                    />
                  </div>
                </div>
                <div className="application_address">
                  <div className="application_city_container">
                    <div className="application_input_heading">
                      Date of birth
                    </div>
                    <DatePickerComponent
                      value={state.dob}
                      onChange={(dob: string) => {
                        setState({ dob });
                      }}
                    />
                  </div>
                  <div className="application_address_container">
                    <div className="application_input_heading">
                      Full Address <span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(address: string) => {
                        setState({ address });
                      }}
                      value={state.address}
                      name="resume.address"
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_country_container">
                    <div className="application_input_heading">
                      Country<span className="required_field">*</span>
                    </div>
                    <Dropdown
                      data={state.countryList}
                      setActive={(value: any) => {
                        getStatesListByCountry(value?.value);
                        setState({
                          country: value,
                          country_code: value?.value,
                        });
                      }}
                      value={state.country}
                      name={'resume.country'}
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_city_container">
                    <div className="application_input_heading">
                      State <span className="required_field">*</span>
                    </div>
                    <Dropdown
                      data={state.stateList}
                      setActive={(value: any) => {
                        handleChangeState(value);
                      }}
                      value={state.state}
                      name={'resume.state'}
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_city_container">
                    <div className="application_input_heading">
                      City<span className="required_field">*</span>
                    </div>
                    <Dropdown
                      data={state.cityList}
                      setActive={(value: any) => {
                        setState({ city: value });
                      }}
                      value={state.city}
                      name={'resume.city'}
                      error={state.errorArray}
                    />
                  </div>
                </div>
                <div className="application_experience_container">
                  <div className="application_pincode_container">
                    <div className="application_input_heading">
                      Pincode<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'number'}
                      onChange={(pincode: string) => {
                        setState({ pincode });
                      }}
                      value={state.pincode}
                      name="resume.pincode"
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_totalexp_container width25">
                    <div className="application_input_heading">
                      Total Years of Experience
                      <span className="required_field">*</span>
                    </div>
                    <Dropdown
                      onChange={(total_experience) => {
                        setState({
                          total_experience: total_experience,
                        });
                      }}
                      data={getDropdownData(getExperience())}
                      value={state.total_experience}
                      name="resume.total_experience"
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_relevantexp_container width25">
                    <div className="application_input_heading">
                      Total Years of Relevant Experience
                      <span className="required_field">*</span>
                    </div>
                    <Dropdown
                      name="resume.relevant_experience"
                      error={state.errorArray}
                      onChange={(relevant_experience) => {
                        setState({
                          relevant_experience: relevant_experience,
                        });
                      }}
                      data={getDropdownData(getExperience())}
                      value={state.relevant_experience}
                    />
                  </div>
                  <div className="application_notice_container width25">
                    <div className="application_input_heading">
                      Notice Period<span className="required_field">*</span>
                    </div>
                    <Dropdown
                      onChange={(notice_period) => {
                        setState({
                          notice_period,
                        });
                      }}
                      name="resume.notice_period"
                      error={state.errorArray}
                      data={notice_period}
                      value={state.notice_period}
                    />
                  </div>
                  {/* <div className="application_education_container width25">
                    <div className="application_input_heading">
                      Education Level<span className="required_field">*</span>
                    </div>
                    <Dropdown
                      onChange={(education) => {
                        setState({
                          education,
                        });
                      }}
                      error={state.errorArray}
                      name="resume.education"
                      data={getDropdownData(education_level)}
                      value={state.education}
                    />
                  </div> */}
                </div>
                <div className="application_relocate_container">
                  <div className="application_reason_container width33">
                    <div className="application_input_heading">
                      Reason for Job Change
                      <span className="required_field">*</span>
                    </div>
                    <Dropdown
                      onChange={(reasons_for_job_change) => {
                        setState({
                          reasons_for_job_change,
                        });
                      }}
                      name="resume.reason_for_job_change"
                      error={state.errorArray}
                      data={getDropdownData(reasons_for_job_change)}
                      value={state.reasons_for_job_change}
                    />
                  </div>
                  <div className="application_willingness_container width33">
                    <div className="application_input_heading">
                      Willingness to Relocate
                    </div>
                    <Dropdown
                      onChange={(willingness_to_relocate) => {
                        setState({
                          willingness_to_relocate,
                        });
                      }}
                      name="resume.willing_to_relocate"
                      error={state.errorArray}
                      data={getDropdownData(willing_to_relocate)}
                      value={state.willingness_to_relocate}
                    />
                  </div>
                  <div className="application_preference_container width33">
                    <div className="application_input_heading">
                      Remote work Preference
                    </div>
                    <Dropdown
                      onChange={(remote_work_preference) => {
                        setState({
                          remote_work_preference,
                        });
                      }}
                      name="resume.remote_work_preference"
                      error={state.errorArray}
                      data={getDropdownData(remote_work_preference)}
                      value={state.remote_work_preference}
                    />
                  </div>
                </div>
                <div className="application_salary_container">
                  <div className="application_current_salary_container width33">
                    <div className="application_input_heading">
                      Current Salary
                    </div>
                    <Input
                      type={'text'}
                      error={state.errorArray}
                      onChange={(current_salary: any) => {
                        setState({ current_salary });
                      }}
                      value={state.current_salary}
                      name="resume.current_salary"
                    />
                  </div>
                  <div className="application_current_expected_container width33">
                    <div className="application_input_heading">
                      Expected Salary
                    </div>
                    <Input
                      type={'text'}
                      error={state.errorArray}
                      onChange={(expected_salary: any) => {
                        setState({ expected_salary });
                      }}
                      value={state.expected_salary}
                      name="resume.expected_salary"
                    />
                  </div>
                </div>
                <div className="application_skills_container">
                  <div className="application_input_heading">
                    Skills<span className="required_field">*</span>
                  </div>
                  <MultiSelectDropdown
                    data={getDropdownData(state.skills_data)}
                    onChange={(data: any) => {
                      setState({
                        skills_value: data,
                      });
                    }}
                    value={state.skills_value}
                    name="resume.skills"
                    error={state.errorArray}
                  />
                </div>
              </div>
              <div className="application_employer_container">
                <div className="application_sub_heading">Previous Employer</div>
                <div className="application_employer_wrapper">
                  {state.employers.map((item: any, index: any) => {
                    return (
                      <div className="application_employer">
                        <div className="application_employer_input">
                          <div className="application_sub_heading">
                            Employer
                          </div>
                          <Input
                            type={'text'}
                            error={state.errorArray}
                            onChange={(value: any) => {
                              handleSetEmployer(value, index, 'employer');
                            }}
                            value={item.employer}
                            name="item.employer"
                          />
                        </div>
                        <div className="application_date_container">
                          <div className="employer_start_date_wrapper">
                            <div className="application_sub_heading">Start</div>
                            <DatePickerComponent
                              name={'item.start_date'}
                              onChange={(value: string) =>
                                handleSetEmployer(value, index, 'start_date')
                              }
                              value={item.start_date}
                            />
                          </div>
                          <div className="employer_start_date_wrapper">
                            <div className="application_sub_heading">End</div>
                            <DatePickerComponent
                              name={'item.end_date'}
                              onChange={(value: string) =>
                                handleSetEmployer(value, index, 'end_date')
                              }
                              value={item.end_date}
                            />
                          </div>
                        </div>
                        <div className="application_employer_input">
                          <div className="application_sub_heading">
                            Job title
                          </div>
                          <Input
                            type={'text'}
                            onChange={(value: any) => {
                              handleSetEmployer(value, index, 'job_title');
                            }}
                            value={item.job_title}
                            name="item.job_title"
                          />
                        </div>
                        <div className="employer_navbutton_wrapper">
                          <Navbutton
                            icon={Assets.trash}
                            onClick={() => {
                              removeEmployer(index);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <PrimaryButton
                  icon={Assets.add}
                  onClick={() =>
                    setState({ employerLength: state.employerLength + 1 })
                  }
                  className="add_employer"
                  text={'Add Employer'}
                />
              </div>
              <div className="application_employer_container">
                <div className="application_sub_heading">Education</div>
                <div className="application_employer_wrapper">
                  {state.education.map((item: any, index: any) => {
                    return (
                      <div className="application_employer">
                        <div className="application_employer_input">
                          <div className="application_sub_heading">
                            Education Level
                          </div>
                          <Input
                            type={'text'}
                            error={state.errorArray}
                            onChange={(value: any) => {
                              handleSetEducation(
                                value,
                                index,
                                'education_level',
                              );
                            }}
                            value={item.education_level}
                            name="item.education_level"
                          />
                        </div>
                        <div className="application_date_container">
                          <div className="employer_start_date_wrapper">
                            <div className="application_sub_heading">Start</div>
                            <DatePickerComponent
                              name={'item.start_date'}
                              onChange={(value: string) =>
                                handleSetEducation(value, index, 'start_date')
                              }
                              value={item.start_date}
                            />
                          </div>
                          <div className="employer_start_date_wrapper">
                            <div className="application_sub_heading">End</div>
                            <DatePickerComponent
                              name={'item.end_date'}
                              onChange={(value: string) =>
                                handleSetEducation(value, index, 'end_date')
                              }
                              value={item.end_date}
                            />
                          </div>
                        </div>
                        <div className="application_employer_input">
                          <div className="application_sub_heading">
                            Organization
                          </div>
                          <Input
                            type={'text'}
                            onChange={(value: any) => {
                              handleSetEducation(value, index, 'organisation');
                            }}
                            value={item.organisation}
                            name="item.organisation"
                          />
                        </div>
                        <div className="employer_navbutton_wrapper">
                          <Navbutton
                            icon={Assets.trash}
                            onClick={() => {
                              removeEducation(index);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <PrimaryButton
                  icon={Assets.add}
                  onClick={() =>
                    setState({ educationLength: state.educationLength + 1 })
                  }
                  className="add_employer"
                  text={'Add Education'}
                />
              </div>
              <div className="application_cretificate_container">
                <div className="application_sub_heading">
                  Professional Certificates
                </div>
                {state.errorArray?.length > 0 && (
                  <div className="input_field_error">
                    {state.errorArray.map((item: any) => {
                      return (
                        item.path === 'resume.certifications' && item.errors
                      );
                    })}
                  </div>
                )}
                <div className="application_cretificate_wrapper">
                  {state.certification_file.map((item: any, index: any) => {
                    return (
                      <div className="application_certificate_one">
                        <Certification
                          onClick={() => certificateFileRemove(index)}
                          title={item?.title?.split('/').pop()}
                        />
                      </div>
                    );
                  })}
                  <div className="application_certificate_add_tab">
                    <FileUpload
                      multiple
                      ref={inputFileRef}
                      onChange={(certification_file) => {
                        fileHandle(certification_file);
                      }}
                      accept_types={'.jpg, .jpeg,.png,.pdf'}
                      >
                      <PrimaryButton
                        icon={Assets.add}
                        onClick={() => inputFileRef.current.openUpload()}
                        className="green_add_button"
                        text={'Add Certification'}
                      />
                    </FileUpload>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="application_mobile_container">
            <div className="application_page_1">
              <div className="application_wrapper">
                <div className="application_add_resume_container">
                  <div className="application_add_resume_wrapper">
                    <div className="application_input_heading">
                      Add resume<span className="required_field">*</span>
                    </div>
                    <FileUpload
                      ref={inputFileRef}
                      folder={'resume'}
                      onChange={(resume_file) =>{ 
                        
                        
                        uploadResume(resume_file)}}
                      accept_types={'.doc, .docx,.pdf'}
                      >
                      <img
                        onClick={() => inputFileRef.current.openUpload()}
                        src={Assets.resume_upload}
                        alt="add resume"
                        className="upload_image"
                      />
                    </FileUpload>
                    <div className="application_resume_preview_container">
                      {state.parsing ? (
                        <>
                          <div className="application_input_heading">
                            Parsing resume <Loader />
                          </div>
                        </>
                      ) : (
                        state.resume_file.length > 0 && (
                          <div style={{ width: '100%' }}>
                            Documents uploaded{' '}
                            {gets3FileName(state.resume_file)}
                          </div>
                        )
                      )}
                    </div>
                    {state.errorArray.length > 0 && (
                      <div className="input_field_error">
                        {state.errorArray.map((item: any) => {
                          return item.path === 'resume.resume' && item.errors;
                        })}
                      </div>
                    )}
                  </div>
                  <div className="application_sub_heading">
                    Link to Work Samples
                  </div>
                  <div className="application_dropdown_container">
                    <div className="application_input_heading">
                      Work Sample Link
                    </div>
                    {state.data.map((item, index: number) => {
                      return (
                        <Input
                          type="text"
                          error={state.errorArray}
                          value={item.link}
                          name={'resume.work_samples'}
                          icon={Assets.black_close}
                          iconOnPress={() => removeWorkSample(index)}
                          onChange={(work_sample) => {
                            handleChange(index, work_sample);
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="application_add_button">
                    <PrimaryButton
                      onClick={() =>
                        setState({
                          limit: state.limit + 1,
                        })
                      }
                      icon={Assets.add}
                      text="Add Another Link"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="application_page_2">
              <div className="application_form_container">
                <div className="application_profile_container">
                  <UploadComponent
                    icon={Assets.camera}
                    onChange={(file) => {
                      setState({ file: file[0] });
                      uploadFiles(file[0]);
                    }}
                    iconOnClick={() => setState({ profile_picture: '' })}
                    image={state.profile_picture}
                    accept_types={'.jpg, .jpeg,.png'}
                  />
                  {state.errorArray?.length > 0 && (
                    <div className="input_field_error">
                      {state.errorArray?.map((item: any) => {
                        return (
                          item.path === 'resume.profile_picture' && item.errors
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="application_name_container">
                  <div className="application_firstname_container width19">
                    <div className="application_input_heading">
                      Email<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(email: string) => {
                        setState({ email });
                      }}
                      value={state.email}
                      error={state.errorArray}
                      name="resume.email"
                    />
                  </div>
                  <div className="application_firstname_container width19">
                    <div className="application_input_heading">
                      Phone number<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(phone: string) => {
                        setState({ phone });
                      }}
                      value={state.phone}
                      error={state.errorArray}
                      name="resume.phone"
                    />
                  </div>
                  <div className="application_firstname_container width19">
                    <div className="application_input_heading">
                      First Name<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(first_name: string) => {
                        setState({ first_name });
                      }}
                      value={state.first_name}
                      error={state.errorArray}
                      name="resume.first_name"
                    />
                  </div>
                  <div className="application_lastname_container width19">
                    <div className="application_input_heading">
                      Last Name<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(last_name: string) => {
                        setState({ last_name });
                      }}
                      value={state.last_name}
                      name="resume.last_name"
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_gender_container width19">
                    <div className="application_input_heading">Gender</div>
                    <Dropdown
                      onChange={(gender) => {
                        setState({
                          gender,
                        });
                      }}
                      value={state.gender}
                      name="resume.gender"
                      error={state.errorArray}
                      data={getDropdownData(gender)}
                    />
                  </div>
                </div>
                <div className="application_address">
                  <div className="application_address_container">
                    <div className="application_input_heading">
                      Full Address <span className="required_field">*</span>
                    </div>
                    <Input
                      type={'text'}
                      onChange={(address: string) => {
                        setState({ address });
                      }}
                      value={state.address}
                      name="resume.address"
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_country_container">
                    <div className="application_input_heading">
                      Country<span className="required_field">*</span>
                    </div>
                    <Dropdown
                      data={state.countryList}
                      setActive={(value: any) => {
                        getStatesListByCountry(value?.value);
                        setState({
                          country: value,
                          country_code: value?.value,
                        });
                      }}
                      value={state.country}
                      name={'resume.country'}
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_city_container">
                    <div className="application_input_heading">
                      State <span className="required_field">*</span>
                    </div>
                    <Dropdown
                      data={state.stateList}
                      setActive={(value: any) => {
                        handleChangeState(value);
                      }}
                      value={state.state}
                      name={'resume.state'}
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_city_container">
                    <div className="application_input_heading">
                      City<span className="required_field">*</span>
                    </div>
                    <Dropdown
                      data={state.cityList}
                      setActive={(value: any) => {
                        setState({ city: value });
                      }}
                      value={state.city}
                      name={'resume.city'}
                      error={state.errorArray}
                    />
                  </div>
                </div>
                <div className="application_experience_container">
                  <div className="application_pincode_container">
                    <div className="application_input_heading">
                      Pincode<span className="required_field">*</span>
                    </div>
                    <Input
                      type={'number'}
                      onChange={(pincode: string) => {
                        setState({ pincode });
                      }}
                      value={state.pincode}
                      name="resume.pincode"
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_totalexp_container width25">
                    <div className="application_input_heading">
                      Total Years of Experience
                      <span className="required_field">*</span>
                    </div>
                    <Dropdown
                      onChange={(total_experience) => {
                        setState({
                          total_experience: total_experience,
                        });
                      }}
                      data={getDropdownData(getExperience())}
                      value={state.total_experience}
                      name="resume.total_experience"
                      error={state.errorArray}
                    />
                  </div>
                  <div className="application_relevantexp_container width25">
                    <div className="application_input_heading">
                      Total Years of Relevant Experience
                      <span className="required_field">*</span>
                    </div>
                    <Dropdown
                      name="resume.relevant_experience"
                      error={state.errorArray}
                      onChange={(relevant_experience) => {
                        setState({
                          relevant_experience: relevant_experience,
                        });
                      }}
                      data={getDropdownData(getExperience())}
                      value={state.relevant_experience}
                    />
                  </div>
                  <div className="application_notice_container width25">
                    <div className="application_input_heading">
                      Notice Period<span className="required_field">*</span>
                    </div>
                    <Dropdown
                      onChange={(notice_period) => {
                        setState({
                          notice_period,
                        });
                      }}
                      name="resume.notice_period"
                      error={state.errorArray}
                      data={notice_period}
                      value={state.notice_period}
                    />
                  </div>
                  {/* <div className="application_education_container width25">
                    <div className="application_input_heading">
                      Education Level<span className="required_field">*</span>
                    </div>
                    <Dropdown
                      onChange={(education) => {
                        setState({
                          education,
                        });
                      }}
                      error={state.errorArray}
                      name="resume.education"
                      data={getDropdownData(education_level)}
                      value={state.education}
                    />
                  </div> */}
                </div>
                <div className="application_relocate_container">
                  <div className="application_reason_container width33">
                    <div className="application_input_heading">
                      Reason for Job Change
                      <span className="required_field">*</span>
                    </div>
                    <Dropdown
                      onChange={(reasons_for_job_change) => {
                        setState({
                          reasons_for_job_change,
                        });
                      }}
                      name="resume.reason_for_job_change"
                      error={state.errorArray}
                      data={getDropdownData(reasons_for_job_change)}
                      value={state.reasons_for_job_change}
                    />
                  </div>
                  <div className="application_willingness_container width33">
                    <div className="application_input_heading">
                      Willingness to Relocate
                    </div>
                    <Dropdown
                      onChange={(willingness_to_relocate) => {
                        setState({
                          willingness_to_relocate,
                        });
                      }}
                      name="resume.willing_to_relocate"
                      error={state.errorArray}
                      data={getDropdownData(willing_to_relocate)}
                      value={state.willingness_to_relocate}
                    />
                  </div>
                  <div className="application_preference_container width33">
                    <div className="application_input_heading">
                      Remote work Preference
                    </div>
                    <Dropdown
                      onChange={(remote_work_preference) => {
                        setState({
                          remote_work_preference,
                        });
                      }}
                      name="resume.remote_work_preference"
                      error={state.errorArray}
                      data={getDropdownData(remote_work_preference)}
                      value={state.remote_work_preference}
                    />
                  </div>
                </div>
                <div className="application_salary_container">
                  <div className="application_current_salary_container width33">
                    <div className="application_input_heading">
                      Current Salary
                    </div>
                    <Input
                      type={'text'}
                      error={state.errorArray}
                      onChange={(current_salary: any) => {
                        setState({ current_salary });
                      }}
                      value={state.current_salary}
                      name="resume.current_salary"
                    />
                  </div>
                  <div className="application_current_expected_container width33">
                    <div className="application_input_heading">
                      Expected Salary
                    </div>
                    <Input
                      type={'text'}
                      error={state.errorArray}
                      onChange={(expected_salary: any) => {
                        setState({ expected_salary });
                      }}
                      value={state.expected_salary}
                      name="resume.expected_salary"
                    />
                  </div>
                </div>
                <div className="application_skills_container">
                  <div className="application_input_heading">
                    Skills<span className="required_field">*</span>
                  </div>
                  <MultiSelectDropdown
                    data={getDropdownData(skills)}
                    onChange={(data: any) => {
                      setState({
                        skills_value: data,
                      });
                    }}
                    value={state.skills_value}
                    name="resume.skills"
                    error={state.errorArray}
                  />
                </div>
              </div>
              <div className="application_employer_container">
                <div className="application_sub_heading">Previous Employer</div>
                <div className="application_employer_wrapper">
                  {state.employers.map((item: any, index: any) => {
                    return (
                      <div className="application_employer">
                        <div className="application_employer_input">
                          <div className="application_employee_trash_button">
                            <div className="application_sub_heading">
                              Employer
                            </div>
                            <div className="employer_navbutton_wrapper">
                              <img
                                src={Assets.trash}
                                onClick={() => {
                                  removeEmployer(index);
                                }}
                                className="trash_button"
                              />
                            </div>
                          </div>
                          <Input
                            type={'text'}
                            error={state.errorArray}
                            onChange={(value: any) => {
                              handleSetEmployer(value, index, 'employer');
                            }}
                            value={item.employer}
                            name="item.employer"
                          />
                        </div>
                        <div className="application_date_container">
                          <div className="employer_start_date_wrapper">
                            <div className="application_sub_heading">Start</div>
                            <DatePickerComponent
                              name={'item.start_date'}
                              onChange={(value: string) =>
                                handleSetEmployer(value, index, 'start_date')
                              }
                              value={item.start_date}
                            />
                          </div>
                          <div className="employer_start_date_wrapper">
                            <div className="application_sub_heading">End</div>
                            <DatePickerComponent
                              name={'item.end_date'}
                              onChange={(value: string) =>
                                handleSetEmployer(value, index, 'end_date')
                              }
                              value={item.end_date}
                            />
                          </div>
                        </div>
                        <div className="application_employer_input">
                          <div className="application_sub_heading">
                            Job title
                          </div>
                          <Input
                            type={'text'}
                            onChange={(value: any) => {
                              handleSetEmployer(value, index, 'job_title');
                            }}
                            value={item.job_title}
                            name="item.job_title"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <PrimaryButton
                  icon={Assets.add}
                  onClick={() =>
                    setState({ employerLength: state.employerLength + 1 })
                  }
                  className="add_employer"
                  text={'Add Employer'}
                />
              </div>
              <div className="application_employer_container">
                <div className="application_sub_heading">Education Details</div>
                <div className="application_employer_wrapper">
                  {state.education.map((item: any, index: any) => {
                    return (
                      <div className="application_employer">
                        <div className="application_employer_input">
                          <div className="application_employee_trash_button">
                            <div className="application_sub_heading">
                              Education
                            </div>
                            <div className="employer_navbutton_wrapper">
                              <img
                                src={Assets.trash}
                                onClick={() => {
                                  removeEducation(index);
                                }}
                                className="trash_button"
                              />
                            </div>
                          </div>
                          <Input
                            type={'text'}
                            error={state.errorArray}
                            onChange={(value: any) => {
                              handleSetEducation(
                                value,
                                index,
                                'education_level',
                              );
                            }}
                            value={item.education_level}
                            name="item.education_level"
                          />
                        </div>
                        <div className="application_date_container">
                          <div className="employer_start_date_wrapper">
                            <div className="application_sub_heading">Start</div>
                            <DatePickerComponent
                              name={'item.start_date'}
                              onChange={(value: string) =>
                                handleSetEducation(value, index, 'start_date')
                              }
                              value={item.start_date}
                            />
                          </div>
                          <div className="employer_start_date_wrapper">
                            <div className="application_sub_heading">End</div>
                            <DatePickerComponent
                              name={'item.end_date'}
                              onChange={(value: string) =>
                                handleSetEducation(value, index, 'end_date')
                              }
                              value={item.end_date}
                            />
                          </div>
                        </div>
                        <div className="application_employer_input">
                          <div className="application_sub_heading">
                            Organization
                          </div>
                          <Input
                            type={'text'}
                            onChange={(value: any) => {
                              handleSetEducation(value, index, 'organisation');
                            }}
                            value={item.organisation}
                            name="item.organisation"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <PrimaryButton
                  icon={Assets.add}
                  onClick={() =>
                    setState({ educationLength: state.educationLength + 1 })
                  }
                  className="add_employer"
                  text={'Add Education'}
                />
              </div>
              <div className="application_cretificate_container">
                <div className="application_sub_heading">
                  Professional Certificates
                </div>
                {state.errorArray?.length > 0 && (
                  <div className="input_field_error">
                    {state.errorArray.map((item: any) => {
                      return (
                        item.path === 'resume.certifications' && item.errors
                      );
                    })}
                  </div>
                )}
                <div className="application_cretificate_wrapper">
                  {state.certification_file.map((item: any, index: any) => {
                    return (
                      <div className="application_certificate_one">
                        <Certification
                          onClick={() => certificateFileRemove(index)}
                          title={item?.title?.split('/').pop()}
                        />
                      </div>
                    );
                  })}
                  <div className="application_certificate_add_tab">
                    <FileUpload
                      multiple
                      ref={certificateRef}
                      onChange={(certification_file) => {
                        fileHandle(certification_file);
                      }}
                      accept_types={'.jpg, .jpeg,.png,.pdf'}
                      >
                      <PrimaryButton
                        icon={Assets.add}
                        onClick={() => certificateRef.current.openUpload()}
                        className="green_add_button"
                        text={'Add Certification'}
                      />
                    </FileUpload>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile_footer_button">
              <PrimaryButton
                onClick={applyJob}
                text={'Submit'}
                icon={Assets.tick}
                className={'header_button'}
              />
            </div>
          </div>
        )}

        <div className="application_footer">
          {state.active !== 'Professional Information' && (
            <PrimaryButton
              icon={
                state.active !== 'Professional Information' ? Assets.left : null
              }
              text={'Previous'}
              className={'application_footer_buttons'}
              disabled={
                state.active == 'Professional Information' ? true : false
              }
              onClick={() => setState({ active: 'Professional Information' })}
            />
          )}
          {state.active !== 'Personal Information' && !state.isMobile && (
            <PrimaryButton
              icon={
                state.active !== 'Personal Information' ? Assets.right : null
              }
              text={'Next'}
              className={'application_footer_buttons'}
              onClick={() => setState({ active: 'Personal Information' })}
            />
          )}
          {state.active === 'Personal Information' &&
            (state.applying ? (
              <Loader />
            ) : (
              <PrimaryButton
                onClick={applyJob}
                disabled={checkDisabled()}
                text={'Submit'}
                icon={checkDisabled() ? Assets.tick_black : Assets.tick}
                className={'header_button'}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default JobApplication;
