import { GET_DEPARTMENTS } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  departmentList: [],
};

const departmentReducers = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_DEPARTMENTS :
      return {
        ...state,
        departmentList: action.payload,
      };
    default:
      return state;
  }
};

export default departmentReducers;
