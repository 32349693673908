import React, { useEffect } from 'react';
import './draggable.component.scss';
import Assets from 'imports/assets.import';
import { useSetState } from 'utils/functions.utils';
import { Loader, Navbutton } from 'utils/imports.utils';
import _ from 'lodash';
import csvDownload from 'json-to-csv-export';
import { Container, Draggable } from 'react-smooth-dnd';

interface IDraggableComponentProps {
  data?: any;
  loading?: boolean;
  titleArray?: any;
  action_btn?: string;
  action_btn_icon?: string;
  onPress?: any;
  type?: string;
  actionArray?: any;
  loadMore?: any;
  hasMore?: boolean;
  download?: boolean;
  refresh?: any;
  editApproval?: any;
}

export default function DraggableComponent(props: IDraggableComponentProps) {
  const [state, setState] = useSetState({
    isModalOpen: false,
    draggable: props.data,
  });

  useEffect(() => {
    setState({ draggable: props.data });
  }, [props.data]);

  if (_.isEmpty(state.draggable) && _.isEmpty(props.editApproval)) {
    return <div className="empty_text">No data found</div>;
  }

  //Drag and Drop Function
  function applyDrag(data, dropResult) {
    const { addedIndex, payload, removedIndex } = dropResult;
    let iterable: any = data;
    const workValue = iterable.slice();
    if (null !== removedIndex) {
      workValue.splice(removedIndex, 1);
    }
    if (null !== addedIndex) {
      workValue.splice(addedIndex, 0, payload);
    }
    return workValue;
  }
  return (
    <div className="list_component_container">
      {_.isEmpty(props.editApproval) && (
        <div className="list_component_row">
          {props.titleArray.map((item: any, index: number) => (
            <div
              style={{ width: item.width, ...item?.style }}
              className={`${
                index === 0 && 'list_component_block'
              }  list_component_name_wrapper`}>
              <div className="list_component_name list_component_heading">
                {item.label}
              </div>
            </div>
          ))}
          <div className="list_component_action_wrapper">
            {props.download && (
              <div className="csv_download">
                <Navbutton
                  icon={Assets.csv}
                  onClick={() => csvDownload(props.data)}
                  className="csv_download_icon"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {props.loading ? (
        <Loader />
      ) : (
        <Container
          getChildPayload={(index) => {
            return state.draggable[index];
          }}
          onDragStart={(e) => console.log('start', e)}
          onDragEnd={(e) => console.log('end', e)}
          onDrop={(dropResult) => {
            const newValue = applyDrag(state.draggable, dropResult);
            let workValue = state.draggable.slice();
            workValue = newValue;
            workValue.forEach((element, index) => {
              workValue[index].priority = index + 1;
            });
            props.refresh(workValue);
          }}>
          {state.draggable?.map((item: any, index: number) => (
            <Draggable key={index}>
              {!_.isEmpty(props.editApproval) &&
              props.editApproval.length - 1 < index ? (
                <div className="list_component_row" key={index}>
                  {props.titleArray.map((titleObj: any, titleIndex: number) => (
                    <div
                      className={`${
                        titleIndex === 1 && 'list_component_block'
                      }  list_component_name_wrapper`}
                      style={{ width: props?.titleArray[titleIndex]?.width }}>
                      {titleObj.label === '' ? (
                        <div className="drag_icon">
                          <img src={Assets.draggable_icon} />
                        </div>
                      ) : (
                        <div
                          className={`${
                            titleIndex !== 1 && 'list_component_subtitle'
                          }  list_component_name`}>
                          {titleObj.value === 'user'
                            ? item[props?.titleArray[titleIndex]?.value].label
                            : item[props?.titleArray[titleIndex]?.value]}
                        </div>
                      )}
                    </div>
                  ))}

                  <div
                    className="list_component_actions_wrapper"
                    style={{ width: props.actionArray?.style?.width }}>
                    {props.actionArray.actions.map((action: any) =>
                      action.component(item),
                    )}
                  </div>
                </div>
              ) : _.isEmpty(props.editApproval) ? (
                <div className="list_component_row" key={index}>
                  {props.titleArray.map((titleObj: any, titleIndex: number) => (
                    <div
                      className={`${
                        titleIndex === 1 && 'list_component_block'
                      }  list_component_name_wrapper`}
                      style={{ width: props?.titleArray[titleIndex]?.width }}>
                      {titleObj.label === '' ? (
                        <div className="drag_icon">
                          <img src={Assets.draggable_icon} />
                        </div>
                      ) : (
                        <div
                          className={`${
                            titleIndex !== 1 && 'list_component_subtitle'
                          }  list_component_name`}>
                          {titleObj.value === 'user'
                            ? item[props?.titleArray[titleIndex]?.value].label
                            : item[props?.titleArray[titleIndex]?.value]}
                        </div>
                      )}
                    </div>
                  ))}
                  {_.isEmpty(props.editApproval) && (
                    <div
                      className="list_component_actions_wrapper"
                      style={{ width: props.actionArray?.style?.width }}>
                      {props.actionArray.actions.map((action: any) =>
                        action.component(item),
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </Draggable>
          ))}
        </Container>
      )}
    </div>
  );
}
