
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSetState } from 'utils/functions.utils';
import StarRatings from 'react-star-ratings';

const StarRating = (props: any) => {
  // Redux

  // State

  //Hooks
  useEffect(() => {}, []);

  // Network req

  //Logic

  return (
    <div>
      <StarRatings {...props} />
    </div>
  );
};

export default StarRating;
