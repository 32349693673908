import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { overall_feedback } from 'utils/constant.utils';
import {
  filterByKeys,
  filterObjectByKeys,
  getDropdownData,
  getDropdownValue,
  toastifyError,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  PrimaryButton,
  Assets,
  CandidateInfo,
  Dropdown,
  ToggleHeader,
  PdfViewer,
  CardsComponent,
  FeedbackModal,
  AddFeedback,
  Loader,
} from 'utils/imports.utils';
import { getInterviewById, testDispatch } from 'utils/redux.utils';
import './interview_page.screen.scss';

const InterviewPage = (props: any) => {
  // Reference
  const modalRef: any = useRef();
  // Redux
  const interviewState = useSelector((state: any) => state.interview);

  // State
  const [state, setState] = useSetState({
    active: '',
    skills: [],
    behaviours: [],
    feedback_data: [],
    edit: false,
    overall_feedback: '',
    loading: false,
  });

  //Hooks
  useEffect(() => {
    setState({ active: headerValues[0] });
    fetchInterviewById();
  }, []);

  const navigate = useNavigate();
  const query = useQuery();
  let interview_id: any = query.get('id');

  // Network req
  const fetchInterviewById = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.interview.getInterview({ interview_id });
      //dispatch
      getInterviewById(res?.data);
      setState({ loading: false });
    } catch (error: any) {
      console.log(error);
    }
  };

  const editInterview = async (overall_feedback: string) => {
    try {
      const res: any = await Models.interview.editInterview({
        interview_id,
        overall_feedback,
      });
      //dispatch
      getInterviewById(res?.data);
    } catch (error: any) {
      console.log(error);
    }
  };
  const headerValues = ['Candidate details', 'Resume', 'Interview Feedback'];

  if (state.loading) return <Loader />;
  return (
    <div className="interview_container">
      <div className="interview_header">
        <div className="interview_heading_and_icon">
          <div
            className="interview_back_icon"
            onClick={() => navigate('/home')}>
            <img
              src={Assets.back}
              className="interview_back_icon"
              alt={'back button'}
            />
          </div>
          <div className="interview_header_text">Interview Page</div>
        </div>
        {/* <div className="interview_header_button">
          <PrimaryButton
            icon={Assets.big_tick}
            text={'Submit & Leave'}
            onClick={handleSubmit}
          />
        </div> */}
      </div>
      <div className="interview_wrapper">
        <div className="interview_bottom_container">
          <div className="interview_screen_toggle_header">
            <ToggleHeader
              data={headerValues}
              onChange={(item: string) => setState({ active: item })}
              active={state.active}
            />
          </div>
          {state.active === 'Resume' ? (
            <div className="interview_resume_container">
              <div className="interview_resume_image">
                {interviewState?.interview?.candidate?.resume?.resume
                  .split('.')
                  .pop() === 'pdf' && (
                  <PdfViewer
                    file={interviewState?.interview?.candidate?.resume?.resume}
                  />
                )}
              </div>
              <div className="interview_footer_buttons">
                {interviewState?.interview?.candidate?.resume && (
                  <a
                    href={interviewState?.interview?.candidate?.resume?.resume}
                    target="_blank"
                    className="candidate_download_btn"
                    download>
                    <div className="download_icon_wrapper">
                      <img src={Assets.download} alt={'back icon'} />
                    </div>
                    <div className="download_text">Download</div>
                  </a>
                )}
              </div>
            </div>
          ) : state.active == 'Candidate details' ? (
            <div className="interview_candidate_container">
              <CandidateInfo
                profile={
                  interviewState?.interview?.candidate?.resume?.profile_picture
                }
                data={
                  // filterByKeys(
                  interviewState?.interview?.candidate?.resume
                  // [
                  //   'first_name',
                  //   'last_name',
                  //   'email',
                  //   'location',
                  //   'gender',
                  //   'remote_work_preference',
                  //   'phone',
                  // ],
                // )
              }
                className="candidate_container"
              />
            </div>
          ) : (
            <AddFeedback />
          )}
        </div>
        <div className="interview_dropdown_container">
          <div className="interview_input_heading">Overall Feedback</div>
          <Dropdown
            data={getDropdownData(overall_feedback)}
            value={getDropdownValue(
              interviewState?.interview?.overall_feedback,
            )}
            setActive={(overall_feedback: any) => {
              setState({ overall_feedback });
              editInterview(overall_feedback?.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InterviewPage;
