import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  checkAgency,
  getDropdownData,
  getDropdownValue,
  getUrlType,
  rmHtml,
  subscriptionCheck,
  toastify,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  PrimaryButton,
  FileUpload,
  CheckBox,
  Dropdown,
  DatePickerComponent,
  Validation,
  TextArea,
  TextEditor,
  Loader,
  InviteModal,
  Tag,
  DeletePop,
  PromptModal,
  Input,
} from 'utils/imports.utils';
import './post_job.screen.scss';
import { toastifyError, useQuery } from 'utils/functions.utils';
import { useNavigate } from 'react-router-dom';
import {
  JOB_CHANNELS,
  SUBSCRIPTION_FEATURES,
} from 'utils/constant.utils';
import parse from 'html-react-parser';
import moment from 'moment';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import { getUserDetails } from 'utils/redux.utils';

const PostJob = (props: any) => {
  // Redux
  const inviteModalRef: any = useRef();
  const userState = useSelector((state: any) => state.user.auth);
  const inputFileRef: any = useRef();
  const generateRef: any = useRef();
  const query = useQuery();
  const navigate = useNavigate();
  // Network req
  let req_id: any = query?.get('req_id');
  const boardJobId: any = query.get('draft_id');
  //sample_data
  const post_job = ['Internal', 'External', 'Both Internal, & External'];

  // State
  const [state, setState] = useSetState({
    display_close_date: false,
    display_work_location: false,
    display_company_profile: false,
    display_salary: false,
    display_hiring_manager: false,
    display_recruiter: false,
    company_site: false,
    linked_in: false,
    indeed: false,
    job_image: '',
    job_description: '',
    open_date: new Date(),
    close_date: new Date(),
    job_ad_template: '',
    post_job: '',
    errorArray: [],
    job_req_desc: '',
    channels: [],
    requisition: {},
    current_job_post_id: '',
    agencies_list: [],
    agency: [],
    equest_channels: [],
    equest_department: '',
    equest_all_department: [],
    equest_industry: 0,
    equest_all_industry: [],
    equest_boards: [],
    equest_url: '',
    equest_all_board: [],
    equest_edit_channels: [],
    equestError: false,
    isEquest: false,
    planName: '',
    prompt: '',
    generateCode: [],
    desc_loader: false,
    desc_size:{
      label: 'Medium',
      value: 600,
    },
    existJobDesc: '',
    eeo: false,
    desc_tone:{
      label: 'Formal',
      value: 'formal',
    },
    trigger:false,
    company_name:'',
    company_info:false,
    chatGptMaxUse:1,
    company_description: '',
    isEeo: false,
  });

  const createPostJob = async () => {
    try {
      setState({ loader: true });
      let req_id: any = query?.get('req_id');
      let equest_boards: any = [];
      let channels: any = [];
      if (state.company_site) {
        channels.push(JOB_CHANNELS.COMPANY_SITE);
      }
      let body: any = {
        requisition: req_id,
        // job_ad_template: state.job_ad_template.value,
        post_type: state.post_job.value,
        job_open_date: state.open_date,
        job_close_date: state.close_date,
        display_close_date: state.display_close_date,
        display_company_profile: state.display_company_profile,
        display_salary: state.display_salary,
        display_recruiter_profile: state.display_recruiter,
        display_hiring_manager_profile: state.display_hiring_manager,
        display_work_location: state.display_work_location,
        description : state.job_description,
        channels,
      };
      if (state.company_description) {
        body.company_description = state.company_description;
      }
      if (state.company_name) {
        body.company_name = state.company_name;
      }
      if (!_.isEmpty(state.job_image)) {
        body.header_media = state.job_image;
      }
      if (!_.isEmpty(state.job_req_desc)) {
        body.job_req_desc = state.job_req_desc;
      }
      if (state.isEquest) {
        let equestData: any = await equestChannel(body);
        equest_boards = equestData.equest_boards;
        delete equestData.equest_boards;
        body = equestData;
        body.equest_department = state.equest_department;
        body.equest_industry = state.equest_industry;
        body.equest_channels = state.equest_channels;
      } else {
        await Validation.postJobSchema.validate(body, { abortEarly: false });
      }
      await Models.postJob.createPostJob(body);
      if (!_.isEmpty(equest_boards)) {
        const equestQuery: any = {
          requisition_id: req_id,
          department: state.equest_department.value,
          industry: parseInt(state.equest_industry.value),
          boards: equest_boards,
          description: state.job_description,
          return_url: window.location.href,
        };
        const res: any = await Models.equest.createEquestDraftJobBoard(
          equestQuery,
        );
        window.location.href = res.bsd;
      } else {
        navigate(`/requisition?id=${req_id}`);
        toastify('Job posted');
      }
      // if (
      //   userState.org?.subscribed &&
      //   _.isEmpty(state.requisition?.subscription?.plan_name)
      // ) {
      //   let query: any = {
      //     requisition_id: req_id,
      //     subscription: {
      //       plan_name: SUBSCRIPTION_PLAN.PREMIUM,
      //       subscribed: true,
      //     },
      //   };
      //   await Models.jobRecquisition.editRequisition(query);
      // }
      if (state.company_info) {
        let query: any = {
          job_post: {
            company_name: state.company_name,
            company_details: state.company_details,
          },
        };
        await Models.organization.editOrganization(query);
      }
      setState({
        display_close_date: false,
        display_work_location: false,
        display_company_profile: false,
        display_salary: false,
        display_hiring_manager: false,
        display_recruiter: false,
        company_site: false,
        linked_in: false,
        indeed: false,
        job_image: '',
        job_description: '',
        open_date: new Date(),
        close_date: new Date(),
        job_ad_template: '',
        post_job: '',
        loader: false,
        company_info:false,
        company_name: '',
        company_description:''
      });
    } catch (error: any) {
      setState({ loader: false });
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const editJobPost = async () => {
    try {
      setState({ loader: true });
      let req_id: any = query?.get('req_id');
      let channels: any = [];
      let equest_boards: any = [];
      if (state.linked_in) {
        channels.push(JOB_CHANNELS.LINKEDIN);
      }
      if (state.indeed) {
        channels.push(JOB_CHANNELS.INDEED);
      }
      if (state.company_site) {
        channels.push(JOB_CHANNELS.COMPANY_SITE);
      }
            setState({ channels });
      let body: any = {
        job_post_id: state.current_job_post_id,
        // job_ad_template: state.job_ad_template.value,
        post_type: state.post_job.value,
        job_open_date: state.open_date,
        job_close_date: state.close_date,
        display_close_date: state.display_close_date,
        display_company_profile: state.display_company_profile,
        display_salary: state.display_salary,
        display_recruiter_profile: state.display_recruiter,
        display_hiring_manager_profile: state.display_hiring_manager,
        display_work_location: state.display_work_location,
      };

      if (state.job_req_desc.length > 0) {
        body.job_req_desc = state.job_req_desc;
      }
      // if (channels.length > 0) {
      body.channels = channels;
      // }
      // if (state.job_image.length > 0) {
      body.header_media = state.job_image;
      // }
      if (state.job_description) {
        body.description = state.job_description;
      }
      if (state.company_description) {
        body.company_description = state.company_description;
      }
      if (state.company_name) {
        body.company_name = state.company_name;
      }
      if (state.isEquest) {
        let equestData: any = await equestChannel(body);
        equest_boards = equestData.equest_boards;
        delete equestData.equest_boards;
        body = equestData;
        body.equest_department = state.equest_department;
        body.equest_industry = state.equest_industry;
        body.equest_channels = state.equest_channels;
      }
      if (state.company_name) {
        body.company_name = state.company_name;
      }

      if (state.company_details) {
        body.company_details = state.company_details;
      }
      await Models.postJob.editJobPost(body);
      if (!_.isEmpty(equest_boards) && equest_edit()) {
        const equestQuery: any = {
          requisition_id: req_id,
          department: state.equest_department.value,
          industry: parseInt(state.equest_industry.value),
          boards: equest_boards,
          description: state.job_description,
          return_url: window.location.href,
        };
        const res: any = await Models.equest.createEquestDraftJobBoard(
          equestQuery,
        );
        window.location.href = res.bsd;
      } else {
        navigate(`/requisition?id=${req_id}`);
        toastify('Job posted');
      }
      // if (
      //   userState.org?.subscribed &&
      //   !state.requisition?.subscription?.subscribed
      // ) {
      //   let query: any = {
      //     requisition_id: req_id,
      //     subscription: {
      //       plan_name: SUBSCRIPTION_PLAN.PREMIUM,
      //       subscribed: true,
      //     },
      //   };
      //   await Models.jobRecquisition.editRequisition(query);
      // }
      if (state.company_info) {
        let query: any = {
          job_post: {
            company_name: state.company_name,
            company_details: state.company_details,
          },
        };
        await Models.organization.editOrganization(query);
      }
      setState({
        display_close_date: false,
        display_work_location: false,
        display_company_profile: false,
        display_salary: false,
        display_hiring_manager: false,
        display_recruiter: false,
        company_site: false,
        linked_in: false,
        indeed: false,
        job_image: '',
        job_description: '',
        open_date: new Date(),
        close_date: new Date(),
        job_ad_template: '',
        post_job: '',
        loader: false,
        company_name:'',
        company_details:'',
        company_info:false
      });
    } catch (error: any) {
      setState({ loader: false });
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const equestChannel = async (data: any) => {
    let body: any = data;
    let equest_boards: any = [];
    state.equest_channels?.map((item: any) => {
      equest_boards.push({
        board: { name: item.label, id: parseInt(item.value) },
      });
    });
    if (
      _.isEmpty(state.equest_department) ||
      _.isEmpty(state.equest_industry) ||
      _.isEmpty(state.equest_channels)
    ) {
      setState({ equestError: true });
      setTimeout(() => {
        setState({ equestError: false });
      }, 3000);
      await Validation.postJobSchema.validate(body, { abortEarly: false });
    } else {
      await Validation.postJobSchema.validate(body, { abortEarly: false });
      body.equest_boards = equest_boards;
      return body;
    }
  };

  const recquisitionById = async () => {
    try {
      const body = {
        requisition_id: req_id,
      };
      const response: any = await Models.jobRecquisition.getRecquisitionById(
        body,
      );
      setState({ requisition: response.data });
      if (response.data?.subscription?.subscribed) {
        setState({ planName: response.data.subscription.plan_name });
      }
      let agencies_list: any = [];
      if (response.data.agencies?.length > 0) {
        response.data.agencies.map((item: any) =>
          agencies_list.push({ label: item.name, value: item._id }),
        );
        setState({ agency: agencies_list });
      } else {
        setState({ agency: agencies_list });
      }
      const res: any = await Models.postJob.getJobPostByReq({ req_id });
      let currentJobPost: any;
      if (res.data) {
         currentJobPost = await Models.postJob.getPostJob({
          job_post_id: res.data._id,
        });
        if (!_.isEmpty(currentJobPost.data.equest_channels)) {
          setState({
            isEquest: true,
            equest_edit_channels: currentJobPost.data.equest_channels,
            equest_channels: currentJobPost.data.equest_channels,
            equest_department: currentJobPost.data.equest_department,
            equest_industry: currentJobPost.data.equest_industry,
          });
        }
        setState({
          display_close_date: currentJobPost.data.display_close_date,
          display_work_location: currentJobPost.data.display_work_location,
          display_company_profile: currentJobPost.data.display_company_profile,
          display_salary: currentJobPost.data.display_salary,
          display_hiring_manager:
            currentJobPost.data.display_hiring_manager_profile,
          display_recruiter: currentJobPost.data.display_recruiter_profile,
          linked_in: currentJobPost.data.linked_in,
          indeed: currentJobPost.data.indeed,
          job_description: currentJobPost.data.description,
          open_date: new Date(currentJobPost.data.job_open_date),
          close_date: new Date(currentJobPost.data.job_close_date),
          post_job: getDropdownValue(currentJobPost.data.post_type),
          current_job_post_id: currentJobPost.data._id,
          channels: currentJobPost.data.channels,
          company_site: !_.isEmpty(currentJobPost.data.channels),
        });
        if (currentJobPost.data?.header_media) {
          setState({
            job_image: currentJobPost.data?.header_media,
          });
        }
      }
      if (!_.isEmpty(currentJobPost?.data?.company_description)) {
        setState({
          company_description: currentJobPost.data.company_description,
        });
      } else if (
        !_.isEmpty(userState?.org?.company_details?.company_description)
      ) {
        setState({
          company_description:
            userState?.org?.company_details?.company_description,
        });
      }
      if (!_.isEmpty(currentJobPost?.data?.company_name)) {
        setState({
          company_name: currentJobPost.data.company_name,
        });
      } else if (!_.isEmpty(userState?.org?.company_details?.company_name)) {
        setState({
          company_name: userState?.org?.company_details?.company_name,
        });
      }
      
      await getAllAgency(agencies_list);
    } catch (error) {
      toastifyError(error);
    }
  };

  const handleSubmit = async () => {
    if (state.current_job_post_id !== '') {
      await editJobPost();
    } else {
      await createPostJob();
    }
  };

  const getAllAgency = async (agencies) => {
    try {
      const res: any = await Models.agency.getAllAgency({
        limit: 100,
        skip: 0,
        invite_accepted: true,
      });
      let agencies_list: any = [];
      if (agencies.length > 0) {
        res.data.docs.map((item: any) => {
          return agencies.map((agency: any) => {
            if (item._id !== agency.value) {
              agencies_list.push({ label: item.name, value: item._id });
            }
          });
        });
      } else {
        res.data.docs.map((item: any) =>
          agencies_list.push({ label: item.name, value: item._id }),
        );
      }
      setState({ agencies_list });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const removeAgency = async () => {
    try {
      let body = {
        requisition_id: req_id,
        agency: state.agency_id,
      };
      const res: any = await Models.jobRecquisition.removeAgency(body);
      setState({ modal: false });
      recquisitionById();
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const createEquestjobBoard = async () => {
    try {
      let body: any = {
        draftId: parseInt(boardJobId),
        boards: [],
      };
      state.equest_channels?.map((item: any) => {
        body.boards.push({
          board: { name: item.label, id: parseInt(item.value) },
        });
      });
      await Models.equest.createEquestJobBoard(body);
      toastify('Job posted');
      navigate(`/requisition?id=${req_id}`);
      setState({ trigger: false });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getAllEquestBoard = async () => {
    try {
      const res: any = await Models.equest.getAllEquestBoard();
      let array: any = [];
      res.data?.map((item: any) => {
        if (item !== '') {
          array.push({ label: item.name, value: item.id.toString() });
        }
      });
      setState({ equest_all_board: array });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getAllEquestDepartment = async () => {
    try {
      const res: any = await Models.equest.getAllEquestDepartment();
      setState({ equest_all_department: res.data[0].departments });
      setState({ equest_all_industry: res.data[0].industries });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  // const handleEquestBoard = (item: any) => {
  //   let data = state.equest_boards;
  //   if (_.some(data, (e) => e.id === item.id)) {
  //     let index: any = _.findIndex(data, (e: any) => {
  //       return e.id === item.id;
  //     });
  //     data.splice(index, 1);
  //   } else {
  //     data.push(item);
  //   }
  //   setState({ equest_boards: data });
  // };

  const handlePrompt = async () => {
    try {
      setState({ desc_loader: true });
      let query: any = {
        job_desc: rmHtml(state.requisition.job_description),
        desc_size: state.desc_size.value,
        company_name: state.requisition.org.name,
      };
      if(state.company_name){
        query.company_name=state.company_name
      }

      if(state.company_description){
        query.company_description=state.company_description
      }
      if (!_.isEmpty(state.requisition.work_location)) {
        let location = state.requisition.work_location;
        query.job_location = `address: ${location.address}. city: ${location.city}. state: ${location.state}. country: ${location.country}. pincode: ${location.pincode}`;
      }
      if(!_.isEmpty(state.requisition.behaviours)){
        query.behaviours=state.requisition.behaviours.toString()
      }
      if(!_.isEmpty(state.requisition.skills)){
        query.skills=state.requisition.skills.toString()
      }
      if (state.desc_tone.value !== '') {
        query.tone = state.desc_tone.value;
      }
      if(state.generateCode.includes('Custom prompt')){
        if (state.prompt?.length > 0) {
          query.prompt = state.prompt;
          
        }
      }else if (!_.isEmpty(state.existJobDesc)) {
        query.existResponse = state.existJobDesc;
      }
      if (!_.isEmpty(state.generateCode)) {
        query.generateCode = state.generateCode;
      }
      const res: any = await Models.chatGpt.chatGptCreate(query);
      let chatGptMaxUse=state.chatGptMaxUse+1;  
      setState({
        job_description: res.data,
        desc_loader: false,
        chatGptMaxUse
      });
    } catch (error: any) {
      setState({ desc_loader: false });
      toastifyError(error);
    }
  };

  //Hooks
  useEffect(() => {
    if (userState.equest_integration) {
      getAllEquestBoard();
      getAllEquestDepartment();
    }
      }, [userState]);

  useEffect(() => {
    if(!_.isEmpty(userState)){
      recquisitionById();
    }
  }, [userState]);

  useEffect(() => {
    if (!_.isEmpty(boardJobId) && !_.isEmpty(state.equest_channels)) {
      if(!state.trigger){
        setState({trigger: true});
        createEquestjobBoard();
      }
    }
  }, [boardJobId, state.equest_channels]);

  const equest_edit = () => {
    if (
      !_.isEmpty(state.current_job_post_id) &&
      !_.isEmpty(state.equest_edit_channels)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const descriptionLength = [
    {
      label: 'Small',
      value: 400,
    },
    {
      label: 'Medium',
      value: 600,
    },
    {
      label: 'Long',
      value: 800,
    },
  ];
  const descriptionTone = [
    {
      label: 'Formal',
      value: 'formal',
    },
    {
      label: 'Exciting',
      value: 'exciting',
    },
    {
      label: 'Casual',
      value: 'casual',
    },
  ];
  const genderNeutral = [
    {
      label: 'Yes',
      value: 'Gender Neutral',
    },
    {
      label: 'No',
      value: '',
    },
  ];


  // const editOrg=async(check)=>{
  //   try {
  //     let message='Company info successfully saved'
  //     let query:any={
  //       job_post:{
  //         company_name:state.company_name,
  //         company_details:state.company_details
  //       }
  //     }
  // if(check==='delete'){
  //   message='Company info successfully removed'
  //   query.job_post={
  //   }
  //   setState({company_name:'', company_details:''})
  //   // if(_.isEmpty(userState.org?.job_post.company_details)&&_.isEmpty(userState.org?.job_post.company_name)){
  //   //   toastify(message);
  //   //   return
  //   // }
  // }
  // toastify(message);
  // getUser()
  //   } catch (error: any) {
  //     toastifyError(error);
  //   }
  // }

  let handleJobAd = (value,label)=>{
    let data = state.generateCode;
    if (
      !_.isEmpty(value) &&
      !state.generateCode.includes(value)
    ) {
      data = _.concat(data, value);
    } else if (value === '') {
      _.pull(data, label);
    }
    setState({ generateCode: data });
  }
  
  return (
    <div className="postjob_container">
      <div className="postjob_header">
        <div className="postjob_heading_and_icon">
          <div
            className="postjob_back_icon"
            onClick={() => navigate(`/requisition?id=${query?.get('req_id')}`)}>
            <img src={Assets.back} alt={'back button'} />
          </div>
          <div className="postjob_header_text">Post Job</div>
        </div>
        <div className="postjob_header_button">
          {!userState.is_agency && (
            <PrimaryButton
              disabled={
                subscriptionCheck(state.planName, SUBSCRIPTION_FEATURES.STAFFING_PARTNER)
                  ? false
                  : true
              }
              onClick={() => inviteModalRef?.current?.openModal()}
              icon={
                
                subscriptionCheck(state.planName, SUBSCRIPTION_FEATURES.STAFFING_PARTNER)
                  ? Assets.agency_invite
                  : Assets.agency_invite_inactive
              }
              text={'Invite Agency'}
              color={
                !subscriptionCheck(state.planName, SUBSCRIPTION_FEATURES.STAFFING_PARTNER) &&
                '#b4b4b4'
              }
              className="invite_job_btn"
            />
          )}
          {state.loader ? (
            <Loader />
          ) : (
            <PrimaryButton
              className="post_job_btn"
              onClick={handleSubmit}
              icon={Assets.big_tick}
              text={
                state.current_job_post_id !== '' ? 'Edit Job Post' : 'Post Job'
              }
            />
          )}
        </div>
      </div>
      <div className="postjob_wrapper">
        <div className="postjob_subheading margin10">Post Details</div>
        <div className="postjob_main_container">
          <div className="postjob_first_container">
            {/* {state.job_image.length > 0 ? (
              <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <FileUpload
                ref={inputFileRef}
                onChange={(file: any) => {
                  setState({ job_image: file });
                }}>
                <PrimaryButton
                  text={'Change Media'}
                  className={'top_apply_btn'}
                  onClick={() => {
                    inputFileRef?.current?.openUpload();
                  }}
                  width={120}
                />
              </FileUpload>
              <PrimaryButton
                text={'Remove Media'}
                className={'top_apply_btn'}
                onClick={() => {
                  setState({job_image:''})
                }}
                width={120}
              />
              </div>
            ) : (
              <div className="postjob_input_heading">
                Select an Image or Video
              </div>
            )} */}
            <div className="postjob_upload_container">
              <div className="postjob_input_heading">
                Select an Image or Video
              </div>
              {state.job_image === '' ? (
                <div className="upload_poc_doc">
                  <FileUpload
                    ref={inputFileRef}
                    onChange={(file: any) => {
                      // console.log('file[0]',file);

                      setState({ job_image: file });
                    }}
                    accept_types={'.jpg, .jpeg,.png'}
                    folder="job_post">
                    <img
                      src={Assets.upload_doc}
                      alt={'add resume'}
                      className="job_post_header"
                      width={'100%'}
                      onClick={() => inputFileRef?.current?.openUpload()}
                    />
                  </FileUpload>
                </div>
              ) : (
                <div>
                  {getUrlType(state.job_image) === 'image' ? (
                    <img
                      src={state.job_image}
                      height={205}
                      style={{ objectFit: 'contain' }}
                      width="100%"
                    />
                  ) : (
                    <div className="postjob_video_holder">
                      <ReactPlayer
                        width="100%"
                        height={205}
                        url={state.job_image}
                        controls
                      />
                    </div>
                  )}
                </div>
              )}

              {state.job_image.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    // flexDirection: 'column',
                  }}>
                  <FileUpload
                    ref={inputFileRef}
                    onChange={(file: any) => {
                      setState({ job_image: file });
                    }}
                    accept_types={'.jpg, .jpeg,.png'}>
                    <div
                      className="postjob_input_heading"
                      style={{ paddingTop: '10px', cursor: 'pointer' }}
                      onClick={() => inputFileRef?.current?.openUpload()}>
                      Change Media
                    </div>
                  </FileUpload>
                  <div
                    className="postjob_input_heading"
                    style={{ paddingTop: '10px', cursor: 'pointer' }}
                    onClick={() => setState({ job_image: '' })}>
                    Remove Media
                  </div>
                </div>
              )}
            </div>
            <div className="postjob_channels_container">
              <div className="postjob_channels_wrapper">
                <div className="career_channel">
                  <div
                    className="postjob_checkbox_wrapper"
                    onClick={() =>
                      setState({
                        company_site: !state.company_site,
                      })
                    }>
                    <div className="postjob_checkbox">
                      <CheckBox checked={state.company_site} />
                    </div>
                    <div className="postjob_checkbox_value">
                      External Careers Site
                    </div>
                  </div>
                  {/* {state.errorArray.length > 0 &&
                    state.channels.length === 0 && (
                      <div className="input_field_error">
                        Select atleast one channel
                      </div>
                    )} */}
                </div>
                {userState.equest_integration && (
                  <>
                    <div className="equest_channel">
                      <div
                        className="postjob_checkbox_wrapper"
                        onClick={() =>
                          setState({
                            isEquest: !state.isEquest,
                          })
                        }>
                        <div className="postjob_checkbox">
                          <CheckBox checked={state.isEquest} />
                        </div>
                        <div className="postjob_checkbox_value">
                          External Job Boards
                        </div>
                      </div>
                    </div>
                    {state.isEquest && (
                      <div className="equest_container">
                        <div className="channel_site">
                          <div className="postjob_input_heading">
                            Select Channels
                            <span className="required_field">*</span>
                          </div>
                          <div className="postjob_channel_select">
                            <Dropdown
                              isMulti
                              name={'equest_channels'}
                              onChange={(equest_channels: any) => {
                                equest_edit()
                                  ? setState({ equest_channels })
                                  : '';
                              }}
                              data={equest_edit() ? state.equest_all_board : []}
                              value={state.equest_channels}
                            />
                          </div>
                          {state.equestError &&
                            _.isEmpty(state.equest_channels) && (
                              <div className="input_field_error">
                                Please Select Channel
                              </div>
                            )}
                        </div>
                        <div className="postjob_channels_wrapper">
                          <div className="postjob_input_heading">
                            Department<span className="required_field">*</span>
                          </div>
                          <div className="postjob_channel_select">
                            <Dropdown
                              name={'equest_department'}
                              onChange={(department: any) => {
                                equest_edit()
                                  ? setState({ equest_department: department })
                                  : '';
                              }}
                              data={
                                equest_edit() ? state.equest_all_department : []
                              }
                              value={state.equest_department}
                            />
                            {state.equestError &&
                              _.isEmpty(state.equest_department) && (
                                <div className="input_field_error">
                                  Please Select Department
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="postjob_channels_wrapper">
                          <div className="postjob_input_heading">
                            industry<span className="required_field">*</span>
                          </div>
                          <div className="postjob_channel_select ">
                            <Dropdown
                              name={'equest_industry'}
                              error={state.errorArray}
                              onChange={(equest_industry: any) => {
                                equest_edit()
                                  ? setState({ equest_industry })
                                  : '';
                              }}
                              data={
                                equest_edit() ? state.equest_all_industry : []
                              }
                              value={state.equest_industry}
                            />
                            {state.equestError &&
                              _.isEmpty(state.equest_industry) && (
                                <div className="input_field_error">
                                  Please Select Industry
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="postjob_second_container">
            {/* <div className="postjob_input_heading">Job Ad Templates</div>
            <Dropdown
              name={'job_ad_template'}
              error={state.errorArray}
              data={getDropdownData(job_templates)}
              onChange={(job_ad_template: any) => {
                setState({ job_ad_template });
              }}
              value={state.job_ad_template}
            /> */}
           
            {subscriptionCheck(state.planName, SUBSCRIPTION_FEATURES.CHATGPT_DESC) &&
              userState.is_agency && (
                <>
                  <div className="company_details">
                    <div className="company_info_wrapper">
                      <div className="postjob_input_heading">Company Name</div>
                      <div className="company_info">
                        <div
                          className="postjob_checkbox_wrapper"
                          onClick={() => {
                            if (
                              _.isEmpty(state.company_details) &&
                              _.isEmpty(state.company_name)
                            ) {
                              toastifyError('please fill the company details');
                              return;
                            }
                            setState({
                              company_info: !state.company_info,
                            });
                          }}>
                          <div className="postjob_checkbox">
                            <CheckBox checked={state.company_info} />
                          </div>
                          <div className="postjob_checkbox_value">
                            Save Details
                          </div>
                        </div>
                      </div>
                    </div>
                    <Input
                      type="text"
                      onChange={(company_name: string) => {
                        setState({ company_name });
                      }}
                      value={state.company_name}
                      name={'company_name'}
                    />
                  </div>
                  <div className="company_details">
                    <div className="postjob_input_heading">
                      Company Description
                    </div>
                    <TextArea
                      onChange={(e) =>
                        setState({ company_description: e.target.value })
                      }
                      name={'company_description'}
                      value={state.company_description}
                      className="company_description"
                    />
                  </div>
                </>
              )}


           

            <div className="postjob_description_container">
              <div className="postjob_input_heading">
                Job Description / Caption
                <span className="required_field">*</span>
              </div>
              <div className="postjob_description_wrapper">
                <TextEditor
                  onChange={(job_description: string) =>
                    setState({ job_description })
                  }
                  name="description"
                  error={state.errorArray}
                  value={state.job_description}
                />
              </div>
              {subscriptionCheck(state.planName, SUBSCRIPTION_FEATURES.CHATGPT_DESC) && (
                <div className="prompt_container">
                    {/* {generateCode.map((item: string, index: number) => {
                      let generate_code: boolean =
                        state.generateCode.includes(item);
                      return (
                        <div
                          style={item === 'EEO' ? { padding: '0 6px' } : {}}
                          className={`job_desc  ${
                            generate_code && 'job_desc_active'
                          }`}
                          key={index}
                          onClick={ 
                            // () => {
                            //   let arr = state.generateCode;

                            //   let index = _.findIndex(
                            //     arr,
                            //     (e) => e === item,
                            //     0,
                            //   );
                            //   if (_.isNumber(index) && index > -1) {
                            //     arr.splice(index, 1);
                            //   } else {
                            //     arr.push(item);
                            //   }
                            //   setState({ generateCode: arr });
                            // }

                            // setState(
                            //   state.generateCode !== item
                            //     ? { generateCode: item }
                            //     : { generateCode: '' },
                            // )
                          // }>
                          {/* {generate_code && (
                            <img src={Assets.tick} height={16} width={16} />
                          )} 
                          // <div
                          //   className={`prompt_name  ${
                          //     generate_code && 'prompt_active'
                          //   }`}>
                            {/* {item} 
                          // </div>
                        // </div>
                      );
                    })}*/}
                    <div className="job_ad_container">
                      <div className="job_ad_generate">
                        <div className="job_ad">
                          <div className="job_ad_header">Job ad length :</div>
                          {descriptionLength.map((item: any, index: number) => (
                            <div
                              className={
                                state.desc_size.value === item.value
                                  ? 'job_ad_text active'
                                  : 'job_ad_text'
                              }
                              onClick={() => setState({ desc_size: item })}
                              key={index}>
                              {item.label}
                            </div>
                          ))}
                        </div>

                        <div className="generate_code">
                          {/* {state.generateCode.includes('Custom prompt') && (
                      <>
                        <div
                          className="cus_prompt_name"
                          onClick={() => generateRef.current.openModal()}>
                          {state.prompt.length > 0
                            ? 'Edit prompt'
                            : 'Add prompt'}
                        </div>
                        <PromptModal
                          data={state.prompt}
                          ref={generateRef}
                          onClick={(item: string) => setState({ prompt: item })}
                        />
                      </>
                    )} */}
                          {state.desc_loader ? (
                            <Loader loaderSize="desc_loader" />
                          ) : state.chatGptMaxUse > 3 ? (
                            <div className="generate_disable">
                              <img
                                src={Assets.sparkle_inactive}
                                height={10}
                                width={10}
                              />
                              <div className={`generate_name`}>Generate</div>
                            </div>
                          ) : (
                            <div className="generate" onClick={handlePrompt}>
                              <img
                                src={Assets.sparkle_active}
                                height={10}
                                width={10}
                              />
                              <div className={`generate_name  `}>Generate</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="job_ad">
                        {/* <Dropdown
                        data={descriptionLength}
                        setActive={(desc_size: any) => {
                          setState({ desc_size, existJobDesc: '' });
                        }}
                        value={state.desc_size}
                        // className={state.desc_size.label!=='Select'?'dropdown_wrappers_active':'dropdown_control'}
                        // singleValueClassName={state.desc_size.label!=='Select'?'dropdown_name_active':'dropdown_single_value'}
                        {...props}
                      /> */}
                        <div className="job_ad_header">Job ad tone :</div>
                        {descriptionTone.map((item: any, index: number) => (
                          <div
                            className={
                              state.desc_tone.value === item.value
                                ? 'job_ad_text active'
                                : 'job_ad_text'
                            }
                            onClick={() => setState({ desc_tone: item })}
                            key={index}>
                            {item.label}
                          </div>
                        ))}
                      </div>
                      <div className="job_ad">
                        <div className="job_ad_header">Make it gender neutral :</div>
                        {genderNeutral.map((item: any, index: number) => (
                          <div
                            className={`job_ad_text ${
                              state.generateCode.includes(item.value) &&
                              'active'
                            }
                          ${
                            item.value === '' &&
                            !state.generateCode.includes('Gender Neutral') &&
                            'active'
                          }
                                `}
                            onClick={() => {
                              handleJobAd(item.value, 'Gender Neutral');
                            }}
                            key={index}>
                            {item.label}
                          </div>
                        ))}
                      </div>
                      <div
                        className="job_ad"
                        onClick={() => {
                          let data = state.generateCode.includes('EEO')
                            ? ''
                            : 'EEO';
                          handleJobAd(data, 'EEO');
                        }}>
                        <div className="postjob_checkbox">
                          <CheckBox
                            checked={state.generateCode.includes('EEO')}
                          />
                        </div>
                        <div className="job_ad_eeo">EEO Statement</div>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </div>
          <div className="postjob_third_container">
            <div className="postjob_postjob_container">
              <div className="postjob_input_heading">
                Post Job<span className="required_field">*</span>
              </div>
              <Dropdown
                name={'post_type'}
                error={state.errorArray}
                onChange={(post_job: any) => {
                  setState({ post_job });
                }}
                data={getDropdownData(post_job)}
                value={state.post_job}
              />
            </div>
            <div className="postjob_open_date_container">
              <div className="postjob_input_heading">Open Date</div>
              <DatePickerComponent
                minDate={new Date()}
                name={'job_open_date'}
                error={state.errorArray}
                onChange={(open_date: string) => setState({ open_date })}
                value={state.open_date}
              />
            </div>
            <div className="postjob_date_container">
              <div className="postjob_input_heading">Close Date</div>
              <DatePickerComponent
                minDate={new Date()}
                name={'job_close_date'}
                error={state.errorArray}
                onChange={(close_date: string) => setState({ close_date })}
                value={state.close_date}
              />
            </div>

            <div className="postjob_third_wrapper">
              <div
                className="postjob_checkbox_wrapper"
                onClick={() =>
                  setState({
                    display_close_date: !state.display_close_date,
                  })
                }>
                <div className="postjob_checkbox">
                  <CheckBox
                    onPress={() =>
                      setState({
                        display_close_date: !state.display_close_date,
                      })
                    }
                    checked={state.display_close_date}
                  />
                </div>
                <div className="postjob_checkbox_value">Display Close Date</div>
              </div>
              <div
                className="postjob_checkbox_wrapper"
                onClick={() =>
                  setState({
                    display_work_location: !state.display_work_location,
                  })
                }>
                <div className="postjob_checkbox">
                  <CheckBox
                    onPress={() =>
                      setState({
                        display_work_location: !state.display_work_location,
                      })
                    }
                    checked={state.display_work_location}
                  />
                </div>
                <div className="postjob_checkbox_value">
                  Display Work Location
                </div>
              </div>
              {/* <div
                className="postjob_checkbox_wrapper"
                onClick={() =>
                  setState({
                    display_company_profile: !state.display_company_profile,
                  })
                }>
                <div className="postjob_checkbox">
                  <CheckBox
                    onPress={() =>
                      setState({
                        display_company_profile: !state.display_company_profile,
                      })
                    }
                    checked={state.display_company_profile}
                  />
                </div>
                <div className="postjob_checkbox_value">
                  Display Company Profile
                </div>
              </div> */}
              <div
                className="postjob_checkbox_wrapper"
                onClick={() =>
                  setState({
                    display_salary: !state.display_salary,
                  })
                }>
                <div className="postjob_checkbox">
                  <CheckBox
                    onPress={() =>
                      setState({
                        display_salary: !state.display_salary,
                      })
                    }
                    checked={state.display_salary}
                  />
                </div>
                <div className="postjob_checkbox_value">Display Salary</div>
              </div>
              <div
                className="postjob_checkbox_wrapper"
                onClick={() =>
                  setState({
                    display_recruiter: !state.display_recruiter,
                  })
                }>
                <div className="postjob_checkbox">
                  <CheckBox
                    onPress={() =>
                      setState({
                        display_recruiter: !state.display_recruiter,
                      })
                    }
                    checked={state.display_recruiter}
                  />
                </div>
                <div className="postjob_checkbox_value">
                  Display Recruiter Profile
                </div>
              </div>
              <div
                className="postjob_checkbox_wrapper"
                onClick={() =>
                  setState({
                    display_hiring_manager: !state.display_hiring_manager,
                  })
                }>
                <div className="postjob_checkbox">
                  <CheckBox
                    onPress={() =>
                      setState({
                        display_hiring_manager: !state.display_hiring_manager,
                      })
                    }
                    checked={state.display_hiring_manager}
                  />
                </div>
                <div className="postjob_checkbox_value">
                  Display Hiring Manager Profile
                </div>
              </div>
            </div>
            {!checkAgency() && state.agency.length > 0 && (
              <div className="postjob_date_container">
                <div className="postjob_input_heading">Agencies</div>
                <div className="requisition_status_dropdown">
                  {state.agency.map((item: any, index: number) => (
                    <Tag
                      text={item.label}
                      className={'tag_color'}
                      icon={Assets.close}
                      onClick={() => {
                        setState({ agency_id: item.value, modal: true });
                      }}
                      key={index}
                    />
                  ))}
                  {/* <Dropdown
                    data={state.agencies_list}
                    value={state.agency}
                    isMulti
                    disabled
                    agency
                    setActive={(agency: any, value: any) => {
                      if (value.action == 'remove-value') {
                        removeAgency(value.removedValue.value);
                      } else {
                        inviteAgency(value.option.value);
                      }
                      setState({ agency });
                    }}
                  /> */}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div className="post_job_upload_container">
          <div className="post_job_upload_wrapper">
            <div className="post_job_input_heading">
              Upload video Job Description
            </div>
            <FileUpload
              ref={inputFileRef}
              multiple={true}
              onChange={(file: any) => {
                setState({
                  job_req_desc: file,
                });
              }}
              folder="video_jd">
              <div
                onClick={() => inputFileRef?.current?.openUpload()}
                className="post_job_upload_box">
                <div className="post_job_req_desc_file">
                  {state.job_req_desc?.length > 0
                    ? state.job_req_desc?.split('/').pop()
                    : 'Upload'}
                </div>
                <div>
                  <img src={Assets.attachment} alt="" />
                </div>
              </div>
            </FileUpload>
          </div>
        </div> */}
      </div>
      <div className="postjob_preview_container">
        <div className="postjob_subheading">Preview</div>
        <div className="postjob_preview_wrapper">
          <div className="postjob_desktop_preview_container">
            <div className="postjob_input_heading">Desktop</div>
            <div className="postjob_desktop_preview_wrapper">
              <div className="job_post_content_container_desktop">
                <div className="landing_header">
                  <div className="job_head">{state.requisition?.job_title}</div>
                  <div className="job_sub" style={{ borderBottom: 'none' }}>
                    {state.requisition?.job_type} |{' '}
                    {state.display_work_location &&
                      state.requisition?.work_location &&
                      `${state.requisition?.work_location?.address} |`}{' '}
                    {state.requisition?.job_role_type &&
                      state.requisition?.job_role_type}
                  </div>
                  <div className="job_sub" style={{ borderBottom: 'none' }}>
                    {state.display_close_date &&
                      `Close date:
                        ${moment(state.close_date).format('DD MMMM YYYY')}`}
                  </div>
                  <div className="job_sub" style={{ paddingBottom: '5px' }}>
                    {state.display_salary &&
                      `Salary: ${state.requisition.salary_max} ${
                        state.requisition?.salary_currency
                          ? `(${state.requisition?.salary_currency} )`
                          : ''
                      }`}
                  </div>
                </div>
                {state.job_image !== '' && (
                  <div className="postjob_desktop_hiring_container">
                    <div className="postjob_desktop_hiring_wrapper">
                      {getUrlType(state.job_image) === 'image' ? (
                        <img
                          src={state.job_image}
                          height={'100%'}
                          style={{ objectFit: 'contain' }}
                          width="100%"
                        />
                      ) : (
                        <div style={{ width: '100%' }}>
                          <ReactPlayer
                            width="100%"
                            height={300}
                            url={state.job_image}
                            controls
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className="postjob_desktop_preview_content"
                  style={{ paddingTop: '10px' }}>
                  {parse(`${state.job_description}`)}
                </div>
                <div
                  className="landing_sub_button"
                  style={{
                    paddingBottom: '20px',
                    paddingTop: '20px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}>
                  <PrimaryButton
                    text={'Apply for this'}
                    className={'top_apply_btn'}
                    onClick={() => {}}
                    width={'250px'}
                  />
                </div>
              </div>
              <div className="job_post_container_team">
                <div
                  className="landing_sub_button"
                  style={{ paddingBottom: '20px' }}>
                  <PrimaryButton
                    text={'Apply for this'}
                    className={'top_apply_btn'}
                    onClick={() => {}}
                    width={'200px'}
                  />
                </div>
                {state.display_hiring_manager && (
                  <div className="landing_recruiter_name">Hiring Manager</div>
                )}
                {state.display_hiring_manager &&
                  state.requisition?.hiring_team.map((item: any) => (
                    <>
                      <div className="job_post_mobile_image">
                        <img
                          className="job_post_mobile_image
                          "
                          src={item.profile || Assets.profile_placeholder}
                        />
                      </div>
                      <div className="landing_recruiter_name">
                        {item.first_name} {item.last_name}
                      </div>
                      <div className="landing_recruiter_name">
                        {item.job_title}
                      </div>
                    </>
                  ))}
                {state.display_recruiter && (
                  <div className="landing_recruiter_name">Recruiter</div>
                )}
                {state.display_recruiter &&
                  state.requisition?.recruiter.map((item: any) => (
                    <>
                      <div className="job_post_mobile_image">
                        <img
                          className="job_post_mobile_image
                          "
                          src={item.profile || Assets.profile_placeholder}
                        />
                      </div>
                      <div className="landing_recruiter_name">
                        {item.first_name} {item.last_name}
                      </div>
                      <div className="landing_recruiter_name">
                        {item.job_title}
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingTop: '20px',
            }}>
            <div className="postjob_tablet_preview_container">
              <div className="postjob_input_heading">Tablet</div>
              <div className="postjob_tablet_preview_wrapper">
                <div className="landing_header">
                  <div className="job_head">{state.requisition?.job_title}</div>
                  <div className="job_sub" style={{ borderBottom: 'none' }}>
                    {state.requisition?.job_type} |{' '}
                    {state.display_work_location &&
                      state.requisition?.work_location &&
                      `${state.requisition?.work_location?.address} |`}{' '}
                    {state.requisition?.job_role_type &&
                      state.requisition?.job_role_type}
                  </div>
                  <div className="job_sub" style={{ borderBottom: 'none' }}>
                    {state.display_close_date &&
                      `Close date:
                        ${moment(state.close_date).format('DD MMMM YYYY')}`}
                  </div>
                  <div className="job_sub" style={{ paddingBottom: '5px' }}>
                    {state.display_salary &&
                      `Salary: ${state.requisition.salary_max} ${
                        state.requisition?.salary_currency
                          ? `(${state.requisition?.salary_currency})`
                          : ''
                      }`}
                  </div>
                </div>
                {state.job_image !== '' && (
                  <div className="postjob_tablet_hiring_wrapper">
                    {getUrlType(state.job_image) === 'image' ? (
                      <img
                        src={state.job_image}
                        height={'100%'}
                        style={{ objectFit: 'contain' }}
                        width="100%"
                      />
                    ) : (
                      <div style={{ width: '100%' }}>
                        <ReactPlayer
                          width="100%"
                          height={300}
                          url={state.job_image}
                          controls
                        />
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="postjob_tablet_preview_content"
                  style={{ paddingTop: '10px' }}>
                  {parse(`${state.job_description}`)}
                </div>
                <div className="postjob_tablet_hiring_container">
                  {state.display_hiring_manager && (
                    <div className="landing_recruiter_name">Hiring Manager</div>
                  )}
                  {state.display_hiring_manager &&
                    state.requisition?.hiring_team.map((item: any) => (
                      <>
                        <div className="job_post_mobile_image">
                          <img
                            className="job_post_mobile_image
                          "
                            src={item.profile || Assets.profile_placeholder}
                          />
                        </div>
                        <div className="landing_recruiter_name">
                          {item.first_name} {item.last_name}
                        </div>
                        <div className="landing_recruiter_name">
                          {item.job_title}
                        </div>
                      </>
                    ))}
                  {state.display_recruiter && (
                    <div className="landing_recruiter_name">Recruiter</div>
                  )}
                  {state.display_recruiter &&
                    state.requisition?.recruiter.map((item: any) => (
                      <>
                        <div className="job_post_mobile_image">
                          <img
                            className="job_post_mobile_image
                          "
                            src={item.profile || Assets.profile_placeholder}
                          />
                        </div>
                        <div className="landing_recruiter_name">
                          {item.first_name} {item.last_name}
                        </div>
                        <div className="landing_recruiter_name">
                          {item.job_title}
                        </div>
                      </>
                    ))}
                </div>
                <div
                  className="landing_sub_button"
                  style={{
                    paddingBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <PrimaryButton
                    text={'Apply for this'}
                    className={'top_apply_btn'}
                    onClick={() => {}}
                    width={'50%'}
                  />
                </div>
              </div>
            </div>
            <div className="postjob_mobile_preview_container">
              <div className="postjob_input_heading">Mobile</div>
              <div className="postjob_mobile_preview_wrapper">
                <div className="landing_header">
                  <div className="job_head">{state.requisition?.job_title}</div>
                  <div className="job_sub" style={{ borderBottom: 'none' }}>
                    {state.requisition?.job_type} |{' '}
                    {state.display_work_location &&
                      state.requisition?.work_location &&
                      `${state.requisition?.work_location?.address} |`}{' '}
                    {state.requisition?.job_role_type &&
                      state.requisition?.job_role_type}
                  </div>
                  <div className="job_sub" style={{ borderBottom: 'none' }}>
                    {state.display_close_date &&
                      `Close date:
                      ${moment(state.close_date).format('DD MMMM YYYY')}`}
                  </div>
                  <div className="job_sub" style={{ paddingBottom: '5px' }}>
                    {state.display_salary &&
                      `Salary: ${state.requisition.salary_max} ${
                        state.requisition?.salary_currency
                          ? `(${state.requisition?.salary_currency})`
                          : ''
                      }`}
                  </div>
                </div>
                {state.job_image !== '' && (
                  <div className="postjob_mobile_hiring_wrapper">
                    {getUrlType(state.job_image) === 'image' ? (
                      <img
                        src={state.job_image}
                        height={'100%'}
                        style={{ objectFit: 'contain' }}
                        width="100%"
                      />
                    ) : (
                      <div style={{ width: '100%' }}>
                        <ReactPlayer
                          width="100%"
                          height={300}
                          url={state.job_image}
                          controls
                        />
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="postjob_mobile_preview_content"
                  style={{ paddingTop: '10px' }}>
                  {parse(`${state.job_description}`)}
                </div>
                <div className="job_post_mobile_hiring_container">
                  {state.display_hiring_manager && (
                    <div className="landing_recruiter_name">Hiring Manager</div>
                  )}
                  {state.display_hiring_manager &&
                    state.requisition?.hiring_team.map((item: any) => (
                      <>
                        <div className="job_post_mobile_image">
                          <img
                            className="job_post_mobile_image
                          "
                            src={item.profile || Assets.profile_placeholder}
                          />
                        </div>
                        <div className="landing_recruiter_name">
                          {item.first_name} {item.last_name}
                        </div>
                        <div className="landing_recruiter_name">
                          {item.job_title}
                        </div>
                      </>
                    ))}
                  {state.display_recruiter && (
                    <div className="landing_recruiter_name">Recruiter</div>
                  )}
                  {state.display_recruiter &&
                    state.requisition?.recruiter.map((item: any) => (
                      <>
                        <div className="job_post_mobile_image">
                          <img
                            className="job_post_mobile_image
                          "
                            src={item.profile || Assets.profile_placeholder}
                          />
                        </div>
                        <div className="landing_recruiter_name">
                          {item.first_name} {item.last_name}
                        </div>
                        <div className="landing_recruiter_name">
                          {item.job_title}
                        </div>
                      </>
                    ))}
                </div>
                <div
                  className="landing_sub_button"
                  style={{
                    paddingBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <PrimaryButton
                    text={'Apply for this'}
                    className={'top_apply_btn'}
                    onClick={() => {}}
                    width={'50%'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InviteModal ref={inviteModalRef} agencies={state.agencies_list} />
      <DeletePop
        text={'Are you sure you want to remove this agency?'}
        onClick={removeAgency}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  );
};

export default PostJob;
