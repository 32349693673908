import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ClipBoard = (props: any) => {
  // Redux

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req

  //Logic
  const testLogic = () => {};

  //sample_data

  return <CopyToClipboard {...props}>{props.children}</CopyToClipboard>;
};

export default ClipBoard;
