import React, { useEffect, useRef } from 'react';
import { isoToDateConvert, toastify, useSetState } from 'utils/functions.utils';
import {
  AdminToggleHeader,
  Assets,
  Models,
  OrgListComponent,
  OrgModal,
  PrimaryButton,
  Searchbar,
} from 'utils/imports.utils';
import './client_organisation.screen.scss';
import { useDebouncedCallback } from 'use-debounce';
import _ from 'lodash';
const ClientOrgs = () => {
  const headerValues = ['Organization', 'Agencies'];
  const orgModalRef: any = useRef();
  const [state, setState] = useSetState({
    active: 'Organization',
    orgAllData: [],
    orgData: [],
    search_value: '',
    trialEndDate: '',
    vacancies_count: 0,
    skip: 0,
    loadMore: false,
    limit: 10,
  });
  const getManyOrg = async () => {
    try {
      setState({ loading: true });
      const body = {
        search: state.search_value,
        is_agency: state.active === 'Agencies',
        skip: state.skip,
        limit: state.limit,
      };
      let res: any = await Models.organization.getManyOrganization(body);
      setState({
        orgAllData: res.data.docs,
        loading: false,
        loadMore: res.data.totalDocs >= 10,
      });
    } catch (error: any) {
      setState({
        loading: false,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const editOrgDetails = async (item:any) => {
    try {
      const body: any = {
        org_id: state.orgData._id,
        org_type: state.active,
      };
      if (state.active === 'Agencies') {
        if (
          !_.isDate(state.orgData?.trial?.end_date) ||
          isoToDateConvert(state.orgData?.trial?.end_date) !==
            isoToDateConvert(item.trialEndDate)
        ) {
          body.trial = true;
          body.trial_end_date = item.trialEndDate;
          body.trail_start_date = item.trailStartDate;
          if (
            isoToDateConvert(item.trailStartDate) ===
            isoToDateConvert(new Date())
          ) {
            body.subscribe = true;
          }
        }
      } else if (state.active === 'Organization') {
        if (
          state.orgData?.trial?.total_quantity !== item.vacancies_count &&
          item.vacancies_count !== 0
        ) {
          body.trial = true;
          body.vacancies_count = item.vacancies_count;
        }
      }

      await Models.organization.updateOrganization(body);
      orgModalRef.current.closeModal();

      getManyOrg();
      toastify('organization details saved');
    } catch (error: any) {
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  //Hooks
  const debounced = useDebouncedCallback((search_value) => {
    setState({ search_value });
  }, 500);

  const orgHeader = [
    { label: 'Company Name', value: 'name', width: '20%' },
    { label: 'Plan', value: 'plan', width: '20%' },
    { label: 'Vacancies Count', value: 'total_quantity', width: '20%' },
  ];
  const agenciesHeader = [
    { label: 'Company Name', value: 'name', width: '15%' },
    { label: 'Plan', value: 'plan', width: '15%' },
    { label: 'Vacancies Count', value: 'count', width: '15%' },
    { label: 'User Count', value: 'user', width: '10%' },
    { label: 'Start Date', value: 'start_date', width: '15%' },
    { label: 'End Date', value: 'end_date', width: '15%' },
  ];

  useEffect(() => {
    getManyOrg();
  }, [state.search_value, state.active, state.skip]);

  let actionArray = {
    style: { width: state.active === 'Agencies' ? '15%' : '40%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <PrimaryButton
                text={'Edit'}
                className={'org_list_component_action_btn'}
                icon={Assets.edit}
                onClick={() => {
                  orgModalRef?.current.openModal();
                  setState({ orgData: item, modal: true });
                }}
              />
            </div>
          );
        },
      },
    ],
  };

  return (
    <div className="client_org_container">
      <div className="client_org_wrapper">
        <div className="client_org_header">
          <AdminToggleHeader
            data={headerValues}
            onChange={(item: string) => setState({ active: item })}
            active={state.active}
          />
          <div className="search_org">
            <Searchbar
              onChange={(search_value: string) => {
                debounced(search_value);
              }}
              name={`Search ${state.active}`}
            />
          </div>
        </div>
        <div className="org_list_wrapper">
          <OrgListComponent
            data={state.orgAllData}
            titleArray={
              state.active === 'Agencies' ? agenciesHeader : orgHeader
            }
            hasMore={state.loadMore}
            actionArray={actionArray}
            loadMore={() => setState({ skip: state.skip + 5 })}
            loading={state.loading}
          />
        </div>
        <OrgModal
          data={state.orgData}
          title={state.active}
          ref={orgModalRef}
          onClick={(item: any) => editOrgDetails(item)}
        />
      </div>
    </div>
  );
};

export default ClientOrgs;
