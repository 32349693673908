import test from 'models/test.model';
import auth from 'models/auth.model';
import organization from 'models/organization.model';
import department from 'models/department.model';
import workLocation from 'models/work_location.model';
import email from 'models/email.model';
import agency from 'models/agency.model';
import business from 'models/business_unit.model';
import jobRecquisition from 'models/job_recquisition.model';
import postJob from 'models/post_job.model';
import candidateModel from 'models/candidate.model';
import resume from 'models/resume.model';
import country from 'models/country.model';
import interview from 'models/interview.model';
import feedback from 'models/feedback.model';
import notes from 'models/notes.model';
import notification from 'models/notification.model';
import smtp from 'models/smtp.model';
import skills from 'models/skills.model';
import client from 'models/client.model';
import paymentTerms from 'models/payment_terms.model';
import equest from 'models/equest.model';
import subscription from 'models/subscription.model';
import customer from 'models/customer.model';
import chatGpt from 'models/chat_gpt.model';

export const Models = {
  test,
  auth,
  organization,
  department,
  workLocation,
  email,
  agency,
  business,
  jobRecquisition,
  postJob,
  candidateModel,
  resume,
  country,
  interview,
  feedback,
  notes,
  notification,
  smtp,
  skills,
  client,
  paymentTerms,
  equest,
  subscription,
  customer,
  chatGpt
};

export default Models;
