import React from 'react';
import funnelImage from '../../../assets/images/funnel.png';
import './funnel.scss';
interface funnel {
  applicationNumber?: number;
  longlistedNumber?: number;
  shortlistedNumber?: number;
  interviewedNumber?: number;
  offerNumber?: number;
  joined?: number;
  screening?: number;
}
const Funnel = ({
  applicationNumber,
  longlistedNumber,
  shortlistedNumber,
  interviewedNumber,
  offerNumber,
  joined,
  screening,
  classname,
  textbox,
}: any) => {
  {
    return (
      <div className="funnel_container">
        <h6 className="title">Candidate Funnel</h6>
        <div className="funnel_block">
          <div className="funnel_image_block">
            <img
              src={funnelImage}
              className={`${classname} funnel_image`}
              alt=""
            />
          </div>
          <div className="text_block">
            <div className={`${textbox} text_box`}>
              <div className="title">Applications Received</div>
              <div className="number">{applicationNumber}</div>
            </div>
            <div className={`${textbox} text_box`}>
              <div className="title">screening</div>
              <div className="number">{screening}</div>
            </div>
            <div className={`${textbox} text_box`}>
              <div className="title">Interviewing</div>
              <div className="number">{interviewedNumber}</div>
            </div>
            <div className={`${textbox} text_box`}>
              <div className="title">Offered</div>
              <div className="number">{offerNumber}</div>
            </div>
            <div className={`${textbox} text_box`}>
              <div className="title">Joined</div>
              <div className="number">{joined}</div>
            </div>
            {/* <div className="text_box">
              <div className="title">Offer Accepted</div>
              <div className="number">{offerAccepted}</div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
};

export default Funnel;
