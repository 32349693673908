import ListComponent from 'common_components/ui/list/list.component';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  mergeArrayWithoutDuplicates,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  PrimaryButton,
  Assets,
  DeletePop,
  Dropdown,
  Validation,
} from 'utils/imports.utils';
import { getBusinessUnitList, testDispatch } from 'utils/redux.utils';
import './departments.screen.scss';
import { toastify, uploadFile, toastifyError } from 'utils/functions.utils';
import Notiflix from 'notiflix';
import { getDepartmentList } from '../../utils/redux.utils';

const Departments = (props: any) => {
  // Redux
  const departmentState = useSelector((state: any) => state.department);
  const businessState = useSelector((state: any) => state.business_unit);

  // State
  const [state, setState] = useSetState({
    department_name: '',
    data: '',
    loading: false,
    business_unit: '',
    modal: false,
    businessUnits: [],
    errorArray: [],
    limit: 10,
    skip: 0,
    loadMore: true,
  });

  //Hooks
  useEffect(() => {
    getAllDeparment();
  }, []);

  // Network req

  // get department

  const getAllDeparment = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.department.getAllDepartment({
        limit: state.limit,
        skip: 0,
      });
      if (res.data.totalDocs === res.data?.docs?.length) {
        setState({ loadMore: false });
      } else {
        setState({ loadMore: true });
      }
      setState({ loading: false, skip: 0 });
      getDepartmentList(res?.data?.docs);
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const loadMoreDepartment = async (skip: number) => {
    try {
      setState({ loading: true });
      const res: any = await Models.department.getAllDepartment({
        limit: state.limit,
        skip,
      });
      setState({ loading: false });
      let data = mergeArrayWithoutDuplicates(
        departmentState?.departmentList,
        res?.data.docs,
      );
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      getDepartmentList(data);
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const handleClick = async () => {
    try {
      const body = {
        name: state.department_name,
      };
      await Validation.departmentSchema.validate(body, {
        abortEarly: false,
      });
      const res: any = await Models.department.createDepartment(body);
      await getAllDeparment();
      setState({ department_name: '', business_unit: '' });
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  // delete

  const deleteDepartmentById = async () => {
    try {
      const body = {
        department_id: state.data._id,
      };
      const res: any = await Models.department.deleteDepartmentById(body);
      setState({ modal: false, data: '' });
      await getAllDeparment();
    } catch (error: any) {}
  };

  //Logic
  const loadMore = () => {
    setState({ skip: state.skip + 10 });
    loadMoreDepartment(state.skip + 10);
  };

  //sample data
  const titleData = [{ label: 'Departments', value: 'name', width: '70%' }];

  let actionArray = {
    style: { width: '30%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div style={{}}>
              <PrimaryButton
                text={'Delete department'}
                className={'list_component_action_btn'}
                icon={Assets.trash}
                onClick={() => {
                  setState({ data: item, modal: true });
                }}
              />
            </div>
          );
        },
      },
    ],
  };

  return (
    <div className="departments_container">
      <div className="departments_heading">Departments</div>
      <div className="departments_wrapper">
        <div className="departments_input_container">
          <div className="departments_input_heading">
            Department Name<span className="required_field">*</span>
          </div>
          <div className="departments_input_and_button">
            <div className="department_input_container">
              <Input
                onChange={(value) => {
                  setState({ department_name: value });
                }}
                error={state.errorArray}
                type={'text'}
                value={state.department_name}
                name={'name'}
              />
            </div>
            {/* <div className="department_input_container"> */}
            {/* <div className="departments_input_heading">Business unit</div>
              <div style={{ width: '100%' }}>
                <Dropdown
                  onChange={(business_unit: any) => {
                    setState({
                      business_unit,
                    });
                  }}
                  error={state.errorArray}
                  name={'business_unit'}
                  data={state.businessUnits}
                />
              </div> */}
            {/* </div> */}
            <PrimaryButton
              onClick={handleClick}
              text={'Add Departments'}
              icon={Assets.add}
              className={'add_button'}
            />
          </div>
          <div className="departments_table_container">
            <ListComponent
              titleArray={titleData}
              data={
                departmentState?.departmentList.length > 0
                  ? departmentState?.departmentList
                  : []
              }
              loading={state.loading}
              loadMore={loadMore}
              hasMore={state.loadMore}
              actionArray={actionArray}
            />
          </div>
        </div>
      </div>
      <DeletePop
        text={'Are you sure you want to delete this department?'}
        onClick={deleteDepartmentById}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  );
};

export default Departments;
