import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { getUrlType, useSetState } from 'utils/functions.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './view_resume.scss';
import { PdfViewer, Assets, PrimaryButton } from 'utils/imports.utils';

const ViewResumeModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();
  //Redux
  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    time_zone: '',
    first_name: '',
    last_name: '',
    role: [],
  });

  //Hooks
  useEffect(() => {}, []);

  // Network req

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  //Logic

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'upload_resume_wrapper_modal',
        }}>
        <div>
          <div
            className="view_resume_modal_close_icon"
            onClick={() => {
              setState({ isModalOpen: false });
            }}>
            <img src={Assets.black_close} className="view_resume_modal_close" />
          </div>
          {getUrlType(props.src) === 'image' ? (
            <img src={props.src} className="view_resume_image" />
          ) : props.src.split('.').pop() === 'pdf' ? (
            <PdfViewer file={props.src} />
          ) : props.src.split('.').pop() === 'doc' ||props.src.split('.').pop() === 'docx' ?(
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${props.src}`}
              width="100%"
              height="600px"
              frameBorder="0">
              This is an embedded document by AskHugo.
            </iframe>
          ):null}
        </div>
      </Modal>
    </div>
  );
});

export default ViewResumeModal;
