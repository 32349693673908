import feedback from 'models/feedback.model';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FeedbackStatus, TYPE } from 'utils/constant.utils';
import { toastifyError, useQuery, useSetState } from 'utils/functions.utils';
import {
  Assets,
  CardsComponent,
  DeletePop,
  FeedbackModal,
  Models,
  NotesModal,
} from 'utils/imports.utils';
import { getInterviewById, getManyFeedback } from 'utils/redux.utils';

const AddNotes = (props: any) => {
  const modalRef: any = useRef();
  // Redux
  const interviewState = useSelector((state: any) => state.interview);
  const candidateState = useSelector((state: any) => state.candidate);
  const userState = useSelector((state: any) => state.user.auth);
  const feedbackState = useSelector((state: any) => state.feedback);
  // State
  const [state, setState] = useSetState({
    active: '',
    skills: [],
    behaviours: [],
    feedback_data: [],
    edit: false,
    overall_feedback: '',
    modal: false,
    feedback_id: '',
    resume_id: '',
  });

  //Hooks
  useEffect(() => {
    if (props.type == FeedbackStatus.CANDIDATE) {
      fetchCandidate();
    } else {
      fetchInterviewById();
    }
  }, []);

  const navigate = useNavigate();
  const query = useQuery();

  // Network req
  const fetchInterviewById = async () => {
    try {
      let interview_id: any = query.get('id');
      const res: any = await Models.interview.getInterview({ interview_id });
      //dispatch
      getInterviewById(res?.data);
      setState({
        skills: res.data?.candidate?.requisition?.skills,
        resume_id: res.data?.candidate?.resume?._id,
        behaviours: res.data?.candidate?.requisition?.behaviours,
      });
      fetchFeedbackList(res.data?.candidate?.resume?._id);
    } catch (error: any) {
      console.log(error);
    }
  };

  const createFeedback = async (body: any) => {
    try {
      const res: any = await Models.feedback.createFeedback(body);
      fetchFeedbackList(state.resume_id);
      //dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const editFeedback = async (data) => {
    try {
      let body = {
        feedback_id: state.current_feedback._id,
        skills: data.skills,
        behaviours: data.behaviours,
        feedback: data.feedback,
        resume: state.resume_id,
      };
      const res: any = await Models.feedback.editFeedback(body);
      fetchFeedbackList(state.resume_id);
      //dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const fetchFeedbackList = async (resume_id: string) => {
    try {
      const res: any = await Models.feedback.getManyFeedback({
        resume: resume_id,
        type: TYPE.NOTE,
      });
      getManyFeedback(res?.data?.docs);
      //dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const deleteFeedback = async () => {
    try {
      let body = {
        feedback_id: state.feedback_id,
      };
      const res: any = await Models.feedback.deleteFeedback(body);
      fetchFeedbackList(state.resume_id);
      setState({ feedback_id: '', modal: false });
      //dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const fetchCandidate = async () => {
    try {
      let candidate_id: any = query.get('id');
      const body = {
        candidate_id,
      };
      const res: any = await Models.candidateModel.getCandidate(body);
      setState({
        skills: res?.data?.requisition?.skills,
        behaviours: res?.data?.requisition?.behaviours,
        resume_id: res?.data?.resume?._id,
      });
      fetchFeedbackList(res?.data?.resume?._id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    state.feedback_data.map(
      async (feedback: any) => await createFeedback(feedback),
    );
  };

  const removeFeedBack = (feedback: any) => {
    setState({ modal: true, feedback_id: feedback._id });
  };

  const handleGetFeedbackData = (data: any) => {
    let body: any = { feedback: data };
    body.resume = candidateState.candidate.resume._id;
    body.type = TYPE.NOTE;
    if (state.edit) {
      editFeedback(body);
    } else {
      createFeedback(body);
    }
  };

  return (
    <div>
      <div className="interview_skills_content_container">
        <div
          className="interview_feedback_container"
          onClick={() => {
            setState({ current_feedback: '', edit: false });
            modalRef?.current?.openModal();
          }}>
          <div className="interview_feedback_text_container">
            <div className="feedback_icon_container">
              <img
                src={Assets.add_green}
                className="feedback_icon"
                alt="feedback_icon"
              />
            </div>
            <div className="feedback_text">Add Notes</div>
          </div>
        </div>
        {feedbackState?.feedback_list?.map((feedback: any, index: number) => (
          <div className="interview_feedback_card_container">
            <CardsComponent
              description={feedback.feedback}
              name={`${userState?.first_name} ${userState?.last_name}`}
              // time={new Date().getTime()}
              className={'candidate_cards_component'}
              onClick={() => {
                setState({ current_feedback: feedback, edit: true, index });
                modalRef?.current?.openModal();
              }}
              iconOnClick={() => {
                // modalRef?.current?.openModal();
                removeFeedBack(feedback);
              }}
              editOnClick={() => {
                setState({ current_feedback: feedback, edit: true, index });
                modalRef?.current?.openModal();
              }}
            />
          </div>
        ))}
      </div>
      <NotesModal
        ref={modalRef}
        skills={state.skills}
        behaviour={state.behaviours}
        data={state.current_feedback}
        onSubmit={(data: any) => {
          handleGetFeedbackData(data);
        }}
        edit={state.edit}
      />
      <DeletePop
        text={'Are you sure you want to delete this feedback?'}
        onClick={() => deleteFeedback()}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  );
};

export default AddNotes;
