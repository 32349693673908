import { GET_AGENCIES_LIST } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  agencies_list: [],
};
const agencyReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_AGENCIES_LIST:
      return {
        ...state,
        agencies_list: action.payload,
      };
    default:
      return state;
  }
};

export default agencyReducer;
