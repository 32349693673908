import React, { useEffect, Fragment } from 'react';
import {
  checkAgency,
  getDropdownData,
  toastifyError,
  useSetState,
} from 'utils/functions.utils';
import { useSelector } from 'react-redux';
import { reducers } from 'interfaces/common.interface';
import connectSocket from 'utils/socket.utils';
import { Dropdown, Models, Navbar, Sidebar } from 'utils/imports.utils';
import './main.hoc.scss';
import { getUserDetails } from 'utils/redux.utils';
import _ from 'lodash';
let socket: any;

export default function Main(props: any) {
  const [state, setState] = useSetState({ signout: false, loading: false });
  const user: any = useSelector((store: reducers) => store.user);
  let token = localStorage.getItem('token');
  let confirmed: any = localStorage.getItem('confirmed');
  let redirect:any=window.location.href
  const getUser = async () => {
    let userId = localStorage.getItem('user_id');
    try {
      const res: any = await Models.auth.viewUser(userId);
      // console.log('res.data', res.data);
      // dispatch
      if (res.data.confirmed) {
        // window.location.href = '/home';
        confirmed = res.data.confirmed.toString();
        localStorage.setItem('confirmed', 'true');
      }else{
        window.location.href = '/confirm_email';
      }
      getUserDetails(res.data);
    } catch (error: any) {
      if (error == 'invalid signature' || error == 'Invalid token') {
        localStorage.clear();
        window.location.href = '/login';
      }
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
    // socket = connectSocket();
  }, []);
  useEffect(() => {
    if (user.auth.is_agency) {
      getOrgsBasedOnAgency();
    }
  }, [user]);
  // const handleWindowClose = (e) => {
  //   e.preventDefault();
  //   return (e.returnValue = '');
  // };
  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleWindowClose);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleWindowClose);
  //   };
  // }, [window]);
  const showSuccess = (text: string) => {
    //show snack success
  };

  const throwError = (text: string) => {
    //show snack error
  };

  const setMainLoading = (loading: boolean) => {
    setState({ loading: loading });
  };

  const renderChildren = () => {
    return React.Children.map(props.children, (child: any) => {
      if (child) {
        return React.cloneElement(child, {
          user,
          showSuccess,
          throwError,
          socket,
          setMainLoading,
        });
      }
    });
  };

  const hideSideBar = (hide: boolean) => {
    if (hide) {
      setState({ hide: true });
    } else {
      setState({ hide: false });
    }
  };

  const getOrgsBasedOnAgency = async () => {
    try {
      const res: any = await Models.agency.getOrgs({
        domain: user.auth.org.domain,
      });
      let agency_orgs: any = [];
      res.data.map((item: any) =>
        agency_orgs.push({ label: item.org.name, value: item.agency }),
      );
      let stages = [
        { label: 'My clients', options: agency_orgs },
        {
          label: 'My Organization',
          options: [{ label: 'My Organization', value: '' }],
        },
      ];
      setState({ agency_orgs: stages });
      const agency = localStorage.getItem('agency');
      if (agency) {
        let index = _.findIndex(agency_orgs, { value: agency });
        setState({
          agency_id: { label: agency_orgs[index].label, value: agency },
        });
      } else {
        setState({
          agency_id: { label: 'My Organization', value: '' },
        });
      }
    } catch (error: any) {
      console.log('error', error);
    }
  };

  const setAgency = (agency: string) => {
    if (agency !== '') {
      localStorage.setItem('agency', agency);
    } else {
      localStorage.removeItem('agency');
    }
    window.location.href = '/requisition_board';
  };

  if (state.signout) window.location.href = '/';
  if (state.loading) return <div>Loading</div>;
  if (!token) {
    if (window.location.pathname !== '/login') {
      window.location.href = `/login?redirect=${redirect}`;
    } else {
      window.location.href = '/login';
    }
  } else if (token && confirmed == 'false') {
    window.location.href = '/confirm_email';
  }

  return (
    <div className="main_hoc_container">
      <div
        className={`${
          state.hide ? 'main_hide_sidebar_wrapper' : 'main_hoc_sidebar_wrapper'
        }`}>
        <Sidebar onHide={hideSideBar} />
      </div>
      <div
        className={`${
          state.hide ? 'main_hide_content_wrapper' : 'main_hoc_content_wrapper'
        }`}>
        <div
          className={`${
            user.auth.is_agency
              ? 'main_hoc_navbar_agency_wrapper'
              : 'main_hoc_navbar_wrapper'
          }`}>
          {user.auth.is_agency && (
            <div className="req_orgs_dropdown_container">
              <Dropdown
                data={state.agency_orgs}
                setActive={(agency_id: any) => {
                  setAgency(agency_id.value);
                  setState({ agency_id });
                }}
                value={state.agency_id}
                {...props}
              />
            </div>
          )}
          <Navbar />
        </div>
        <div className="main_hoc_children_wrapper">{renderChildren()}</div>
      </div>
    </div>
  );
}
