import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  gets3FileName,
  mergeArrayWithoutDuplicates,
  useSetState,
} from 'utils/functions.utils';
import {
  DatePickerComponent,
  DeletePop,
  FileUpload,
  Functions,
  Loader,
  Models,
  Validation,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './agencies_screen.scss';
import { Assets } from '../../utils/imports.utils';
import {
  Input,
  Dropdown,
  PrimaryButton,
  Navbutton,
} from '../../utils/imports.utils';
import { toastify, uploadFile, toastifyError } from 'utils/functions.utils';
import { MultiValueGeneric } from 'react-select/dist/declarations/src/components/MultiValue';
import ListComponent from 'common_components/ui/list/list.component';
import { getAllAgencies } from '../../utils/redux.utils';
import { ICreateAgency, ILocation } from 'interfaces/common.interface';
import { SUBSCRIPTION_FEATURES } from 'utils/constant.utils';
import { useNavigate } from 'react-router-dom';

const Agencies = (props: any) => {
  //reference
  const fileUploadRef: any = useRef();
  const inputFileRef: any = useRef();
  // Redux
  const agencyState = useSelector((state: any) => state.agency);
  const userState = useSelector((state: any) => state.user.auth);
  const navigate = useNavigate();
  // State
  const [state, setState] = useSetState({
    name: '',
    poc: '',
    email: '',
    phone_number: '',
    file: '',
    limit: 1,
    data: [],
    month: '',
    focus: false,
    contract_end_date: '',
    id: '',
    modal: false,
    agencyData: {},
    errorArray: [],
    skip: 0,
    data_limit: 10,
    loadMore: true,
    loading: false,
    isLoading: false
  });

  //Hooks
  useEffect(() => {
    getData();
  }, [state.limit]);

  useEffect(() => {
    getAllAgency();
  }, []);

  // Network req

  const getAllAgency = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.agency.getAllAgency({
        limit: state.data_limit,
        skip: 0,
      });
      if (res.data.totalDocs === res?.data?.docs.length) {
        setState({ loadMore: false });
      } else {
        setState({ loadMore: true });
      }
      getAllAgencies(res?.data?.docs);
      setState({ loading: false, skip: 0 });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const loadmoreAgency = async (skip: number) => {
    try {
      const res: any = await Models.agency.getAllAgency({
        limit: state.data_limit,
        skip,
      });
      let data = mergeArrayWithoutDuplicates(
        agencyState?.agencies_list,
        res?.data.docs,
      );
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      getAllAgencies(data);
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const createAgenices = async () => {
    try {
      setState({
        isLoading: true,
      });
      let commission_slabs: any = [];

      state.data.map((res) => {
        if (res.min_salary_value !== '') {
          commission_slabs.push({
            min_salary: res.min_salary_value,
          });
        }
        if (res.max_salary_value !== '') {
          commission_slabs.push({
            max_salary: res.max_salary_value,
          });
        }
        if (res.percentage_value !== '') {
          commission_slabs.push({
            percentage: res.percentage_value,
          });
        }
      });
      let body: ICreateAgency = {
        name: state.name,
        poc: state.poc,
        email: state.email,
        contract_start_date: new Date(),
      };
      if (commission_slabs.length > 0) {
        body.commission_slabs = commission_slabs;
      }
      if (state.contract_end_date.length > 0) {
        body.contract_end_date = state.contract_end_date;
      }
      if (state.file.length > 0) {
        body.contract = state.file;
      }
      if (state.phone_number.length > 0) {
        body.phone = state.phone_number;
      }
      await Validation.agencySchema.validate(body, {
        abortEarly: false,
      });
      const res: any = await Models.agency.createAgency(body);
      await getAllAgency();
      let data = [
        {
          min_salary: 'Min Salary',
          min_salary_value: '',
          max_salary: 'Max Salary',
          max_salary_value: '',
          percentage: 'Percentage',
          percentage_value: '',
        },
      ];
      setState({
        name: '',
        poc: '',
        email: '',
        phone_number: '',
        file: '',
        contract_end_date: '',
        focus: false,
        data,
        limit: 1,
        isLoading: false,
      });
    } catch (error: any) {
      toastifyError(error);
      let data = [
        {
          min_salary: 'Min Salary',
          min_salary_value: '',
          max_salary: 'Max Salary',
          max_salary_value: '',
          percentage: 'Percentage',
          percentage_value: '',
        },
      ];
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner || [],
        isLoading: false,
        data
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const deleteAgency = async () => {
    try {
      const body = {
        agency_id: state.agencyData._id,
      };
      const res: any = await Models.agency.agencyDeleteById(body);
      setState({ agencyData: '', modal: false });
      await getAllAgency();
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //Logic
  const handleChange = (index, key, value) => {
    let data: any = state.data;
    data[index][key] = value;
    setState({ data });
  };

  const getData = () => {
    let data: any = [];
    for (let i = 0; i < state.limit; i++) {
      data.push({
        min_salary: 'Min Salary',
        min_salary_value: state.data[i]?.min_salary_value || '',
        max_salary: 'Max Salary',
        max_salary_value: state.data[i]?.max_salary_value || '',
        percentage: 'Percentage',
        percentage_value: state.data[i]?.percentage_value || '',
      });
    }
    setState({ data });
  };

  //sample data
  const titleData = [
    { label: 'Agency Name', value: 'name', width: '20%' },
    { label: 'Point of contact', value: 'poc', width: '50%' },
  ];

  let actionArray = {
    style: { width: '30%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div style={{}}>
              <PrimaryButton
                text={'Delete agency'}
                className={'list_component_action_btn'}
                icon={Assets.trash}
                onClick={() => {
                  setState({ agencyData: item, modal: true });
                }}
              />
            </div>
          );
        },
      },
    ],
  };

  const loadMore = () => {
    setState({ skip: state.skip + 10 });
    loadmoreAgency(state.skip + 10);
  };

  return userState?.org?.subscribed ? (
    <div className="agencies_container">
      <div className="create_agency_wrapper">
        <div className="agencies_heading">Staffing Partner</div>
        <div className="agencies_flex_container">
          <div className="agency_wrapper">
            <div className="agencies_input_wrapper">
              <div className="agencies_label">
                Agency Name<span className="required_field">*</span>
              </div>
              <Input
                value={state.name}
                type="text"
                name="name"
                error={state.errorArray}
                onChange={(value) => setState({ name: value })}
              />
            </div>
            <div className="agencies_input_wrapper">
              <div className="agencies_label">
                Point of Contact<span className="required_field">*</span>{' '}
              </div>
              <Input
                value={state.poc}
                type="text"
                name="poc"
                error={state.errorArray}
                onChange={(value) => setState({ poc: value })}
              />
            </div>
            <div className="agencies_input_wrapper">
              <div className="agencies_label">
                Work Email<span className="required_field">*</span>
              </div>
              <Input
                value={state.email}
                type="text"
                name="email"
                error={state.errorArray}
                onChange={(value) => setState({ email: value })}
              />
            </div>
            <div className="agencies_input_wrapper">
              <div className="agencies_label">Phone Number</div>
              <Input
                value={state.phone_number}
                type="number"
                name="phone"
                error={state.errorArray}
                onChange={(value) => setState({ phone_number: value })}
              />
            </div>
            <div className="agency_flex_1">
              <div className="agencies_input_wrapper" style={{ width: '50%' }}>
                <div className="agencies_label">Upload Current Contract</div>
                <div
                  className={
                    state.focus ? 'file_type file_type_active' : 'file_type'
                  }>
                  <FileUpload
                    ref={inputFileRef}
                    onChange={(file: any) => {
                      setState({ file });
                    }}
                    folder="agency_contract">
                    <div
                      className="agency_screen_file_upload"
                      onClick={() => inputFileRef.current.openUpload()}>
                      {state.file !== ''
                        ? gets3FileName(state.file)
                        : 'Upload contract'}
                    </div>
                  </FileUpload>
                </div>
                {state.errorArray.length > 0 ? (
                  <div className="input_field_error">
                    {state.errorArray.map((res) => {
                      return res.path === 'contract' ? res.errors : '';
                    })}
                  </div>
                ) : null}
              </div>

              <div className="agencies_input_wrapper" style={{ width: '50%' }}>
                <div className="agencies_label">Contract Ending Date</div>
                <DatePickerComponent
                  name={'contract_end_date'}
                  error={state.errorArray}
                  onChange={(contract_end_date: string) =>
                    setState({ contract_end_date })
                  }
                  value={state.contract_end_date}
                />
              </div>
            </div>
            <div className="agencies_commision_slab">Commision Slabs</div>
            <div className="add_commission_lab">
              <div>
                {state.data.map((item, index: number) => {
                  return (
                    <div className="agency_flex_2">
                      <>
                        <div
                          className={
                            'agencies_input_wrapper agencies_min_salary_wrapper'
                          }>
                          <div className="agencies_label">
                            {item.min_salary}
                          </div>
                          <Input
                            error={state.errorArray}
                            value={item.min_salary_value}
                            type="number"
                            name={item.min_salary_value}
                            onChange={(value) =>
                              handleChange(index, 'min_salary_value', value)
                            }
                          />
                        </div>
                        <div
                          className={
                            'agencies_input_wrapper agencies_max_salary_wrapper'
                          }>
                          <div className="agencies_label">
                            {item.max_salary}
                          </div>
                          <Input
                            value={item.max_salary_value}
                            type="number"
                            name={item.max_salary_value}
                            onChange={(value) =>
                              handleChange(index, 'max_salary_value', value)
                            }
                          />
                        </div>
                        <div
                          className={
                            'agencies_input_wrapper agencies_percentage_wrapper'
                          }>
                          <div className="agencies_label">
                            {item.percentage}
                          </div>
                          <Input
                            value={item.percentage_value}
                            type="number"
                            name={item.percentage_value}
                            onChange={(value) =>
                              handleChange(index, 'percentage_value', value)
                            }
                          />
                        </div>
                        <div>
                          <div className="agencies_navbutton_wrapper">
                            <Navbutton
                              icon={Assets.trash}
                              onClick={() =>
                                setState({ limit: state.limit - 1 })
                              }
                            />
                          </div>
                        </div>
                      </>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="agencies_commision_add_button">
              <PrimaryButton
                className={'agencies_commision_button'}
                text="Add Commison Slab"
                icon={Assets.add}
                onClick={() => setState({ limit: state.limit + 1 })}
              />
            </div>
            <div className="btn-input_wrapper">
              {state.isLoading ? (
                <Loader />
              ) : (
                <PrimaryButton
                  onClick={createAgenices}
                  text={'Add agency'}
                  className={'agencies_button'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="agency_table_wrapper">
        <ListComponent
          titleArray={titleData}
          data={
            agencyState?.agencies_list.length > 0
              ? agencyState?.agencies_list
              : []
          }
          actionArray={actionArray}
          hasMore={state.loadMore}
          loadMore={loadMore}
          loading={state.loading}
        />
      </div>
      <DeletePop
        text={'Are you sure you want to delete this agency?'}
        onClick={deleteAgency}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  ) : (
    <div className="agencies_subscribe">
      <img src={Assets.info_icon}  height={32} width={32} />
      <div className="subscribe_text">
      Subscription required to enable staffing partners.
      </div>
      <div
        className="subscribe_link"
        onClick={() => navigate(`/settings/payment_settings`)}>
        View Plan
      </div>
    </div>
  );
};

export default Agencies;
