import {
  GET_CANDIDATE_LIST,
  GET_SUMMARY,
  GET_CANDIDATE,
  GET_CANDIDATE_HISTORY,
  GET_CANDIDATE_DASHBOARD
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {};

const CandidateReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_CANDIDATE_LIST:
      return {
        ...state,
        candidate_list: action.payload,
      };
    case GET_CANDIDATE:
      return {
        ...state,
        candidate: action.payload,
      };
    case GET_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case GET_CANDIDATE_HISTORY:
      return {
        ...state,
        candidate_history: action.payload,
      };
      case GET_CANDIDATE_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    default:
      return state;
  }
};

export default CandidateReducer;
