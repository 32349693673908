import { GET_RESUME_LIST } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';
const initialState = {
  resume_list: [],
};
const resumeReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_RESUME_LIST:
      return {
        ...state,
        resume_list: action.payload,
      };
    default:
      return state;
  }
};
export default resumeReducer;
