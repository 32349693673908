import instance from 'utils/axios.utils';

const organization = {
  createOrganization: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/create_org';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editOrganization: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/edit_org';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  updateOrganization: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/update_org';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getManyOrganization: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/get_many_org';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default organization;
