import React, { useEffect, useRef, useImperativeHandle } from 'react';
import ListComponent from 'common_components/ui/list/list.component';
import { useSelector } from 'react-redux';
import {
  getDifferenceInHours,
  toastifyError,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Dropdown,
  PrimaryButton,
  OfferCard,
  Colors,
  Assets,
} from 'utils/imports.utils';
import { getFeedbackDues, getManyInterviews } from 'utils/redux.utils';
import './home.screen.scss';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  candidate_filter,
  candidate_status,
  InterviewStatus,
  interview_filter,
  REQ_APPROVAL,
} from 'utils/constant.utils';
import _ from 'lodash';

const Home = (props: any) => {
  // Redux
  const interviewState = useSelector((state: any) => state.interview);
  // State
  const [state, setState] = useSetState({
    limit: 20,
    skip: 0,
    summary: {},
    req_summary: {},
    interview_status: { label: 'All', value: 'all' },
    candidate_filter: { label: 'All', value: 'all' },
    approvalData:[]
  });

  //Hooks
  useEffect(() => {
    fetchInterviewLists();
    fetchFeedbackDues();
    getCandidateSummary();
    getReqSummary();
    getManyReqApproval();
  }, []);

  const navigate = useNavigate();

  // Network req
  const fetchInterviewLists = async (status?: any) => {
    try {
      let body: any = {
        limit: state.limit,
        skip: 0,
      };
      if (status === InterviewStatus.TODAY) {
        body.from = moment().startOf('day').toDate();
        body.to = moment().endOf('day').toDate();
      }
      if (status === InterviewStatus.UPCOMING) {
        body.from = new Date();
      }
      if (status === InterviewStatus.COMPLETED) {
        body.to = new Date();
      }
      const res: any = await Models.interview.getAllInterviews(body);
      res.data?.docs.map((item: any) => {
        item.candidate_name = `${item.candidate?.resume?.first_name} ${item.candidate?.resume?.last_name}`;
        item.job_title = item.candidate?.requisition?.job_title;
        return item;
      });
      //dispatch
      getManyInterviews(res.data?.docs);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchFeedbackDues = async () => {
    try {
      const res: any = await Models.interview.feedbackDue();
      res.data.map((feedback: any) => {
        feedback.candidate_name = `${feedback.candidate?.resume?.first_name} ${feedback.candidate?.resume?.last_name}`;
        feedback.job_title = feedback.requisition?.job_title;
        return feedback;
      });
      //dispatch
      getFeedbackDues(res.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getCandidateSummary = async (filter?: any) => {
    try {
      let user_id = localStorage.getItem('user_id');
      let body: any = {
        user: user_id,
      };
      if (filter === 30) {
        body.from = moment().subtract(30, 'days');
        body.to = new Date();
      }
      if (filter == 60) {
        body.from = moment().subtract(60, 'days');
        body.to = new Date();
      }
      const res: any = await Models.candidateModel.getSummary(body);
      setState({ summary: res?.data });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getReqSummary = async () => {
    try {
      let user_id = localStorage.getItem('user_id');
      const res: any = await Models.jobRecquisition.getSummary({
        user: user_id,
      });
      setState({ req_summary: res?.data });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getInterviewStatus = (item: any) => {
    const interviewDate = new Date(moment(`${item.to}`).format());
    const currentDate = new Date();
    if (interviewDate > currentDate) {
      return true;
    } else {
      return false;
    }
  };

  const disableBtn = (item: any) => {
    const interviewDate: any = new Date(moment(`${item.from}`).format());
    const currentDate: any = moment();
    let diff;
    if (currentDate > interviewDate) {
      diff = moment(currentDate).diff(moment(interviewDate), 'minutes');
    } else {
      diff = moment(interviewDate).diff(moment(currentDate), 'minutes');
    }
    if (diff <= 60) {
      return false;
    } else {
      return true;
    }
  };

  const getManyReqApproval = async () => {
    try {
      let res: any = await Models.jobRecquisition.getManyReqApproval({});
      let user_id = localStorage.getItem('user_id');
      let data: any = [];
      _.forEach(res.data, (item: any, uuu: any) =>
        item?.approval_user.map((e: any, index: number) => {
          if (user_id === e.user.value.toString()) {
            if (item?.approval_type === REQ_APPROVAL.SEQUENTIAL) {
              if (
                (item?.approval_user[index - 1]?.approval === true &&
                  e.approval !== false &&
                  e.approval !== true) ||
                (item?.approval_user[index].approval !== false &&
                  item?.approval_user[index].approval !== true &&
                  index === 0)
              ) {
                data.unshift(item);
              }
            } else if (
              item?.approval_type === REQ_APPROVAL.PARALLEL &&
              e.approval !== false &&
              e.approval !== true
            ) {
              data.unshift(item);
            }
          }
        }),
      );
      setState({ approvalData: data });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //Logic
  const handleEditInterview = (item: any) => {
    navigate(
      `/interview_setup?id=${item.candidate._id}&interview_id=${item._id}&edit=true`,
    );
  };

  const titleData = [
    { label: 'Candidate Name', value: 'candidate_name', width: '25%' },
    { label: 'Job Requisition', value: 'job_title', width: '25%' },
    { label: 'Interview date', value: 'date', width: '25%', type: 'date' },
  ];
  const feedback_dues_title = [
    { label: 'Candidate Name', value: 'candidate_name', width: '30%' },
    { label: 'Job Requisition', value: 'job_title', width: '30%' },
    { label: 'Interview Date', value: 'date', width: '30%', type: 'date' },
  ];

  const approvalDataTitle = [
    { label: 'Requisition ID', value: 'id', width: '30%' },
    { label: 'Job Requisition', value: 'job_title', width: '30%' },
  ];
  const titleData2 = [
    { label: 'Candidate Name', value: 'name2', width: '20%' },
    { label: 'Job Requisition', value: 'recquisition2', width: '20%' },
    { label: 'Pending Since', value: 'pending_since', width: '20%' },
  ];

  const data2 = [
    {
      name2: 'Sairamm',
      recquisition2: 'developer',
      pending_since: 'Thursday, 23 May',
    },
    {
      name2: 'Sairamm',
      recquisition2: 'developer',
      pending_since: 'Thursday, 23 May',
    },
    {
      name2: 'Sairamm',
      recquisition2: 'developer',
      pending_since: 'Thursday, 23 May',
    },
  ];

  let actionArray = {
    style: { width: '20%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div style={{ width: '100px' }}>
              <PrimaryButton
                text={!getInterviewStatus(item) ? 'Completed' : 'Attend'}
                backgroundColor={
                  getInterviewStatus(item)
                    ? Colors.primaryLinkColor
                    : Colors.buttonTextColor
                }
                className={
                  !getInterviewStatus(item)
                    ? 'list_component_action_btn'
                    : 'list_component_interview_btn'
                }
                onClick={() => {
                  if (getInterviewStatus(item)) {
                    navigate(`/interview?id=${item._id}`);
                  }
                }}
                disabled={disableBtn(item)}
              />
            </div>
          );
        },
      },
      {
        component: (item: any) => {
          return (
            <div
              className="home_interview_edit"
              onClick={() => handleEditInterview(item)}>
              <img src={Assets.edit} />
            </div>
          );
        },
      },
    ],
  };
  const actionArray1 = {
    style: { width: '10%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div
              style={{}}
              className="add_table_btn"
              onClick={() => navigate(`/interview?id=${item._id}`)}>
              <img src={Assets.plus_black} />
            </div>
          );
        },
      },
    ],
  };
  const actionArray2 = {
    style: { width: '40%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div style={{ color: Colors.primaryLinkColor }}>
              View Offer Letter
            </div>
          );
        },
      },
      {
        component: (item: any) => {
          return (
            <div style={{}}>
              <PrimaryButton
                backgroundColor={Colors.primaryLinkColor}
                icon={Assets.tick}
                text={'Approve'}
              />
            </div>
          );
        },
      },
      {
        component: (item: any) => {
          return (
            <div style={{}}>
              <PrimaryButton
                backgroundColor={Colors.error}
                icon={Assets.close}
                text={'Rejected'}
              />
            </div>
          );
        },
      },
    ],
  };

  const approvalAction = {
    style: { width: '40%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div
              style={{}}
              className="approval_btn"
              onClick={() => navigate(`/requisition?id=${item._id}`)}>
              <img src={Assets.eye_icon} height={20} width={22} />
            </div>
          );
        },
      },
    ],
  };
  const time = [
    { label: 'today', value: 'today' },
    { label: 'yesterday', value: 'yesterday' },
  ];

  const candidate_card_data = {
    title: 'Candidate',
    new: `${state.summary.applied + state.summary.screening}`,
    interviewing: state.summary.interviewing,
  };
  const offerCardData1 = {
    title: 'Offers',
    new: '16',
    interviewing: '41',
  };
  return (
    <div className="home_container">
      {/* <div className="home_heading_text">Welcome</div> */}
      <div className="home_wrapper">
        <div className="home_main_container">
          {!_.isEmpty(state.approvalData)&&<div className="home_approval_container">
            <div className="home_approval_header">Approvals</div>
            <div className="home_approval_wrapper">
              <ListComponent
                titleArray={approvalDataTitle}
                data={state.approvalData}
                type="approval_table"
                actionArray={approvalAction}
              />
            </div>
          </div>}
          <div className="home_interview_container">
            <div className="home_interview_header">
              <div className="home_interview_heading">Interviews for You</div>
              <div className="home_interview_dropdown">
                <Dropdown
                  data={interview_filter}
                  value={state.interview_status}
                  onChange={(interview_status: any) => {
                    setState({ interview_status });
                    fetchInterviewLists(interview_status.label);
                  }}
                />
              </div>
            </div>
            
            <div className="home_interview_table">
              <ListComponent
                titleArray={titleData}
                data={interviewState?.interview_list}
                type="interview_table"
                actionArray={actionArray}
              />
            </div>
          </div>
          <div className="home_feedback_container">
            <div className="home_feedback_header">Feedback Due</div>
            <div className="home_feedback_wrapper">
              <ListComponent
                titleArray={feedback_dues_title}
                data={interviewState?.feedback_dues}
                type="interview_table"
                actionArray={actionArray1}
              />
            </div>
          </div>
        </div>
        <div className="home_sub_container">
          <div className="home_sub_container_one">
            <div className="home_requisition_text">My Open Requisitions</div>
            <div className="home_reruisition_value">
              {state.req_summary.open}
            </div>
          </div>
          <div className="home_sub_container_two">
            <OfferCard
              data={candidate_card_data}
              filterData={candidate_filter}
              setFilter={(value: any) => {
                setState({ candidate_filter: value });
                getCandidateSummary(value.value);
              }}
              value={state.candidate_filter}
            />
          </div>
          <div className="home_sub_container_three">
            {/* <OfferCard data={offerCardData1} /> */}
          </div>
        </div>
      </div>
      {/* <div className="home_offers_container">
        <div className="home_offers_header">Offers Awaiting</div>
        <div className="home_offers_wrapper">
          <ListComponent
            titleArray={titleData2}
            data={data2}
            type="offer_table"
            actionArray={actionArray2}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Home;
