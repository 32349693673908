import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  mergeArrayWithoutDuplicates,
  mergeTemplates,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  PrimaryButton,
  Assets,
  Input,
  TemplateButton,
  DeletePop,
  Validation,
  TextArea,
  Dropdown,
  TextEditor,
  Loader,
  Constants,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './email_template.screen.scss';
import { toastify, toastifyError } from 'utils/functions.utils';
import { getAllEmailTemplates } from '../../utils/redux.utils';
import { email_templates } from 'utils/constant.utils';
import _ from 'lodash';

interface IEmailTemplate {
  text?: String;
}

const EmailTemplate = (props: IEmailTemplate) => {
  // Redux
  const emailTemplate = useSelector((state: any) => state.email);

  // State
  const [state, setState] = useSetState({
    name: '',
    body: '',
    subject: '',
    focus: false,
    id: '',
    selected: false,
    modal: false,
    errorArray: [],
    limit: 20,
    skip: 0,
    loadMore: true,
    loading: false,
    templates:[]
  });

  //Hooks
  const quillRef: any = useRef();
  useEffect(() => {
    getEmailTemplates();
  }, []);

  // Network req

  const getEmailTemplates = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.email.getAllEmail({
        limit: state.limit,
        skip: 0,
      });
      if (res.data.totalDocs === res?.data?.docs?.length) {
        setState({ loadMore: false });
      } else {
        setState({ loadMore: true });
      }
      const templates = mergeTemplates(res.data.docs)
      setState({ loading: false, skip: 0, templates });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const loadMoreTemplates = async (skip: number) => {
    try {
      const res: any = await Models.email.getAllEmail({
        limit: state.limit,
        skip,
      });
      let data = mergeArrayWithoutDuplicates(
        emailTemplate?.email_templates,
        res?.data.docs,
      );
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      const templates = mergeTemplates(data)
      setState({ templates });
      getAllEmailTemplates(data);
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const addEmailTemplate = async () => {
    try {
      const body = {
        name: state.name,
        body: state.body,
        subject: state.subject,
      };
      await Validation.emailTemplateSchema.validate(body, {
        abortEarly: false,
      });
      const res: any = await Models.email.createEmail(body);
      toastify(res.message)
      await getEmailTemplates();
      setState({
        name: '',
        body: '',
        subject: '',
        id:''
      });
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const editEmailTemplate = async () => {
    try {
      const body = {
        name: state.name,
        body: state.body,
        subject: state.subject,
        email_template_id: state.id,
      };
      const res: any = await Models.email.updateEmailTemplate(body);
      toastify(res.message)
      await getEmailTemplates();
      setState({
        id: '',
        name: '',
        body: '',
        subject: '',
      });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getEmailTemplateById = async (id) => {
    try {
      const body = {
        email_template_id: id,
      };
      const res: any = await Models.email.getEmailById(body);
      setState({
        name: res.data?.name,
        body: res.data?.body,
        subject: res.data?.subject,
      });
    } catch (error: any) {}
  };

  const deleteEmailTemplateById = async () => {
    try {
      const body = {
        email_template_id: state.id,
      };
      const res: any = await Models.email.deleteEmailTemplateById(body);
      await getEmailTemplates();
      setState({
        name: '',
        body: '',
        subject: '',
        modal: false,
      });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //Logic

  const typeInTextarea = (text: string) => {
    // var curPos: any = document.getElementById('text_area');
    // let x = curPos.value;
    // x =
    //   x.slice(0, curPos.selectionStart) + text + x.slice(curPos.selectionStart);
    // setState({ body: x });
    quillRef?.current
      ?.getMethods()
      ?.getEditor()
      .insertEmbed(
        quillRef.current.getMethods().getEditor().getSelection(true)?.index,
        text,
      );
  };

  const loadMore = () => {
    setState({ skip: state.skip + 20 });
    loadMoreTemplates(state.skip + 20);
  };


  const templateOnClick = (template) =>{
    if (template._id) {
      setState({
        selected: !state.selected,
        id: template._id,
        create: false
      });
      getEmailTemplateById(template._id);
    } else {
      setState({
        name: template.name,
        body: template.body,
        subject: template.subject,
        id: template.name,
        create: true
      });
    }
  }

  return (
    <div className="email_template_container">
      <div className="email_template_header">
        <div className="email_template_heading">Email Templates</div>
        <div className="email_template_header_buttons">
          {state.id.length > 0 && !state.create  && (
            <div className="email_template_delete_button">
              <PrimaryButton
                onClick={() => setState({ modal: true })}
                icon={Assets.trash}
                text={'Delete'}
                className={'delete_btn'}
              />
            </div>
          )}
          <div className="email_template_save_button">
            <PrimaryButton
              onClick={
                state.id.length > 0 && !state.create ? editEmailTemplate : addEmailTemplate
              }
              icon={Assets.save}
              text={state.id.length > 0 ? 'Modify template' : 'Create template'}
              className={'save_btn'}
            />
          </div>
          {state.id.length > 0 && (
            <div className="email_template_save_button">
              <PrimaryButton
                onClick={() =>
                  setState({ id: '', name: '', body: '', subject: '' })
                }
                text={'Cancel'}
                className={'save_btn'}
              />
            </div>
          )}
        </div>
      </div>
      <div className="email_template_wrapper">
        <div className="email_template_sub_container">
          <div className="email_template_button">
            {state.loading ? (
              <Loader />
            ) : state.templates.length > 0 ? (
              state.templates.length > 0 &&
              state.templates.map((template: any) => {
                return (
                  <>
                    <TemplateButton
                      text={template.name}
                      selected={state.id === template.name && true}
                      onClick={() => {
                        templateOnClick(template)
                      }}
                    />
                  </>
                );
              })
            ) : (
              <div className="empty_text">No templates found</div>
            )}
          </div>
          {state.loadMore && (
            <div className="email_template_load_more_btn">
              <PrimaryButton text={'Load more'} onClick={loadMore} />
            </div>
          )}
        </div>
        <div className="email_template_main_container">
          <div className="email_template_subject_input">
            <div className="email_template_name_container">
              <div className="email_template_input_heading">
                Template Name<span className="required_field">*</span>
              </div>
              <Input
                onChange={(value) => {
                  setState({ name: value });
                }}
                type={'text'}
                value={state.name}
                name={'name'}
                error={state.errorArray}
              />
            </div>
            <div className="email_template_subject_container">
              <div className="email_template_input_heading">
                Email Subject<span className="required_field">*</span>
              </div>
              <Input
                onChange={(value) => {
                  setState({ subject: value });
                }}
                type={'text'}
                value={state.subject}
                name={'subject'}
                error={state.errorArray}
              />
            </div>
          </div>
          <div className="email_template_body_content">
            <div className="email_template_input_heading">
              Email Body<span className="required_field">*</span>
            </div>
            {/* <TextArea
              error={state.errorArray}
              name={'body'}
              value={state.body}
              id="text_area"
              onChange={(e) => {
                setState({
                  body: e.target.value,
                });
              }}
            /> */}
            <TextEditor
              onChange={(body: any) => setState({ body })}
              error={state.errorArray}
              name={'body'}
              value={state.body}
              id="text_area"
              ref={quillRef}
              {...props}
            />
          </div>
          <div className="email_template_email_merge_fields">
            <div className="email_template_input_heading">Merge fields</div>
            <Dropdown
              data={getDropdownData(Constants.merge_fields)}
              setActive={(value: any) => {
                setState({
                  email_field: value,
                });
                typeInTextarea(`{{${value.value}}}`);
              }}
              value={{ label: 'Choose field', value: '' }}
            />
          </div>
        </div>
      </div>
      <DeletePop
        text={'Are you sure you want to delete this template?'}
        onClick={deleteEmailTemplateById}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  );
};

export default EmailTemplate;
