import React, { useEffect, useRef } from 'react';
import './org_list.component.scss';
import Assets from 'imports/assets.import';
import PrimaryButton from '../button/primary_Button.ui';
import { isoToDateConvert, useSetState } from 'utils/functions.utils';
import { Loader, Models, Navbutton } from 'utils/imports.utils';
import _ from 'lodash';
import csvDownload from 'json-to-csv-export';

interface IOfferListComponentProps {
  data?: any;
  loading?: boolean;
  titleArray?: any;
  action_btn?: string;
  action_btn_icon?: string;
  onPress?: any;
  type?: string;
  actionArray?: any;
  loadMore?: any;
  hasMore?: boolean;
  download?: boolean;
  show?: string;
  editApproval?: any;
  search?: string;
}

export default function OfferListComponent(props: IOfferListComponentProps) {
  const [state, setState] = useSetState({
    isModalOpen: false,
    orgData: [],
  });
  const modalRef: any = useRef();
  const getManyOrg = async () => {
    try {
      const body = {
        search: props.search,
      };

      let res: any = await Models.organization.getManyOrganization(body);
    } catch (error: any) {
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  useEffect(() => {
    getManyOrg();
  }, [props.search]);

  if (_.isEmpty(props.data) && !props.loading) {
    return <div className="empty_text">No data found</div>;
  }

  return (
    <div className="list_component_container">
      <div className="list_component_row">
        {props.titleArray.map((item: any, index: number) => (
          <div
            style={{ width: item.width, ...item?.style }}
            className={`${
              index === 0 && 'list_component_block'
            }  list_component_name_wrapper`}
            key={index}>
            <div className="list_component_name list_component_heading">
              {item.label}
            </div>
          </div>
        ))}
        <div className="list_component_action_wrapper">
          {props.download && (
            <div className="csv_download">
              <Navbutton
                icon={Assets.csv}
                onClick={() => csvDownload(props.data)}
                className="csv_download_icon"
              />
            </div>
          )}
        </div>
      </div>
      {props.loading ? (
        <Loader />
      ) : (
        // )
        // : !props.loading && props?.data?.length == 0 ? (
        //   <div className="empty_text">No data found</div>

        props?.data?.map((item: any) => (
          <div className="list_component_row">
            {props.titleArray.map((titleObj: any, titleIndex: number) => {
              let value = props?.titleArray[titleIndex]?.value;

              return (
                <div
                  className={`${
                    titleIndex === 0 && 'list_component_block'
                  }  list_component_name_wrapper`}
                  style={{ width: props?.titleArray[titleIndex]?.width }}>
                  <div
                    className={`${
                      titleIndex !== 0 && 'list_component_subtitle'
                    }  list_component_name`}>
                    {value === 'count' && item.plan === 'basic'
                      ? `${item[item.plan].quantity}/${
                          item[item.plan].total_quantity
                        }`
                      : value === 'total_quantity'
                      ? `${item[item.plan].quantity}/${
                          item[item.plan].total_quantity
                        }`
                      : value === 'user' && item.plan !== 'basic'
                      ? item[item.plan].quantity
                      : value === 'start_date' && item.plan !== 'basic'
                      ? isoToDateConvert(item[item.plan].created_at)
                      : value === 'end_date' && item.plan === 'trial'
                      ? isoToDateConvert(item[item.plan].end_date)
                      : value === 'plan'
                      ? `${item[value]=== "trial"?"Free":item[value]} Plan`
                      : !_.isEmpty(item[value])
                      ? item[value]
                      : '--'}
                  </div>
                </div>
              );
            })}
            <div
              className="list_component_actions_wrapper"
              style={{
                width: props.actionArray.style.width,
                justifyContent: 'flex-end',
                paddingRight: '30px',
              }}>
              {props?.actionArray?.actions?.map((action: any) =>
                action.component(item),
              )}
            </div>
          </div>
        ))
      )}
      {props.hasMore && !props.loading && (
        <div className="list_component_load_button_wrapper">
          <PrimaryButton
            text={'Load more'}
            onClick={() => {
              props.loadMore();
            }}
            className="list_component_load_button"
          />
        </div>
      )}
    </div>
  );
}
