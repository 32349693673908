import ListComponent from 'common_components/ui/list/list.component';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  checkAgency,
  getDropdownData,
  mergeArrayWithoutDuplicates,
  useSetState,
} from 'utils/functions.utils';
import { useNavigate } from 'react-router-dom';
import {
  Functions,
  Models,
  PrimaryButton,
  Assets,
  Dropdown,
  ClipBoard,
  Share,
} from 'utils/imports.utils';
import './recquisition_board.screen.scss';
import { getRecquisitionList, getSummary, getUserDetails } from '../../utils/redux.utils';
import { toastify, uploadFile, toastifyError } from 'utils/functions.utils';
import moment from 'moment';
import _ from 'lodash';
import { req_status, REQUISITION_STATUS, SOURCE } from 'utils/constant.utils';

const RecquisitionBoard = (props: any) => {
  // const domain = 'https://askhugo-client.augmo.io';
  const domain = 'https://careers.askhugo.io';
  // const domain = 'http://localhost:3000';
  // Redux
  const recquisition = useSelector(
    (state: any) => state.recquisition?.recquisition_list,
  );
      const candidateState = useSelector((state: any) => state.candidate);
  const userState = useSelector((state: any) => state.user.auth);
  // State
  const [state, setState] = useSetState({
    data: [],
    skip: 0,
    limit: 20,
    loadMore: true,
    id: '',
    share: false,
    loading: false,
    agency_orgs: [],
    agency_id: '',
    subscribed:false
  });

  //Hooks
  let agency_id = localStorage.getItem('agency')
    useEffect(() => {
    fetchRecquisitionLists();
  }, [agency_id]);

  useEffect(() => {
    getReqSummary();
    getUser()
  }, []);

  const navigate = useNavigate();

  // Network req
  const fetchRecquisitionLists = async () => {
    try {
      setState({ loading: true });
      let body: any = {
        limit: state.limit,
        skip: 0,
      };
      if (agency_id !=='') {
        body.agency = agency_id;
      }
      const res: any = await Models.jobRecquisition.getNewRecquisition(body);
      res?.data?.docs.map((item) => {
        item.aging_days = moment(item?.created_at).format('DD-MM-YYYY');
        item.address = item.work_location?.nickname;
        item.hiring_manager = item?.hiring_team.map(
          (hiringList: any) =>
            `${hiringList?.first_name} ${hiringList?.last_name}`,
        );
        item.recruiter_team = item?.recruiter.map(
          (hiringList: any) =>
            `${hiringList?.first_name} ${hiringList?.last_name}`,
        );
        if(!_.isEmpty(item.account_manager)){
          item.acc_manager_name = [`${item.account_manager.first_name} ${item.account_manager.last_name}`]
        }
        return item;
      });
      // let data = mergeArrayWithoutDuplicates(res?.data?.docs, recquisition);
      if (res.data.totalDocs < state.limit) {
        setState({ loadMore: false });
      }
      // dispatch
      getRecquisitionList(res.data.docs);
      setState({ loading: false });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const changeReqStatus = async (role: any, item: any) => {
    try {
      let body = {
        requisition_id: item._id,
        status: role.value,
      };
      let requisitionList = recquisition;
      const index = _.findIndex(requisitionList, { _id: item._id });
      requisitionList[index].status = role.value;
      getRecquisitionList(requisitionList);
      const res: any = await Models.jobRecquisition.editRequisition(body);
      getReqSummary();
      setState({ skip: 0 });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getReqSummary = async () => {
    try {
      let query:any ={}
      if (agency_id !=='') {
        query.agency = agency_id;
      }
      const res: any = await Models.jobRecquisition.getSummary(query);
      getSummary(res?.data);
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const loadMoreRequisitions = async (skip: number) => {
    try {
      // setState({ loading: true });
      const res: any = await Models.jobRecquisition.getNewRecquisition({
        limit: state.limit,
        skip,
      });
      res?.data?.docs.map((item) => {
        item.aging_days = moment(item?.created_at).format('DD-MM-YYYY');
        item.address = item.work_location?.nickname;
        item.hiring_manager = item?.hiring_team.map(
          (hiringList: any) =>
            `${hiringList?.first_name} ${hiringList?.last_name}`,
        );
        if(!_.isEmpty(item.account_manger)){
          item.acc_manager_name = [`${item.account_manger.first_name} ${item.account_manger.last_name}`]
        }
        return item;
      });
      let data = mergeArrayWithoutDuplicates(recquisition, res?.data?.docs);
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      // dispatch
      getRecquisitionList(data);
      // setState({ loading: false });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const loadMore = () => {
    setState({ skip: state.skip + 20 });
    loadMoreRequisitions(state.skip + 20);
  };


  const getUser = async () => {
    let userId = localStorage.getItem('user_id');
    try {
      const res: any = await Models.auth.viewUser(userId);
      if (
        res.data?.org?.subscribed &&
        !res.data?.is_agencies &&
        res.data?.org?.plan.quantity !== 0
      ) {
        setState({ subscribed: res.data.org.subscribed });
      }
      // dispatch
      getUserDetails({ ...res.data });
    } catch (error: any) {
      console.log(error);
    }
  };
  //Logic
  const DropdownIndicator = () => {
    return (
      <img
        src={Assets.arrow_down_white}
        style={{ marginRight: '10px' }}
        width={'13px'}
        height={'13px'}
      />
    );
  };

  const getShareIcons = (item) => {
    if (item.job_post) {
      return (
        <>
          <Share
            facebook={{
              quote: item.job_title,
              url: `${process.env.REACT_APP_CAREER_SITE}/job_application_page?req_id=${item.external_id}&utm_source=${SOURCE.FACEBOOK}`,
            }}
            linkedIn={{
              title: item.job_title,
              summary: '',
              url: `${process.env.REACT_APP_CAREER_SITE}/job_application_page?req_id=${item.external_id}&utm_source=${SOURCE.LINKEDIN}`,
            }}
            {...props}
          />
          <ClipBoard
            text={`${process.env.REACT_APP_CAREER_SITE}/job_application_page?req_id=${item.external_id}&utm_source=${SOURCE.REFERRAL}`}
            onCopy={() => {
              setState({ copied: true });
              toastify('Requisition link copied');
            }}
            {...props}>
            <img src={Assets.copy} className="req_board_clipboard" />
          </ClipBoard>
        </>
      );
    } else {
      return (
        <>
          <img src={Assets.facebook_disabled} height={20} width={20} />
          <img src={Assets.linkedin_disabled} height={20} width={20} />
          <img src={Assets.copy_disabled} height={20} width={20} />
        </>
      );
    }
  };

  //sample data
  const titleData = [
    {
      label: 'Job Id',
      value: 'id',
      width: '12%',
      onPress: (item: any) => {
        navigate(`/requisition?id=${item._id}`);
      },
      className: 'req_table_job_id',
      type: 'id',
    },
    {
      label: 'Job Title',
      value: 'job_title',
      width: '11%',
      onPress: (item: any) => {
        navigate(`/requisition?id=${item._id}`);
      },
    },
    {
      label: 'Job Location',
      value: 'address',
      width: '12%',
      onPress: (item: any) => {
        navigate(`/requisition?id=${item._id}`);
      },
    },
    {
      label: 'Total',
      value: 'number_of_vacancies',
      width: '7%',
      onPress: (item: any) => {
        navigate(`/requisition?id=${item._id}`);
      },
      style: { justifyContent: 'center' },
    },
    {
      label: 'Open',
      value: 'open_vacancies',
      width: '7%',
      onPress: (item: any) => {
        navigate(`/requisition?id=${item._id}`);
      },
      style: { justifyContent: 'center' },
    },
    {
      label: 'Status',
      value: 'status',
      width: '12%',
      component: (item: any) => (
        <div style={{ width: '12%' }}>
          <div className="div" style={{ width: '100%' }}>
            <Dropdown
              disabled={item.approval_required && item.status === REQUISITION_STATUS.DRAFT ?true:false}
              data={getDropdownData(userState?.is_agency?[...req_status,REQUISITION_STATUS.LOST]:req_status)}
              className="requisition_dropdown"
              setActive={(role: any) => changeReqStatus(role, item)}
              value={[{ label: item.status, value: item.status }]}
              components={{ DropdownIndicator }}
              singleValueClassName='single_value'
              menuListClassName='req_menu_list'
              isSearchable={false}
              {...props}
            />
          </div>
        </div>
      ),
      style: { justifyContent: 'center' },
    },
    {
      label: 'Aging days',
      value: 'aging_days',
      width: '12%',
      type: 'date',
      onPress: (item: any) => {
        navigate(`/requisition?id=${item._id}`);
      },
      style: { justifyContent: 'center' },
    },
    {
      label:`${userState?.is_agency  && !agency_id? 'Account Manager':'Hiring Manager'}`,
      value: `${userState?.is_agency && !agency_id  ? 'acc_manager_name':'hiring_manager'}`,
      width: '15%',
      type: 'hiring_team',
      onPress: (item: any) => {
        navigate(`/requisition?id=${item._id}`);
      },
    },
  ];

  let actionArray = {
    style: { width: '8%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div
              onClick={() => {
                setState({ share: true, id: item._id });
              }}
              {...props}
              style={{ width: '100%' }}>
              <div className="req_table_share_button">
                {getShareIcons(item)}
              </div>
            </div>
          );
        },
      },
    ],
  };
 
  return (
    <div className="recquisition_container">
      <div className="recquisition_header">
        <div className="recquisition_heading_text">Requisitions</div>
        {!checkAgency()&&
        <div className="recquisition_button_container">
          <PrimaryButton
            icon={Assets.add}
            text={'New Job Requisition'}
            className={'recquisition_add_button'}
            onClick={() => navigate('/new_job_requisition')}
          />
        </div>}
      </div>
      <div className="recquisition_wrapper">
        {/* <div className="recquisition_wrapper_element">
          <div className="recquisition_wrapper_headings">Open</div>
          <div className="recquisition_wrapper_values">32</div>
        </div> */}
        <div className="recquisition_wrapper_element">
          <div className="recquisition_wrapper_headings">Total</div>
          <div className="recquisition_wrapper_values">
            {candidateState?.summary?.total}
          </div>
        </div>
        <div className="recquisition_wrapper_element">
          <div className="recquisition_wrapper_headings">Open</div>
          <div className="recquisition_wrapper_values">
            {candidateState?.summary?.open}
          </div>
        </div>
        <div className="recquisition_wrapper_element">
          <div className="recquisition_wrapper_headings">On hold</div>
          <div className="recquisition_wrapper_values">
            {candidateState?.summary?.on_hold}
          </div>
        </div>
        <div className="recquisition_wrapper_element">
          <div className="recquisition_wrapper_headings">Cancelled</div>
          <div className="recquisition_wrapper_values">
            {candidateState?.summary?.cancelled}
          </div>
        </div>
        <div className="recquisition_wrapper_element no_border_right">
          <div className="recquisition_wrapper_headings">Filled</div>
          <div className="recquisition_wrapper_values">
            {candidateState?.summary?.filled}
          </div>
        </div>
        {/* <div className="recquisition_wrapper_element">
          <div className="recquisition_wrapper_headings">Filled</div>
          <div className="recquisition_wrapper_values">00</div>
        </div>
        <div className="recquisition_wrapper_element">
          <div className="recquisition_wrapper_headings">On Hold</div>
          <div className="recquisition_wrapper_values">04</div>
        </div> */}
      </div>
      <div className="requisition_board_table">
        {/* <div className="recquisition_heading_text requisition_table_heading">
          My Requisitions
        </div> */}
        <div className="requistion_table_container">
          <ListComponent
            titleArray={titleData}
            data={recquisition.length > 0 ? recquisition : []}
            type="requisition_board_table"
            actionArray={actionArray}
            hasMore={state.loadMore}
            loadMore={loadMore}
            loading={state.loading}
            // download
          />
        </div>
      </div>
    </div>
  );
};

export default RecquisitionBoard;
