import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  phoneCheck,
  toastify,
  toastifyError,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Navbutton,
  Assets,
  Input,
  Tag,
  Dropdown,
  PrimaryButton,
  TextArea,
  Validation,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './subscription_modal.ui.scss';
import _ from 'lodash';

const SubscriptionModal = forwardRef((props: any, ref) => {
  // Redux
  const userState = useSelector((state: any) => state.user.auth);
  // ref
  const modalRef: any = useRef();
  let query = useQuery();
  let req_id = query.get('req_id');
  //Redux
  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    name: '',
    email: '',
    address: '',
    phone_number: '',
    customerData: {},
    phoneError: false,
  });

  //Hooks
  useEffect(() => {}, []);

  // Network req

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  //Logic
  const createCustomer = async (agency) => {
    try {
      let body = {
        name: state.name,
        email: state.email,
        address: state.address || '',
        phone_number: state.phone_number || '',
      };
      await Validation.createCustomer.validate(body, { abortEarly: false });
      if (!_.isEmpty(state.phone_number)) {
        if (!phoneCheck(state.phone_number)) {
          setState({ phoneError: true });
          return setTimeout(() => {
            setState({ phoneError: false });
          }, 3000);
        }
      }
      if (_.isEmpty(state.customerData)) {
        await Models.customer.createCustomer(body);
        getCustomer();
      } else if (
        !_.isEqual(state.customerData?.name, state.name) ||
        !_.isEqual(state.customerData?.email, state.email) ||
        !_.isEqual(state.customerData?.phone_number, state.phone_number) ||
        !_.isEqual(state.customerData?.address, state.address)
      ) {
        await Models.customer.editCustomer(body);
        getCustomer();
      }
      props.onClick();
      setState({ isModalOpen: false });
    } catch (error: any) {
      setState({
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };
  const getCustomer = async () => {
    try {
      const res: any = await Models.customer.getCustomer({});
      if (!_.isEmpty(res.data)) {
        setState({
          customerData: res.data,
          name: res.data.name,
          email: res.data.email,
          address: res.data.address,
          phone_number: res.data.phone_number,
        });
      }
    } catch (error: any) {
      toastifyError(error);
    }
  };

  useEffect(() => {
    if(state.isModalOpen){
      getCustomer();
    }
  }, [state.isModalOpen]);

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModalSubscription',
        }}>
        <div className="subscription_agency_container">
          <div className="subscription_wrapper">
            <div className="subscription_header">
              <div className="subscription_heading">Billing Details</div>
              <Navbutton
                icon={Assets.black_close}
                onClick={() => {
                  setState({ isModalOpen: false });
                }}
              />
            </div>
            <div className="input_wrapper">
              <div className="input_detail">
                <div className="subscription_subheading">
                  Name<span className="required_field">*</span>
                </div>
                <Input
                  onChange={(value: any) => {
                    setState({ name: value });
                  }}
                  type="text"
                  value={state.name}
                  name="name"
                  error={state.errorArray}
                />
              </div>
              <div className="input_detail">
                <div className="subscription_subheading">
                  Email<span className="required_field">*</span>
                </div>
                <Input
                  onChange={(value: any) => {
                    setState({ email: value });
                  }}
                  type="text"
                  value={state.email}
                  name="email"
                  error={state.errorArray}
                />
              </div>
              <div className="input_detail">
                <div className="subscription_subheading">Phone Number</div>
                <Input
                  onChange={(value: any) => {
                    setState({ phone_number: value });
                  }}
                  type="text"
                  value={state.phone_number}
                  name="email"
                />
                {state.phoneError && (
                  <div className="input_field_error">
                    {state.phone_number.length > 10
                      ? 'At least 10 Digit'
                      : 'Invalid Phone Number'}
                  </div>
                )}
              </div>
              <div className="input_detail">
                <div className="subscription_subheading">Address</div>
                <TextArea
                  onChange={(e: any) => {
                    setState({ address: e.target.value });
                  }}
                  value={state.address}
                  name="address"
                  className="sub_modal_textarea"
                />
              </div>
            </div>
            <div className="subscription_send_button">
              <PrimaryButton
                text={'Proceed to Pay'}
                className={'send_sub_button'}
                onClick={createCustomer}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default SubscriptionModal;
