import instance from 'utils/axios.utils';

const postJob = {
  createPostJob: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'job_post/create_job_post';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getManyJobPosts: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'job_post/public/get_many_job_post';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getJobPostByReq: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'job_post/public/get_job_post_by_req';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getPostJob: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'job_post/get_job_post';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editJobPost: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'job_post/edit_job_post';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

  export default postJob