import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import { toastify, toastifyError, useQuery, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Navbutton,
  Assets,
  Input,
  Tag,
  Dropdown,
  PrimaryButton,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './invite_modal.ui.scss';

const InviteModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();
  let query = useQuery()
  let req_id = query.get('req_id')
  //Redux
  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    time_zone: '',
    first_name: '',
    last_name: '',
    role: [],
    agency:[],
    agencies_list: []
  });

  //Hooks
  useEffect(() => {
    // getAllAgency();
    setState({ agency: [] });
  }, []);

  // Network req

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  //Logic
  const inviteAgency = async (agency) => {
    try {
      let body = {
        requisition_id: req_id,
        agency,
      };
      const res: any = await Models.jobRecquisition.inviteAgency(body);
      console.log('invite agency', res);
      setState({ isModalOpen: false });
      toastify('Agency invited')
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getAllAgency = async () => {
    try {
      const res: any = await Models.agency.getAllAgency({
        limit: 100,
        skip: 0,
        invite_accepted: true,
      });
      let agencies_list: any = [];
      res.data.docs.map((item: any) =>
        agencies_list.push({ label: item.name, value: item._id }),
      );
      setState({ agencies_list });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const handleSubmit = () => {
    state.agency.map(async (item: any) => await inviteAgency(item.value));
  };

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'inviteModalAgency',
        }}>
        <div className="invite_agency_container">
          <div className="invite_wrapper">
            <div className="invite_header">
              <div className="invite_heading">Invite Agencies</div>
              <Navbutton
                icon={Assets.black_close}
                onClick={() => {
                  setState({ isModalOpen: false });
                }}
              />
            </div>
            <div className="invite_subheading">Agency</div>
            <Dropdown
              data={props.agencies}
              value={state.agency}
              isMulti
              agency
              setActive={(agency: any) => {
                setState({ agency });
              }}
            />
            <div className="invite_send_button">
              <PrimaryButton
                text={'Save'}
                className={'send_invite_button'}
                onClick={()=>
                  handleSubmit()
                }
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default InviteModal;
