import AddNotes from 'components/add_notes/add_notes.component';
import CandidateOfferedModal from 'components/candidate_offered_modal/candidate_offered_modal.component';
import EmailCandidate from 'components/email_resume/email_candidate.component';
import _ from 'lodash';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  CANDIDATE_STATUS,
  candidate_status,
  FeedbackStatus,
  REQUISITION_STATUS,
} from 'utils/constant.utils';
import {
  checkAgency,
  filterObjectByKeys,
  getDropdownData,
  getDropdownValue,
  gets3FileName,
  jobScoreMatch,
  toastifyError,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  PrimaryButton,
  ToggleHeader,
  Dropdown,
  TextEditor,
  Attachment,
  CandidateInfo,
  EmailList,
  CandidateHistory,
  CardsComponent,
  PdfViewer,
  AddFeedback,
  Loader,
  EditCandidate,
  EmailResume,
  CircularProgress,
  CandidateJoinModal,
  Validation,
} from 'utils/imports.utils';
import {
  getCandidate,
  getCandidateHistory,
  getRecquisitionById,
  getRecquisitionList,
  testDispatch,
} from 'utils/redux.utils';
import './candidate_page.screen.scss';

const CandidatePage = (props: any) => {
  // Redux
  const candidateState = useSelector((state: any) => state.candidate);
  const userState = useSelector((state: any) => state.user);
  // State
  const [state, setState] = useSetState({
    active: '',
    req_list: [],
    current_req: {},
    loading: false,
    req: {},
    limit: 200,
    errorArray: [],
  });
  //Hooks
  const query = useQuery();
  const navigate = useNavigate();
  const candidate_id: any = query.get('id');
  const wrapperRef: any = useRef();
  const modalRef: any = useRef();
  const resumeModalRef: any = useRef();
  const resume_id: any = query.get('resume_id');
  const req_id: any = query.get('req_id');
  const candidateJoinModalRef: any = useRef();
  const candidateOfferedModalRef: any = useRef();
  const emailCandidateRef: any = useRef();
  useEffect(() => {
    setState({ active: headerData[0] });
    if (resume_id) {
      fetchResume();
    } else {
      fetchCandidate();
    }
    recquisitionById()
    wrapperRef?.current?.scrollTo(0, 0);
  }, []);

  // Network req
  const fetchCandidate = async () => {
    try {
      setState({ loading: true });
      let body = {
        candidate_id,
      };
      const res: any = await Models.candidateModel.getCandidate(body);
      // const res: any = await Models.resume.getResume(body);
      setState({
        current_req: getDropdownValue(res?.data?.requisition?.job_title),
      });
      //dispatch
      getCandidate(res?.data);
      fetchCandidateHistory(res?.data?.resume?._id);
      getRecquisitionLists();
      setState({ loading: false });
    } catch (error: any) {
      console.log(error);
    }
  };
  
  const editCandidate = async (stage, data?: any) => {
    try {
      let body: any = {
        candidate_id,
        stage,
      };
      if (stage === CANDIDATE_STATUS.OFFERED) {
            body.offered_ctc = data.offered_ctc,
            body.fixed_salary = data.fixed_salary
            body.tentative_joining_date=data.tentative_joining_date
        await Validation.editCandidateSchema.validate(body, {
          abortEarly: false,
        });
      }
      if (data?.date) {
        body.joined_date = data.date;
      }
      const res: any = await Models.candidateModel.editCandidate(body);
      candidateJoinModalRef?.current?.closeModal();
      candidateOfferedModalRef?.current?.closeModal();
      //dispatch
      getCandidate(res?.data);
    } catch (error: any) {
      toastifyError(error);
      setState({ errorArray: JSON.parse(JSON.stringify(error))?.inner || []});
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const getRecquisitionLists = async () => {
    try {
      const res: any = await Models.jobRecquisition.getNewRecquisition({
        limit: 500,
        skip: 0,
        status: REQUISITION_STATUS.OPEN,
      });
      // dispatch
      let req_list: any = [];
      res.data.docs.map((item: any) =>
      req_list.push({ label: `${item?.job_title} Jr-${item.id}`, value: item._id }),
      );
      setState({ req_list });
      getRecquisitionList(res?.data?.docs);
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const moveCandidate = async (new_requisition: string) => {
    try {
      const body = {
        candidate_id,
        new_requisition,
      };
      const res: any = await Models.candidateModel.moveCandidate(body);
      navigate(`/requisition?id=${new_requisition}`);
      // dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const fetchCandidateHistory = async (resume_id: any) => {
    try {
      let body = {
        resume_id,
      };
      const res: any = await Models.candidateModel.getManyCandidates(body);
      //dispatch
      getCandidateHistory(res?.data?.docs);
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const fetchResume = async () => {
    try {
      setState({ loading: true });
      let body = {
        resume_id,
      };
      const res: any = await Models.resume.getResume(body);
      //dispatch
      getCandidate({ resume: res?.data });
      fetchCandidateHistory(resume_id);
      getRecquisitionLists();
      setState({ loading: false });
    } catch (error: any) {
      console.log(error);
    }
  };

  const createCandidate = async (new_requisition: string) => {
    try {
      const body = {
        resume: resume_id,
        requisition: new_requisition,
        stage: CANDIDATE_STATUS.APPLIED,
      };
      const res: any = await Models.candidateModel.createCandidate(body);
      console.log('create candidate', res);
      // navigate(`/requisition?id=${new_requisition}`);
      // dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //Logic
  const headerData = [
    'Candidate details',
    'Resume',
    'Notes',
    'Feedback',
    'Candidate History',
  ];
  const agencyHeaderData = [
    'Candidate details',
    'Resume',
  ];
  const candidateHeaderData = [
    'Candidate details',
    'Resume',
    'Feedback',
    'Candidate History',
  ];

  const emailListData = {
    profile: Assets.profile_placeholder,
    name: 'John Doe',
    time: 'June 8, 2021, 10:33 PM',
    content:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a',
  };

  const checkUserStatus = () => {
    let index = _.findIndex(
      candidateState?.candidate?.requisition?.interviewer,
      { _id: userState.auth._id },
    );
    if (index !== -1) {
      return true;
    } else return false;
  };

  const tagJobReq = (new_req: any) => {
    setState({ current_req: new_req });
    if (candidate_id) {
      moveCandidate(new_req?.value);
    } else {
      createCandidate(new_req?.value);
    }
  };

  const handleNavigate = () => {
    if (candidate_id) {
      navigate(
        `/requisition?id=${candidateState?.candidate?.requisition?._id}`,
      );
    } else {
      navigate('/resume_search');
    }
  };

  const getCandidateInfo = (interviewer?: boolean) => {
    if (interviewer) {
      return filterObjectByKeys(candidateState?.candidate?.resume, [
        'created_at',
        '_id',
        'certifications',
        'work_samples',
        'modified_at',
        'is_deleted',
        '__v',
        'profile_picture',
        'id',
        'current_salary',
        'expected_salary',
        'resume_identifier',
        'employers',
        'dob',
        'bulk_upload',
        'education',
        'orgs',
        'skills'
      ]);
    } else {
      return filterObjectByKeys(candidateState?.candidate?.resume, [
        'created_at',
        '_id',
        'certifications',
        'work_samples',
        'modified_at',
        'is_deleted',
        '__v',
        'profile_picture',
        'id',
        'resume_identifier',
        'employers',
        'dob',
        'bulk_upload',
        'education',
        'orgs',
        'skills'
      ]);
    }
  };

  const getJobScore = () => {
    let value = 0;
    if (candidateState?.candidate?.job_match_score) {
      value = candidateState?.candidate?.job_match_score?.score * 100;
    }
    return parseFloat(value.toFixed(2));
  };

  const getCandidateStages = () => {
    let applied: any = _.filter(candidate_status, (item: any) => {
      if (item == CANDIDATE_STATUS.APPLIED) {
        return true;
      }
    });
    let screening: any = _.filter(candidate_status, (item: any) => {
      if (
        item == CANDIDATE_STATUS.SCREENING ||
        item == CANDIDATE_STATUS.REQUEST_FOR_SCREENING_AVAILABILITY ||
        item == CANDIDATE_STATUS.CONFIRM_SCREENING_AVAILABILITY ||
        item == CANDIDATE_STATUS.LONGLIST ||
        item == CANDIDATE_STATUS.SHORTLIST
      ) {
        return true;
      }
    });
    let interviewing: any = _.filter(candidate_status, (item: any) => {
      if (
        item == CANDIDATE_STATUS.CONFIRM_INTERVIEW_AVAILABILITY ||
        item == CANDIDATE_STATUS.REQUEST_FOR_INTERVIEW_AVAILABILITY ||
        item == CANDIDATE_STATUS.INTERVIEWING
      ) {
        return true;
      }
    });
    let offered: any = _.filter(candidate_status, (item: any) => {
      if (
        item == CANDIDATE_STATUS.OFFERED ||
        item == CANDIDATE_STATUS.OFFER_ACCEPTED ||
        item == CANDIDATE_STATUS.OFFER_DECLINED ||
        item == CANDIDATE_STATUS.OFFER_WITHDRAWN
      ) {
        return true;
      }
    });
    let joined: any = _.filter(candidate_status, (item: any) => {
      if (item == CANDIDATE_STATUS.JOINED) {
        return true;
      }
    });
    let reject: any = _.filter(candidate_status, (item: any) => {
      if (item == CANDIDATE_STATUS.REJECT||
        item == CANDIDATE_STATUS.INTERVIEW_REJECT ||
        item == CANDIDATE_STATUS.SCREEN_REJECT || item == CANDIDATE_STATUS.ON_HOLD) {
        return true;
      }
    });
    let submitted: any = _.filter(candidate_status, (item: any) => {
      if (item == CANDIDATE_STATUS.SUBMITTED || item == CANDIDATE_STATUS.TO_BE_SUBMITTED) {
        return true;
      }
    });
    let stages = [
      { label: CANDIDATE_STATUS.APPLIED, options: getDropdownData(applied) },
      {
        label: CANDIDATE_STATUS.SCREENING,
        options: getDropdownData(screening),
      },
      {
        label: CANDIDATE_STATUS.INTERVIEWING,
        options: getDropdownData(interviewing),
      },
      { label: CANDIDATE_STATUS.OFFERED, options: getDropdownData(offered) },
      { label: CANDIDATE_STATUS.JOINED, options: getDropdownData(joined) },
      {
        label: CANDIDATE_STATUS.REJECT,
        options: getDropdownData(reject),
      },
      {
        label: CANDIDATE_STATUS.CANDIDATE_DECLINED,
        options: getDropdownData([CANDIDATE_STATUS.CANDIDATE_DECLINED]),
      },
    ];
    if (userState.auth?.is_agency) {
      stages.push({
        label: CANDIDATE_STATUS.SUBMITTED,
        options: getDropdownData(submitted),
      });
    }

    return stages;
  };

  const changeCandidateStage = (stage: any) => {
    if (stage.value == CANDIDATE_STATUS.JOINED) {
      if (state.req.open_vacancies > 0) {
        candidateJoinModalRef.current.openModal();
      } else {
        toastifyError('All vacancies for this job are filled');
      }
    } else if (stage.value == CANDIDATE_STATUS.OFFERED) {
      candidateOfferedModalRef.current.openModal();
    }
    else if (
      stage.value == CANDIDATE_STATUS.CONFIRM_INTERVIEW_AVAILABILITY ||
      stage.value == CANDIDATE_STATUS.CONFIRM_SCREENING_AVAILABILITY ||
      stage.value == CANDIDATE_STATUS.REQUEST_FOR_INTERVIEW_AVAILABILITY ||
      stage.value == CANDIDATE_STATUS.REQUEST_FOR_SCREENING_AVAILABILITY ||
      stage.value == CANDIDATE_STATUS.REJECT
    ) {
      emailCandidateRef?.current?.openModal();
      setState({ stage });
      editCandidate(stage.value);
    } else if (stage.value == CANDIDATE_STATUS.REJECT) {
      emailCandidateRef?.current?.openModal();
    } else {
      setState({ stage });
      editCandidate(stage.value);
    }
  };

  const recquisitionById = async () => {
    try {
      const body = {
        requisition_id: req_id,
      };
      const response: any = await Models.jobRecquisition.getRecquisitionById(
        body,
      );

      setState({
        req: response.data
      })
    } catch (error) {
      toastifyError(error);
    }
  };

  if (state.loading) return <Loader />;
console.log("error",state.errorArray)
  return (
    <div className="candidate_container" ref={wrapperRef}>
      <div className="candidate_header">
        <div className="candidate_heading_and_back">
          <div className="candidate_back_icon" onClick={() => handleNavigate()}>
            <img
              src={Assets.back}
              className="candidate_back_icon_wrapper"
              alt={'back icon'}
            />
          </div>
          <div className="candidate_heading_text">Candidate Page</div>
        </div>
        <div className="candidate_header_buttons">
          <PrimaryButton
            icon={Assets.email_active}
            text={'Email Resume'}
            onClick={() => emailCandidateRef?.current?.openModal()}
          />
          {/* <PrimaryButton icon={Assets.whatsapp} text={'WhatsApp'} /> */}
          {candidate_id && !checkAgency() && (
            <PrimaryButton
              icon={Assets.small_right}
              text={'Setup Interview'}
              onClick={() =>
                navigate(
                  `/interview_setup?id=${candidate_id}&&req_id=${req_id}`,
                )
              }
            />
          )}
        </div>
      </div>
      <div className="candidate_container1">
        <div className="candidate_container2">
          <div className="candidate_toggle_header">
            <ToggleHeader
              data={
                checkAgency()
                  ? agencyHeaderData
                  : candidate_id?.length > 0
                    ? headerData
                    : candidateHeaderData
              }
              onChange={(item: string) => setState({ active: item })}
              active={state.active}
            />
          </div>
          {state.active === 'Resume' ? (
            <div className="candidate_resume_container">
              <div className="candidate_resume_wrapper">
                <div className="candidate_resume_image">
                  {candidateState?.candidate?.resume.resume.split('.').pop() ===
                    'pdf' ? (
                    <PdfViewer
                      file={candidateState?.candidate?.resume?.resume}
                    />
                  ) : (
                    <div
                      className="application_sub_heading"
                      style={{ height: '100%' }}>
                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${candidateState?.candidate?.resume?.resume}`}
                        width="98%"
                        height="98%"
                        frameBorder="0">
                        This is an embedded document by AskHugo.
                      </iframe>
                    </div>
                    // gets3FileName(candidateState?.candidate?.resume?.resume)
                  )}
                </div>
                <div className="candidate_footer_buttons">
                  {/* <PrimaryButton
                  text={'View In Full Screen'}
                  icon={Assets.full_screen}
                /> */}
                  {candidateState?.candidate?.resume && (
                    <a
                      href={candidateState?.candidate?.resume?.resume}
                      target="_blank"
                      className="candidate_download_btn"
                      download>
                      <div className="download_icon_wrapper">
                        <img src={Assets.download} alt={'back icon'} />
                      </div>
                      <div className="download_text">Download</div>
                    </a>
                  )}
                </div>
              </div>
              {/* <div className="candidate_document_container">
              <div className="candidate_document_header">
                <div className="candidate_document_text">Documents</div>
                <div className="candidate_document_button">
                  <div className="candidate_document_upload_icon">
                    <img src={Assets.upload_green} alt={'upload icon'} />
                  </div>
                  <div className="candidate_document_upload_text">Upload</div>
                </div>
              </div>
              <div className="candidate_attachment_container">
                {data.map((item) => (
                  <Attachment data={item} />
                ))}

                {data.map((item) => (
                  <Attachment data={item} />
                ))}

                {data.map((item) => (
                  <Attachment data={item} />
                ))}
              </div>
            </div> */}
            </div>
          ) : state.active === 'Email' ? (
            <div className="candidate_email_container">
              <div className="candidate_email_list">
                <EmailList data={emailListData} />
                <EmailList data={emailListData} />
                <EmailList data={emailListData} />
                <EmailList data={emailListData} />
              </div>
              <div className="candidate_email_dropdown_container">
                <div className="candidate_email_dropdown_wrapper">
                  <div className="candidate_input_heading">Stage</div>
                  <div className="candidate_dropdown">
                    {/* <Dropdown data={''} /> */}
                  </div>
                </div>
                <div className="candidate_reply_button">
                  <PrimaryButton
                    text={'Reply to this thread'}
                    icon={Assets.email_active}
                  />
                </div>
              </div>
              <div className="candidate_texteditor_container">
                <TextEditor
                  onChange={(content: string) => setState({ content })}
                />
              </div>
            </div>
          ) : state.active === 'Candidate History' ? (
            <div className="candidate_history_wrapper">
              {candidateState?.candidate_history.map((candidate: any) => (
                <CandidateHistory data={candidate} />
              ))}
            </div>
          ) : state.active == 'Candidate details' ? (
            <div className="candidate_candidateinfo_container">
              {candidateState?.candidate?.resume && (
                <CandidateInfo
                  editIcon={resume_id?.length > 0 ? false : true}
                  onClick={() => modalRef?.current?.openModal()}
                  profile={candidateState?.candidate?.resume?.profile_picture}
                  distance={candidateState?.candidate?.distance}
                  data={
                    checkUserStatus()
                      ? getCandidateInfo(true)
                      : getCandidateInfo()
                  }
                />
              )}
            </div>
          ) : state.active === 'Feedback' ? (
            <div className="candidate_resume_container">
              <AddFeedback type={FeedbackStatus.CANDIDATE} showAddBtn={false} />
            </div>
          ) : (
            candidate_id?.length > 0 && (
              <div className="candidate_resume_container">
                <AddNotes type="candidate" />
              </div>
            )
          )}
        </div>
     
        <div className="candidate_flowchart_container">
          {/* <div className="candidate_circular_progress">
            <CircularProgress
              title="Joiner prediction score"
              percentage={'90'}
              value={20}
            />
          </div> */}
          <div className="candidate_circular_progress">
            <CircularProgress
              title={jobScoreMatch(getJobScore())}
              percentage={getJobScore()}
              value={getJobScore()}
            />
          </div>
        </div>
        <div className="candidate_dropdown_container">
          <div className="candidate_dropdown_wrapper">
            {candidate_id &&
              !checkAgency() &&
              candidateState?.candidate?.stage !== CANDIDATE_STATUS.JOINED && (
                <div className="candidate_input_heading">Stage</div>
              )}
            <div className="candidate_dropdown">
              {candidate_id &&
                !checkAgency() &&
                candidateState?.candidate?.stage !==
                CANDIDATE_STATUS.JOINED && (
                  <Dropdown
                    data={getCandidateStages()}
                    value={getDropdownValue(candidateState?.candidate?.stage)}
                    setActive={(stage: any) => {
                      changeCandidateStage(stage);
                    }}
                  />
                )}
            </div>
          </div>
          <div className="candidate_dropdown_wrapper">
            {!checkAgency() && state.req_list.length > 0 && (
              <>
                <div className="candidate_input_heading">
                  Tag Job Requisition
                </div>
                <div className="candidate_dropdown">
                  <Dropdown
                    data={state.req_list}
                    value={state.current_req}
                    setActive={(new_req: any) => {
                      tagJobReq(new_req);
                    }}
                  />
                </div>
              </>
            )}
          </div>
         
        </div>
      </div>
      {candidate_id?.length > 0 && (
        <EditCandidate candidateId={candidate_id} ref={modalRef} />
      )}
      {/* <EmailResume
        ref={resumeModalRef}
        resume={[candidateState?.candidate?.resume?.resume]}
      /> */}
      {/* <EmailResume
        ref={emailCandidateRef}
        // resume={[candidateState?.candidate?.resume?.resume]}
        recipients={[candidateState?.candidate?.resume?.email]}
      /> */}
      <EmailCandidate
        ref={emailCandidateRef}
        // resume={[candidateState?.candidate?.resume?.resume]}
        recipients={[candidateState?.candidate?.resume?.email]}
      />
      <CandidateOfferedModal
        ref={candidateOfferedModalRef}
        error={state.errorArray}
        onPress={(data) => {
          setState({ stage: getDropdownValue(CANDIDATE_STATUS.OFFERED) });
          editCandidate(CANDIDATE_STATUS.OFFERED, data);
        }}
      />
      <CandidateJoinModal
        ref={candidateJoinModalRef}
        onPress={(data) => {
          setState({ stage: getDropdownValue(CANDIDATE_STATUS.JOINED) });
          editCandidate(CANDIDATE_STATUS.JOINED, {date:data});
        }}
      />
    </div>
  );
};

export default CandidatePage;
