import Assets from 'imports/assets.import';
import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';

interface ILineChart {
  data: any[];
  colors: any;
  labels: any[];
  joinersData: any[];
  type?: string;
}

const LineChart = (props: ILineChart) => {
  const chartRef: any = useRef();
  const saveCanvas = () => {
    const link: any = document.createElement('a');
    link.href = chartRef?.current?.chartInstance?.toBase64Image();
    link.download = 'chart.jpeg';
    link.click();
    link.parentNode.removeChild(link);
  };
  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: true,
            padding: 10,
            // min: 0,
            // max: 200,
            stepSize: 10000,
          },

          gridLines: {
            drawBorder: false,
            display: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: true,
            padding: 10,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
    },
  };
  return (
    <div>
      <div className="line_chart_wrapper"></div>
      <Line
        ref={chartRef}
        data={{
          labels: props.labels,
          datasets: [
            {
              label: props.type==="Revenues"?"Actual Revenues":'Open Vacancies',
              data: props.data,
              fill: true,
              backgroundColor: 'rgba(29, 123, 223, 0.2)',
              borderColor: '#1C7BDF',
            },
            {
              label: props.type==="Revenues"?"Potential Revenues":'Joiners',
              data: props.joinersData,
              fill: true,
              backgroundColor: 'rgba(38, 188, 168, 0.2)',
              borderColor: '#27C69E',
              type: 'line',
            },
          ],
        }}
        options={options}
      />
      <div style={{ position: 'absolute', top: '22px', right: '20px' }}>
        <img onClick={() => saveCanvas()} src={Assets.csv} />
      </div>
    </div>
  );
};

export default LineChart;
