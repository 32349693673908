import instance from 'utils/axios.utils';

const client = {
  createClient: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'client/create_client';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getAllClient: (body?: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'client/get_many_client';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteClientById: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'client/delete_client';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editClientById: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'client/edit_client';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getClientById: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'client/get_client';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default client;
