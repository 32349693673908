import axios from 'axios';

const country = {
  getAllCountries: () => {
    let promise = new Promise((resolve, reject) => {
      const config: any = {
        url: 'https://api.countrystatecity.in/v1/countries',
        headers: {
          'X-CSCAPI-KEY':
            'OEtIcnNhcDlvcTBpQ0hrTllMZVFZUWVPNkhHd3o5bE9wdHhzcGFhTQ==',
        },
      };
      axios(config)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  },
  getStatesOfCountry: (isoCode: string) => {
    let promise = new Promise((resolve, reject) => {
      const config: any = {
        method: 'get',
        url: `https://api.countrystatecity.in/v1/countries/${isoCode}/states`,
        headers: {
          'X-CSCAPI-KEY':
            'OEtIcnNhcDlvcTBpQ0hrTllMZVFZUWVPNkhHd3o5bE9wdHhzcGFhTQ==',
        },
      };
      axios(config)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  },
  getCitiesByCountry: (country: string, state: string) => {
    let promise = new Promise((resolve, reject) => {
      const config: any = {
        method: 'get',
        url: `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
        headers: {
          'X-CSCAPI-KEY':
            'OEtIcnNhcDlvcTBpQ0hrTllMZVFZUWVPNkhHd3o5bE9wdHhzcGFhTQ==',
        },
      };
      axios(config)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  },
};

export default country;
