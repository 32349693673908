import {  useMemo, useRef } from 'react';
import JoditEditor, { Jodit } from 'jodit-react';
import './table_text_editor.scss';
interface ITableTextEditorProps {
  onChange: any;
  error?: any;
  name?: string;
  value?: any;
  placeholder?: string;
  className?: string;
}

function TableTextEditor(props: ITableTextEditorProps) {
  const editor = useRef(null);

  const parser = new DOMParser();
  const htmlString = '';
  const doc3: any = parser.parseFromString(htmlString, 'text/html');
  const defaultEmail: any = doc3?.body?.firstChild?.textContent;

  //const defaultEmail= "Default email text";
  // const [state, setState] = useSetState({
  //   value: '',
  // });
  // useEffect(() => {
  //   if (props.value) {
  //     setState({ value: props.value });
  //   }
  // }, [props.value]);

  /*useEffect(() => {
    if (doc3) {
      setContent(doc3);
    }
  }, [htmlString]);*/
  function preparePaste(jodit) {
    jodit.e.on(
      'paste',
      (e) => {
        if (true) {
          jodit.e.stopPropagation('paste');
          jodit.s.insertHTML(
            //@ts-ignore
            Jodit.modules.Helpers.getDataTransfer(e).getData(
              Jodit.constants.TEXT_HTML,
            ),
          );
          return false;
        }
      },
      { top: true },
    );
  }
  Jodit.plugins.add('preparePaste', preparePaste);
  const config = {
    readonly: false,
    placeholder: props.placeholder || '',
    toolbarAdaptive: false,
    buttons: [
      'undo',
      'redo',
      'font',
      'fontsize',
      'bold',
      'italic',
      'underline',
      'eraser',
      'brush',
      'link',
      'ul',
      'ol',
      'indent',
      'outdent',
      'left',
      // 'fullsize',
      // 'source',
    ],
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };

  return (
    <div className="table_text_editor">
      {useMemo(
        () => (
          <JoditEditor
            ref={editor}
            config={config}
            value={props.value}
            onChange={(content: any) => {
              props.onChange(content);
            }}
            className={props.className}
          />
        ),
        [],
      )}
      {props.error &&
        props.error.map((error: any) => (
          <div className="input_field_error">
            {props.name === error?.path && error.message}
          </div>
        ))}
    </div>
  );
}
export default TableTextEditor;
