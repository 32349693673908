import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import './admin_toggle_header.scss';

interface IToggleHeader {
  data?: any;
  onChange?: any;
  active?: any;
}

const ToggleHeader = (props: IToggleHeader) => {
  // State
  const [state, setState] = useSetState({
    active: '',
  });

  //Hooks
  useEffect(() => {
    setState({ active: props.active });
  }, [props.active]);

  return (
    <div className="admin_toggle_header_container">
      {props.data.map((item, index) => (
        <div>
          <div
            className={`${
              state.active === item && 'admin_toggle_header_active'
            } toggle_header_wrapper
          `}
            onClick={() => {
              props.onChange(item);
              setState({ active: item });
            }}
            key={index}>
            {item}
          </div>
          <div
            className={`${state.active === item && 'line_active'} line
          `}></div>
        </div>
      ))}
    </div>
  );
};

export default ToggleHeader;
