import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSetState } from 'utils/functions.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  Assets,
  Colors,
  DatePickerComponent,
  Input,
  Navbutton,
  PrimaryButton,
} from 'utils/imports.utils';
import './candidate_offered_modal.component.scss';

const CandidateOfferedModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();
  //Redux
  // State
  const [state, setState] = useSetState({
    fixedSalary: '',
    offeredCtc: '',
    tentativeJoiningDate: '',
  });

  // Network req
  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({
        isModalOpen: false,
        fixedSalary: '',
        offeredCtc: '',
        tentativeJoiningDate: '',
      });
    },
  }));
  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'candidate_offered_modal_container',
        }}>
        <div className="candidate_offered_modal_wrapper">
          <div className="candidate_offered_header">
            <div className="candidate_offered_heading">Salary & Ctc</div>
            <Navbutton
              icon={Assets.black_close}
              onClick={() => {
                setState({
                  isModalOpen: false,
                  fixedSalary: '',
                  offeredCtc: '',
                });
              }}
            />
          </div>
          <div className="candidate_offered">
            <div className="candidate_offered_modal_heading">
              Fixed Salary<span className="required_field">*</span>
            </div>
            <div className="candidate_offered_fixed_salary">
              <Input
                value={state.fixedSalary.toString()}
                type="number"
                name="fixed_salary"
                error={props.error}
                onChange={(value) => setState({ fixedSalary: value })}
              />
            </div>
          </div>
          <div className="candidate_offered">
            <div className="candidate_offered_modal_heading">
              Offered Ctc<span className="required_field">*</span>
            </div>
            <div className="candidate_offered_ctc">
              <Input
                value={state.offeredCtc}
                type="number"
                name="offered_ctc"
                error={props.error}
                onChange={(value) => setState({ offeredCtc: value })}
              />
            </div>
          </div>
          <div className="candidate_offered">
            <div className="candidate_offered_modal_heading">
              Tentative Joining Date<span className="required_field">*</span>
            </div>
            <div className="candidate_join_modal_date">
              <DatePickerComponent
                value={state.tentativeJoiningDate}
                onChange={(tentativeJoiningDate: any) => {
                  setState({ tentativeJoiningDate });
                }}
                name={'tentative_joining_date'}
                // maxDate={new Date()}
                error={props.error}
              />
            </div>
          </div>

          <div className="candidate_offered_button_wrapper">
            <PrimaryButton
              text={'Cancel'}
              backgroundColor={Colors.tabInactiveColor}
              color={Colors.primaryDarkColor}
              className={'candidate_offered_button'}
              onClick={() =>
                setState({
                  isModalOpen: false,
                  fixedSalary: '',
                  offeredCtc: '',
                })
              }
            />
            <PrimaryButton
              text={'Save'}
              className={'candidate_offered_button'}
              onClick={() => {
                props.onPress({
                  fixed_salary: parseInt(state.fixedSalary),
                  offered_ctc: parseInt(state.offeredCtc),
                  tentative_joining_date:state.tentativeJoiningDate
                });
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default CandidateOfferedModal;
