import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  Functions,
  Models,
  Assets,
  Input,
  PrimaryButton,
} from 'utils/imports.utils';
import './login.screen.scss';
import {
  toastify,
  toastifyError,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import { Link, useNavigate } from 'react-router-dom';
import { getUserDetails } from 'utils/redux.utils';
import { UserRole } from 'utils/constant.utils';

const Login = (props: any) => {
  // Redux

  // State
  const [state, setState] = useSetState({
    email: '',
    password: '',
    visible: false,
    link:`/signup`
  });

  // navigation
  const route = useNavigate();
  const query = useQuery();
  const redirect = query.get('redirect') || ''
  const is_agency = query.get('is_agency')
  //Hooks
  useEffect(() => {
    if (redirect){
      setState({link:`/signup?redirect=${encodeURIComponent(redirect)}`})
    }
    let token = localStorage.getItem('token');
    let user_id = localStorage.getItem('user_id');
    if(user_id) {
      getUser()
    }
    if (token) {
      window.location.href = '/home';
    }
  }, []);

  const getUser = async () => {
    let userId = localStorage.getItem('user_id');
    try {
      const res: any = await Models.auth.viewUser(userId);
      console.log('res.data', res.data);
      // dispatch
      if(res.data.confirmed) {
        localStorage.setItem('confirmed','true');
      }
      getUserDetails(res.data);
    } catch (error: any) {
      if (error == 'invalid signature'|| error == 'Invalid token') {
        localStorage.clear();
        window.location.href = '/login';
      }
      console.log(error);
    }
  };
  // Network req
  const handleLogin = async () => {
    try {
      if (state.email.length > 0 && state.password.length > 0) {
        const body = {
          email: state.email,
          password: state.password,
        };
        const res: any = await Models.auth.login(body);
        getUserDetails(res.data);
        await localStorage.setItem('token', res.token);
        await localStorage.setItem('user_id', res.data._id);
        await localStorage.setItem(
          'user',
          JSON.stringify({
            user_id: res.data._id,
            full_name: `${res.data.first_name} ${res.data.last_name}`,
            role: res.data.role,
            email: res.data.email,
          }),
        );
        await localStorage.setItem('confirmed', res.data.confirmed.toString());
        await localStorage.setItem('org', JSON.stringify(res.data.org));
        let redirect = query.get('redirect');
      if (redirect) {
        window.location.href = `${redirect}`;
       } else if(res.data.role === UserRole.SUPER_ADMIN){
         window.location.href = '/client_org';
       }
       else if (res?.data?.is_profile_completed) {
        window.location.href = '/home';
        } else if(res.data.confirmed == false) {
        window.location.href = '/confirm_email';
        }else{
        window.location.href = '/create_profile';
      }
      } else if (state.email.length == 0) {
        toastifyError('Enter your email');
      } else {
        toastifyError('Enter your password');
      }
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //Logic

  const handleKeyPress = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      handleLogin();
    }
  };

  return (
    <div className="login_container">
      <div className="login_wrapper">
        <div className="login_container_logo">
          <img
            src={Assets.logo}
            height={100}
            width={100}
            alt="sidebar logo"
            className="sidebar_logo"
          />
        </div>
        {/* <div className="welcome"> Welcome 👋 </div> */}
        <div className="login_text">Login to your Account</div>
        <div className="inputs">
          <div className="input_heading">
            Work Email<span className="required_field">*</span>
          </div>
          <div className="input_box">
            <Input
              type={'text'}
              onChange={(value) => {
                setState({ email: value });
              }}
              value={state.email}
              name={'email'}
            />
          </div>
          <div className="input_heading">
            Password<span className="required_field">*</span>
          </div>
          <div className="input_box">
            <Input
              type={state.visible ? 'text' : 'password'}
              onChange={(value) => {
                setState({ password: value });
              }}
              value={state.password}
              name={'password'}
              icon={state.visible ? Assets.eye_slash : Assets.eye}
              iconOnPress={() => setState({ visible: !state.visible })}
              onKeyDown={(e) => handleKeyPress(e)}
            />
          </div>
          <div className='forgot_password'>

          <Link to="/forget_password" className="forgot_link">
            Forgot Password?
          </Link>
          </div>
          <div className="login_button">
            <PrimaryButton
              text={'Login'}
              className={'login_btn'}
              onClick={handleLogin}
            />
          </div>
          <div className="footer">
            <div>Don't have an account?</div>
            <Link to={state.link} className="sign_up">
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
