import React, { useEffect } from 'react';
import { toastifyError, useQuery } from 'utils/functions.utils';
import { Models } from 'utils/imports.utils';

const ApprovalScreen = () => {
  const requisition_id: any = useQuery().get('id');
  const user_id: any = useQuery().get('user');
  const requisitionApproval = async () => {
    try {
      const query = {
        requisition_id,
        user_id,
      };
      const res: any = await Models.jobRecquisition.requisitionApproval(query);
    } catch (error: any) {
      toastifyError(error);
    }
  };
  useEffect(() => {}, []);
  return (
    <div>
      <div className="approval" onClick={requisitionApproval}>
        yes
      </div>
    </div>
  );
};

export default ApprovalScreen;
