import ListComponent from 'common_components/ui/list/list.component';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  mergeArrayWithoutDuplicates,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  PrimaryButton,
  Input,
  Dropdown,
  Assets,
  Colors,
  Searchbar,
  Details,
  FileUpload,
  DeletePop,
  Validation,
  UploadResumeModal,
} from 'utils/imports.utils';
import { getUserDetails, testDispatch } from 'utils/redux.utils';
import './manage_users.screen.scss';
import { toastify, uploadFile, toastifyError } from 'utils/functions.utils';
import { getUserList } from '../../utils/redux.utils';
import { UserRole, user_role } from 'utils/constant.utils';
import _ from 'lodash';
const csvtojson = require('csvtojson');
const sample = require('../../assets/docs/sample.csv');

const ManageUsers = (props: any) => {
  // Redux
  const userData = useSelector((state: any) => state.user);
  const userState = useSelector((state: any) => state.user.auth);

  

  //Reference
  const inputFileRef: any = useRef();

  // State
  const [state, setState] = useSetState({
    first_name: '',
    last_name: '',
    email: '',
    role: {},
    search: '',
    loading: false,
    modal: false,
    errorArray: [],
    role_data: [
      { value: 'Recruiter', label: 'Recruiter' },
      { value: 'Hiring manager', label: 'Hiring manager' },
      { value: 'Interviewer', label: 'Interviewer' },
    ],
    loadMore: true,
    limit: 200,
    skip: 0,
    subscribed:false
  });

  //Hooks
  useEffect(() => {
    getUserLists();
  }, [state.search]);

  // Network req
  const getUserLists = async () => {
    try {
      setState({ loading: true });
      let body: any = {
        limit: state.limit,
        skip: 0,
      };
      if (state.search.length > 0) {
        body.search = state.search;
      }
      const res: any = await Models.auth.getUserList(body);
      res.data?.docs?.map((item: any) => {
        item.name = `${item.first_name} ${item.last_name}`;
        item.is_joined = item.is_profile_completed ? 'Joined' : 'Invited';
        return item;
      });
      //dispatch
      getUserList(res?.data?.docs);
      if (res.data.totalDocs == res.data.docs.length) {
        setState({ loadMore: false });
      } else {
        setState({ loadMore: true });
      }
      setState({ loading: false, skip: 0 });
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const loadMoreUsers = async (skip) => {
    try {
      let body: any = {
        limit: state.limit,
        skip,
      };
      const res: any = await Models.auth.getUserList(body);
      res.data?.docs?.map((item: any) => {
        item.name = `${item.first_name} ${item.last_name}`;
        item.is_joined = item.is_profile_completed ? 'Joined' : 'Invited';
        return item;
      });
      //dispatch
      let data = mergeArrayWithoutDuplicates(
        userData?.userList,
        res?.data.docs,
      );
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      getUserList(data);
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const sendInvite = async () => {
    try {
      let email = state.email.split('@').pop();
      if (state.email === userData?.auth?.email) {
        toastifyError('inviting the same user');
      } else if (email == userData?.auth?.org?.domain) {
        const body = {
          users: [
            {
              first_name: state.first_name,
              last_name: state.last_name,
              email: state.email,
              role: state.role?.value,
            },
          ],
        };
        await Validation.manageUsersSchema.validate(body, {
          abortEarly: false,
        });
        const res: any = await Models.auth.inviteUser(body);
        toastify('Invite sent');
        setState({
          first_name: '',
          last_name: '',
          email: '',
          role_data: [],
          role: [],
        });
      } else {
        toastifyError('Only users from the same organisation can be invited');
      }
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const deleteUser = async (item: any) => {
    try {
      const body = {
        user_id: state.user_data._id,
      };
      const res: any = await Models.auth.deleteUser(body);
      if(userState?.org?.subscribed && userState.is_agency){
        await Models.subscription.updateSubscription({});
      }
      if (res) {
        getUserLists();
      }
      setState({ modal: false });
      toastify('User deleted successfully');
    } catch (error) {
      console.log(error);
    }
  };

  const resendInvite = async (item: any) => {
    try {
      const body = {
        email: item.email,
      };
      const res: any = await Models.auth.resendInviteUser(body);
      toastify('Invite resent');
    } catch (error) {
      toastifyError('Failed to send invite');
    }
  };

  const inviteBulkUsers = async (json: any) => {
    try {
      let bulkInvite: boolean = false;
      json.map((item: any) => {
        if (item.email.split('@').pop() === userData?.auth?.org?.domain) {
          bulkInvite = true;
        } else {
          bulkInvite = false;
        }
      });
      if (bulkInvite) {
        const res: any = await Models.auth.inviteUser({ users: json });
        toastify('Invites sent');
      } else {
        toastifyError('Failed to send invite');
      }
      // console.log('bulk invite', res);
    } catch (error) {
      toastifyError('Failed to send invite, incorrect fields sent');
    }
  };

  const PromisifiedCsvToJson = (csvfile) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsText(csvfile, 'UTF-8');
      reader.onload = () => {
        csvtojson()
          .fromString(reader.result)
          .then(async (jsonArrayObj) => {
            await inviteBulkUsers(jsonArrayObj);
            resolve(jsonArrayObj);
          });
      };
    });
  };

  const handleFileUpload = async (file: any) => {
    let filename = file.name.split('.').pop();
    if (filename === 'csv') {
      await PromisifiedCsvToJson(file);
    } else {
      toastifyError('Upload only csv files');
    }
  };

  //Logic
  const loadMore = () => {
    setState({ skip: state.skip + 20 });
    loadMoreUsers(state.skip + 20);
  };

  // sample data
  let actionArray = [
    {
      name: (item) => {
        return item.is_joined === UserRole.IS_JOINED ? 'Delete user' : 'Resend Invite';
      },
      icon: (item) => {
        return item.is_joined === UserRole.IS_JOINED ? Assets.trash : Assets.send;
      },
      // style: { background: Colors.primaryBtnColor, width: '200px' },
      style: {
        background: (item) => {
          return item.is_joined !== UserRole.IS_JOINED 
            ? Colors.primaryBtnColor
            : Colors.inputBackground;
        },
        color: (item) => {
          return item.is_joined !== UserRole.IS_JOINED 
            ? Colors.buttonTextColor
            : Colors.primaryBtnColor;
        },
        width:'100%'
      },
      onPress: (item) => {
        if (item.is_joined === UserRole.IS_JOINED) {
          return setState({ modal: true, user_data: item });
        } else {
          return resendInvite(item);
        }
      },
    },
  ];
  const DropdownIndicator = () => {
    return (
      <img
        src={Assets.arrow_down_white}
        style={{ marginRight: '10px' }}
        width={'13px'}
        height={'13px'}
      />
    );
  };

  const changeRole = async (role: string, id: string) => {
    try {
      let body: any = { role };
      const res: any = await Models.auth.editUserByAdmin(body, id);
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };


  const getUser = async () => {
    let userId = localStorage.getItem('user_id');
    try {
      const res: any = await Models.auth.viewUser(userId);
      // dispatch
      getUserDetails(res.data);
      if(res.data?.org?.subscribed && res.data?.is_agency) {
        setState({subscribed:res.data.org.subscribed})
      }
    } catch (error: any) {
      if (error == 'invalid signature'|| error == 'Invalid token') {
        localStorage.clear();
        window.location.href = '/login';
      }
      console.log(error);
    }
  };

  useEffect(() => {
    getUser()
  }, []);
  const titleData = [
    {
      label: 'Name',
      value: 'name',
      width: '15%',
      onPress: (item: any) => {},
      className: 'user_table_title',
    },
    { label: 'Email', value: 'email', width: '15%' },
    {
      label: 'Status',
      value: 'is_joined',
      width: '12%',
      style: {
        background: (value) =>
          value === UserRole.IS_JOINED ? Colors.checkboxActive : '#FF754C',
        color: Colors.buttonTextColor,
        className: 'user_table_status',
        // width: '60%',
      },
    },
    {
      label: 'Role',
      value: 'role',
      width: '12%',
      component: (item) => (
        <div style={{ width: '90%' }}>
          <Dropdown
            data={user_role}
            // className="requisition_dropdown"
            setActive={(role: any) => {
              changeRole(role.value, item._id)
            }}
            value={{ label:item?.role, value:item?.role }}
            {...props}
          />
        </div>
      ),
    },
    { label: 'Last active', value: 'modified_at', width: '15%', type: 'date' },
    { label: 'Joined', value: 'created_at', width: '15%', type: 'date' },
  ];

  return (
    <div className="manage_users_container">
      <div className="header">
        <div className="manage_users_heading">Manage Users</div>
        <div className="download_and_upload">
          <div className="download_button">
            <a href={sample} download className="download">
              <div className="download_icon">
                <img
                  src={Assets.download}
                  className="user_table_download_icon"
                  alt="download"
                />
              </div>
              <div className="download_text">Download Sample CSV Format</div>
            </a>
          </div>
          <div className="upload_button">
            <FileUpload
              ref={inputFileRef}
              type="csv"
              onChange={async (file: any) => {
                handleFileUpload(file);
              }}>
              <PrimaryButton
                text={'Upload Bulk Users'}
                className={'upload'}
                icon={Assets.upload}
                onClick={() => inputFileRef?.current?.openUpload()}
              />
            </FileUpload>
          </div>
        </div>
      </div>
      <div className="inputs">
        <div className="firstname_wrapper">
          <div className="input_heading">
            First Name<span className="required_field">*</span>
          </div>
          <Input
            onChange={(value) => {
              setState({ first_name: value });
            }}
            type={'text'}
            value={state.first_name}
            name={'users[0].first_name'}
            error={state.errorArray}
          />
        </div>
        <div className="lastname_wrapper">
          <div className="input_heading">
            Last Name<span className="required_field">*</span>
          </div>
          <Input
            onChange={(value) => {
              setState({ last_name: value });
            }}
            type={'text'}
            value={state.last_name}
            name={'users[0].last_name'}
            error={state.errorArray}
          />
        </div>
        <div className="email_wrapper">
          <div className="input_heading">
            Email<span className="required_field">*</span>
          </div>
          <Input
            onChange={(value) => {
              setState({ email: value });
            }}
            type={'text'}
            value={state.email}
            name={'users[0].email'}
            error={state.errorArray}
          />
        </div>
        <div className="role_wrapper">
          <div className="input_heading">Role</div>
          <Dropdown
            name={'users[0].role'}
            error={state.errorArray}
            data={user_role}
            // isMulti
            setActive={(role: any) => setState({ role: role })}
            value={state.role}
            {...props}
          />
        </div>
        <div className="send_invite_wrapper">
          <PrimaryButton
            onClick={sendInvite}
            text={'Send Invite'}
            className={'send_invite_button'}
            icon={Assets.send}
          />
        </div>
      </div>
      <div className="search_wrapper">
        <Searchbar
          onChange={(search: string) => setState({ search })}
          value={state.search}
          name={'search'}
          className="search"
          placeholder="Search users"
        />
      </div>
      <div className="manage_user_screen_table_wrapper">
        <ListComponent
          titleArray={titleData}
          data={userData?.userList.length > 0 ? userData?.userList : []}
          type="user_table"
          action_btn="Delete location"
          action_btn_icon={Assets.trash}
          actionArray={actionArray}
          hasMore={state.loadMore}
          loadMore={loadMore}
          loading={state.loading}
        />
      </div>
      <DeletePop
        text={'Are you sure you want to delete this user?'}
        onClick={deleteUser}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  );
};

export default ManageUsers;
