import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './email_list.scss';
interface IEmailList {
  data?: any;
}

const EmailList = (props: IEmailList) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className="email_list_container">
      <div className="email_profile_container">
        <img src={props.data.profile} width={"88px"} alt={"profile picture"}/>
      </div>
      <div className="email_list_wrapper">
        <div className="email_list_header">
          <div className="email_user_name">{props.data.name}</div>
          <div className="email_time_container">{props.data.time}</div>
        </div>
        <div className="email_content_container">{props.data.content}</div>
      </div>
    </div>
   
  );
};

export default EmailList;