import React from 'react';
import funnelImage from '../../../assets/images/funnel.png';
import './recruitment_funnel.scss';

const RecruitmentFunnel = ({
  applicationNumber,
  longlistedNumber,
  shortlistedNumber,
  interviewedNumber,
  offerNumber,
  joined,
  screening,
  classname,
  textbox,
}: any) => {
  {
    return (
      <div className="recruitment_funnel_container">
        <h6 className="title" style={{paddingBottom:'10px'}}>Candidate Funnel</h6>
        <div className="recruitment_funnel_block">
          <div className="recruitment_funnel_image_block">
            <img
              src={funnelImage}
              className={`${classname} recruitment_funnel_image`}
              alt=""
            />
          </div>
          <div className="recruitment_text_block">
            <div className={`${textbox} recruitment_text_box`}>
              <div className="title">Applications Received</div>
              <div className="number">{applicationNumber}</div>
            </div>
            <div className={`${textbox} recruitment_text_box`}>
              <div className="title">screening</div>
              <div className="number">{screening}</div>
            </div>
            <div className={`${textbox} recruitment_text_box`}>
              <div className="title">Interviewing</div>
              <div className="number">{interviewedNumber}</div>
            </div>
            <div className={`${textbox} recruitment_text_box`}>
              <div className="title">Offered</div>
              <div className="number">{offerNumber}</div>
            </div>
            <div className={`${textbox} recruitment_text_box`}>
              <div className="title">Joined</div>
              <div className="number">{joined}</div>
            </div>
            {/* <div className="recruitment_text_box">
              <div className="title">Offer Accepted</div>
              <div className="number">{offerAccepted}</div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
};

export default RecruitmentFunnel;
