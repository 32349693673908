import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  Assets,
  PrimaryButton,
  Validation,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './change_password.scss';
import { toastify, toastifyError } from 'utils/functions.utils';

interface IChangePassword {
  text?: String;
}

const ChangePassword = (props: IChangePassword) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    visibleCurrentPassword: false,
    visibleNewPassword: false,
    visibleConfirmPassword: false,
  });

  //Hooks
  useEffect(() => {
    errorArray: [];
  }, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      Functions.Failure(error);
    }
  };

  const handleClick = async () => {
    try {
      const body = {
        confirm_password: state.confirmPassword,
        password: state.newPassword,
        old_password: state.currentPassword,
      };
      await Validation.changePasswordSchema.validate(body, {
        abortEarly: false,
      });
      delete body.confirm_password;

      const res: any = await Models.auth.changePassword(body);
      toastify('Password changed successfully!');
      setState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  //Logic

  return (
    <div className="change_password_container">
      <div className="change_password_wrapper">
        <div className="heading">Change Password</div>
        <div className="input_heading">
          Current Password<span className="required_field">*</span>
        </div>
        <div className="change_password_input_container">
          <Input
            type={state.visibleCurrentPassword ? 'text' : 'password'}
            onChange={(value) => {
              setState({ currentPassword: value });
            }}
            value={state.currentPassword}
            name={'old_password'}
            icon={state.visibleCurrentPassword ? Assets.eye_slash : Assets.eye}
            iconOnPress={() =>
              setState({
                visibleCurrentPassword: !state.visibleCurrentPassword,
              })
            }
            error={state.errorArray}
          />
        </div>
        <div className="input_heading">
          New Password<span className="required_field">*</span>
        </div>
        <div className="change_password_input_container">
          <Input
            type={state.visibleNewPassword ? 'text' : 'password'}
            onChange={(value) => {
              setState({ newPassword: value });
            }}
            value={state.newPassword}
            name={'password'}
            icon={state.visibleNewPassword ? Assets.eye_slash : Assets.eye}
            iconOnPress={() =>
              setState({ visibleNewPassword: !state.visibleNewPassword })
            }
            error={state.errorArray}
          />
        </div>
        <div className="input_heading">
          Confirm New Password<span className="required_field">*</span>
        </div>
        <div className="change_password_input_container">
          <Input
            type={state.visibleConfirmPassword ? 'text' : 'password'}
            onChange={(value) => {
              setState({ confirmPassword: value });
            }}
            value={state.confirmPassword}
            name={'confirm_password'}
            icon={state.visibleConfirmPassword ? Assets.eye_slash : Assets.eye}
            iconOnPress={() =>
              setState({
                visibleConfirmPassword: !state.visibleConfirmPassword,
              })
            }
            error={state.errorArray}
          />
        </div>
        <div className="button">
          <PrimaryButton
            onClick={handleClick}
            text={'Update Password'}
            className={'button'}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
