import React, { useEffect, useRef } from 'react';
import './approval_list.component.scss';
import Assets from 'imports/assets.import';
import PrimaryButton from '../button/primary_Button.ui';
import { useSetState } from 'utils/functions.utils';
import { Loader, Navbutton } from 'utils/imports.utils';
import _ from 'lodash';
import csvDownload from 'json-to-csv-export';

interface IApprovalComponentProps {
  data?: any;
  loading?: boolean;
  titleArray?: any;
  action_btn?: string;
  action_btn_icon?: string;
  onPress?: any;
  type?: string;
  actionArray?: any;
  loadMore?: any;
  hasMore?: boolean;
  download?: boolean;
  show?: string;
  editApproval?: any;
}

export default function ApprovalComponent(props: IApprovalComponentProps) {
  const [state, setState] = useSetState({
    isModalOpen: false,
  });
  const modalRef: any = useRef();
  useEffect(() => {}, []);

  if (_.isEmpty(props.data)) {
    return <div className="empty_text">No data found</div>;
  }

  return (
    <div className="list_component_container">
      <div className="list_component_row">
        {props.titleArray.map((item: any, index: number) => (
          <div
            style={{ width: item.width, ...item?.style }}
            className={`${
              index === 0 && 'list_component_block'
            }  list_component_name_wrapper`}>
            <div className="list_component_name list_component_heading">
              {item.label}
            </div>
          </div>
        ))}
        <div className="list_component_action_wrapper">
          {props.download && (
            <div className="csv_download">
              <Navbutton
                icon={Assets.csv}
                onClick={() => csvDownload(props.data)}
                className="csv_download_icon"
              />
            </div>
          )}
        </div>
      </div>
      {props.loading ? (
        <Loader />
      ) : // )
      // : !props.loading && props?.data?.length == 0 ? (
      //   <div className="empty_text">No data found</div>
      props.type === 'parallelApproval' ? (
        props?.data?.map((item: any, index: number) => (
          <div className="list_component_row">
            {props.titleArray.map((titleObj: any, titleIndex: number) => {
              return (
                <div
                  className={`${
                    titleIndex === 0 && 'list_component_block'
                  }  list_component_name_wrapper`}
                  style={{ width: props?.titleArray[titleIndex]?.width }}>
                  <div
                    className={`${
                      props.show === 'sequentialApproval' && titleIndex === 1
                        ? ''
                        : titleIndex !== 0
                        ? 'list_component_subtitle'
                        : ''
                    }  list_component_name`}>
                    {titleObj.value === 'user'
                      ? item[props?.titleArray[titleIndex]?.value].label
                      : item[props?.titleArray[titleIndex]?.value]}
                  </div>
                </div>
              );
            })}
            {props.show === 'parallelApproval' &&
            props.editApproval.length - 1 < index ? (
              <div
                className="list_component_actions_wrapper"
                style={{ width: props.actionArray?.style?.width }}>
                {props.actionArray.actions.map((action: any) =>
                  action.component(item),
                )}
              </div>
            ) : props.show !== 'sequentialApproval' &&
              _.isEmpty(props.editApproval) ? (
              <div
                className="list_component_actions_wrapper"
                style={{ width: props.actionArray?.style?.width }}>
                {props.actionArray.actions.map((action: any) =>
                  action.component(item),
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        ))
      ) : props.type === 'approvalView' ? (
        props?.data?.map((item: any) => (
          <div className="list_component_row">
            {props.titleArray.map((titleObj: any, titleIndex: number) => {
              return (
                <div
                  className={`${
                    titleIndex === 0 && 'list_component_block'
                  }  list_component_name_wrapper`}
                  style={{ width: props?.titleArray[titleIndex]?.width }}>
                  {titleObj.value === 'status' ? (
                    <div className={`approval_status  list_component_name`}>
                      {item.approval === true
                        ? 'Accepted'
                        : item.approval === false
                        ? 'Cancelled'
                        : _.isEmpty(item.approval)
                        ? 'Pending'
                        : ''}
                    </div>
                  ) : (
                    <div
                      className={`${
                        titleIndex !== 0 && 'list_component_subtitle'
                      }  list_component_name`}>
                      {titleObj.label === 'User'
                        ? item.user[props?.titleArray[titleIndex]?.value]
                        : item[props?.titleArray[titleIndex]?.value]}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ))
      ) : (
        props?.data?.map((item: any) => (
          <div className="list_component_row">
            {props.titleArray.map((titleObj: any, titleIndex: number) => (
              <div
                className={`${
                  titleIndex === 0 && 'list_component_block'
                }  list_component_name_wrapper`}
                style={{ width: props?.titleArray[titleIndex]?.width }}>
                <div
                  className={`${
                    titleIndex !== 0 && 'list_component_subtitle'
                  }  list_component_name`}>
                  {item[props?.titleArray[titleIndex]?.value]}
                </div>
              </div>
            ))}
            <div
              className="list_component_actions_wrapper"
              style={{ width: props.actionArray?.style?.width }}>
              {props.actionArray.actions.map((action: any) =>
                action.component(item),
              )}
            </div>
          </div>
        ))
      )}
      {props.hasMore && !props.loading && (
        <div className="list_component_load_button_wrapper">
          <PrimaryButton
            text={'Load more'}
            onClick={() => {
              props.loadMore();
            }}
            className="list_component_load_button"
          />
        </div>
      )}
      {/* <Details ref={modalRef} /> */}
    </div>
  );
}
