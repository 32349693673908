import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './certification.ui.scss';

interface ICertification {
  data?: any;
  title?:string;
  type?:string;
  onClick?:any;
  


}

const Certification = (props: ICertification) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      console.log(error);
    }
  };
 console.log('props',props.title)
  //Logic
  const testLogic = () => {};

  return (
    <div className="certification_container">
      <div className="certification_wrapper">
        <div className="certification_header">{props.title}</div>
        <div className="certification_type">{props.type}</div>
        <div className="certification_year">{props?.type}</div>
      </div>
      <div className="certification_close_icon">
        <img onClick={props.onClick} src={Assets.black_close} alt="close-icon" />
      </div>
    </div>
  );
};

export default Certification;
