import React, { useState } from 'react';

import './single_slider.ui.scss';

import ReactSlider from 'react-slider';
import { useSetState } from 'utils/functions.utils';


interface ISingleSlider{
  min?: number;
  max?: number;
  defaultValue?: number;
  onChange?: any;
  error?: any;
  name?: string;
}

const SingleSlider = (props:ISingleSlider) => {
  const [state,setState] = useSetState({
    sliderValue:props.defaultValue || 1
  });

  return (
    <div className='single_slider_container'>
      <ReactSlider
        className="single_slider_wrapper"
        marks
        min={props.min}
        max={props.max}
        defaultValue={state.sliderValue}
        onChange={(value:number) => {setState({sliderValue:value});
        props.onChange(value)
      }}
        renderThumb={(props:any, state:any) => (
          <div {...props} className="thumb_container">
            <div className="thumb_name">
              {state.valueNow} {state.valueNow > 1}
            </div>
            <div className="thumb_icon" />
          </div>
        )}
        renderTrack={(props:any, state:any) => (
          <div
            {...props}
            className={`${state.index == 1 && 'track_primary'} ${
              state.index == 0 && 'track_secondary'
            }`}
          />
        )}
      />
       {props.error &&
        props.error.map(
          (error: any) =>
            props.name === error?.path && (
              <div className="input_field_error">{error.message}</div>
            ),
        )}
    </div>
  );
};

export default SingleSlider;
