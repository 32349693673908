import * as yup from 'yup';

export const requisitionSchema = yup.object().shape({
  job_title: yup.string().required('Please enter job title'),
  job_type: yup.string().required('Please enter job type'),
  job_role_type: yup.string().required('Please enter job role type'),
  // salary_currency: yup.string().required('Select salary currency'),
  salary_min: yup
    .number()
    .nullable()
    .optional()
    .typeError('Enter valid number'),
  salary_max: yup
    .number()
    .nullable()
    .optional()
    .moreThan(
      yup.ref('salary_min'),
      'Max Salary must be greater than minimum salary',
    )
    .typeError('Enter valid number'),
  // job_location: yup.string().required('Please enter job location'),
  work_location: yup.string().required('Select work location address'),
  number_of_vacancies: yup
    .number()
    .nullable()
    .required('Please enter number of vacancies'),
  headcount_type: yup.string().required('Select headcount type'),
  // bonus_payout_type: yup.string().required('Select bonus payout type'),
  // bonus_payout_value: yup
  //   .number()
  //   .nullable()
  //   .required('Select bonus payout value'),
  // remote_working: yup.string().required('Please enter remote working'),
  // target_close_date: yup.string().nullable().required('Select closing date'),
  min_experience: yup.string().required('Please enter min.Years of experience'),
  max_experience: yup.string().required('Please enter max.Years of experience'),
  approval_required: yup.boolean().optional(),
  approval_type: yup.string().optional(),
  approval_user: yup.array().of(
    yup.object().shape({
      user: yup.object().shape({
        label: yup.string().optional(),
        value: yup.string().optional(),
      }),
      approval: yup.boolean().optional(),
      priority: yup.number().optional(),
    }),
  ),
  subscription: yup.object().shape({
    plan_name: yup.string().optional(),
    subscribe: yup.boolean().optional(),
  }),
  // job_req_desc: yup.string().optional(),
  // job_description: yup.string().required('Please enter job description'),
  // skills: yup.array().min(1, 'Select skills').required('Select skills'),
  // behaviours: yup
  //   .array()
  //   .min(1, 'Select behaviors')
  //   .required('Select behaviors'),
  // hiring_team: yup
  //   .array()
  //   .min(1, 'Select hiring team')
  //   .required('Select hiring team'),
  // department: yup.string().required('Select department'),
  // business_unit: yup.string().required('Select business unit'),
  // recruiter: yup
  //   .array()
  //   .min(1, 'Select recruiter')
  //   .required('Select recruiter'),
  // interviewer: yup
  //   .array()
  //   .min(1, 'Select interviewer')
  //   .required('Select interviewer'),
});
export const agencyRequisitionSchema = yup.object().shape({
  job_title: yup.string().required('Please enter job title'),
  job_type: yup.string().required('Please enter job type'),
  job_role_type: yup.string().required('Please enter job role type'),
  // salary_currency: yup.string().required('Select salary currency'),
  salary_min: yup
    .number()
    .nullable()
    .optional()
    .typeError('Enter valid number'),
  salary_max: yup
    .number()
    .nullable()
    .optional()
    .moreThan(
      yup.ref('salary_min'),
      'Max Salary must be greater than minimum salary',
    )
    .typeError('Enter valid number'),
  // job_location: yup.string().required('Please enter job location'),
  work_location: yup.string().required('Select work location address'),
  number_of_vacancies: yup
    .number()
    .nullable()
    .required('Please enter number of vacancies'),
  headcount_type: yup.string().required('Select headcount type'),
  // bonus_payout_type: yup.string().required('Select bonus payout type'),
  // bonus_payout_value: yup
  //   .number()
  //   .nullable()
  //   .required('Select bonus payout value'),
  // remote_working: yup.string().required('Please enter remote working'),
  // target_close_date: yup.string().nullable().required('Select closing date'),
  min_experience: yup.string().required('Please enter min.Years of experience'),
  max_experience: yup.string().required('Please enter max.Years of experience'),
  // job_req_desc: yup.string().optional(),
  // job_description: yup.string().required('Please enter job description'),
  // skills: yup.array().min(1, 'Select skills').required('Select skills'),
  // behaviours: yup
  //   .array()
  //   .min(1, 'Select behaviors')
  //   .required('Select behaviors'),
  // hiring_team: yup
  //   .array()
  //   .min(1, 'Select hiring team')
  //   .required('Select hiring team'),
  // department: yup.string().required('Select department'),
  // business_unit: yup.string().required('Select business unit'),
  // recruiter: yup
  //   .array()
  //   .min(1, 'Select recruiter')
  //   .required('Select recruiter'),
  // interviewer: yup
  //   .array()
  //   .min(1, 'Select interviewer')
  //   .required('Select interviewer'),
  client: yup.string().optional(),
  payment_terms: yup.string().optional(),
  account_manager: yup.string().optional(),
  poc: yup.string().optional(),
  approval_required: yup.boolean().optional(),
  approval_type: yup.string().optional(),
  approval_user: yup.array().of(
    yup.object().shape({
      user: yup.object().shape({
        label: yup.string().optional(),
        value: yup.string().optional(),
      }),
      approval: yup.boolean().optional(),
      priority: yup.number().optional(),
    }),
  ),
  subscription: yup.object().shape({
    plan_name: yup.string().optional(),
    subscribe: yup.boolean().optional(),
  }),
});

export const postJobSchema = yup.object().shape({
  // job_ad_template: yup.string().required('Select Job Ad template'),
  description: yup.string().required('Please enter description'),
  channels:yup.array().optional(),
  post_type: yup.string().required('Select post type'),
  job_open_date: yup.string().required('Select open date'),
  job_close_date: yup.string().required('Select close date'),
  // header_media: yup.string().required('Please upload image/video'),
});

export const changePasswordSchema = yup.object().shape({
  password: yup.string().required('Please enter  new password'),
  old_password: yup.string().required('Please enter  old password'),
  // confirmpassword: yup.string().required('Please enter confirm password'),
  confirm_password: yup
    .string()
    .required('Please enter confirm password')
    .oneOf(
      [yup.ref('password'), null],
      'confirm password  must match to password',
    ),
});

export const manageUsersSchema = yup.object().shape({
  users: yup.array().of(
    yup.object().shape({
      first_name: yup
        .string()
        .required('Please enter first name')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      last_name: yup
        .string()
        .required('Please enter last name')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      email: yup
        .string()
        .email('Enter valid email id')
        .required('Please enter email'),
      // role: yup.array().min(1, 'Select role').required('Select a role'),
    }),
  ),
});

export const emailTemplateSchema = yup.object().shape({
  name: yup.string().required('Please enter template name'),
  body: yup.string().required('Please enter email body'),
  subject: yup.string().required('Please enter email subjects'),
});

export const workLocationSchema = yup.object().shape({
  nickname: yup.string().required('Please enter location nickname'),
  address: yup.string().required('Please enter address'),
  city: yup.string().required('Please enter city'),
  pincode: yup.string().required('Please enter pincode'),
  state: yup.string().required('Select a state'),
  country: yup.string().required('Select a country'),
});
export const departmentSchema = yup.object().shape({
  name: yup.string().required('Please enter department name'),
  // business_unit: yup.string().required('Select business unit'),
});
export const businessSchema = yup.object().shape({
  name: yup.string().required('Please enter business unit name'),
});
export const agencySchema = yup.object().shape({
  name: yup.string().required('Please enter agency name'),
  poc: yup.string().required('Please enter point of contact'),
  email: yup.string().required('Please enter email address'),
  // phone: yup.string().required('Please enter phone number'),
  // contract: yup.string().required('Please upload a file'),
  // contract_end_date: yup.string().required('Select a end date'),
  // commission_slabs: yup.array().of(
  //   yup.object().shape({
  //     min_salary: yup.string().required('Please enter minimum salary'),
  //     max_salary: yup.string().required('Please enter maximum salary'),
  //     percentage: yup.string().required('Please enter percentage'),
  //   }),
  // ),
});

export const candidateApplyJobSchema = yup.object().shape({
  resume: yup.object().shape({
    email: yup.string().email('invalid email').required('Please enter email'),
    phone: yup.string().nullable().required('Please enter phone number'),
    work_samples: yup.array().optional(),
    certifications: yup.array().optional(),
    resume: yup.string().required('Resume is required'),
    first_name: yup.string().required('Please enter first name'),
    last_name: yup.string().required('Please enter last name'),
    // gender: yup.string().required('Please enter gender'),
    address: yup.string().required('Please enter full address'),
    total_experience: yup
      .number()
      .nullable()
      .required('Select a total years of experience'),
    relevant_experience: yup
      .number()
      .nullable()
      .required('Select a total years of relevant experience')
      .max(
        yup.ref('total_experience'),
        'Relevant experience must be less than total experience',
      ),
    notice_period: yup.string().required('Select a notice period'),
    education: yup.array().optional(),
    reason_for_job_change: yup
      .string()
      .required('Select a reason for job change'),
    // willing_to_relocate: yup
    //   .string()
    //   .required('Select a willingness to relocate'),
    // remote_work_preference: yup
    //   .string()
    //   .required('Select a remote work prefernece'),
    current_salary: yup
      .number()
      .nullable()
      .optional()
      .typeError('Enter valid number'),
    expected_salary: yup
      .number()
      .nullable()
      .optional()
      .typeError('Enter valid number'),
    pincode: yup.string().required('Enter pincode'),
    country: yup.string().required('Select country'),
    state: yup.string().required('Select state'),
    city: yup.string().required('Select city'),
    skills: yup
      .array()
      .nullable()
      .min(1, 'Select skills')
      .required('Select skills'),
    profile_picture: yup.string().optional(),
    job_description_identifier: yup.string().optional(),
    resume_identifier: yup.string().optional(),
    dob: yup.string().optional(),
  }),
});

export const setupInterview = yup.object().shape({
  name: yup.string().required('Select interview round'),
  interview_medium: yup.string().required('Select interview medium'),
  panelist_email: yup.string().required('Enter panelist email'),
  candidate_email: yup.string().required('Enter candidate email'),
});

export const signup = yup.object().shape({
  password: yup.string().min(8, 'Password must be atleast 8 characters'),
});

export const createProfile = yup.object().shape({
  first_name: yup.string().required('Enter First name'),
  last_name: yup.string().required('Enter Last name'),
});

export const clientSchema = yup.object().shape({
  client_name: yup.string().required('Please enter client name'),
  // .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  poc: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Please enter name'),
      // .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      email: yup
        .string()
        .email('Enter valid email id')
        .required('Please enter email'),
    }),
  ),
  // email: yup.string().email('invalid email').required('Please enter email'),
  // phone: yup.string().optional(),
});
export const editClientSchema = yup.object().shape({
  client_name: yup.string().required('Please enter client name')
  .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  poc: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required('Please enter name')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      email: yup
        .string()
        .email('Enter valid email id')
        .required('Please enter email'),
    }),
  ),
  client_id: yup.string().required(),
  // email: yup.string().email('invalid email').required('Please enter email'),
  // phone: yup.string().optional(),
});
export const paymentTermsSchema = yup.object().shape({
  name: yup.string().required('Please enter client name')
  .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  payment_type: yup.string().required('Please enter payment type'),
  commission: yup.number().required('Please enter commission'),
  due_days: yup.number().required('Please enter due days'),
  client: yup.string().required('Please enter client'),
});
export const paymentTermsSlabsSchema = yup.object().shape({
  name: yup.string().required('Please enter client name')
  .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  payment_type: yup.string().required('Please enter payment type'),
  commission_slabs: yup.array().of(
    yup.object().shape({
      min_salary: yup.number().required('Enter Minimum Salary'),
      max_salary: yup.number().required('Enter Maximum Salary'),
      percentage: yup.number().required('Enter Percentage'),
    }),
  ),
  due_days: yup.number().required('Please enter due days'),
  client: yup.string().required('Please enter client'),
});
export const editPaymentSchema = yup.object().shape({
  name: yup.string().required('Please enter client name')
  .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  payment_type: yup.string().required('Please enter payment type'),
  commission: yup.number().required('Please enter commission'),
  due_days: yup.number().required('Please enter due days'),
  payment_terms_id: yup.string().required(),
  client: yup.string().required('Please enter client'),
});
export const editPaymentTermsSlabsSchema = yup.object().shape({
  name: yup.string().required('Please enter client name')
  .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  payment_type: yup.string().required('Please enter payment type'),
  commission_slabs: yup.array().of(
    yup.object().shape({
      min_salary: yup.number().required('Enter Minimum Salary'),
      max_salary: yup.number().required('Enter Maximum Salary'),
      percentage: yup.number().required('Enter Percentage'),
    }),
  ),
  due_days: yup.number().required('Please enter due days'),
  payment_terms_id: yup.string().required(),
  client: yup.string().required('Please enter client'),
});
export const candidateEmail = yup.object().shape({
  email: yup.string().email('Invalid Email').required('Please Enter Email'),
});
export const editCandidateSchema = yup.object().shape({
  offered_ctc: yup.number().transform((value) => Number.isNaN(value) ? null : value )
  .nullable().required('Please Enter Offered Ctc'),
  fixed_salary: yup.number().transform((value) => Number.isNaN(value) ? null : value )
  .nullable().required('Please Enter Fixed Salary'),
  tentative_joining_date:yup.string().required('Please Enter Tentative Joining Date'),
  candidate_id: yup.string().required(),
});

export const createCustomer = yup.object().shape({
  name: yup.string().required('Please Enter Name'),
  email: yup.string().email('Invalid Email').required('Please Enter Email'),
  address: yup.string().optional(),
  phone_number: yup.string().optional(),
});
export const editOrganizationSchema = yup.object().shape({
  org_id: yup.string().required("don't have org id"),
  company_details: yup.object().shape({
    company_name: yup.string().required('Please enter company name'),
    company_description: yup
      .string()
      .required('Please enter company description'),
  })})