import store from 'store/store';
import {
  TEST_ACTION,
  GET_USER,
  APP_ROUTE,
  GET_LOCATIONS,
  GET_EMAIL_TEMPLATES,
  GET_AGENCIES_LIST,
  USER_LIST,
  GET_DEPARTMENTS,
  GET_BUSINESS_UNITS,
  GET_JOB_RECQUISITION_LIST,
  GET_POST_JOB,
  GET_RECQUISITION_BY_ID,
  GET_RESUME_LIST,
  GET_CANDIDATE_LIST,
  GET_SUMMARY,
  GET_CANDIDATE,
  GET_CANDIDATE_HISTORY,
  GET_INTERVIEW,
  GET_MANY_INTERVIEW,
  GET_MANY_FEEDBACK,
  GET_FEEDBACK_DUES,
  GET_MANY_NOTES,
  GET_CANDIDATE_DASHBOARD,
} from './types.utils';

export const testDispatch = (payload: object) =>
  store.dispatch({
    type: TEST_ACTION,
    payload: payload,
  });

export const getUserDetails = (payload: object) => {
  store.dispatch({
    type: GET_USER,
    payload: payload,
  });
};

export const setAppRoute = (payload: object) => {
  store.dispatch({
    type: APP_ROUTE,
    payload: payload,
  });
};

export const getAllEmailTemplates = (payload: object) => {
  store.dispatch({
    type: GET_EMAIL_TEMPLATES,
    payload: payload,
  });
};

export const getAllWorkLocations = (payload: object) => {
  store.dispatch({
    type: GET_LOCATIONS,
    payload: payload,
  });
};

export const getAllAgencies = (payload: object) => {
  store.dispatch({
    type: GET_AGENCIES_LIST,
    payload: payload,
  });
};

export const getUserList = (payload: object) => {
  store.dispatch({
    type: USER_LIST,
    payload: payload,
  });
};

export const getDepartmentList = (payload: object) => {
  store.dispatch({
    type: GET_DEPARTMENTS,
    payload: payload,
  });
};

export const getBusinessUnitList = (payload: object) => {
  store.dispatch({
    type: GET_BUSINESS_UNITS,
    payload: payload,
  });
};

export const getRecquisitionList = (payload: object) => {
  store.dispatch({
    type: GET_JOB_RECQUISITION_LIST,
    payload: payload,
  });
};
export const getRecquisitionById = (payload: object) => {
  store.dispatch({
    type: GET_RECQUISITION_BY_ID,
    payload: payload,
  });
};
export const getPostJobList = (payload: object) => {
  store.dispatch({
    type: GET_POST_JOB,
    payload: payload,
  });
};

export const getResumeList = (payload: object) => {
  store.dispatch({
    type: GET_RESUME_LIST,
    payload: payload,
  });
};

export const getAllCandidates = (payload: object) => {
  store.dispatch({
    type: GET_CANDIDATE_LIST,
    payload: payload,
  });
};

export const getSummary = (payload: object) => {
  store.dispatch({
    type: GET_SUMMARY,
    payload: payload,
  });
};

export const getCandidate = (payload: object) => {
  store.dispatch({
    type: GET_CANDIDATE,
    payload: payload,
  });
};

export const getCandidateHistory = (payload: object) => {
  store.dispatch({
    type: GET_CANDIDATE_HISTORY,
    payload: payload,
  });
};

export const getInterviewById = (payload: object) => {
  store.dispatch({
    type: GET_INTERVIEW,
    payload,
  });
};

export const getManyInterviews = (payload: object) => {
  store.dispatch({
    type: GET_MANY_INTERVIEW,
    payload,
  });
};

export const getManyFeedback = (payload: object) => {
  store.dispatch({
    type: GET_MANY_FEEDBACK,
    payload,
  });
};

export const getFeedbackDues = (payload: object) => {
  store.dispatch({
    type: GET_FEEDBACK_DUES,
    payload,
  });
};

export const getManyNotes = (payload: object) => {
  store.dispatch({
    type: GET_MANY_NOTES,
    payload,
  });
};

export const getDashboardData = (payload: object) => {
  store.dispatch({
    type: GET_CANDIDATE_DASHBOARD,
    payload,
  });
};