import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './Attachment.scss';

interface IAttchment {
  text?: String;
  data: any;
  onClick?: any;
  //   fileName?: String;
  //   fileFormat?: String;
  //   size?: String;
}

const Attchment = (props: IAttchment) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className="attachment_file_block">
      <div className="attachment_file_div">
        <div className="attachment_file_icon_wrapper">
          <img
            src={Assets.attachment}
            alt=""
            className="attachment_file_icon"
          />
        </div>
        <div className="file_block">
          <h6 className="title">{props.data.fileName}</h6>
          <p className="para">
            {props.data.fileFormat &&
              props.data.fileFormat + ' . ' + props.data.size}
          </p>
        </div>
        <div className="attachment_file_icon_wrapper" onClick={props.onClick}>
          <img
            src={Assets.black_close}
            alt=""
            className="attachment_file_close_icon"
          />
        </div>
      </div>
    </div>
  );
};

export default Attchment;
