import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  subtractTwoDates,
  toastifyError,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  PrimaryButton,
  Assets,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './reset_password.screen.scss';

const ResetPassword = (props: any) => {
  // Redux
  const appState = useSelector((state: any) => state.app_state);

  // State
  const [state, setState] = useSetState({
    confirm_password_visible: false,
    password_visible: false,
  });

  //Hooks
  const navigate = useNavigate();
  const query = useQuery();
  let reset_password_hash: any = query?.get('reset_password_hash');
  useEffect(() => {
    if (reset_password_hash) {
      getUser();
    }
  }, []);

  // Network req
  const ResetPassword = async () => {
    try {
      const body = {
        reset_password_hash:
          reset_password_hash?.length > 0
            ? reset_password_hash
            : appState?.reset_password_hash,
        password: state.password,
      };
      // send reset pass hash
      const res: any = await Models.auth.resetPassword(body);
      window.location.href = '/login';
      //dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getUser = async () => {
    try {
      const res: any = await Models.auth.getInviteExpiry({
        reset_password_hash,
      });
      if (!res.data) {
        toastifyError('Invite link expired');
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      }
      let difference = subtractTwoDates(res.data.invited_at, new Date());
      if (res.data.confirmed) {
        toastifyError('Invite link expired');
      }
      if (difference > 14) {
        toastifyError('Invite link expired');
        window.location.href = '/login';
      }
      // dispatch
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic

  return (
    <div className="reset_container">
      <div className="reset_wrapper">
        <div className="reset_container_logo">
          <img
            src={Assets.logo}
            height={100}
            width={100}
            alt="sidebar logo"
            className="sidebar_logo"
          />
        </div>
        <div className="reset_heading">
          {reset_password_hash ? 'Set Password' : 'Reset Your Password'}
        </div>
        <div className="inputs">
          <div className="input_heading">Enter Your New Password</div>
          <div className="input_box">
            <Input
              type={state.password_visible ? 'text' : 'password'}
              onChange={(password) => {
                setState({ password });
              }}
              value={state.password}
              name={'password'}
              icon={state.password_visible ? Assets.eye_slash : Assets.eye}
              iconOnPress={() =>
                setState({
                  password_visible: !state.password_visible,
                })
              }
            />
          </div>
          <div className="input_heading">Confirm New Password</div>
          <div className="input_box">
            <Input
              type={state.confirm_password_visible ? 'text' : 'password'}
              onChange={(confirm_password) => {
                setState({ confirm_password });
              }}
              value={state.confirm_password}
              name={'confirm_password'}
              icon={
                state.confirm_password_visible ? Assets.eye_slash : Assets.eye
              }
              iconOnPress={() =>
                setState({
                  confirm_password_visible: !state.confirm_password_visible,
                })
              }
            />
          </div>
        </div>
        <div className="reset_button">
          <PrimaryButton
            text={'Reset'}
            onClick={ResetPassword}
            className={'button'}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
