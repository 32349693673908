import instance from 'utils/axios.utils';
import { uploadFile } from 'utils/functions.utils';

const auth = {
  login: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/user_login';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  signup: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/user_signup';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getUser: (id) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'user/view/' + id;
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log('model error', error);
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  resetPassword: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/reset_password';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  sendOtp: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/send_otp';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  verifyotp: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/verify_otp';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  uploadFile: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/get_signed_url';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editUser: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/edit_user';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  changePassword: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/change_password';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  viewUser: (userId) => {
    let promise = new Promise((resolve, reject) => {
      let url = `auth/view_user/${userId}`;
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  inviteUser: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = `auth/invite_users`;
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  resendInviteUser: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = `auth/resend_invite_users`;
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getUserList: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = `auth/user_list`;
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  emailConfirmation: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/confirm_email';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  resendConfirmation: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/resend_confirmation_email';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteUser: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/delete_user';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  userExists: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/is_user_exist';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  googleAuth: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/auth_google';
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  fetchEvents: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/calender_events';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  removeIntegration: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/remove_integration';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  microsoftAuth: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/auth_microsoft';
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getInviteExpiry: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/invite_expiry';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  fetchOutlookEvents: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/outlook_events';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editUserByAdmin: (data, id: string) => {
    let promise = new Promise((resolve, reject) => {
      let url = `auth/edit_user_by_admin/${id}`;
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getOrganization: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/get_org';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  inviteOrganization: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'org/invite_org';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  sendSupportEmail: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/send_support_email';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default auth;
