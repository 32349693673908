import {
  GET_JOB_RECQUISITION_LIST,
  GET_RECQUISITION_BY_ID,
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  recquisition_list: [],
  recquisition_by_id: {},
};

const recquisition = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_JOB_RECQUISITION_LIST:
      return {
        ...state,
        recquisition_list: action.payload,
      };
    case GET_RECQUISITION_BY_ID:
      return {
        ...state,
        recquisition_by_id: action.payload,
      };
    default:
      return state;
  }
};

export default recquisition;
