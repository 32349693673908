import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import { getDropdownData, useQuery, useSetState } from 'utils/functions.utils';
import {
  Assets,
  Categories,
  CheckBox,
  Dropdown,
  Functions,
  Models,
  PrimaryButton,
} from 'utils/imports.utils';
import Board from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';
import './kanban_board.scss';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { JOB_SCORE_STATUS, job_types } from 'utils/constant.utils';
import { isTemplateTail } from 'typescript';
import OutsideClickHandler from 'react-outside-click-handler';

const KanbanBoard = (props: any) => {
  // Redux
  // State
  const [state, setState] = useSetState({
    checked: false,
    data: [],
    selectedCandidates: [],
    id: '',
    showFilter: false,
    candidates: []
  });
  //Hooks
  const navigate = useNavigate();
  useEffect(() => {}, []);

  // Network req

  //Logic
  const query = useQuery();
  let req_id: any = query.get('id');
  const handleSelect = (item) => {
    let data: any = state.data;
    let selectedCandidates: any = state.selectedCandidates;
    let candidates: any = state.candidates;
    const index = _.findIndex(data, (id) => {
      return id == item._id;
    });
    if (index !== -1) {
      data.splice(index, 1);
      selectedCandidates.splice(index, 1);
      candidates.splice(index, 1);
      setState({ data, selectedCandidates });
      props.getSelectedCandidates(selectedCandidates);
      props.candidatesIds(candidates);
    } else {
      data.push(item._id);
      selectedCandidates.push(item.resume.resume);
      candidates.push(item._id);
      setState({ data, selectedCandidates });
      props.getSelectedCandidates(selectedCandidates);
      props.candidatesIds(candidates);
    }
  };

  const setCheckbox = (item: any) => {
    if (
      _.findIndex(state.data, (id) => {
        return id == item._id;
      }) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setState({
          showFilter: false,
          id: '',
        });
      }}>
      <div>
        <Board
          allowRemoveLane
          allowRenameColumn
          allowRemoveCard
          onLaneRemove={(data) => console.log(data)}
          onCardRemove={(data) => console.log(data)}
          onLaneRename={(data) => console.log(data)}
          // initialBoard={props.data}
          children={props.data}
          disableColumnDrag
          disableCardDrag
          renderColumnHeader={(column, columnBag) => {
            return (
              <div className="kanban_board_column_wrapper">
                <div className="kanban_board_column_header">
                  <div className="kanban_column_heading">{column.title}</div>
                  <div className="kanban_filter_wrapper">
                    <div className="kanban_column_filter_icon">
                      <img
                        src={Assets.filter}
                        height={15}
                        width={15}
                        onClick={() =>
                          setState({
                            id: column.title,
                            showFilter: !state.showFilter,
                          })
                        }
                      />
                      {state.showFilter && state.id === column.title && (
                        <div className="filter_content">
                          <div
                            className="kanban_column_filter"
                            onClick={() => {
                              setState({
                                filter: JOB_SCORE_STATUS.HIGH_MATCH,
                                showFilter: false,
                              });
                              props.filter(column.title, '');
                            }}>
                            All
                          </div>
                          <div
                            className="kanban_column_filter"
                            onClick={() => {
                              setState({
                                filter: JOB_SCORE_STATUS.HIGH_MATCH,
                                showFilter: false,
                              });
                              props.filter(
                                column.title,
                                JOB_SCORE_STATUS.HIGH_MATCH,
                              );
                            }}>
                            {JOB_SCORE_STATUS.HIGH_MATCH}
                          </div>
                          <div
                            className="kanban_column_filter"
                            onClick={() => {
                              setState({
                                filter: JOB_SCORE_STATUS.AVERAGE_MATCH,
                                showFilter: false,
                              });
                              props.filter(
                                column.title,
                                JOB_SCORE_STATUS.AVERAGE_MATCH,
                              );
                            }}>
                            {JOB_SCORE_STATUS.AVERAGE_MATCH}
                          </div>
                          <div
                            className="kanban_column_filter"
                            onClick={() => {
                              setState({
                                filter: JOB_SCORE_STATUS.LOW_MATCH,
                                showFilter: false,
                              });
                              props.filter(
                                column.title,
                                JOB_SCORE_STATUS.LOW_MATCH,
                              );
                            }}>
                            {JOB_SCORE_STATUS.LOW_MATCH}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="kanban_column_data_count">
                      {column.cards.length}
                    </div>
                  </div>
                </div>
                <div className="kanban_board_column_card_wrapper">
                  {column.cards.map((item: any, index: number) => (
                    <>
                    <div
                      style={{ width: '100%' }}
                      className="kanban_content_wrapper">
                      <div
                        className="kanban_board_checkbox_wrapper"
                        onClick={() => handleSelect(item)}>
                        <CheckBox checked={setCheckbox(item)} />
                      </div>
                      <Categories
                        image={
                          item.resume?.profile_picture ||
                          Assets.profile_placeholder
                        }
                        score={item.job_match_score?.score ? item.job_match_score?.score : 0}
                        name={`${item.resume?.first_name} ${item.resume?.last_name}`}
                        role={item.requisition?.job_title}
                        source={item.resume?.source}
                        status={item.stage}
                        onClick={() =>
                          navigate(
                            `/candidate?id=${item._id}&&req_id=${req_id}`,
                          )
                        }
                      />
                     
                    </div>
                    {props.hasMore(column) &&
                        index === column.cards.length - 1 && (
                        <div className="kanban_board_load_more_btn_wrapper" onClick={() => props.loadMore(column)}>

                        <div className="kanban_board_load_more_text"             >
                            Load more
                          </div>
                          <div className="load_more_line"></div>
                        </div>
                       )} 
                    </>
                  ))}
               
                </div>
              </div>
            );
          }}
        />
      </div>
    </OutsideClickHandler>
  );
};

export default KanbanBoard;
