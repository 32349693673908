import ListComponent from 'common_components/ui/list/list.component';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { toastifyError, useSetState } from 'utils/functions.utils';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import './recruitment.screen.scss';
import {
  DatePickerComponent,
  Dropdown,
  Assets,
  CandidateStatus,
  Funnel,
  MultiSelectDropdown,
  Models,
  PieChart,
  Colors,
  Loader,
  PrimaryButton,
  GroupBarChart,
} from 'utils/imports.utils';
import BarChart from 'components/bar_chart/bar_chart.component';
import LineChart from 'components/line_chart/line_chart.component';
import { getDashboardData, getDepartmentList } from 'utils/redux.utils';
import moment from 'moment';
import {
  CANDIDATE_STATUS,
  gender,
  REQUISITION_STATUS,
  req_status,
  UserRole,
  REVENUES,
  } from 'utils/constant.utils';
import RecruitmentFunnel from 'common_components/ui/Candidate/recruitment_funnel';
import csvDownload from 'json-to-csv-export';
import * as XLSX from 'xlsx';
const Recruitment = (props: any) => {
  // Redux
  const dashboard = useSelector((state: any) => state.candidate.dashboard);
  const userState = useSelector((state: any) => state.user.auth);
  const agingChartRef: any = useRef();
  const sourceMixChartRef: any = useRef();
  const revenuesMixChartRef: any = useRef();
  const requisitionsChartRef: any = useRef();
  const candidatesChartRef: any = useRef();
  const actualRevenueRef: any = useRef();
  const potentialRevenueRef: any = useRef();
  const candidateSummaryref: any = useRef();
  // State
  const [state, setState] = useSetState({
    data: [],
    skip: 0,
    limit: 200,
    loadMore: true,
    id: '',
    share: false,
    loading: false,
    hiring_team_value: '',
    recruiter_data: [],
    hiring_team: [],
    recruiter_value: '',
    req_list: [],
    req_value: '',
    departments: [],
    department_value: {},
    departments_list: [],
    recruiters: [],
    hiring_managers: [],
    // from_date: new Date(moment().startOf('year').format()),
    // to_date: new Date(),
    from_date: null,
    to_date: null,
    requisitions: [],
    account_manager_list: [],
    clients_list:[],
    vacanciesChart:{},
    exportRevenues:false,
    revenuesData:{}
  });
  const wrapperRef: any = useRef();

  //Hooks
  useEffect(() => {
    getUserLists();
    getAllDeparment();
    getAllBusinessUnits();
    getClientList()
    wrapperRef?.current?.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchVacanciesData()
    fetchDashboardData();
    fetchRecquisitionLists();
  }, [
    state.from_date,
    state.to_date,
    state.departments,
    state.recruiters,
    state.hiring_managers,
    state.requisitions,
    state.business_unit,
    state.account_manager,
    state.client
  ]);

  const navigate = useNavigate();

  // Network req

  //Logic

  //sample data

  const color = {
    color1: '#27C69E',
    color2: '#27C69E',
  };

  const getUserLists = async () => {
    try {
      let body: any = { limit: state.limit };
      const res: any = await Models.auth.getUserList(body);
      let nameArray: any[] = [];
      res.data?.docs?.map((item) => {
        nameArray.push({
          value: item?._id,
          label: `${item?.first_name} ${item?.last_name}`,
        });
      });
      setState({
        hiring_team: nameArray,
        recruiter_data: nameArray,
        interviewer_data: nameArray,
        account_manager_list: nameArray 
      });
      //dispatch
    } catch (error: any) {
      // toastifyError(error);
    }
  };

  const fetchRecquisitionLists = async () => {
    try {
      let recruiters: any = [];
      let hiring_team: any = [];
      let body: any = {
        limit: '',
        skip: 0,
      };
      if (state.recruiters.length > 0) {
        state.recruiters.map((item: any) => recruiters.push(item.value));
        body.recruiters = recruiters;
      }
      if (state.hiring_managers.length > 0) {
        state.hiring_managers.map((item: any) => hiring_team.push(item.value));
        body.hiring_team = hiring_team;
      }
      if(!_.isEmpty(state.account_manager)){
        body.account_manager = state.account_manager.value;
      }
      if(!_.isEmpty(state.client)){
        body.client = state.client.value;
      }
      const res: any = await Models.jobRecquisition.getNewRecquisition(body);
      if (state.recruiters.length > 0) {
        let departments_list: any = [];
        let business_unit_list: any = [];
        let hiring_team: any = [];
        res.data.docs.map((item: any) => {
          if (
            item.department &&
            _.findIndex(departments_list, { value: item.department._id }) == -1
          ) {
            departments_list.push({
              label: item.department.name,
              value: item.department._id,
            });
          }
          if (item.hiring_team) {
            item.hiring_team.map((user: any) => {
              if (_.findIndex(hiring_team, { value: user._id }) == -1) {
                hiring_team.push({
                  value: user?._id,
                  label: `${user?.first_name} ${user?.last_name}`,
                });
              }
            });
          }
          if (
            item.business_unit &&
            _.findIndex(business_unit_list, {
              value: item.business_unit._id,
            }) == -1
          ) {
            business_unit_list.push({
              label: item.business_unit.name,
              value: item.business_unit._id,
            });
          }
        });
        setState({
          hiring_team,
          departments_list,
          business_unit_list,
        });
      }
      let req_list: any = [];
      res.data.docs.map((req: any) =>
        req_list.push({ label: req?.job_title, value: req._id }),
      );
      // dispatch
      setState({ loading: false, req_list });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getAllDeparment = async () => {
    try {
      const res: any = await Models.department.getAllDepartment({
        limit: state.limit,
      });
      let departments_list: any = [];
      res.data.docs.map((item: any) => {
        departments_list.push({
          label: item?.name,
          value: item?._id,
        });
      });

      getDepartmentList(res.data);
      setState({ departments_list });
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };
  const getAllBusinessUnits = async () => {
    try {
      const res: any = await Models.business.getAllBusinessUnits({
        limit: state.limit,
      });
      let business_unit_list: any = [];
      res.data.docs.map((item: any) => {
        business_unit_list.push({
          label: item?.name,
          value: item?._id,
        });
      });
      setState({ business_unit_list });
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };
  const fetchDashboardData = async () => {
    try {
      let body: any = {};
      if (state.from_date !== null) {
        body.from = moment(state.from_date).format('DD/MM/YYYY');
      }
      if (state.to_date !== null) {
        body.to = moment(state.to_date).format('DD/MM/YYYY');
      }
      let hiring_managers: any = [];
      let requisitions: any = [];
      let recruiters: any = [];
      if (state.requisitions.length > 0) {
        state.requisitions.map((req: any) => requisitions.push(req.value));
        body.requisitions = requisitions;
      }
      if (state.hiring_managers.length > 0) {
        state.hiring_managers.map((item: any) =>
          hiring_managers.push(item.value),
        );
        body.hiring_managers = hiring_managers;
      }
      if (state.recruiters.length > 0) {
        state.recruiters.map((item: any) => recruiters.push(item.value));
        body.recruiters = recruiters;
      }
      if (!_.isEmpty(state.departments)) {
        body.departments = state.departments.value;
      }
      if (!_.isEmpty(state.business_unit)) {
        body.business_unit = state.business_unit.value;
      }
      if(!_.isEmpty(state.account_manager)){
        body.account_manager = state.account_manager.value;
      }
      if(!_.isEmpty(state.client)){
        body.client = state.client.value;
      }
      const res: any = await Models.candidateModel.dashboard(body);
            getDashboardData(res.data);
    } catch (error: any) {
      toastifyError(error);
    }
  };
  const fetchVacanciesData = async () => {
    try {
      let body: any = {};
      if (state.from_date !== null) {
        body.from = moment(state.from_date).format('DD/MM/YYYY');
      }
      if (state.to_date !== null) {
        body.to = moment(state.to_date).format('DD/MM/YYYY');
      }
      let hiring_managers: any = [];
      let requisitions: any = [];
      let recruiters: any = [];
      if (state.requisitions.length > 0) {
        state.requisitions.map((req: any) => requisitions.push(req.value));
        body.requisitions = requisitions;
      }
      if (state.hiring_managers.length > 0) {
        state.hiring_managers.map((item: any) =>
          hiring_managers.push(item.value),
        );
        body.hiring_managers = hiring_managers;
      }
      if (state.recruiters.length > 0) {
        state.recruiters.map((item: any) => recruiters.push(item.value));
        body.recruiters = recruiters;
      }
      if (!_.isEmpty(state.departments)) {
        body.departments = state.departments.value;
      }
      if (!_.isEmpty(state.business_unit)) {
        body.business_unit = state.business_unit.value;
      }
      if(!_.isEmpty(state.account_manager)){
        body.account_manager = state.account_manager.value;
      }
      if(!_.isEmpty(state.client)){
        body.client = state.client.value;
      }
      const res: any = await Models.candidateModel.getVacanciesChart(body);
      setState({vacanciesChart: res.data})
    } catch (error: any) {
      toastifyError(error);
    }
  };
  const exportRequisitions = async () => {
    try {
      setState({ exportReq: true });
      let requisitions: any = [];
      let body: any = {};
      if (state.requisitions.length > 0) {
        state.requisitions.map((req: any) => requisitions.push(req.value));
        body.requisitions = requisitions;
      }
      if (state.from_date !== null) {
        body.from = moment(state.from_date).format('DD/MM/YYYY');
      }
      if (state.to_date !== null) {
        body.to = moment(state.to_date).format('DD/MM/YYYY');
      }
      let hiring_managers: any = [];
      let recruiters: any = [];
      if (state.hiring_managers.length > 0) {
        state.hiring_managers.map((item: any) =>
          hiring_managers.push(item.value),
        );
        body.hiring_managers = hiring_managers;
      }
      if (state.recruiters.length > 0) {
        state.recruiters.map((item: any) => recruiters.push(item.value));
        body.recruiters = recruiters;
      }
      if (!_.isEmpty(state.departments)) {
        body.departments = state.departments.value;
      }
      if (!_.isEmpty(state.business_unit)) {
        body.business_unit = state.business_unit.value;
      }
      if(!_.isEmpty(state.account_manager)){
        body.account_manager = state.account_manager.value;
      }
      if(!_.isEmpty(state.client)){
        body.client = state.client.value;
      }
      const res: any = await Models.jobRecquisition.getRequisitionDump(body);
      let reqData = res.data;
      reqData.forEach((item: any) => {
        if (item['Filled Date']) {
          item['Filled Date'] = moment(item['Filled Date']).format(
            'DD/MM/YYYY',
          );
        }
      });
      csvDownload(reqData, 'Requisition Data.csv');
      setState({ exportReq: false });
    } catch (error: any) {
      setState({ exportReq: false });
      toastifyError(error);
    }
  };

  const exportJoinedCandidates = async () => {
    try {
      setState({ exportJoinedCandidates: true });
      let requisitions: any = [];
      let body: any = {};
      if (state.requisitions.length > 0) {
        state.requisitions.map((req: any) => requisitions.push(req.value));
        body.requisitions = requisitions;
      }
      if (state.from_date !== null) {
        body.from = moment(state.from_date).format('DD/MM/YYYY');
      }
      if (state.to_date !== null) {
        body.to = moment(state.to_date).format('DD/MM/YYYY');
      }
      let hiring_managers: any = [];
      let recruiters: any = [];
      if (state.hiring_managers.length > 0) {
        state.hiring_managers.map((item: any) =>
          hiring_managers.push(item.value),
        );
        body.hiring_managers = hiring_managers;
      }
      if (state.recruiters.length > 0) {
        state.recruiters.map((item: any) => recruiters.push(item.value));
        body.recruiters = recruiters;
      }
      if (!_.isEmpty(state.departments)) {
        body.departments = state.departments.value;
      }
      if (!_.isEmpty(state.business_unit)) {
        body.business_unit = state.business_unit.value;
      }
      if (!_.isEmpty(state.account_manager)) {
        body.business_unit = state.account_manager.value;
      }
      if (!_.isEmpty(state.client)) {
        body.client = state.client.value;
      }
      const res: any = await Models.candidateModel.joinedDatadump(body);
      let joinerData = res.data;
      joinerData.forEach((item: any) => {
        if (item['Applied Date']) {
          item['Applied Date'] = moment(item['Applied Date']).format(
            'DD/MM/YYYY',
          );
        }
        if (item['Screening Date']) {
          item['Screening Date'] = moment(item['Screening Date']).format(
            'DD/MM/YYYY',
          );
        }
        if (item['Interview Round 1 Date']) {
          item['Interview Round 1 Date'] = moment(
            item['Interview Round 1 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Interview Round 2 Date']) {
          item['Interview Round 2 Date'] = moment(
            item['Interview Round 2 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Interview Round 3 Date']) {
          item['Interview Round 3 Date'] = moment(
            item['Interview Round 3 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Interview Round 4 Date']) {
          item['Interview Round 4 Date'] = moment(
            item['Interview Round 4 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Interview Round 5 Date']) {
          item['Interview Round 5 Date'] = moment(
            item['Interview Round 5 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Offer Date']) {
          item['Offer Date'] = moment(item['Offer Date']).format('DD/MM/YYYY');
        }
        if (item['Joined Date']) {
          item['Joined Date'] = moment(item['Joined Date']).format(
            'DD/MM/YYYY',
          );
        }
        if (item['Date Of Upload']) {
          item['Date Of Upload'] = moment(item['Date Of Upload']).format(
            'DD/MM/YYYY',
          );
        }
        if (item['Time Of Upload']) {
          item['Time Of Upload'] = moment(item['Time Of Upload']).format(
            'HH:mm:ss A',
          );
        }
      });
      csvDownload(joinerData, 'Joiner Data.csv');
      setState({ exportJoinedCandidates: false });
    } catch (error: any) {
      setState({ exportJoinedCandidates: false });
      toastifyError(error);
    }
  };

  const exportCandidates = async () => {
    try {
      setState({ exportCandidates: true });
      let requisitions: any = [];
      let body: any = {};
      if (state.requisitions.length > 0) {
        state.requisitions.map((req: any) => requisitions.push(req.value));
        body.requisitions = requisitions;
      }
      if (state.from_date !== null) {
        body.from = moment(state.from_date).format('DD/MM/YYYY');
      }
      if (state.to_date !== null) {
        body.to = moment(state.to_date).format('DD/MM/YYYY');
      }
      let hiring_managers: any = [];
      let recruiters: any = [];
      if (state.hiring_managers.length > 0) {
        state.hiring_managers.map((item: any) =>
          hiring_managers.push(item.value),
        );
        body.hiring_managers = hiring_managers;
      }
      if (state.recruiters.length > 0) {
        state.recruiters.map((item: any) => recruiters.push(item.value));
        body.recruiters = recruiters;
      }
      if (!_.isEmpty(state.departments)) {
        body.departments = state.departments.value;
      }
      if (!_.isEmpty(state.business_unit)) {
        body.business_unit = state.business_unit.value;
      }
      if (!_.isEmpty(state.account_manager)) {
        body.business_unit = state.account_manager.value;
      }
      if (!_.isEmpty(state.client)) {
        body.client = state.client.value;
      }
      const res: any = await Models.candidateModel.dump(body);
      let candidateData = res.data;
      candidateData.forEach((item: any) => {
        if (item['Applied Date']) {
          item['Applied Date'] = moment(item['Applied Date']).format(
            'DD/MM/YYYY',
          );
        }
        if (item['Screening Date']) {
          item['Screening Date'] = moment(item['Screening Date']).format(
            'DD/MM/YYYY',
          );
        }
        if (item['Interview Round 1 Date']) {
          item['Interview Round 1 Date'] = moment(
            item['Interview Round 1 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Interview Round 2 Date']) {
          item['Interview Round 2 Date'] = moment(
            item['Interview Round 2 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Interview Round 3 Date']) {
          item['Interview Round 3 Date'] = moment(
            item['Interview Round 3 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Interview Round 4 Date']) {
          item['Interview Round 4 Date'] = moment(
            item['Interview Round 4 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Interview Round 5 Date']) {
          item['Interview Round 5 Date'] = moment(
            item['Interview Round 5 Date'],
          ).format('DD/MM/YYYY');
        }
        if (item['Offer Date']) {
          item['Offer Date'] = moment(item['Offer Date']).format('DD/MM/YYYY');
        }
        if (item['Joined Date']) {
          item['Joined Date'] = moment(item['Joined Date']).format(
            'DD/MM/YYYY',
          );
        }
        if (item['Date Of Upload']) {
          item['Date Of Upload'] = moment(item['Date Of Upload']).format(
            'DD/MM/YYYY',
          );
        }
        if (item['Time Of Upload']) {
          item['Time Of Upload'] = moment(item['Time Of Upload']).format(
            'HH:mm:ss A',
          );
        }
      });
      csvDownload(candidateData, 'Candidate Data.csv');
      setState({ exportCandidates: false });
    } catch (error: any) {
      setState({ exportCandidates: false });
      toastifyError(error);
    }
  };
  const exportRevenues = async () => {
    try {
      setState({ exportRevenues: true });
      let requisitions: any = [];
      let body: any = {};
      if (state.requisitions.length > 0) {
        state.requisitions.map((req: any) => requisitions.push(req.value));
        body.requisitions = requisitions;
      }
      if (state.from_date !== null) {
        body.from = moment(state.from_date).format('DD/MM/YYYY');
      }
      if (state.to_date !== null) {
        body.to = moment(state.to_date).format('DD/MM/YYYY');
      }
      let hiring_managers: any = [];
      let recruiters: any = [];
      if (state.hiring_managers.length > 0) {
        state.hiring_managers.map((item: any) =>
          hiring_managers.push(item.value),
        );
        body.hiring_managers = hiring_managers;
      }
      if (state.recruiters.length > 0) {
        state.recruiters.map((item: any) => recruiters.push(item.value));
        body.recruiters = recruiters;
      }
      if (!_.isEmpty(state.departments)) {
        body.departments = state.departments.value;
      }
      if (!_.isEmpty(state.business_unit)) {
        body.business_unit = state.business_unit.value;
      }
      if (!_.isEmpty(state.account_manager)) {
        body.business_unit = state.account_manager.value;
      }
      if (!_.isEmpty(state.client)) {
        body.client = state.client.value;
      }
      const res: any = await Models.candidateModel.revenuesDump(body);
      let candidateData = res.data;
      // candidateData.forEach((item: any) => {
      //   if (item['Applied Date']) {
      //     item['Applied Date'] = moment(item['Applied Date']).format(
      //       'DD/MM/YYYY',
      //     );
      //   }
      //   if (item['Screening Date']) {
      //     item['Screening Date'] = moment(item['Screening Date']).format(
      //       'DD/MM/YYYY',
      //     );
      //   }
      //   if (item['Interview Round 1 Date']) {
      //     item['Interview Round 1 Date'] = moment(
      //       item['Interview Round 1 Date'],
      //     ).format('DD/MM/YYYY');
      //   }
      //   if (item['Interview Round 2 Date']) {
      //     item['Interview Round 2 Date'] = moment(
      //       item['Interview Round 2 Date'],
      //     ).format('DD/MM/YYYY');
      //   }
      //   if (item['Interview Round 3 Date']) {
      //     item['Interview Round 3 Date'] = moment(
      //       item['Interview Round 3 Date'],
      //     ).format('DD/MM/YYYY');
      //   }
      //   if (item['Interview Round 4 Date']) {
      //     item['Interview Round 4 Date'] = moment(
      //       item['Interview Round 4 Date'],
      //     ).format('DD/MM/YYYY');
      //   }
      //   if (item['Interview Round 5 Date']) {
      //     item['Interview Round 5 Date'] = moment(
      //       item['Interview Round 5 Date'],
      //     ).format('DD/MM/YYYY');
      //   }
      //   if (item['Offer Date']) {
      //     item['Offer Date'] = moment(item['Offer Date']).format('DD/MM/YYYY');
      //   }
      //   if (item['Joined Date']) {
      //     item['Joined Date'] = moment(item['Joined Date']).format(
      //       'DD/MM/YYYY',
      //     );
      //   }
      //   if (item['Date Of Upload']) {
      //     item['Date Of Upload'] = moment(item['Date Of Upload']).format(
      //       'DD/MM/YYYY',
      //     );
      //   }
      //   if (item['Time Of Upload']) {
      //     item['Time Of Upload'] = moment(item['Time Of Upload']).format(
      //       'HH:mm:ss A',
      //     );
      //   }
      // });
      // csvDownload(candidateData.revenuePotential, 'Candidate Data.csv');
    

    // Create Excel file with multiple sheets
    setState({revenuesData:candidateData})
    const wb = XLSX.utils.book_new();
    for (let key in candidateData) {
      const revenue = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(revenue, [candidateData[key][0]]);

      // const revenue = XLSX.utils.json_to_sheet();
      const columnWidths = candidateData[key].reduce((acc: any, row: any) => {
        row.forEach((cell: any, columnIndex: number) => {
          const cellWidth = (cell || '').toString().length + 2; // Add 2 for padding
          acc[columnIndex] = Math.max(acc[columnIndex] || 0, cellWidth);
        });
        return acc;
      }, []);

      // Set column widths for all columns
      revenue['!cols'] = columnWidths.map((width: any) => ({ wch: width }));
      candidateData[key].splice(0, 1);
      XLSX.utils.sheet_add_json(revenue, candidateData[key], {
        origin: 'A2',
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(wb, revenue, key);
    }

    // Save the Excel file
    XLSX.writeFile(wb, 'Revenue Data.xlsx');
    // done()
      setState({ exportRevenues: false });
    } catch (error: any) {
      setState({ exportRevenues: false });
      toastifyError(error);
    }
  };
  const getTotalUnattendedCandidatesData = () => {
    let sum = 0;
    let obj = dashboard?.candidates_unattended;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      sum += obj[key];
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      sum,
      label,
      value,
    };
    return data;
  };

  const getAgingData = () => {
    let obj = dashboard?.aging;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      label,
      value,
    };
    return data;
  };

  const getSourcemixData = () => {
    let obj = dashboard?.source;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      label,
      value,
    };
    return data;
  };

  const getRequisitionsData = () => {
    let obj = state.vacanciesChart?.requisitions;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      label,
      value,
    };
    return data;
  };

  const getCandidatesData = () => {
    let obj = state.vacanciesChart?.candidates;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      label,
      value,
    };
    return data;
  };
  const getRevenuePipelineData = () => {
    let obj = dashboard?.revenues?.pipeline;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      label,
      value,
    };
    return data;
  };
  const getActualRevenueData = () => {
    let obj = dashboard?.revenues?.actual;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      label,
      value,
    };
    return data;
  };
  const getCandidateSummary = () => {
    let obj = dashboard?.candidate_summary;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      label,
      value,
    };
    return data;
  };
  const getPotentialRevenueData = () => {
    let obj = dashboard?.revenues?.potential;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      label,
      value,
    };
    return data;
  };

  const getDiversityData = () => {
    let total =
      dashboard?.diversity.Male +
      dashboard?.diversity.Female +
      dashboard?.diversity.do_not_disclose;
    let noOfMales = dashboard?.diversity.Male;
    let noOfFemales = dashboard?.diversity.Female;
    let noOfOthers = dashboard?.diversity.do_not_disclose;
    let percentageOfMale = ((noOfMales / total) * 100).toFixed(1);
    let percentageOfFemale = ((noOfFemales / total) * 100).toFixed(1);
    let percentageOfOthers = ((noOfOthers / total) * 100).toFixed(1);
    let obj = {
      percentageOfMale,
      percentageOfFemale,
      percentageOfOthers,
      sum: noOfMales + noOfFemales + noOfOthers,
    };
    return obj;
  };

  const getSummary = () => {
    let lost_reqs= userState?.is_agency?dashboard?.requisition_summary[REQUISITION_STATUS.LOST]:0;
    let total =
      dashboard?.requisition_summary[REQUISITION_STATUS.OPEN] +
      dashboard?.requisition_summary[REQUISITION_STATUS.CANCELLED] +
      dashboard?.requisition_summary[REQUISITION_STATUS.FILLED] +
      dashboard?.requisition_summary[REQUISITION_STATUS.ON_HOLD]+
      lost_reqs
    let open_req = dashboard?.requisition_summary[REQUISITION_STATUS.OPEN];
    let cancelled_reqs =
      dashboard?.requisition_summary[REQUISITION_STATUS.CANCELLED];
    let filled_reqs = dashboard?.requisition_summary[REQUISITION_STATUS.FILLED];
    let on_hold_reqs =
      dashboard?.requisition_summary[REQUISITION_STATUS.ON_HOLD];

    let percentageOfOpenReqs = ((open_req / total) * 100).toFixed(1);
    let percentageOfCancelledReqs = ((cancelled_reqs / total) * 100).toFixed(1);
    let percentageOfFilledReqs = ((filled_reqs / total) * 100).toFixed(1);
    let percentageOfOnHoldReqs = ((on_hold_reqs / total) * 100).toFixed(1);
    let percentageOfLostReqs = ((lost_reqs / total) * 100).toFixed(1);

    let obj: any = {
      Open: percentageOfOpenReqs,
      Cancelled: percentageOfCancelledReqs,
      Filled: percentageOfFilledReqs,
      OnHold: percentageOfOnHoldReqs,
      sum: total,
    }
    if(userState.is_agency){
      obj.Lost= percentageOfLostReqs
    }
    return obj;
  };

  const getRequisitionsSummaryData = () => {
    let obj = dashboard?.requisition_summary;
    let label: any = [];
    let value: any = [];
    for (const key in obj) {
      label.push(key);
      value.push(obj[key]);
    }
    let data = {
      label,
      value,
    };
    return data;
  };
  const diversityData = {
    labels: ['Male', 'Female', 'Undisclosed'],
    datasets: [
      {
        // label: '# of Votes',
        data: [
          getDiversityData().percentageOfMale,
          getDiversityData().percentageOfFemale,
          getDiversityData().percentageOfOthers,
        ],
        backgroundColor: [
          Colors.primaryLinkColor,
          Colors.activeTabColor,
          Colors.inputHeadingColor,
        ],
        borderColor: [
          Colors.primaryLinkColor,
          Colors.activeTabColor,
          Colors.inputHeadingColor,
        ],
        borderWidth: 1,
      },
    ],
  };

  const requisitionSummaryData = {
    labels: ['Open', 'Cancelled', 'Filled', 'On hold'],
    datasets: [
      {
        // label: '# of Votes',
        data: [
          getSummary().Open,
          getSummary().Cancelled,
          getSummary().Filled,
          getSummary().OnHold,
                  ],
        backgroundColor: [
          Colors.primaryLinkColor,
          '#1C7BDF',
          Colors.inputHeadingColor,
        ],
        borderColor: [
          Colors.primaryLinkColor,
          '#1C7BDF',
          Colors.inputHeadingColor,
        ],
        borderWidth: 1,
      },
    ],
  };

  const getReqJoinersData = () => {
    let labelArray: any = [
      ...getRequisitionsData().label,
      ...getCandidatesData().label,
    ];
    let newArray: any = new Set(labelArray);
    let labels: any = [...newArray];
    let reqData = state.vacanciesChart?.requisitions;
    let candidateData = state.vacanciesChart?.candidates;
    let candidatesData: any = [];
    if (!_.isEmpty(reqData)) {
      Object.keys(reqData).map((key) => {
        let index = labels.indexOf(key);
        if (!candidateData[key]) {
          candidatesData[index] = { [key]: 0 };
        } else {
          candidatesData[index] = { [key]: candidateData[key] };
        }
      });
    }
    let joinersData: any = {};
    candidatesData.map(
      (item: any) => (joinersData = { ...joinersData, ...item }),
    );
    let obj = joinersData;
    let value: any = [];
    for (const key in obj) {
      value.push(obj[key]);
    }
    return { labels, value };
  };
  const getRevenuesData = () => {
    let labelArray: any = [
      ...getPotentialRevenueData().label,
      ...getRevenuePipelineData().label,
    ];
    let newArray: any = new Set(labelArray);
    let labels: any = [...newArray];
    let potentialData = dashboard?.revenues?.potential;
    let actualData = dashboard?.revenues?.actual;
    let revenuesData: any = [];
    if (!_.isEmpty(potentialData)) {
      Object.keys(potentialData).map((key) => {
        let index = labels.indexOf(key);
        if (!actualData[key]) {
          revenuesData[index] = { [key]: 0 };
        } else {
          revenuesData[index] = { [key]: actualData[key] };
        }
      });
    }
    let joinersData: any = {};
    revenuesData.map(
      (item: any) => (joinersData = { ...joinersData, ...item }),
    );
    let obj = joinersData;
    let value: any = [];
    for (const key in obj) {
      value.push(obj[key]);
    }
    return { labels, value };
  };

  const getClientList = async () => {
    try {
      let res: any = await Models.client.getAllClient({ limit: 200, skip: 0 })
      let clients_list: any = []
      res.data.docs.map((item: any) => {
        clients_list.push({
          label: item?.client_name,
          value: item?._id,
        });
      });
      setState({ clients_list });
    } catch (error: any) {
      toastifyError(error);
    }
  };
let reqStatusBar=['Open', 'Cancelled', 'Filled', 'On hold']

  return (
    <div className="recruitment_container" ref={wrapperRef}>
      <div className="recruitment_date_wrapper">
        <div className="recruitment_heading_text">Dashboard</div>
      </div>
      <div className="recruitment_search_container">
        <div className="recruitment_dropdown_container">
          <div className="recruitment_dropdown_wrapper">
            <div className="recruitment_dropdown_label">Recruiter</div>
            <div className="recruitment_dropdown">
              <Dropdown
                isMulti
                clear
                data={state.recruiter_data}
                onChange={(data: any) => {
                  setState({
                    recruiters: data,
                  });
                }}
                value={state.recruiters}
                name="hiring_team"
                error={state.errorArray}
              />
            </div>
          </div>
          <div className="recruitment_dropdown_wrapper">
            <div className="recruitment_dropdown_label">Hiring Manager</div>
            <div className="recruitment_dropdown">
              <Dropdown
                clear
                isMulti
                data={state.hiring_team}
                onChange={(hiring_managers: any) => {
                  setState({
                    hiring_managers,
                  });
                }}
                value={state.hiring_managers}
                name="hiring_team"
                error={state.errorArray}
              />
            </div>
          </div>
          <div className="recruitment_dropdown_wrapper">
            <div className="recruitment_dropdown_label">Requisition</div>
            <div className="recruitment_dropdown">
              <Dropdown
                isMulti
                clear
                onChange={(requisitions: any) => {
                  setState({
                    requisitions,
                  });
                }}
                data={state.req_list}
                value={state.requisitions}
                error={state.errorArray}
                name="req_value"
              />
            </div>
          </div>
        </div>
        <div className="recruitment_wrapper">
          <div className="recruitment_main_wrapper">
            {userState.is_agency && (
              <div className="recruitment_date_dropdown_wrapper">
                <div className="recruitment_agency_wrapper">
                  <div className="recruitment_dropdown_label">
                    Account Manager
                  </div>
                  <div className="recruitment_dropdown">
                    <Dropdown
                      onChange={(account_manager: any) => {
                        setState({
                          account_manager,
                        });
                      }}
                      clear
                      data={state.account_manager_list}
                      value={state.account_manager}
                      error={state.errorArray}
                      name="account_manager"
                    />
                  </div>
                </div>
                <div className="recruitment_agency_wrapper">
                  <div className="recruitment_dropdown_label">Client</div>
                  <div className="recruitment_dropdown">
                    <Dropdown
                      onChange={(client: any) => {
                        setState({
                          client,
                        });
                      }}
                      clear
                      data={state.clients_list}
                      value={state.client}
                      error={state.errorArray}
                      name="department"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="recruitment_date_dropdown_wrapper">
              <div className="recruitment_department_dropdown_wrapper">
                <div className="recruitment_dropdown_label">Department</div>
                <div className="recruitment_dropdown">
                  <Dropdown
                    onChange={(departments: any) => {
                      setState({
                        departments,
                      });
                    }}
                    clear
                    data={state.departments_list}
                    value={state.departments}
                    error={state.errorArray}
                    name="department"
                  />
                </div>
              </div>
              <div className="recruitment_department_dropdown_wrapper">
                <div className="recruitment_dropdown_label">Business unit</div>
                <div className="recruitment_dropdown">
                  <Dropdown
                    onChange={(business_unit: any) => {
                      setState({
                        business_unit,
                      });
                    }}
                    clear
                    data={state.business_unit_list}
                    value={state.business_unit}
                    error={state.errorArray}
                    name="business_unit"
                  />
                </div>
              </div>
              <div className="recruitment_dropdown_wrapper">
                <div className="recruitment_dropdown_label">From</div>
                <div className="recruitment_dropdown">
                  <DatePickerComponent
                    value={state.from_date}
                    onChange={(from_date: any) => setState({ from_date })}
                  />
                </div>
              </div>
              <div className="recruitment_dropdown_wrapper">
                <div className="recruitment_dropdown_label">To</div>
                <div className="recruitment_dropdown">
                  <DatePickerComponent
                    value={state.to_date}
                    onChange={(to_date: any) => setState({ to_date })}
                  />
                </div>
              </div>
            </div>
            <div className="recruitment_source_header">
              <div className="recruitment_source_card">
                <div className="recruitment_source_time_imagetext">
                  <img src={Assets.clock} alt="clock image" />
                  <div className="recruitment_source_time_text">
                    Time to Offer
                  </div>
                </div>
                <div className="recruitment_source_card_text">
                  {dashboard?.time_to_offer ? dashboard?.time_to_offer : 0} Days
                </div>
              </div>
              <div className="recruitment_source_card">
                <div className="recruitment_source_time_imagetext">
                  <img src={Assets.clock} alt="clock image" />
                  <div className="recruitment_source_time_text">
                    Time to Hire
                  </div>
                </div>
                <div className="recruitment_source_card_text">
                  {dashboard?.time_to_hire ? dashboard?.time_to_hire : 0} Days
                </div>
              </div>
              <div className="recruitment_source_card">
                <div className="recruitment_source_time_imagetext">
                  <img src={Assets.clock} alt="clock image" />
                  <div className="recruitment_source_time_text">Velocity</div>
                </div>
                <div className="recruitment_source_card_text">
                  {dashboard?.velocity ? dashboard?.velocity : 0}
                </div>
              </div>
              <div className="recruitment_source_card no_border">
                <div className="recruitment_source_time_imagetext">
                  <img src={Assets.clock} alt="clock image" />
                  <div className="recruitment_source_time_text">Lead Times</div>
                </div>
                <div className="recruitment_source_card_text">
                  {dashboard?.lead_times ? dashboard?.lead_times : 0}
                </div>
              </div>
            </div>

            {/* <div className="recruitment_department_container">
            <div className="recruitment_subheadings">Departments</div>
            <div className="recruitment_department_chart">
              <BarChart colors={color} data={data} labels={labels} />
            </div>
          </div> */}
          </div>
          <div className="recruitment_side_wrapper">
            <div className="recruitment_funnel_container">
              <RecruitmentFunnel
                classname="recruitment_funnel_image"
                textbox="recruitment_funnel_textbox"
                applicationNumber={
                  dashboard?.candidate_summary[CANDIDATE_STATUS.APPLIED]
                }
                interviewedNumber={
                  dashboard?.candidate_summary[CANDIDATE_STATUS.INTERVIEWING]
                }
                offerNumber={
                  dashboard?.candidate_summary[CANDIDATE_STATUS.OFFERED]
                }
                screening={
                  dashboard?.candidate_summary[CANDIDATE_STATUS.SCREENING]
                }
                joined={dashboard?.candidate_summary[CANDIDATE_STATUS.JOINED]}
              />
            </div>
          </div>
        </div>
        <div className="recruitment_export_wrapper">
          <div>
            {state.exportReq ? (
              <Loader />
            ) : (
              <PrimaryButton
                backgroundColor={Colors.primaryLinkColor}
                text={'Export Requisitions'}
                onClick={exportRequisitions}
              />
            )}
          </div>
          <div>
            {state.exportJoinedCandidates ? (
              <Loader />
            ) : (
              <PrimaryButton
                backgroundColor={Colors.primaryLinkColor}
                text={'Export Joined Candidates'}
                onClick={exportJoinedCandidates}
              />
            )}
          </div>
          <div>
            {state.exportCandidates ? (
              <Loader />
            ) : (
              <PrimaryButton
                backgroundColor={Colors.primaryLinkColor}
                text={'Export Candidates'}
                onClick={exportCandidates}
              />
            )}
          </div>
          {userState.is_agency && (
            <div>
              {state.exportRevenues ? (
                <Loader />
              ) : (
                <PrimaryButton
                  backgroundColor={Colors.primaryLinkColor}
                  text={'Export Financials'}
                  onClick={exportRevenues}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="recruitment_chart_wrapper">
        <div
          className="recruitment_requisitions_container"
          style={{ width: '100%' }}>
          <div className="recruitment_subheadings">
            Open Vacancies Vs Joiners
          </div>
          <div className="recruitment_aging_chart">
            <LineChart
              joinersData={getReqJoinersData().value}
              labels={getReqJoinersData().labels}
              colors={color}
              data={getRequisitionsData().value}
            />
            {/* <BarChart
              colors={color}
              ref={requisitionsChartRef}
              data={getRequisitionsData().value}
              labels={getRequisitionsData().label}
              saveCanvas={() => requisitionsChartRef?.current.saveChart()}
            /> */}
          </div>
        </div>
        <div className="recruitment_source_container">
          <div className="recruitment_source_wrapper">
            <div className="recruitment_subheadings">Source Mix</div>
            <div className="recruitment_source_chart">
              <BarChart
                colors={color}
                ref={sourceMixChartRef}
                data={getSourcemixData().value}
                labels={getSourcemixData().label}
                saveCanvas={() => sourceMixChartRef?.current.saveChart()}
              />
            </div>
          </div>
        </div>
        <div className="recruitment_aging_container">
          <div className="recruitment_subheadings">Aging</div>
          <div className="recruitment_aging_chart">
            <BarChart
              colors={color}
              ref={agingChartRef}
              data={getAgingData().value}
              labels={getAgingData().label}
              saveCanvas={() => agingChartRef?.current.saveChart()}
            />
          </div>
        </div>
        <div className="recruitment_unattendend_container">
          <CandidateStatus
            heading="Candidates Unattended"
            data={getTotalUnattendedCandidatesData().value}
            label={getTotalUnattendedCandidatesData().label}
            heading_number={getTotalUnattendedCandidatesData().sum}
          />
        </div>
        <div className="recruitment_diversity_container">
          <div className="recruitment_subheadings">Diversity</div>
          <div className="recruitment_diversity_chart_container">
            <PieChart data={diversityData} genderData={getDiversityData()} />
          </div>
        </div>
        <div className="recruitment_diversity_container">
          <div className="recruitment_subheadings">Requisitions</div>
          <div className="recruitment_diversity_chart_container">
            {/* <PieChart data={requisitionSummaryData} genderData={getSummary()} /> */}
            <BarChart
              colors={color}
              ref={requisitionsChartRef}
              data={getRequisitionsSummaryData().value}
              labels={
                userState?.is_agency
                  ? [...reqStatusBar, REQUISITION_STATUS.LOST]
                  : reqStatusBar
              }
              saveCanvas={() => requisitionsChartRef?.current.saveChart()}
            />
          </div>
        </div>
        <div className="recruitment_diversity_container">
          <div className="recruitment_subheadings">Candidate summary</div>
          <div className="recruitment_diversity_chart_container">
            <BarChart
              colors={color}
              ref={candidateSummaryref}
              data={getCandidateSummary().value}
              labels={getCandidateSummary().label}
              saveCanvas={() => candidateSummaryref?.current.saveChart()}
              stepSize={Math.round(
                Math.max(...getCandidateSummary().value) / 2,
              )}
            />
          </div>
        </div>
        {userState?.is_agency && userState?.role === UserRole.ADMIN && (
          <div className="recruitment_source_container">
            <div className="recruitment_source_wrapper">
              <div className="recruitment_subheadings">Revenue Potential</div>
              <div className="recruitment_source_chart">
                <BarChart
                  colors={color}
                  ref={potentialRevenueRef}
                  data={getPotentialRevenueData().value}
                  labels={getPotentialRevenueData().label}
                  saveCanvas={() => potentialRevenueRef?.current.saveChart()}
                  stepSize={Math.round(
                    Math.max(...getPotentialRevenueData().value) / 2,
                  )}
                />
              </div>
            </div>
          </div>
        )}
        {userState?.is_agency && userState?.role === UserRole.ADMIN && (
          <div className="recruitment_source_container">
            <div className="recruitment_source_wrapper">
              <div className="recruitment_subheadings">Revenue Pipeline</div>
              <div className="recruitment_source_chart">
                <BarChart
                  colors={color}
                  ref={actualRevenueRef}
                  data={getRevenuePipelineData().value}
                  labels={getRevenuePipelineData().label}
                  saveCanvas={() => actualRevenueRef?.current.saveChart()}
                  stepSize={Math.round(
                    Math.max(...getRevenuePipelineData().value) / 2,
                  )}
                />
              </div>
            </div>
          </div>
        )}
        {userState?.is_agency && userState?.role === UserRole.ADMIN && (
          <div className="recruitment_source_container">
            <div className="recruitment_source_wrapper">
              <div className="recruitment_subheadings">Actual Revenue</div>
              <div className="recruitment_source_chart">
                <BarChart
                  colors={color}
                  ref={actualRevenueRef}
                  data={getActualRevenueData().value}
                  labels={getActualRevenueData().label}
                  saveCanvas={() => actualRevenueRef?.current.saveChart()}
                  stepSize={Math.round(
                    Math.max(...getActualRevenueData().value) / 2,
                  )}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Recruitment;
