import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import Assets from 'imports/assets.import';
import './pdf_viewer.scss';

const PdfViewer = (props: any) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({ pageNumber: 1, numPages: '' });

  //Hooks
  useEffect(() => {}, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState({ numPages });
  };

  // Network req

  //Logic
  const testLogic = () => {};

  //sample_data

  const handleNextPage = () => {
    if (state.pageNumber < state.numPages) {
      setState({ pageNumber: state.pageNumber + 1 });
    }
  };

  const handleDecrementPage = () => {
    if (state.pageNumber > 1) {
      setState({ pageNumber: state.pageNumber - 1 });
    }
  };

  return (
    <div className="pdf_wrapper">
      <div className="pdf_button_wrapper ">
        <div
          className="pdf_btn decrement_btn"
          onClick={() => handleDecrementPage()}>
          <img src={Assets.arrow} />
        </div>
        <div className="pdf_page_number">
          {state.pageNumber} / {state.numPages}
        </div>
        <div className="pdf_btn increment_btn" onClick={() => handleNextPage()}>
          <img src={Assets.arrow} />
        </div>
      </div>
      <Document
        file={{
          URL: props.file,
        }}
        options={{
          cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
        onLoadSuccess={onDocumentLoadSuccess}
        {...props}>
        <Page pageNumber={state.pageNumber} />
      </Document>
    </div>
  );
};

export default PdfViewer;
