import Assets from 'imports/assets.import';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = forwardRef((props: any, ref: any) => {
  const chartRef: any = useRef();
  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(20, 600, 1000, 0);
    gradient.addColorStop(0, props.colors.color2);
    gradient.addColorStop(0.5, props.colors.color1);
    gradient.addColorStop(0.7, props.colors.color1);
    gradient.addColorStop(1, props.colors.color2);
    return {
      labels: props.labels,
      datasets: [
        {
          barPercentage: 0.5,
          barThickness: 30,
          maxBarThickness: 100,
          minBarLength: 2,
          // label: 'Confirmed',
          data: props.data,
          backgroundColor: gradient,
          borderWidth: 2,
        },
      ],
    };
  };

  const options = {
    // responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: true,
            stepSize: props.stepSize && props.stepSize
          },

          gridLines: {
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            dispaly: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontColor: 'rgba(242, 38, 19, 1)',
        font: {
          size: 10,
        },
      },
    },
    plugins:{
      labels: {
        render: 'value',
        precision: 0,
      }}
  };

  const saveCanvas = () => {
    const link: any = document.createElement('a');
    link.href = chartRef?.current?.chartInstance?.toBase64Image();
    link.download = 'chart.jpeg';
    link.click();
    link.parentNode.removeChild(link);
  };

  useImperativeHandle(ref, () => ({
    saveChart() {
      saveCanvas();
    },
  }));

  return (
    <div className="bar_chart_wrapper">
      {props.data.reduce((a, b) => a + b, 0) > 0 ? (
        <Bar ref={chartRef} data={chartData} options={options} />
      ) : (
        <div className="empty_text">No data found</div>
      )}
      <div style={{ position: 'absolute', top: '22px', right: '20px' }}>
        <img onClick={props.saveCanvas} src={Assets.csv} />
      </div>
    </div>
  );
});

export default BarChart;
