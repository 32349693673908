import React from 'react';
import { JOB_SCORE_STATUS, SOURCE } from 'utils/constant.utils';
import { jobScoreMatch } from 'utils/functions.utils';
import { Colors } from 'utils/imports.utils';
import './categories.scss';
interface categoryTypes {
  image?: string;
  caption?: string;
  name: string;
  role: string;
  company: string;
  bgCard?: string;
  status?: string;
  onClick?: any;
  score?: any;
}

const Categories = (props: any) => {
  const getBgColor = () => {
    
    let scoreMatch = jobScoreMatch(props.score * 100);
    if (scoreMatch === JOB_SCORE_STATUS.HIGH_MATCH) {
      return Colors.checkboxActive;
    } else if (scoreMatch === JOB_SCORE_STATUS.AVERAGE_MATCH) {
      return Colors.lowMatch;
    } else return Colors.iconBadgeBackground;
  }

  {
    return (
      <div className="candidates_block" onClick={props.onClick}>
        <div className="candidate_image_block">
          <div className="image_name_wrapper">
            <img src={props.image} className="candidate_image" alt="" />
            <div className="candidate_profile_details">
              <div className="name">{props.name}</div>
            </div>
          </div>
          <div
            className="candidate_status_content"
            >
            <div className="score_percentage" style={{ backgroundColor: getBgColor() }}>
              {(props.score * 100).toFixed()}%
            </div>
            {props.source === SOURCE.AGENCY &&<div className="agency_tag">
              A
            </div>}
          </div>
        </div>
        <div className="category_status_wrapper">{props.status}</div>
      </div>
    );
  }
};
export default Categories;