import Assets from 'imports/assets.import';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { useSetState } from 'utils/functions.utils';
import './multiselect_dropdown.scss';

const MultiSelectDropdown = (props: any) => {
  // Redux
  
  // State
  const [state, setState] = useSetState({ inputValue: '' });

  //Hooks
  useEffect(() => {
    setState({ options: props?.data, inputValue: '' });
  }, [props.data]);

  // Network req

  //Logic

  const createOption = (label: string) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  const createNewOption = (label: string) => ({
    label,
    value: label.toLowerCase(),
  });

  const handleChange = (newValue: any, actionMeta: any) => {
    setState({ value: newValue });
    props?.onChange(newValue, actionMeta);
  };

  const handleCreate = (inputValue: string) => {
    setState({ isLoading: true });
    let newOption;
    if (props.type) {
      newOption = createNewOption(inputValue);
    } else {
      newOption = createOption(inputValue);
    }
    setState({
      isLoading: false,
      options: [...props.data, newOption],
      value: newOption,
    });
    let values = props.value;
    values.push(newOption);
    props?.onChange(values, newOption);
    setState({ isLoading: false });
  };

  const customStyles: any = {
    multiValueRemove: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'white',
      },
    }),
  };

  const onOutsideClick = () => {
    if (state.inputValue) {
      handleCreate(state.inputValue);
    } else {
      console.log('click');
    }
  };
  const DropdownIndicator = (props) => {
    return (
      <div style={{ paddingRight: '10px' }}>
        <img src={Assets.arrow} height={10} width={10} />
      </div>
    );
  };
  const ClearIndicator = (props) => {
      return (
        <div
        style={{ paddingRight: '10px', paddingTop: '5px' }}
        onClick={props.clearValue}>
        <img src={Assets.close_black} height={20} width={20} />
      </div>
    );
  };
  return (
    <div className="multiselect_dropdown_wrapper">
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <CreatableSelect
          isClearable
          isMulti
          styles={customStyles}
          value={props.value}
          // isDisabled={state.isLoading}
          // isLoading={state.isLoading}
          onChange={handleChange}
          onCreateOption={(inputValue) => {
            handleCreate(inputValue);
          }}
          options={state.options}
          placeholder={props.placeholder || 'Select..'}
          onInputChange={(newValue: string) => {
            if (newValue !== '') {
              setState({ inputValue: newValue });
            }
          }}
          isDisabled={props.disabled}
          classNames={{
            control: (state) =>
              state.isFocused ? 'css-1s2u09g-control' : 'css-1s2u09g-control',
            indicatorSeparator: (state) =>
              state.isFocused ? 'css-1s2u09g-control' : 'css-1s2u09g-control',
            multiValue: (state) =>
              state.isFocused
                ? 'css-1rhbuit-multiValue'
                : 'css-1rhbuit-multiValue',
            multiValueLabel: (state) =>
              state.isFocused ? 'css-12jo7m5' : 'css-12jo7m5'
          }}
        components={{ DropdownIndicator, ClearIndicator }}
        />
        {props.error &&
          props.error.map((error: any) => (
            <div className="input_field_error">
              {props.name === error?.path && error.message}
            </div>
          ))}
      </OutsideClickHandler>
    </div>
  );
};

export default MultiSelectDropdown;
