import { GET_POST_JOB } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';



const initialState = {
    postJob_list:""
}

const postJobReducer = (state = initialState, action: storeAction) => {
    switch (action.type) {
      case GET_POST_JOB:
        return {
          ...state,
          postJob_list: action.payload,
        };
      default:
        return state;
    }
  };
  export default postJobReducer ;