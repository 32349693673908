import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  getTimeZones,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Navbutton,
  Assets,
  Input,
  Tag,
  Dropdown,
  PrimaryButton,
  TextArea,
} from 'utils/imports.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './feedback_modal.component.scss';
import StarRatings from 'react-star-ratings';

const FeedbackModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();

  //Redux
  // State
  const [state, setState] = useSetState({
    skills: [],
    behaviours: [],
    feedback: '',
  });
  //Hooks
  useEffect(() => {
    if (props.edit) {
      setState({
        skills: props.data.skills,
        behaviours: props.data.behaviours,
        feedback: props.data.feedback,
      });
    } else {
      getFeedBackData();
    }
  }, [props.edit, props.data]);

  // Network req

  useImperativeHandle(ref, () => ({
    openModal() {
      if (!props.edit) {
        getFeedBackData();
      }
      setState({ isModalOpen: true, feedback: '' });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  //Logic
  const getFeedBackData = () => {
    let skills: any = [];
    props.skills?.map((skill: string) =>
      skills.push({
        name: skill,
        ratings: 0,
      }),
    );
    let behaviours: any = [];
    props.behaviour?.map((behaviour: string) =>
      behaviours.push({
        name: behaviour,
        ratings: 0,
      }),
    );
    setState({ behaviours, skills });
  };

  const setStarRating = (type: string, index: number, value: number) => {
    if (type == 'skill') {
      let skills: any = state.skills;
      skills[index].ratings = value;
      setState({ skills });
    } else {
      let behaviours: any = state.behaviours;
      behaviours[index].ratings = value;
      setState({ behaviours });
    }
  };

  const onSubmit = () => {
    let feedback: any = {};
    feedback.skills = state.skills;
    feedback.behaviours = state.behaviours;
    feedback.feedback = state.feedback;
    props.onSubmit(feedback);
    setState({ isModalOpen: false });
  };

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'feedback_modal_wrapper',
        }}>
        <div className="feedback_container">
          <div className="feedback_wrapper">
            <div className="feedback_header">
              <div className="feedback_heading">Notes</div>
              <Navbutton
                icon={Assets.black_close}
                onClick={() => {
                  setState({ isModalOpen: false });
                }}
              />
            </div>
            <div className="feedback_skills_container">
              <div className="feedback_heading_skills">Skills</div>
              {state.skills.map((skill: any, index: number) => (
                <div className="feedback_skills_wrapper">
                  <div className="feedback_skill_text">{skill.name}</div>
                  <div className="feedback_skill_rating">
                    <StarRatings
                      rating={skill.ratings || 0}
                      starRatedColor="#FFBC09"
                      starHoverColor="#FFBC09"
                      changeRating={(rating) =>
                        setStarRating('skill', index, rating)
                      }
                      starDimension={'30px'}
                      starSpacing={'2px'}
                      numberOfStars={5}
                      name="rating"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="feedback_skills_container">
              <div className="feedback_heading_behaviour">Behaviour</div>
              {state.behaviours.map((behaviour: any, index: number) => (
                <div className="feedback_skills_wrapper">
                  <div className="feedback_skill_text">{behaviour.name}</div>
                  <div className="feedback_skill_rating">
                  <StarRatings
                      rating={behaviour.ratings || 0}
                      starRatedColor="#FFBC09"
                      starHoverColor="#FFBC09"
                      changeRating={(rating) =>
                        setStarRating('behaviour', index, rating)
                      }
                      starDimension={'30px'}
                      starSpacing={'2px'}
                      numberOfStars={5}
                      name="rating"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="feedback_modal_notes_wrapper">
              <div className="feedback_heading_notes">Feedback</div>
              <div className="feedback_notes_container">
                <TextArea
                  onChange={(e) => setState({ feedback: e.target.value })}
                  name={'feedback'}
                  value={state.feedback}
                  className="feedback_modal_textarea"
                />
              </div>
            </div>
            <div className="feedback_modal_submit_button_wrapper">
              <PrimaryButton
                text={'Submit'}
                className="feedback_modal_submit_button"
                onClick={() => onSubmit()}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default FeedbackModal;
