import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  toastify,
  toastifyError,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Models,
  PrimaryButton,
  Assets,
} from 'utils/imports.utils';
import './email_confirmation.screen.scss';


const EmailConfirmation = (props: any) => {
  // Redux
  const userState = useSelector((state: any) => state.user.auth);
  const appState = useSelector((state: any) => state.app);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {
    let email_confirmation_id: any = query.get('email_confirmation_id');
    if (email_confirmation_id?.length > 0) {
      VerifyEmail();
    } else {
      // ResendConfirmation();
    }
  }, []);

  const query = useQuery();

  // Network req
  const ResendConfirmation = async () => {
    try {
      const res: any = await Models.auth.resendConfirmation({
        email: appState?.email,
      });
      toastify('Confirmation sent');
    } catch (error: any) {
      toastifyError(error);
      console.log(error);
    }
  };

  const VerifyEmail = async () => {
    try {
      let email_confirmation_id: any = query.get('email_confirmation_id');
      const res: any = await Models.auth.emailConfirmation({
        email_confirmation_id,
      });
      if (res) {
        window.location.href = '/home';
      }
    } catch (error: any) {
      toastifyError(error);
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className="email_confirmation_container">
      <div className="email_confirmation_wrapper">
        <div className="email_confirmation_container_logo">
          <img src={Assets.mail} alt="sidebar logo" className="sidebar_logo" />
        </div>
        <div className="email_confirmation_heading">Verify your email</div>
        <div className="email_confirmation_content">
          We sent a verification email to
          <div className="email_confirmation_mailid">{userState?.email}</div>
          Please tap the link in the email to verify your account
        </div>
        <div className="continue">
          <PrimaryButton
            text={'Resend Verification Email'}
            className={'button'}
            onClick={() => ResendConfirmation()}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
