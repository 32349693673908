let isDark = true;

const Light = {
  primaryDarkColor: '#10213F',
  primaryLightColor: '#586479',
  primaryDarkText: '#0B162B',
  primaryLightText: '#8E8E93',
  secondaryDarkColor: '#E73F3F',
  secondaryLightColor: '#FFF4EF',
  secondaryDarkText: '#F16722',
  secondaryLightText: '#8E8E93',
  background: '#F8F9FB',
  secondaryBackground: '#F0F5FF',
  inputBackground: '#F2F4F7',
  buttonTextColor: '#FFFFFF',
  outline: '#F1F1F4',
  focusOutLine: '#C9C9C9',
  otpOutLine: '#EBEBEB',
  cancel: '#E73F3F',
  gradientButtonColor:
    'linear - gradient(100.12deg, #27C69E - 22.5 %, #1C7BDF 122.07 %)',
  inputHeadingColor: '#808191',
  primaryLinkColor: '#27C69E',
  tabInactiveColor: '#efefef',
  primaryBtnColor: '#1B1D21',
  inputPlaceholder: '#808191',
  inputTextColor: '#11142d',
  activeTabColor: '#1C7BDF',
  socialBtnColor: '#0bb78c',
  checkboxActive: '#7fba7a',
  iconBadgeBackground: '#ff754c',
  primaryCardColor: '#F0F3F6',
  primaryBorderColor: 'rgba(128, 129, 145, 0.6)',
  secondaryBorderColor: '#1c7bdf',
  primaryNumberBg: 'rgba(28, 123, 212, 0.2)',
  error: '#EA5224',
  secondaryBtnColor: '#e4e4e4',
  lowMatch: '#F3A81D',
  btnGradientText: {
    background:
    '-webkit-linear-gradient(100.12deg, #1C7BDF   -22.5%, #27C69E 122.07%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  }
};

const Dark = {
  primaryDarkColor: '#10213F',
  primaryLightColor: '#586479',
  primaryDarkText: '#0B162B',
  primaryLightText: '#8E8E93',
  secondaryDarkColor: '#F16722',
  secondaryLightColor: '#FFF4EF',
  secondaryDarkText: '#F16722',
  secondaryLightText: '#8E8E93',
  background: '#F8F9FB',
  secondaryBackground: '#F0F5FF',
  inputBackground: '#F2F4F7',
  buttonTextColor: '#FFFFFF',
  outline: '#F1F1F4',
  focusOutLine: '#CFCFCF',
  otpOutLine: '#EBEBEB',
  cancel: '#E73F3F',
  gradientButtonColor:
    'linear - gradient(100.12deg, #27C69E - 22.5 %, #1C7BDF 122.07 %)',
  inputHeadingColor: '#808191',
  primaryLinkColor: '#27C69E',
  tabInactiveColor: '#efefef',
  primaryBtnColor: '#1B1D21',
  inputPlaceholder: '#808191',
  inputTextColor: '#11142d',
  activeTabColor: '#1C7BDF',
  socialBtnColor: '#0bb78c',
  checkboxActive: '#7fba7a',
  iconBadgeBackground: '#ff754c',
  primaryCardColor: '#F0F3F6',
  primaryBorderColor: 'rgba(128, 129, 145, 0.6)',
  secondaryBorderColor: '#1c7bdf',
  primaryNumberBg: 'rgba(28, 123, 212, 0.2)',
  error: '#EA5224',
  secondaryBtnColor: '#e4e4e4',
  lowMatch: '#F3A81D',
  btnGradientText: {
    background:
    '-webkit-linear-gradient(100.12deg, #1C7BDF   -22.5%, #27C69E 122.07%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  }
};

export default isDark ? Dark : Light;
