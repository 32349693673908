import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  email_templates,
  explanations,
  feminine_coded_words,
  JOB_SCORE_STATUS,
  masculine_coded_words,
  possible_codings,
  subscriptionFeatures,
  subscriptionPlan,
  timeZones,
} from './constant.utils';
import html2pdf from 'html2pdf.js';

export const getBaseURL = () => {
  let baseURL = 'http://localhost:8001';
  // console.log('process.env.REACT_APP_NODE_ENV', process.env.REACT_APP_NODE_ENV);
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    baseURL = 'http://localhost:8001';
  } else if (process.env.REACT_APP_NODE_ENV === 'stage') {
    baseURL = 'https://stage-api.askhugo.io';
  }
  else if (process.env.REACT_APP_NODE_ENV === 'production') {
    baseURL = 'https://api.askhugo.io';
  }
  return baseURL;
};

export const useSetState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const newSetState = (newState: any) => {
    setState((prevState: any) => ({ ...prevState, ...newState }));
  };
  return [state, newSetState];
};

export const Failure = (text: string) => {
  console.log(text);
};

export const Success = (text: string) => {
  console.log(text);
};

export const modelError = (error: any) => {
  console.log(JSON.stringify(error.response));
  if (error.response.data.message) {
    return error.response.data.message;
  } else if (error.message) {
    return error.message;
  } else if (error.response) {
    return error.response;
  } else {
    return error;
  }
};

export const toastifyError = (text?: any) => {
  if (!_.isEmpty(text)) {
    toast.error(text, {
      position: 'top-center',
      // autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
};

export const toastify = (text?: any) => {
  toast(text, {
    position: 'top-center',
    // autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const uploadFile = (file, signedRequest, url, cb) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', signedRequest);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        let data = {
          url: url,
        };
        cb(data);
      } else {
        cb({ error: 'Upload failed' });
      }
    }
  };
  xhr.send(file);
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const getTimeZones = () => {
  let timeZone: any = [];
  timeZones.map((item: any) => timeZone.push({ label: item, value: item }));
  return timeZone;
};

export const getDropdownData = (data: any) => {
  let array: any = [];
  data?.map((item: string) => {
    if (item !== '') {
      array.push({ label: item, value: item });
    }
  });
  return array;
};

export const getDataFromDropdown = (data: any) => {
  let array: any = [];
  data?.map((item: any) => array.push(item.value));
  return array;
};

export const getDropdownValue = (data: string) => {
  // if (!_.isEmpty(data)) {
  return { label: data, value: data };
  // } else {
  //   return { label: '', value: '' };
  // }
};

export const useAuth = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return true;
  } else {
    return false;
  }
};

export const filterObjectByKeys = (object = {}, keys) => {
  return _.omit(object, keys);
};

export const getExperience = (type?: string) => {
  let exp: any = [];
  if (type == 'max') {
    for (let i = 1; i <= 30; i++) {
      exp.push(i);
    }
    return exp;
  } else {
    for (let i = 0; i <= 30; i++) {
      exp.push(i);
    }
    return exp;
  }
};

export const filterByKeys = (obj, keys: any = []) => {
  const filtered: any = {};
  keys.forEach((key) => {
    if (obj?.hasOwnProperty(key)) {
      filtered[key] = obj[key];
    }
  });
  return filtered;
};

export const mergeArrayWithoutDuplicates = (array1: any, array2: any) => {
  if (array1.length == 0) {
    return array2;
  } else if (array2.length == 0) {
    return array1;
  } else if (array1.length > 0 && array2.length > 0) {
    array1.map((element1: any, index1: number) => {
      array2.map((element2: any, index2: number) => {
        if (element1._id == element2._id) {
          array1[index1] = array2[index2];
        } else {
        }
      });
    });
    return Array.from(new Set([...array1, ...array2]));
  }
};
export const isGenderBiased = (text: string) => {
  let masculineWords = masculine_coded_words;
  let feminineWords = feminine_coded_words;
  let masculineCount = 0;
  let feminineCount = 0;
  let textWords = text.split(' ');
  let genderbiasedObject = {
    masculine: {},
    feminine: {},
  };

  for (var i = 0; i < textWords.length; i++) {
    if (masculineWords.includes(textWords[i])) {
      masculineCount++;
      if (genderbiasedObject.masculine[textWords[i]] == undefined) {
        genderbiasedObject.masculine[textWords[i]] = 1;
      } else {
        genderbiasedObject.masculine[textWords[i]] =
          genderbiasedObject.masculine[textWords[i]] + 1;
      }
    } else if (feminineWords.includes(textWords[i])) {
      feminineCount++;
      if (genderbiasedObject.feminine[textWords[i]] == undefined) {
        genderbiasedObject.feminine[textWords[i]] = 1;
      } else {
        genderbiasedObject.feminine[textWords[i]] =
          genderbiasedObject.feminine[textWords[i]] + 1;
      }
    }
  }

  let genderStrength = {};
  if (masculineCount > feminineCount) {
    if (masculineCount - feminineCount > 1) {
      genderStrength['strength'] = possible_codings[4];
      genderStrength['reason'] = explanations[possible_codings[4]];
    } else {
      genderStrength['strength'] = possible_codings[3];
      genderStrength['reason'] = explanations[possible_codings[3]];
    }
  } else if (masculineCount < feminineCount) {
    if (feminineCount - masculineCount > 1) {
      genderStrength['strength'] = possible_codings[0];
      genderStrength['reason'] = explanations[possible_codings[0]];
    } else {
      genderStrength['strength'] = possible_codings[1];
      genderStrength['reason'] = explanations[possible_codings[1]];
    }
  } else {
    genderStrength['strength'] = possible_codings[2];
    genderStrength['reason'] = explanations[possible_codings[2]];
  }

  genderbiasedObject.feminine = Object.entries(genderbiasedObject.feminine);
  genderbiasedObject.masculine = Object.entries(genderbiasedObject.masculine);

  let genderData: any = {
    genderStrength: genderStrength,
    genderData: [
      { gender: 'Masculine coded words', data: genderbiasedObject.masculine },
      { gender: 'Feminine coded words', data: genderbiasedObject.feminine },
    ],
  };
  return genderData;
};

export const gets3FileName = (file: any) => {
  let filename: string = file.split('/').pop();
  return filename.split('_').pop();
};

export const calculateTimeForComments = (date) => {
  var datePast: any = new Date(date);
  var dateNow: any = new Date();

  var seconds = Math.floor((dateNow - datePast) / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);
  var weeks = Math.floor(days / 7);
  var years = Math.floor(days / 365);

  // hours = hours-(days*24);
  // minutes = minutes-(days*24*60)-(hours*60);
  // seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
  if (seconds < 60) {
    return 'Just now';
  } else if (minutes < 60) {
    return minutes + 'm';
  } else if (hours < 24) {
    return hours + 'h';
  } else if (days < 7) {
    return days + 'd';
  } else if (weeks < 52) {
    return weeks + 'w';
  } else {
    return years + 'y';
  }
};

export const jobScoreMatch = (score: number) => {
  if (score < 50) {
    return JOB_SCORE_STATUS.LOW_MATCH;
  } else if (score < 74 && score > 50) {
    return JOB_SCORE_STATUS.AVERAGE_MATCH;
  } else if (score >= 75) {
    return JOB_SCORE_STATUS.HIGH_MATCH;
  }
};

export const addCommasToCurrency = (value: any) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberFromCurrency = (value: any) => {
  var regExp = new RegExp('^[0-9,/]+$');
  if (regExp.test(value)) {
    return true;
  } else {
    false;
  }
};

export const removeCommasFromCurrency = (value: any) => {
  let number = parseFloat(value?.replaceAll(',', ''));
  return number;
};

export const subtractTwoDates = (start, end) => {
  const date1: any = new Date(start);
  const date2: any = new Date(end);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = diffTime / (1000 * 60 * 60 * 24);
  return parseFloat(diffDays.toFixed(1));
};

export const getDifferenceInHours = (start, end) => {
  let dt2 = new Date(end)
  let dt1 = new Date(start)
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  let diffInHours = Math.abs(Math.round(diff));
  return diffInHours
};

export const getUrlType = (url: any) => {
  if (url.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/) != null) {
    return 'image';
  } else if (url.match(/\.(mp4|MP4|mov|MOV)$/) != null) {
    return 'video';
  }
};

export const checkAgency = () => {
  let agency = localStorage.getItem('agency')
  if (agency) {
    return true
  } else {
    return false
  }
}

export const mergeTemplates = (data: any) => {
  let templates: any = [];
  let default_templates = email_templates;
  data.map((item: any, idx: number) => {
    let index = _.findIndex(default_templates, { name: item?.name });
    if (index !== -1) {
      templates.push(item);
      default_templates.splice(index, 1);
    } else {
      templates.push(item);
    }
  });
  return [...templates, ...default_templates];
};


export const isoToDateConvert = (data: any) => {
  const date = new Date(data)
  const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = date
    .toLocaleDateString(undefined, options)
    .replace(',', '');
  return formattedDate;
};

export const unixTimeToDateConvert = (data: number, year?: boolean) => {
  const milliseconds = data * 1000;
  const date = new Date(milliseconds);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  if (year) {
    const year = date.toLocaleString('default', { year: 'numeric' });
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  } else {
    const formattedDate = `${day} ${month}`;
    return formattedDate;
  }
};

export const emailCheck = (email: string) => {
  var email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (_.isEmpty(email)) {
    return { message: 'Please Enter Email', isEmail: false };
  } else if (!email_regex.test(email)) {
    return { message: 'InValid Email', isEmail: false };
  } else {
    return { isEmail: true };
  }
};

export const createPdf = (element: string) => {
  var opt = {
    // margin:  0.5,
    filename: `invoice.pdf`,
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
  };
  html2pdf().set(opt).from(element).save();
};

export const phoneCheck = (phoneNumber: string) => {
  const phoneRegex: any = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return phoneRegex.test(phoneNumber);
};

// string remove the html element
export const rmHtml = (html: string) => {
  var tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  return tempElement.textContent;
};

// remove +91/91
export const phoneNumberCheck = (phone_number: string) => {
  if (!_.isEmpty(phone_number)) {
    const regex = /^(?:\+91|91)/;
    const cleanedPhoneNumber = phone_number.replace(regex, '');
    return cleanedPhoneNumber;
  }
  return '';
};

export const subscriptionCheck = (planName: string, featureName: string) => {
  return subscriptionPlan.includes(planName)  && subscriptionFeatures.includes(featureName);
};