import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets, PrimaryButton } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './candidate_info.scss';

interface ICandidateInfo {
  data?: any;
  className?: String;
  wrapperClassName?: String;
  profile?: string;
  onClick?: any;
  editIcon?: boolean;
  distance?: number;
}

const CandidateInfo = (props: ICandidateInfo) => {
  // Redux
  const userState = useSelector((state: any) => state.user);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  //Logic
    return (
    <div className={`candidate_info_container ${props.className} || ''`}>
      <div className=".candidate_info_component_header">
        {/* <div className="candidate_info_header">Candidate Info</div> */}
        {props.editIcon && (
          <div onClick={props.onClick} className="candidate_info_icon_wrapper">
            <img className="candidate_info_icon" src={Assets.edit} />
          </div>
        )}
      </div>
      <div className={`candidate_info_wrapper ${props.wrapperClassName} || ''`}>
        <div className="candidate_info_profile">
          <div className="candidate_info_profile_picture">
            <img
              src={props.profile || Assets.profile_placeholder}
              alt={'profile picture'}
              className="candidate_image"
            />
          </div>
        </div>
        <div className="candidate_info_information">
          {/* {Object.keys(props?.data).map((key) => {
            if (key == '_id' || key == 'resume') {
              return null;
            } else {
              return ( */}
          {props.data.first_name && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">First Name</div>
              <div className="candidate_info_value">
                {props.data.first_name}
              </div>
            </div>
          )}
          {props.data.last_name && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Last Name</div>
              <div className="candidate_info_value">{props.data.last_name}</div>
            </div>
          )}
          {props.data.email && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Email</div>
              <div className="candidate_info_value">{props.data.email}</div>
            </div>
          )}
          {props.data.phone && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Phone</div>
              <div className="candidate_info_value">{props.data.phone}</div>
            </div>
          )}
          {props.data.current_salary > 0 && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Current Salary</div>
              <div className="candidate_info_value">
                {props.data.current_salary}
              </div>
            </div>
          )}
          {props.data.expected_salary > 0 && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Expected Salary</div>
              <div className="candidate_info_value">
                {props.data.expected_salary}
              </div>
            </div>
          )}
          {props.data.notice_period || props.data.notice_period ===0 && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Notice Period</div>
              <div className="candidate_info_value">
                {props.data.notice_period}
              </div>
            </div>
          )}
          {props.data.reason_for_job_change && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">
                Reasons For Job Change
              </div>
              <div className="candidate_info_value">
                {props.data.reason_for_job_change}
              </div>
            </div>
          )}
          {props.data.gender && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Gender</div>
              <div className="candidate_info_value">{props.data.gender}</div>
            </div>
          )}
          {props.data.source && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Source</div>
              <div className="candidate_info_value">{props.data.source}</div>
            </div>
          )}
          {props.data.address && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Address</div>
              <div className="candidate_info_value">{props.data.address}</div>
            </div>
          )}
          {/* ); */}
          {/* } */}
          {/* })} */}
          {props.distance && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Distance</div>
              <div className="candidate_info_value">{props.distance} Km</div>
            </div>
          )}
          {props.data.willing_to_relocate && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Willing to relocate</div>
              <div className="candidate_info_value">
                {props.data.willing_to_relocate}
              </div>
            </div>
          )}
          {props.data.country && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Country</div>
              <div className="candidate_info_value">{props.data.country}</div>
            </div>
          )}
          {props.data.state && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">State</div>
              <div className="candidate_info_value">{props.data.state}</div>
            </div>
          )}
          {props.data.city && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">City</div>
              <div className="candidate_info_value">{props.data.city}</div>
            </div>
          )}
          {props.data.relevant_experience || props.data.relevant_experience ===0  && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Relevant Experience</div>
              <div className="candidate_info_value">
                {props.data.relevant_experience}
              </div>
            </div>
          )}
         {props.data.total_experience || props.data.total_experience ===0  && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Total Experience</div>
              <div className="candidate_info_value">
                {props.data.total_experience}
              </div>
            </div>
          )}
          {props.data.remote_work_preference && (
            <div className="candidate_position_container container_padding">
              <div className="candidate_info_headings">Remote Work Preference</div>
              <div className="candidate_info_value">
                {props.data.remote_work_preference}
              </div>
            </div>
          )}
          {/* <div className="candidate_name_container container_padding">
            <div className="candidate_info_headings">Full Name</div>
            <div className="candidate_info_value">{props.data.name}</div>
          </div> */}

          {/* <div className="candidate_email_container container_padding">
            <div className="candidate_info_headings">Email</div>
            <div className="candidate_info_value">{props.data.email}</div>
          </div>
          <div className="candidate_location_container container_padding">
            <div className="candidate_info_headings">Current Location</div>
            <div className="candidate_info_value">{props.data.location}</div>
          </div>
          <div className="candidate_gender_container container_padding">
            <div className="candidate_info_headings">Gender</div>
            <div className="candidate_info_value">{props.data.gender}</div>
          </div>
          <div className="candidate_relocation_container container_padding">
            <div className="candidate_info_headings">Open To Relocation</div>
            <div className="candidate_info_value">{props.data.relocation}</div>
          </div>
          <div className="candidate_contact_container container_padding">
            <div className="candidate_info_headings">Contact Number</div>
            <div className="candidate_info_value">{props.data.contact}</div>
          </div>
          <div className="candidate_compensation_container container_padding">
            <div className="candidate_info_headings">Current Compensation</div>
            <div className="candidate_info_value">
              {props.data.compensation}
            </div>
          </div>
          <div className="candidate_resume_container container_padding">
            <div className="candidate_info_headings">Resume Source</div>
            <div className="candidate_info_value">{props.data.resume}</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CandidateInfo;
