import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserRole } from 'utils/constant.utils';
import {
  calculateTimeForComments,
  checkAgency,
  mergeArrayWithoutDuplicates,
  toastifyError,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  PrimaryButton,
  Colors,
  Navbutton,
  Assets,
  Searchbar,
  Dropdown,
  ToggleHeader,
  Loader,
} from 'utils/imports.utils';
import { getRecquisitionList, getUserDetails } from 'utils/redux.utils';
import './nav_bar.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDebouncedCallback } from 'use-debounce';
import _ from 'lodash';
import connectSocket from 'utils/socket.utils';

let socket:any
const Navbar = (props: any) => {
  // Redux
  const userState = useSelector((state: any) => state.user);
  const headerValues = ['Requisitions', 'Candidates'];
  // State
  const [state, setState] = useSetState({
    hover: false,
    show: false,
    showNotification: false,
    search: false,
    active:'Requisitions',
    search_value: '',
    limit: 50,
    skip: 0,
    loadMore: true,
    notifications: [],
    unread_count: 0,
    globalRequisition:[],
    globalResume:[]
  });


  //Hooks
  const debounced = useDebouncedCallback((search_value) => {
    setState({ search_value });
  }, 500);

  useEffect(() => {
    getNofications()
    socket =  connectSocket();
    socket.on('notification-list', () => {
    getNofications()
    });
  }, []);
  
  useEffect(() => {
    globalSearch(state.skip);
  }, [state.search_value]);

  const navigate = useNavigate();
  let agency = localStorage.getItem('agency')
  // Network req
  const globalSearch = async (skip: number) => {
    try {
      setState({ loading: true });
      let body: any = {
        limit: state.limit,
        skip: skip,
        search: state.search_value,
      };
      if (agency!=='') {
        body.agency = agency
      }
      const res: any = await Models.jobRecquisition.globalSearch(body);
      setState({ loading: false,globalRequisition:res.data.requisitions.docs,globalResume:res.data.resumes.docs});
    } catch (error: any) {
      setState({ loading: false,globalRequisition: [],globalResume: []});
    }
  };

  const getNofications = async () => {
    try {
      const res: any = await Models.notification.getManyNotification({
        limit: state.limit,
        skip: 0,
      });
      let unread = res.data.docs.filter((item:any) => {if(!item.seen){return true}})
      setState({ notifications: res.data.docs, unread_count: unread.length });
    } catch (error) {
      console.log('error', error);
    }
  };

  //Logic
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  const updateNotifications = async () => {
    try {
      const res: any = await Models.notification.updateNotification()
    } catch (error) {
      console.log('error', error);
    }
  };


  const notificationOnClick = async () => {
    if (state.unread_count > 0) {
      setState({ unread_count: 0 });
      await updateNotifications();
    }
    setState({ showNotification: !state.showNotification });
  };
  
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setState({ search: false, showNotification: false, show: false });
      }}>
      <div className="navbar">
        {UserRole.SUPER_ADMIN !== userState.auth.role &&<>
        <div className="search margin_right">
          {!checkAgency() && (
            <Searchbar
              onChange={(search_value: string) => {
                debounced(search_value);
                // globalSearch(state.skip, search_value);
                // setState({ search_value });
              }}
              onPress={() => {
                setState({
                  search: true,
                  showNotification: false,
                  // globalRequisition: [],
                  // globalResume: [],
                });
              }}
              // value={state.search_value}
              name={'search'}
            />
          )}
          {state.search && (
            <div className="navbar_search_content">
              <div className="navbar_search_content_container">
                <div className="toggle_header">
                  <ToggleHeader
                    data={headerValues}
                    onChange={(item: string) => setState({ active: item })}
                    active={state.active}
                  />
                </div>
                <div className="navbar_content_wrapper">
                  {state.loading ? (
                    <Loader />
                  ) : state.active === 'Requisitions' ? (
                    state.globalRequisition.length > 0 ? (
                      state.globalRequisition.map(
                        (item: any, index: number) => {
                          return (
                            <div
                              className="navbar_search_list"
                              onClick={() => {
                                setState({ search: '' });
                                navigate(`/requisition?id=${item._id}`);
                              }}>
                              <div className="navabr_search_req_id">
                                JR - {item.id}
                              </div>
                              <div className="navbar_search_req_title">
                                {item.job_title}
                              </div>
                            </div>
                          );
                        },
                      )
                    ) : (
                      <div className="navbar_search_text">No data found</div>
                    )
                  ) : state.active === 'Candidates' ? (
                    state.globalResume.length > 0 ? (
                      state.globalResume.map((item: any, index: number) => (
                        <div
                          className="navbar_search_list_candidate"
                          onClick={() => {
                            setState({ search: '' });
                            navigate(`/candidate?resume_id=${item._id}`);
                          }}>
                          <div className="search_candidate_image">
                            <img
                              src={
                                item.profile_picture ||
                                Assets.profile_placeholder
                              }
                              height={50}
                              width={50}
                            />
                          </div>
                          <div className="search_name_email_wrapper">
                            <div className="navabr_search_req_id">
                              {item.first_name} {item.last_name}
                            </div>
                            <div className="navbar_search_req_title">
                              {item.email}
                            </div>
                            <div className="navbar_search_req_title">
                              {item.phone}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="navbar_search_text">No data found</div>
                    )
                  ) : (
                    ''
                  )}
                </div>

                {/* {state.loadMore && (
                <div
                  className="search_load_more_wrapper"
                  onClick={() => setState({ skip: state.skip + 50 })}>
                  Load more
                </div>
              )} */}
              </div>
            </div>
          )}
        </div>
        <div className="create_button margin_right">
          {/* <div className="create_button_wrapper">
            <PrimaryButton
              text={'Create New'}
              icon={Assets.add}
              onClick={() => setState({ show: !state.show })}
              // className={'create_btn'}
            />
            {state.show && (
              <div className="create_dropdown">
                <div
                  className="create_dropdown_list_wrapper"
                  onClick={() => {
                    setState({ show: false });
                    navigate('/new_job_requisition');
                  }}>
                  Create New Job Requsition
                </div>
                {userState?.auth?.role == UserRole.ADMIN && (
                  <div
                    className="create_dropdown_list_wrapper"
                    onClick={() => {
                      setState({ show: false });
                      navigate('/settings/manage_users');
                    }}>
                    Create New User
                  </div>
                )}
              </div>
            )}
          </div> */}
        </div>
        {userState?.auth?.org?.subscribed&&!userState?.auth?.is_agency&&
        <div className="subscription_vacancies">
          <div className="vacancies_heading">
         Available Vacancies:
          </div>
          <div className="vacancies_balance">{userState?.auth?.org[userState?.auth?.org?.plan]?.quantity}</div>
          <div className="vacancies_total">
       /{userState?.auth?.org[userState?.auth?.org?.plan]?.total_quantity}
          </div>
        </div>}
        <div className="notification_icon margin_right">
          <div className="notification_icon_container">
          <Navbutton icon={Assets.bell} onClick={() => notificationOnClick()} />
          {state.unread_count > 0 && <div className="notification_badge">{state.unread_count}</div>}
          </div>
          {state.showNotification && (
            <div className="navbar_notification_wrapper">
              <div className="notification_title">Recent notification</div>
              <div className="navbar_notification_list_container">
                <div className="notification_list_wrapper">
                  {state.notifications.map((item: any) => (
                    <div
                      className="notification_list_element"
                      onClick={() => {
                        if (item.redirect) {
                          navigate(item.redirect);
                          setState({ showNotification: false });
                        }
                      }}>
                      {/* <div className="notification_profile_wrapper">
                      <img src={Assets.profile_placeholder} alt="" />
                    </div> */}
                      <div className="notification_text_wrapper">
                        <div className="notification_text">{item.body}</div>
                      </div>
                      <div className="notification_time_wrapper">
                        {calculateTimeForComments(item.created_at)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        </>}
        <div
          className="navbar_logout_wrapper"
          onMouseOver={() => setState({ hover: true })}
          onMouseLeave={() => setState({ hover: false })}>
          <div className="navbar_user_image">
            <img
              src={
                userState?.auth?.profile_picture || Assets.profile_placeholder
              }
              width={35}
              className="navbar_profile_image"
              height={35}
            />
          </div>
          <div className="navbar_logout_text">
            <div className="navbar_logout_user_name">
              {userState?.auth?.first_name} {userState?.auth?.last_name}
            </div>
          </div>
          <div className="navbar_logout_icon" onClick={handleLogout}>
            <img src={Assets.logout} width={20} height={20} />
          </div>
          {userState?.auth?.first_name &&
            userState?.auth?.job_title &&
            state.hover && (
              <div className="navabr_profile_hover_container">
                <div className="navbar_profile_user_name">
                  {userState?.auth?.first_name} {userState?.auth?.last_name}
                </div>
                <div className="navbar_profile_user_job_title">
                  {userState?.auth?.job_title}
                </div>
              </div>
            )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Navbar;
