import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { toastify, toastifyError, useSetState } from 'utils/functions.utils';
import {
  Navbutton,
  Assets,
  Input,
  PrimaryButton,
  PdfViewer,
  Models,
  Colors,
  Loader,
  Validation,
} from 'utils/imports.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './bulk_upload_resume_modal.scss';
import _ from 'lodash';
import '../../index.scss';

const BulkUploadResumeModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();

  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    email: '',
    resumeData: {},
    bulkEmptyEmail: [],
    emptyEmailCount: 0,
    uploadResumeParse: false,
    errorArray: [],
  });

  // singleUploadResume
  const bulkUploadResume = async () => {
    try {
      setState({ uploadResumeParse: true });
      await Validation.candidateEmail.validate(
        { email: state.email },
        { abortEarly: false },
      );
      let body: any = state.resumeData;
      body.resume.email = state.email;
      let res: any = await Models.candidateModel.uploadBulkResume(body);
      // let filterEmptyEmail: any = state.bulkEmptyEmail.filter(
      //   (item: any) => item.resume.email === '',
      // );
      let bulkEmptyEmail: any = state.bulkEmptyEmail;
      let index: any = _.findIndex(bulkEmptyEmail, {
        resume: { resume: state.resumeData.resume.resume },
      });
      bulkEmptyEmail[index].uploaded = true;
      const filterEmptyEmail = _.filter(bulkEmptyEmail, {
        uploaded: true,
      }).length;
      setState({
        emptyEmailCount: filterEmptyEmail,
        uploadResumeParse: false,
        bulkEmptyEmail,
        resumeData: bulkEmptyEmail[index],
      });
      toastify('Candidate uploaded');
    } catch (error: any) {
      setState({
        uploadResumeParse: false,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      toastifyError(error.message);
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  //  candidateEmail
  const candidateEmail = (value: any) => {
    setState({ email: value });
    let bulkEmptyEmail: any = state.bulkEmptyEmail;
    let index: any = _.findIndex(bulkEmptyEmail, {
      resume: { resume: state.resumeData.resume.resume },
    });
    bulkEmptyEmail[index].resume.email = value;
    setState({ bulkEmptyEmail });
  };

  // fileFormat
  const fileFormat = (data: any) => {
    return data.resume?.resume?.split('/').pop().split('.').pop() === 'pdf';
  };

  //Hooks
  useEffect(() => {
    setState({
      bulkEmptyEmail: props.data,
      resumeData: { ...props.data[0] },
      emptyEmailCount: props.data.length,
      notUploadedResumes: props.fileCount - state.bulkEmptyEmail.length,
    });
  }, [props.data]);

  useEffect(() => {
    setState({ email: state.resumeData?.resume?.email });
  }, [state.resumeData]);

  // Network req
  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          props.onChange(),
            setState({
              isModalOpen: false,
              bulkEmptyEmail: [],
              resumeData: {},
              emptyEmailCount: 0,
              email: '',
            });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'bulkOverlay',
          modal: 'bulkUploadModal',
        }}>
        <div className="bulk_upload_container">
          <div className="bulk_upload_wrapper">
            <div className="bulk_upload_header">
              <div className="bulk_upload_heading">
                Uploaded{' '}
                {props.fileCount -
                  _.filter(state.bulkEmptyEmail, { resume: { email: '' } })
                    .length}
                /{props.fileCount}
              </div>
              <Navbutton
                icon={Assets.black_close}
                onClick={() => {
                  props.onChange();
                  setState({
                    isModalOpen: false,
                    bulkEmptyEmail: [],
                    resumeData: {},
                    emptyEmailCount: 0,
                    email: '',
                  });
                }}
              />
            </div>
            <div className="bulk_upload_resume_panel">
              <div className="bulk_upload_resume">
                {state.bulkEmptyEmail.map((item: any, index: number) => {
                  return (
                    <div
                      className="upload_resume_panel"
                      key={index}
                      onClick={() => setState({ resumeData: item })}>
                      <div className="resume_file_panel">
                        <div className="resume_format_line">
                          <div
                            className="select_line"
                            style={{
                              background:
                                item.resume.resume ===
                                state.resumeData?.resume?.resume
                                  ? Colors.activeTabColor
                                  : Colors.buttonTextColor,
                            }}></div>
                          <img
                            src={
                              fileFormat(item)
                                ? Assets.pdf_icon
                                : Assets.doc_icon
                            }
                            alt="no img"
                          />
                        </div>
                        <div className="resume_file_format">
                          <div className="resume_file_name">
                            {item.resume.resume
                              .split('/')
                              .pop()
                              .split('_')
                              .pop()}
                          </div>
                          <div className="resume_format_size">
                            <div className="resume_format_name">
                              {fileFormat(item) ? 'PDF' : 'DOC'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="resume_check_panel">
                        <img
                          src={
                            item?.uploaded
                              ? Assets.check_icon
                              : Assets.wrong_icon
                          }
                          alt="no img"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="resume_preview">
                <div className="preview_title">Preview</div>
                {!_.isEmpty(state.resumeData) &&
                fileFormat(state.resumeData) ? (
                  <PdfViewer file={state.resumeData?.resume.resume} />
                ) : (
                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${state.resumeData?.resume?.resume}`}
                    width="98%"
                    height="98%"
                    frameBorder="0">
                    This is an embedded document by AskHugo.
                  </iframe>
                )}
              </div>
              <div className="candidate_email_wrapper">
                <div className="candidate_title">Candidate Email ID</div>
                <div className="candiate_email">
                  <Input
                    onChange={(value: any) => {
                      candidateEmail(value);
                    }}
                    type="text"
                    value={state.email}
                    name="email"
                    disabled={state.resumeData?.uploaded ? true: false}
                  />
                </div>
                <div className="email_send_button">
                  {state.uploadResumeParse ? (
                    <div style={{ width: '80px', marginRight: '20px' }}>
                      <Loader />
                    </div>
                  ) : (
                    <PrimaryButton
                      disabled={state.resumeData?.uploaded ? true : false}
                      text={state.resumeData?.uploaded ? 'Uploaded' : 'Submit'}
                      className={'send_bulk_upload_button'}
                      onClick={() => bulkUploadResume()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default BulkUploadResumeModal;
