import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Models,
  PrimaryButton,
  Input,
  Loader,
  TextArea,
  Validation,
} from 'utils/imports.utils';
import { getUserDetails } from 'utils/redux.utils';
import './organization.screen.scss';
import { toastify } from 'utils/functions.utils';
import _ from 'lodash';

const Organization = (props: any) => {
  // Redux
  const orgState = useSelector((state: any) => state.user.auth?.org);

  // State
  const [state, setState] = useSetState({
    loading: false,
    company_name: '',
    company_description: '',
    errorArray: [],
  });

  //Hooks
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      setState({ loading: true });
      const userId: string | any = localStorage.getItem('user_id');
      const res: any = await Models.auth.viewUser(userId);
      // dispatch
      getUserDetails(res.data);
      if (!_.isEmpty(res.data?.org?.company_details)) {
        setState({
          company_name: res.data?.org?.company_details?.company_name,
          company_description:
            res.data?.org?.company_details?.company_description,
        });
      }
      setState({ loading: false });
    } catch (error: any) {
      console.log(error);
    }
  };

  const saveOrgDetails = async () => {
    try {
      const body = {
        org_id: orgState._id,
        company_details: {
          company_name: state.company_name,
          company_description: state.company_description,
        },
      };
      await Validation.editOrganizationSchema.validate(body, {
        abortEarly: false,
      });
      await Models.organization.editOrganization(body);
      toastify('organization details saved');
    } catch (error: any) {
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  if (state.loading) return <Loader />;
  return (
    <div className="organization_container">
      <div className="organization_wrapper">
        <div className="heading">Organization Details</div>
        <>
          <div className="company_details">
            <div className="input_heading">
              Company Name<span className="required_field">*</span>
            </div>
            <Input
              type="text"
              onChange={(company_name: string) => {
                setState({ company_name });
              }}
              value={state.company_name}
              name={'company_details.company_name'}
              error={state.errorArray}
            />
          </div>
          <div className="company_description">
            <div className="input_heading">
              Company Description<span className="required_field">*</span>
            </div>
            <TextArea
              onChange={(e) =>
                setState({ company_description: e.target.value })
              }
              name={'company_details.company_description'}
              value={state.company_description}
              className="company_description"
              error={state.errorArray}
            />
          </div>
        </>

        <div className="save_and_continue_button">
          <PrimaryButton
            text={'Save and Continue'}
            className={'save_and_continue'}
            onClick={saveOrgDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default Organization;
