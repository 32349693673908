import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Dropdown } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './offer_card.scss';

const OfferCard = (props: any) => {
  // Redux

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req

  //Logic

  return (
    <div className="offercard_container">
      <div className="offercard_header">
        <div className="offercard_heading_text">{props.data.title}</div>
        <div className="offercard_header_dropdown">
          <Dropdown
            data={props.filterData || []}
            // className={'offercard_dropdown'}
            setActive={(value: any) => props.setFilter(value)}
            value={props.value}
          />
        </div>
      </div>
      <div className="offercard_wrapper">
        <div className="offercard_value_container">
          <div className="offercard_type">New</div>
          <div className="offercard_value">{props.data.new}</div>
        </div>
        <div className="offercard_value_container">
          <div className="offercard_type">Interviewing</div>
          <div className="offercard_value">{props.data.interviewing}</div>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
