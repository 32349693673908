import {
  GET_FEEDBACK_DUES,
  GET_INTERVIEW,
  GET_MANY_INTERVIEW,
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {};

const InterviewReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_INTERVIEW:
      let newState = { ...state };
      newState = { ...newState, interview: action.payload };
      return newState;
    case GET_MANY_INTERVIEW:
      return { ...state, interview_list: action.payload };
    case GET_FEEDBACK_DUES:
      return { ...state, feedback_dues: action.payload };
    default:
      return state;
  }
};

export default InterviewReducer;
