import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CALENDAR,
  InterviewMedium,
  interview_rounds,
  MapTypeId,
} from 'utils/constant.utils';
import {
  getDropdownData,
  getDropdownValue,
  mergeTemplates,
  toastify,
  toastifyError,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  PrimaryButton,
  Dropdown,
  DatePickerComponent,
  TextEditor,
  Attachment,
  CheckBox,
  Input,
  TimePickerComponent,
  FileUpload,
  GoogleMapsPicker,
  Validation,
  Tag,
  Colors,
} from 'utils/imports.utils';
import {
  getAllEmailTemplates,
  getAllWorkLocations,
  getCandidate,
  getInterviewById,
} from 'utils/redux.utils';
import './interview_setup.screen.scss';
import momentTz from 'moment-timezone';

const InterviewSetup = (props: any) => {
  // reference
  const inputFileRef: any = useRef();
  const candidateUploadRef: any = useRef();
  const targetRef: any = useRef();
  // Redux
  const emailTemplates = useSelector(
    (state: any) => state.email.email_templates,
  );
  const candidate = useSelector((state: any) => state.candidate.candidate);
  const user = useSelector((state: any) => state.user.auth);
  // State
  const [state, setState] = useSetState({
    interview_rounds: '',
    panelist_email: '',
    candidate_email: '',
    panelist_attachments: [],
    candidate_attachments: [],
    from: '',
    to: '',
    date: new Date(),
    interview_medium: '',
    address: '',
    video_call_link: '',
    phone: false,
    email_templates: [],
    email_template_body: '',
    in_person: false,
    video: false,
    phone_number: '',
    start_time: moment(),
    end_time: moment().add(1, 'hour'),
    errorArray: [],
    work_locations: [],
    panelists: [],
    calendar_timeline_width: '',
    panelist_data: [],
    candidate: {},
    limit: 200,
  });

  //Hooks
  useEffect(() => {
    fetchCandidate();
    getEmailTemplates();
    getWorkLocations();
    if (edit) {
      fetchInterviewById();
    }
  }, []);

  useEffect(() => {
    getPanelistData(state.candidate);
  }, [state.end_time, state.start_time]);

  const query = useQuery();
  const navigate = useNavigate();
  const candidate_id = query.get('id');
  const interview_id = query.get('interview_id');
  const edit = query.get('edit');
  const req_id = query.get('req_id');
  const ref: any = React.createRef();
  // Network req
  const createInterview = async () => {
    try {
      setState({ loader: true });
      let panelists: any = [];
      state.panelists.map((member: any) => panelists.push(member));
      const req_id = query.get('req_id');
      let body: any = {
        candidate: candidate_id,
        name: state.interview_rounds.value,
        date: state.date,
        interview_medium: state.interview_medium,
        panelist_email: state.panelist_email,
        candidate_email: state.candidate_email,
        from: new Date(state.start_time),
        to: new Date(state.end_time),
        panelists,
        requisition: req_id,
      };
      if (state.in_person && state.address?.value.length > 0) {
        body.address = state.address?.value;
      }
      if (state.video && state.video_call_link.length > 0) {
        body.video_call_link = state.video_call_link;
      }
      if (state.panelist_attachments.length > 0) {
        body.panelist_attachments = state.panelist_attachments;
      }
      if (state.candidate_attachments.length > 0) {
        body.candidate_attachments = state.candidate_attachments;
      }
      if (state.phone && state.phone_number.length > 0) {
        body.phone = state.phone_number;
      }
      await Validation.setupInterview.validate(body, { abortEarly: false });
      const res: any = await Models.interview.createInterview(body);
      toastify('Interview scheduled');
      navigate('/home');
      setState({ loader: false });
    } catch (error: any) {
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
        loader: false,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const getEmailTemplates = async () => {
    try {
      const res: any = await Models.email.getAllEmail({ limit: 200 });
      getAllEmailTemplates(res.data);
      let email_templates: any = [];
      const templates = mergeTemplates(res.data.docs);
      templates.map((item: any) =>
        email_templates.push({
          label: item.name,
          value: item.body,
        }),
      );
      setState({
        email_templates,
      });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getWorkLocations = async () => {
    try {
      const res: any = await Models.workLocation.getAllLocation({ limit: 200 });
      getAllWorkLocations(res.data);
      let work_locations: any = [];
      res?.data?.docs.map((item: any) =>
        work_locations.push({
          value: item?._id,
          label: item?.address,
        }),
      );
      setState({ work_locations });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const fetchInterviewById = async () => {
    try {
      const body = {
        interview_id,
      };
      const res: any = await Models.interview.getInterview(body);
      let panelists: any = [];
      res.data?.panelists?.map((member: any) =>
        panelists.push({
          id: member._id,
          profile_picture: member.profile_picture,
          name: `${member.first_name} ${member.last_name}`,
        }),
      );
      setState({
        interview_rounds: getDropdownValue(res.data.name),
        date: new Date(res.data?.date),
        interview_medium: res?.data?.interview_medium,
        panelist_email: res.data?.panelist_email,
        candidate_email: res.data?.candidate_email,
        panelist_attachments: res.data?.panelist_attachments,
        candidate_attachments: res.data?.candidate_attachments,
        start_time: res.data?.from,
        end_time: res.data?.to,
        panelists,
      });
      if (res?.data?.interview_medium === InterviewMedium.IN_PERSON) {
        setState({
          interview_medium: InterviewMedium.IN_PERSON,
          address: {
            label: res.data?.address?.address,
            value: res.data?.address?._id,
          },
          in_person: true,
        });
      }
      if (res?.data?.interview_medium === InterviewMedium.VIDEO) {
        setState({
          interview_medium: InterviewMedium.VIDEO,
          video_call_link: res.data.video_call_link,
          video: true,
        });
      }
      if (res?.data?.interview_medium === InterviewMedium.PHONE) {
        setState({
          interview_medium: InterviewMedium.PHONE,
          phone_number: res.data.phone,
          phone: true,
        });
      }
      getInterviewById(res?.data);
    } catch (error) {
      toastifyError(error);
    }
  };

  const handleSaveInterview = async () => {
    try {
      setState({ loader: true });
      let body: any = {
        interview_id,
        name: state.interview_rounds.value,
        date: state.date,
        interview_medium: state.interview_medium,
        panelist_email: state.panelist_email,
        candidate_email: state.candidate_email,
        from: new Date(state.start_time),
        to: new Date(state.end_time),
        panelists: state.panelists,
      };
      if (state.in_person && state.address?.value.length > 0) {
        body.address = state.address?.value;
      }
      if (state.video && state.video_call_link.length > 0) {
        body.video_call_link = state.video_call_link;
      }
      if (state.panelist_attachments.length > 0) {
        body.panelist_attachments = state.panelist_attachments;
      }
      if (state.candidate_attachments.length > 0) {
        body.candidate_attachments = state.candidate_attachments;
      }
      if (state.phone && state.phone_number.length > 0) {
        body.phone = state.phone_number;
      }
      const res: any = await Models.interview.editInterview(body);
      navigate('/home');
      getInterviewById(res?.data);
      setState({ loader: false });
    } catch (error) {
      setState({ loader: false });
      toastifyError(error);
    }
  };

  const fetchCandidate = async () => {
    try {
      let body = {
        candidate_id,
      };
      const res: any = await Models.candidateModel.getCandidate(body);
      setState({ candidate: res.data });
      getPanelistData(res.data);
      //dispatch
      if (!edit) {
        let panelist_attachments: any = state.panelist_attachments;
        panelist_attachments.push(res.data?.resume?.resume);
        if (res.data?.requisition?.job_description_file){
          panelist_attachments.push(res.data?.requisition?.job_description_file);
        }
        setState({ panelist_attachments });
      }
      getCandidate(res?.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic
  const handleUploadAttachments = (files: any, type: string) => {
    if (type == 'panelist') {
      let attachments: any = state.panelist_attachments;
      attachments.push(files);
      setState({
        panelist_attachments: attachments,
      });
    } else {
      let attachments: any = state.candidate_attachments;
      attachments.push(files);
      setState({
        candidate_attachments: attachments,
      });
    }
  };

  const getPanelistData = async (data: any) => {
    try {
      let panelists: any = [];
      data?.requisition?.hiring_team?.map((item: any) =>
        panelists.push(item._id),
      );
      data?.requisition?.recruiter?.map((item: any) =>
        panelists.push(item._id),
      );
      data?.requisition?.interviewer?.map((item: any) =>
        panelists.push(item._id),
      );
      if (user.default_calendar === CALENDAR.GOOGLE) {
        let res: any = await Models.auth.fetchEvents({ users: panelists });
        res.data.map((item: any) => {
          item.events.push({
            start_time: state.start_time,
            end_time: state.end_time,
            type: 'current_event',
          });
          return item;
        });
        setState({ panelists, panelist_data: res.data });
      }
      if (user.default_calendar === CALENDAR.OUTLOOK) {
        let res: any = await Models.auth.fetchOutlookEvents({
          users: panelists,
        });
        //ugly - fix (timezone)
        res.data.map((item: any) => {
          item.events.map((event: any) => {
            event.start_time = momentTz
              .tz(
                moment(event.start_time)
                  .add(330, 'minutes')
                  // .utc()
                  .format('YYYY-MM-DDTHH:mm:ss'),
                'Asia/Kolkata',
              )
              .format();
            event.end_time = momentTz
              .tz(
                moment(event.end_time)
                  .add(330, 'minutes')
                  // .utc()
                  .format('YYYY-MM-DDTHH:mm:ss'),
                'Asia/Kolkata',
              )
              .format();
          });
          item.events.push({
            start_time: state.start_time,
            end_time: state.end_time,
            type: 'current_event',
          });
          return item;
        });
        setState({ panelists, panelist_data: res.data });
      }
      if (user.default_calendar === '' || user.default_calendar == null) {
        let panelist_data: any = [
          ...data?.requisition?.interviewer,
          ...data?.requisition?.hiring_team,
          ...data?.requisition?.recruiter,
        ];
        panelist_data.map((item) => {
          item.user = item;
          item.events = [];
          item.events.push({
            start_time: state.start_time,
            end_time: state.end_time,
            type: 'current_event',
          });
          return item;
        });
        setState({ panelists, panelist_data });
      }
      // else {
      //   let res: any = await Models.auth.fetchOutlookEvents({
      //     users: panelists,
      //   });
      //   //ugly - fix (timezone)
      //   res.data.map((item: any) => {
      //     item.events.map((event: any) => {
      //       event.start_time = momentTz
      //         .tz(
      //           moment(event.start_time)
      //             .add(330, 'minutes')
      //             // .utc()
      //             .format('YYYY-MM-DDTHH:mm:ss'),
      //           'Asia/Kolkata',
      //         )
      //         .format();
      //       event.end_time = momentTz
      //         .tz(
      //           moment(event.end_time)
      //             .add(330, 'minutes')
      //             // .utc()
      //             .format('YYYY-MM-DDTHH:mm:ss'),
      //           'Asia/Kolkata',
      //         )
      //         .format();
      //     });
      //     item.events.push({
      //       start_time: state.start_time,
      //       end_time: state.end_time,
      //       type: 'current_event',
      //     });
      //     return item;
      //   });
      //   setState({ panelists, panelist_data: res.data });
      // }
    } catch (error) {
      console.log('error', error);
    }
  };

  const removePanelists = (index: number) => {
    let panelist_data: any = state.panelist_data;
    panelist_data.splice(index, 1);
    setState({ panelist_data });
  };

  const handleSubmitInterview = () => {
    if (edit) {
      handleSaveInterview();
    } else {
      createInterview();
    }
  };

  const removeAttachments = (type: string, index: number) => {
    if (type == 'panelist') {
      let attachments: any = state.panelist_attachments;
      attachments.splice(index, 1);
      setState({ panelist_attachments: attachments });
    } else {
      let attachments: any = state.candidate_attachments;
      attachments.splice(index, 1);
      setState({ candidate_attachments: attachments });
    }
  };

  const handleNavigate = () => {
    if (edit) {
      navigate('/home');
    } else {
      navigate(`/candidate?id=${candidate_id}&req_id=${req_id}`);
    }
  };

  const getSizeOfTimeline = (data: any) => {
    let width: any = document.getElementById('calendar_wrapper')?.clientWidth;
    width = (width * 65) / 100;
    let widthForOneMinute = width / 1440;
    let from = moment(data.start_time);
    let formatted: any = moment(data.start_time).format('HH:mm');
    let timeValue = formatted.split('T').pop().split(':');
    let to = moment(data.end_time);
    let difference = to.diff(from, 'hours');
    let leftPosition =
      (timeValue[0] * 60 + parseInt(timeValue[1])) * widthForOneMinute;
    let sizeOfTimeline = {
      width: `${difference * 60 * widthForOneMinute}px`,
      left: `${leftPosition}px`,
    };
    return sizeOfTimeline;
  };
  const handleWindowClose = (e) => {
    e.preventDefault();
    return (e.returnValue = '');
  };
  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [window]);

  const setInterviewTime = (values: any) => {
    let interviewDate: any = state.date;
    let dt: any = state.date;
    dt = new Date(dt.setHours(new Date(values.endTime).getHours()));
    dt = new Date(dt.setMinutes(new Date(values.endTime).getMinutes()));
    let start_time: any = new Date(
      interviewDate.setHours(new Date(values.startTime).getHours()),
    );
    start_time = new Date(
      interviewDate.setMinutes(new Date(values.startTime).getMinutes()),
    );
    dt = moment(dt).format();
    start_time = moment(start_time).format();
    if (values.startTime !== state.start_time) {
      setState({
        start_time: start_time,
        end_time: moment(start_time).add(1, 'hours'),
        to: moment(dt).add(1, 'hours'),
      });
    } else {
      setState({
        start_time: start_time,
        end_time: values.endTime,
        to: dt,
      });
    }
  };

  const setInterviewDate = (date:any) => {
    let interviewDate: any = date;
    let dt: any = date;
    dt = new Date(dt.setHours(new Date(state.end_time).getHours()));
    dt = new Date(dt.setMinutes(new Date(state.end_time).getMinutes()));
    let start_time: any = new Date(
      interviewDate.setHours(new Date(state.start_time).getHours()),
    );
    start_time = new Date(
      interviewDate.setMinutes(new Date(state.start_time).getMinutes()),
    );
    dt = moment(dt).format();
    start_time = moment(start_time).format();
    setState({
      start_time: start_time,
      end_time: dt,
    });
  };

  return (
    <div className="setup_container">
      <div className="setup_header">
        <div className="setup_back_and_text">
          <div className="setup_back" onClick={() => handleNavigate()}>
            <img src={Assets.back} className="interview_setup_back_icon" />
          </div>
          <div className="setup_header_text">Interview Setup</div>
        </div>
        <div className="setup_header_button">
          <PrimaryButton
            text={edit ? 'Save' : 'Send Invite'}
            icon={Assets.big_tick}
            loading={state.loader}
            onClick={handleSubmitInterview}
            className="interview_setup_submit_btn"
          />
        </div>
      </div>
      <div className="setup_wrapper1">
        <div className="setup_wrapper1_main">
          <div className="setup_info_container">
            <div className="setup_subheading">Candidate Info</div>
            <div className="setup_info_wrapper">
              <div className="setup_info_round_container">
                <div className="setup_info_input_heading">
                  Interview Round <span className="required_field">*</span>
                </div>
                <div className="setup_info_dropdown">
                  <Dropdown
                    data={getDropdownData(interview_rounds)}
                    value={state.interview_rounds}
                    setActive={(interview_rounds: string) =>
                      setState({ interview_rounds })
                    }
                    error={state.errorArray}
                    name="name"
                  />
                </div>
              </div>
              <div className="setup_info_date_container">
                <div className="setup_info_input_heading">
                  Date<span className="required_field">*</span>
                </div>
                <DatePickerComponent
                  value={state.date}
                  onChange={(date: string) => {
                    setState({ date });
                    setInterviewDate(date)
                  }}
                />
              </div>
              <div className="setup_info_time_container">
                <div className="setup_info_input_heading">
                  Time<span className="required_field">*</span>
                </div>
                <div className="setup_info_time_wrapper">
                  <TimePickerComponent
                    startMoment={state.start_time}
                    endMoment={state.end_time}
                    onChange={(values: any) => {
                      setInterviewTime(values);
                    }}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="setup_calendar_container">
            {/* <div className="setup_subheading">Calender</div> */}
            <div className="setup_subheading">Panelists</div>
            {/* <div className="interview_setup_hiring_team">
              {state.panelists.map((item: any, index: number) => (
                <div className="interview_setup_hiring_team_list">
                  <Tag
                    text={item.name}
                    icon={Assets.close}
                    profile_picture={item.profile_picture}
                    onClick={() => removePanelists(index)}
                  />
                </div>
              ))}
            </div> */}
            <div className="setup_calender_wrapper" id="calendar_wrapper">
              <div className="setup_calender_header">
                <div className="calendar_timeline">
                  <div>0</div>
                  <div>3</div>
                  <div>6</div>
                  <div>9</div>
                  <div>12</div>
                  <div>15</div>
                  <div>18</div>
                  <div>21</div>
                  <div>24</div>
                </div>
              </div>
              <div className="setup_calender_footer">
                {state.panelist_data.map((item: any, index: number) => {
                  if (
                    item.user.google_calender_integration ||
                    item.user.microsoft_calender_integration
                  ) {
                    return (
                      <>
                        <div className="interview_setup_hiring_team_list">
                          <Tag
                            text={item.user.first_name}
                            icon={Assets.close}
                            profile_picture={
                              item.profile_picture ||
                              Assets.user_placeholder_white
                            }
                            onClick={() => removePanelists(index)}
                          />
                        </div>
                        <div
                          className="setup_calender_timechart"
                          id="calendar_timeline">
                          {item.events.map((event: any) => (
                            <div
                              className="setup_calender_timechart_1"
                              style={{
                                width: `${getSizeOfTimeline(event).width}`,
                                left: `${getSizeOfTimeline(event).left}`,
                                backgroundColor: event.type && '#1c7bdf',
                              }}></div>
                          ))}
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div className="interview_setup_hiring_team_list">
                          <Tag
                            text={item.user.first_name}
                            icon={Assets.close}
                            profile_picture={
                              item.profile_picture ||
                              Assets.user_placeholder_white
                            }
                            onClick={() => removePanelists(index)}
                          />
                        </div>
                        <div
                          className="setup_calender_timechart"
                          id="calendar_timeline">
                          {item.events.map((event: any) => (
                            <div className="calender_not_integrated">
                              Calender not integrated
                            </div>
                          ))}
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="setup_wrapper1_sub">
          <div className="setup_medium_container">
            <div className="setup_info_input_heading">Interview Medium</div>
            {state.interview_medium?.length == 0 &&
              state.errorArray?.length > 0 && (
                <div className="input_field_error">
                  Select any one of the interview medium
                </div>
              )}
            <div className="setup_info_medium_checkbox">
              <div
                className="setup_checkbox_wrapper"
                onClick={() =>
                  setState({
                    in_person: !state.in_person,
                    video: false,
                    phone: false,
                  })
                }>
                <div className="setup_checkbox">
                  <CheckBox checked={state.in_person} />
                </div>
                <div className="setup_checkbox_value">In-Person (Onsite)</div>
              </div>
              {state.in_person && (
                <div className="interview_setup_address">
                  <Dropdown
                    data={state.work_locations}
                    value={state.address}
                    setActive={(address: any) =>
                      setState({ address, interview_medium: 'In person' })
                    }
                    error={state.errorArray}
                    name="address"
                  />
                  {state.errorArray?.length > 0 && state.address === '' && (
                    <div className="input_field_error">Enter address</div>
                  )}
                </div>
              )}
              <div
                className="setup_checkbox_wrapper"
                onClick={() =>
                  setState({
                    video: !state.video,
                    phone: false,
                    in_person: false,
                  })
                }>
                <div className="setup_checkbox">
                  <CheckBox checked={state.video} />
                </div>
                <div className="setup_checkbox_value">Video</div>
              </div>
            </div>
          </div>
          {state.video && (
            <div className="setup_link_container">
              <div className="setup_info_input_heading">Meeting Link</div>
              <Input
                onChange={(video_call_link: string) =>
                  setState({ video_call_link, interview_medium: 'Video call' })
                }
                value={state.video_call_link}
                name="meeting link"
                type="text"
              />
              {state.errorArray?.length > 0 && state.video_call_link === '' && (
                <div className="input_field_error">Enter Video call link</div>
              )}
            </div>
          )}
          <div
            className="setup_checkbox_wrapper"
            onClick={() =>
              setState({
                phone: !state.phone,
                video: false,
                in_person: false,
              })
            }>
            <div className="setup_checkbox">
              <CheckBox checked={state.phone} />
            </div>
            <div className="setup_checkbox_value">Phone</div>
          </div>
          {state.phone && (
            <div className="setup_link_container">
              <div className="setup_info_input_heading">Phone number</div>
              <Input
                onChange={(phone_number: any) =>
                  setState({ phone_number, interview_medium: 'Phone' })
                }
                value={state.phone_number}
                name="phone_number"
                type="number"
              />
              {state.errorArray?.length > 0 && state.phone_number === '' && (
                <div className="input_field_error">Enter phone number</div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="setup_wrapper2 editor_container">
        <div className="setup_editor_container">
          <div className="setup_subheading">Panelists Email</div>
          <TextEditor
            onChange={(panelist_email: any) => setState({ panelist_email })}
            error={state.errorArray}
            name={'panelist_email'}
            value={state.panelist_email}
          />
        </div>
        <div className="setup_templates_attachment_container">
          <div className="setup_templates_wrapper">
            <div className="setup_info_input_heading">Templates</div>
            <div className="setup_templates_dropdown">
              <Dropdown
                data={state.email_templates}
                setActive={(panelist_email: any) => {
                  setState({ panelist_email: panelist_email.value });
                }}
              />
            </div>
          </div>
          <div className="setup_attachment_container">
            <div className="setup_subheading">Attachments</div>
            <div className="setup_attachment_wrapper">
              {state.panelist_attachments.length > 0 &&
                state.panelist_attachments.map(
                  (item: string, index: number) => (
                    <Attachment
                      data={{ fileName: item?.split('_')?.pop() }}
                      onClick={() => removeAttachments('panelist', index)}
                    />
                  ),
                )}
              <FileUpload
                ref={inputFileRef}
                multiple
                folder="interview_attachment"
                onChange={(panelist_attachments: any) => {
                  handleUploadAttachments(panelist_attachments, 'panelist');
                }}>
                <PrimaryButton
                  icon={Assets.upload}
                  text={'Upload'}
                  className="setup_upload_button"
                  onClick={() => {
                    inputFileRef?.current?.openUpload();
                  }}
                />
              </FileUpload>
            </div>
          </div>
        </div>
      </div>
      <div className="setup_wrapper3 editor_container">
        <div className="setup_editor_container">
          <div className="setup_subheading">Candidate Email<span className="required_field">*</span></div>
          <TextEditor
            onChange={(candidate_email: any) => setState({ candidate_email })}
            error={state.errorArray}
            name={'candidate_email'}
            value={state.candidate_email}
          />
        </div>
        <div className="setup_templates_attachment_container">
          <div className="setup_templates_wrapper">
            <div className="setup_info_input_heading">Templates</div>
            <div className="setup_templates_dropdown">
              <Dropdown
                data={state.email_templates}
                setActive={(candidate_email: any) =>
                  setState({ candidate_email: candidate_email.value })
                }
              />
            </div>
          </div>
          <div className="setup_attachment_container">
            <div className="setup_subheading">Attachments</div>
            <div className="setup_attachment_wrapper">
              {state.candidate_attachments.map(
                (item: string, index: number) => (
                  <Attachment
                    data={{ fileName: item.split('_')?.pop() }}
                    onClick={() => removeAttachments('candidate', index)}
                  />
                ),
              )}
              <FileUpload
                ref={candidateUploadRef}
                multiple
                folder="interview_attachment"
                onChange={(candidate_attachments: any) => {
                  handleUploadAttachments(candidate_attachments, 'candidate');
                }}>
                <PrimaryButton
                  icon={Assets.upload}
                  text={'Upload'}
                  className="setup_upload_button"
                  onClick={() => {
                    candidateUploadRef?.current?.openUpload();
                  }}
                />
              </FileUpload>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewSetup;
