import { GET_LOCATIONS} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  location_list:"",
};

const locationReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_LOCATIONS:
      return {
        ...state,
        location_list: action.payload,
      };  
    default:
        return state;
  }
};

export default locationReducer;
