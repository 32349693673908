import ListComponent from 'common_components/ui/list/list.component';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  mergeArrayWithoutDuplicates,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  PrimaryButton,
  Assets,
  DeletePop,
  Validation,
} from 'utils/imports.utils';
import { getBusinessUnitList, testDispatch } from 'utils/redux.utils';
import './business_unit.screen.scss';
import { toastify, uploadFile, toastifyError } from 'utils/functions.utils';
import { error } from 'console';

const BusinessUnit = (props: any) => {
  // Redux
  const businessState = useSelector((state: any) => state.business_unit);
  // State
  const [state, setState] = useSetState({
    business_name: '',
    data: '',
    loading: false,
    modal: false,
    errorArray: [],
    limit: 10,
    skip: 0,
    loadMore: true,
  });

  //Hooks
  useEffect(() => {
    getAllBusinessUnits();
  }, []);

  // Network req

  // get department

  const getAllBusinessUnits = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.business.getAllBusinessUnits({
        limit: state.limit,
        skip: 0,
      });
      if (res.data.totalDocs === res?.data?.docs?.length) {
        setState({ loadMore: false });
      } else {
        setState({ loadMore: false });
      }
      getBusinessUnitList(res?.data?.docs);
      setState({ loading: false, skip: 0 });
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const loadMoreBusinessUnits = async (skip: number) => {
    try {
      setState({ loading: true });
      const res: any = await Models.business.getAllBusinessUnits({
        limit: state.limit,
        skip,
      });
      let data = mergeArrayWithoutDuplicates(
        businessState?.business_units,
        res?.data.docs,
      );
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      getBusinessUnitList(data);
      setState({ loading: false });
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const addBusinessUnit = async () => {
    try {
      const body = {
        name: state.business_name,
      };
      await Validation.businessSchema.validate(body, {
        abortEarly: false,
      });
      const res: any = await Models.business.createbusiness(body);
      await getAllBusinessUnits();
      setState({ business_name: '' });
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  // delete

  const deleteBusinessUnit = async () => {
    try {
      const body = {
        business_unit_id: state.data._id,
      };
      const res: any = await Models.business.deleteBusinessUnit(body);
      setState({ modal: false, data: '' });
      await getAllBusinessUnits();
    } catch (error: any) {}
  };

  //sample data
  const titleData = [{ label: 'Business Unit', value: 'name', width: '80%' }];
  let actionArray = {
    style: { width: '20%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div style={{}}>
              <PrimaryButton
                text={'Delete business unit'}
                className={'list_component_action_btn'}
                icon={Assets.trash}
                onClick={() => {
                  setState({ data: item, modal: true });
                }}
              />
            </div>
          );
        },
      },
    ],
  };

  const loadMore = () => {
    setState({ skip: state.skip + 10 });
    loadMoreBusinessUnits(state.skip + 10);
  };

  return (
    <div className="businessunit_container">
      <div className="businessunit_heading">Business Unit</div>
      <div className="businessunit_wrapper">
        <div className="businessunit_input_container">
          <div className="businessunit_input_heading">
            Business Unit Name <span className="required_field">*</span>
          </div>
          <div className="businessunit_input_and_button">
            <div className="businessunit_input_width">
              <Input
                onChange={(value) => {
                  setState({ business_name: value });
                }}
                error={state.errorArray}
                type={'text'}
                value={state.business_name}
                name={'name'}
              />
            </div>
            <PrimaryButton
              onClick={addBusinessUnit}
              text={'Add Business Unit'}
              icon={Assets.add}
              className={'add_button'}
            />
          </div>
          <div className="businessunit_table_container">
            <ListComponent
              titleArray={titleData}
              data={
                businessState?.business_units.length > 0
                  ? businessState?.business_units
                  : []
              }
              loading={state.loading}
              actionArray={actionArray}
              loadMore={loadMore}
              hasMore={state.loadMore}
            />
          </div>
        </div>
      </div>
      <DeletePop
        text={'Are you sure you want to delete this business unit?'}
        onClick={deleteBusinessUnit}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  );
};

export default BusinessUnit;
