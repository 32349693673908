import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { DatePickerComponent, PrimaryButton } from 'utils/imports.utils';
import './candidate_join_modal.component.scss';

const CandidateJoinModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();
  //Redux
  // State
  const [state, setState] = useSetState({ joining_date: new Date() });

  //Hooks
  useEffect(() => {}, []);
  // Network req

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'candidate_join_modal_wrapper',
        }}>
        <div className="candidate_join_modal_container">
          <div
            style={{
              width: '100%',
            }}>
            <div className="candidate_join_modal_heading">
              Select joining date
            </div>
            <div className="candidate_join_modal_date">
              <DatePickerComponent
                value={state.joining_date}
                onChange={(joining_date: any) => {
                  setState({ joining_date });
                }}
                maxDate={new Date()}
                {...props}
              />
            </div>
          </div>
          <div className="candidate_join_button_wrapper">
            <PrimaryButton
              text={'Save'}
              className={'candidate_join_button'}
              onClick={() => props.onPress(state.joining_date)}
            />
            <PrimaryButton
              text={'Cancel'}
              className={'candidate_join_button'}
              onClick={() => setState({ isModalOpen: false })}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default CandidateJoinModal;
