import { useEffect } from 'react';
import {
  mergeArrayWithoutDuplicates,
  useSetState,
} from 'utils/functions.utils';
import { DeletePop, Loader, Models, Navbutton, Validation } from 'utils/imports.utils';
import './client.screen.scss';
import { Assets } from '../../utils/imports.utils';
import { Input, PrimaryButton } from '../../utils/imports.utils';
import { toastifyError } from 'utils/functions.utils';
import ListComponent from 'common_components/ui/list/list.component';
import { IClient, IEditClient } from 'interfaces/common.interface';

const Client = (props: any) => {
  // State
  const [state, setState] = useSetState({
    client_name: '',
    // email: '',
    // phone_number: '',
    modal: false,
    clientData: {},
    clientAllData: {},
    errorArray: [],
    skip: 0,
    data_limit: 50,
    loadMore: true,
    loading: false,
    isLoading: false,
    pocData: [{ name: '', email: '' }],
    client_id: '',
    pocError: false
  });

  //  hooks
  useEffect(() => {
    getAllClient();
  }, []);
  // Network req

  const getAllClient = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.client.getAllClient({
        limit: state.data_limit,
        skip: 0,
      });
      if (res.data.totalDocs === res?.data?.docs.length) {
        setState({ loadMore: false });
      } else {
        setState({ loadMore: true });
      }
      setState({ loading: false, skip: 0, clientAllData: res?.data?.docs });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const loadMoreClient = async (skip: number) => {
    try {
      const res: any = await Models.client.getAllClient({
        limit: state.data_limit,
        skip,
      });
      let data = mergeArrayWithoutDuplicates(
        state.clientAllData,
        res?.data.docs,
      );
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      setState({ clientAllData: data });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const createClient = async () => {
    try {
      setState({
        isLoading: true,
        pocError: true
      });
      let body: IClient = {
        client_name: state.client_name,
        poc: state.pocData,
      };
      await Validation.clientSchema.validate(body, {
        abortEarly: false,
      });
      const res: any = await Models.client.createClient(body);
      await getAllClient();
      setState({
        client_name: '',
        pocData: [{ name: '', email: '' }],
        isLoading: false,
        pocError: false
      });
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner || [],
        isLoading: false,
      });
      setTimeout(() => {
        setState({ errorArray: [], pocError: false });
      }, 3000);
    }
  };

  const editClient = async () => {
    try {
      setState({
        isLoading: true,
        pocError: true
      });

      let body: IEditClient = {
        client_name: state.client_name,
        poc: state.pocData,
        client_id: state.client_id
      };

      await Validation.editClientSchema.validate(body, {
        abortEarly: false,
      });
      const res: any = await Models.client.editClientById(body);
      await getAllClient();
      setState({
        client_name: '',
        pocData: [{ name: '', email: '' }],
        client_id: '',
        isLoading: false,
        pocError: false
      });
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner || [],
        isLoading: false,
      });
      setTimeout(() => {
        setState({ errorArray: [], pocError: false });
      }, 3000);
    }
  };

  const deleteClient = async () => {
    try {
      const body = {
        client_id: state.clientData._id,
      };
      const res: any = await Models.client.deleteClientById(body);
      setState({ clientData: '', modal: false });
      await getAllClient();
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //sample data
  const titleData = [
    { label: 'Name', value: 'client_name', width: '70%' },
  ];

  let actionArray = {
    style: { width: '30%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div style={{ display: "flex", gap: '20px' }}>
              <Navbutton
                icon={Assets.edit}
                onClick={() =>
                  setState({ client_name: item.client_name, client_id: item._id, pocData: item.poc })
                }
              />
              <Navbutton
                icon={Assets.trash}
                onClick={() =>
                  setState({ clientData: item, modal: true })
                }
              />
            </div>
          );
        },
      },
    ],
  };

  const pocAddHandler = () => {
    let data: any = state.pocData
    data.push({ name: '', email: '' });
    setState({ pocData: data })
  }

  const pocUpdateHandler = (item, index) => {
    let data: any = state.pocData;
    if (item.pocName || item.pocName === "") {
      data[index].name = item.pocName
    }
    if (item.pocEmail || item.pocEmail === "") {
      data[index].email = item.pocEmail
    }
    setState({ pocData: data })
  }

  const pocDeleteHandler = (index) => {
    let data: any = state.pocData;
    data.splice(index, 1)
    setState({ pocData: data })
  }
  
  const pocErrorHandler = (data, index?: any) => {
    var email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (state.pocData[0]?.name.length === 0 && state.pocData[0]?.email.length === 0 && data === "poc") {
      return "provide atleast one point of contact"
    }
    else if (state.pocData[index]?.name.length === 0 && data === "pocName") {
      return "Enter Name"
    }
    else if (state.pocData[index]?.email.length === 0 && data === "pocEmail") {
      return "Enter Email Address"
    }
    else if (!email_regex.test(state.pocData[index]?.email) && data === "pocEmail") {
      return "Invalid Email Address"
    }
  }

  const loadMore = () => {
    setState({ skip: state.skip + 50 });
    loadMoreClient(state.skip + 50);
  };

  return (
    <div className="client_container">
      <div className="create_client_wrapper">
        <div className="client_heading">Client</div>
        <div className="client_flex_container">
          <div className="client_wrapper">
            <div className="client_input_wrapper">
              <div className="client_label">
                Client Name<span className="required_field">*</span>
              </div>
              <Input
                value={state.client_name}
                type="text"
                name="client_name"
                error={state.errorArray}
                onChange={(value) => setState({ client_name: value })}
              />
            </div>
            <div className="client_input_wrapper">
              <div className="client_label">
                Point of Contact<span className="required_field">*</span>
              </div>
              <div className="poc_container">

                {state.pocData?.map((item: any, index: number) => {
                  return (
                    <div className="poc_wrapper" key={index}>
                      <div className="poc_name">
                        <div className="poc_label">
                          Name
                        </div>
                        <Input
                          value={item.name}
                          type="text"
                          name={`poc[${index}].name`}
                          error={state.errorArray}
                          onChange={(value) => pocUpdateHandler({ pocName: value }, index)}
                        />
                        {/* {state.pocError &&
                          <div className="input_field_error">
                            {pocErrorHandler("pocName", index)}
                          </div>} */}
                      </div>
                      <div className="poc_email">
                        <div className="poc_label">
                          Email
                        </div>
                        <Input
                          value={item.email}
                          type="text"
                          name={`poc[${index}].email`}
                          error={state.errorArray}
                          onChange={(value) => pocUpdateHandler({ pocEmail: value }, index)}
                        />
                        {/* {state.pocError &&
                          <div className="input_field_error">
                            {pocErrorHandler("pocEmail", index)}
                          </div>} */}
                      </div>

                      <div className="poc_nav_button_wrapper">
                        {state.pocData?.length !== 1 && <Navbutton
                          icon={Assets.trash}
                          onClick={() =>
                            pocDeleteHandler(index)
                          }
                        />}
                      </div>
                    </div>
                  )
                }

                )}
              </div>
              {state.pocError && <div className="input_field_error">
                {pocErrorHandler("poc")}
              </div>}
            </div>
            <div className="poc_add_button">
              <PrimaryButton
                className={'poc_commission_button'}
                text="Add Point of Contact"
                icon={Assets.add}
                onClick={pocAddHandler}
              />
            </div>
            <div className="btn-input_wrapper">
              {state.isLoading ? (
                <Loader />
              ) : (
                <PrimaryButton
                  onClick={state.client_id?.length > 0 ? editClient : createClient}
                  text={'Submit'}
                  className={'client_button'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="client_table_wrapper">
        <ListComponent
          titleArray={titleData}
          data={state?.clientAllData.length > 0 ? state.clientAllData : []}
          actionArray={actionArray}
          hasMore={state.loadMore}
          loadMore={loadMore}
          loading={state.loading}
        />
      </div>
      <DeletePop
        text={'Are you sure you want to delete this client?'}
        onClick={deleteClient}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  );
};

export default Client;
