import { combineReducers } from 'redux';
import testReducer from 'store/reducers/test.reducer';
import chatReducer from 'store/reducers/chat.reducer';
import userReducer from 'store/reducers/user.reducer';
import AppReducer from './app.reducer';
import locationReducer from './location.reducer';
import emailTemplateReducer from './email.reducer';
import agencyReducer from './agency.reducer';
import departmentReducers from './department.reducer';
import businessUnitReducer from './business_unit.reducer';
import recquisition from './job_recquisition.reducer';
import postJobReducer from './post_job.reducer';
import resumeReducer from './resume.reducer';
import CandidateReducer from './candidate.reducer';
import InterviewReducer from './interview.reducer';
import FeedbackReducer from './feedback.reducer';
import NotesReducer from './notes.reducer';

export default combineReducers({
  test: testReducer,
  chat: chatReducer,
  user: userReducer,
  app_state: AppReducer,
  location: locationReducer,
  email: emailTemplateReducer,
  agency: agencyReducer,
  department: departmentReducers,
  business_unit: businessUnitReducer,
  recquisition: recquisition,
  postjob: postJobReducer,
  resume: resumeReducer,
  candidate: CandidateReducer,
  interview: InterviewReducer,
  feedback: FeedbackReducer,
  note: NotesReducer,
});
