import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  phoneCheck,
  toastify,
  toastifyError,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Navbutton,
  Assets,
  Input,
  Tag,
  Dropdown,
  PrimaryButton,
  TextArea,
  Validation,
  Loader,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './cancel_subscription_modal.ui.scss';
import _ from 'lodash';

const CancelSubscriptionModal = forwardRef((props: any, ref) => {
  // ref
  const modalRef: any = useRef();
  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    loading: false,
  });

  //Hooks
  useEffect(() => {}, []);

  // Network req

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));


  const cancelSubscription = async () => {
    try {
      setState({ loading: true });
      await Models.subscription.cancelSubscription({});
      props.onClick();
      setState({ loading: false, isModalOpen: false });
    } catch (error: any) {
      toastifyError(error);
      setState({ loading: false });
    }
  };

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModalSubscription',
        }}>
        <div className="subscription_agency_container">
          <div className="subscription_wrapper">
            <div className="subscription_header">
              <div className="subscription_heading">Cancel Subscription</div>
              <Navbutton
                icon={Assets.black_close}
                onClick={() => {
                  setState({ isModalOpen: false });
                }}
              />
            </div>
            <div className="cancel_content">
              <div className="approval_subheading">
                Are you sure want to cancel subscription
              </div>
            </div>
            <div className="subscription_send_button">
              {state.loading ? (
                <div className="cancel_loader">
                  <Loader />
                </div>
              ) : (
                <>
                  <PrimaryButton
                    onClick={() => {
                      setState({ isModalOpen: false });
                    }}
                    text={'Close'}
                    className={'close_btn'}
                    width={'80px'}
                  />
                  <PrimaryButton
                    text={'Cancel My Subscription'}
                    className={'send_sub_button'}
                    onClick={cancelSubscription}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default CancelSubscriptionModal;

