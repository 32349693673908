import { useEffect, useRef } from 'react';
import {
  isoToDateConvert,
  unixTimeToDateConvert,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Models,
  PrimaryButton,
  Assets,
  ToggleHeader,
  OfferListComponent,
  SingleSlider,
  Colors,
  SubscriptionModal,
  CancelSubscriptionModal,
} from 'utils/imports.utils';
import { getUserDetails } from 'utils/redux.utils';
import './offer_template.screen.scss';
import { toastifyError } from 'utils/functions.utils';
import {
  OFFER_TEMPLATE,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from 'utils/constant.utils';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

interface IOfferTemplate {
  text?: String;
}
const OfferTemplate = (props: IOfferTemplate) => {
  // modalref
  const subscriptionRef: any = useRef();
  const cancelRef: any = useRef();
  const navigate = useNavigate();
  let query = useQuery();
  const sessionID = query.get('session');
  // State
  const [state, setState] = useSetState({
    active: OFFER_TEMPLATE.BILLING,
    no_of_value: 1,
    priceData: [],
    productData: [],
    billingDetailsList: [],
    trigger: false,
    userData: {},
    invoiceData: [],
    loading: false,
    upcomingBill: {},
  });
  // Network req

  const createCheckout = async () => {
    try {
      let query: any = {
        quantity: state.no_of_value,
        price_id: state.productData.id,
      };
      const res: any = await Models.subscription.createCheckout(query);
      window.location.href = res.data;
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const createSubscription = async () => {
    try {
      let query: any = {
        price_id: state.productData.id,
      };
      const res: any = await Models.subscription.createSubscription(query);
      window.location.href = res.data;
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const subscriptionProduct = async () => {
    try {
      let query: any = {
        is_agency: state.userData?.is_agency ? state.userData.is_agency : false,
      };
      let productData: any = await Models.subscription.subscriptionProduct(
        query,
      );
      setState({ productData: productData.data });
    } catch (error) {
      toastifyError(error);
    }
  };

  const addCheckout = async () => {
    // if (state.trigger) {
    try {
      let query = {
        session_id: sessionID,
        price_id: state.productData.id,
      };
      await Models.subscription.addCheckout(query);
      navigate('/settings/payment_settings');
      getUser(true);
      getSubscription();
      // setState({ trigger: false });
    } catch (error: any) {
      toastifyError(error);
    }
    // }
  };

  const addSubscription = async () => {
    // if (state.trigger) {
    try {
      let query = {
        session_id: sessionID,
        price_id: state.productData.id,
        price: state.productData.unit_amount / 100,
      };
      await Models.subscription.addSubscription(query);
      navigate('/settings/payment_settings');
      getUser(true);
      getSubscription();
      // setState({ trigger: false });
    } catch (error: any) {
      toastifyError(error);
    }
    // }
  };

  const getSubscription = async () => {
    try {
      setState({ loading: true });
      const query: any = {
        is_agency: state.userData?.is_agency ? true : false,
      };
      const res: any = await Models.subscription.getSubscription(query);
      let sortInvoiceData = res.data.subscription.sort(
        (a: any, b: any) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
      let upcomingBill: number;
      if (res.data.is_schedule) {
        upcomingBill =
          sortInvoiceData[0].subscription_data.phases[0].start_date;
      } else {
        upcomingBill = sortInvoiceData[0].subscription_data.current_period_end;
      }

      let invoiceData = _.filter(
        sortInvoiceData,
        (e) => e.status === SUBSCRIPTION_STATUS.CREATED,
      );
      setState({ upcomingBill, loading: false });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  // const updateSubscription = async () => {
  //   try {
  //     const res: any = await Models.subscription.updateSubscription();
  //     console.log(res);
  //   } catch (error: any) {
  //     toastifyError(error);
  //   }
  // };


  const billingDetailsList = (product) => {
    setState({ loading: true });
    let billingDetailsList = [
      {
        plan: state.userData?.org?.plan,
        amount:state.userData?.org?.plan!==SUBSCRIPTION_PLAN.PREMIUM? 'free':`${product.unit_amount / 100}${
          state.userData?.is_agency ? '/month' : ''
        }`,
        total: state.userData.org?.[state.userData?.org?.plan]?.total_quantity,
      },
    ];
    setState({ billingDetailsList, loading: false });
  };

  // Network req
  const getUser = async (update?: boolean) => {
    setState({ loading: true });
    let userId = localStorage.getItem('user_id');
    try {
      const res: any = await Models.auth.viewUser(userId);
      setState({ userData: res.data });
      if (update) {
        getUserDetails(res.data);
      }
      // dispatch
    } catch (error: any) {
      console.log(error);
    }
  };

  // data
  const subscription_plan: any = [
    {
      name: 'Basic',
      company: 'For individuals',
      icon: Assets.basic_plan,
      description:
        'Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. ',
      amount: 'Free',
      plan: [
        '100 Requisitions',
        'Up to 250 Email Templates',
        'Normal support',
        'Up to 3 team members',
      ],
      pay: 'Free',
      btn: {},
      isPlan:  state.userData?.org?.plan === SUBSCRIPTION_PLAN.BASIC ,
    },
    {
      name: 'Premium',
      company: 'For startups',
      icon: Assets.premium_plan,
      description:
        'Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. ',
      amount: state.productData.unit_amount / 100,
      monthly: true,
      plan: [
        '300 Requisitions',
        'Up to 250 Email Templates',
        'Premium support',
        'Up to 10 team members',
      ],
      pay: 'Pro',
      btn: 'premium',
      isPlan:
        state.userData?.org?.plan === SUBSCRIPTION_PLAN.PREMIUM &&
        state.userData?.org?.subscribed,
    },
    {
      name: 'Enterprise',
      company: 'For big companies',
      icon: Assets.enterprise_plan,
      description:
        'Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. ',
      amount: '',
      btn: {},
      plan: [
        '500 Requisitions',
        'Up to 500 Email Templates',
        'Dedicated support',
        'Up to 3 team members',
      ],
      pay: 'contact us',
      plan_name: state.userData?.org?.plan === SUBSCRIPTION_PLAN.ENTERPRISE,
    },
  ];
  const billingDetails = [
    { label: 'Current Plan', value: 'plan', width: '40%' },
    { label: 'Cost', value: 'amount', width: '30%' },
    {
      label: `${state.userData.is_agency ? 'Users' : 'Requisitions'}`,
      value: 'total',
      width: '30%',
    },
  ];

  const invoiceDetails = [
    { label: 'Date', value: 'created_at', width: '25%' },
    { label: 'Plan', value: 'plan', width: '20%' },
    {
      label: `${state.userData.is_agency ? 'Users' : 'Requisitions'}`,
      value: 'quantity',
      width: '20%',
    },
    {
      label: 'Total',
      value: 'amount',
      width: '15%',
    },
    {
      label: 'Receipt',
      value: 'invoice_number',
      width: '20%',
    },
  ];
  const tabData = ['Billing', 'Plan'];

  const handleSubscription = (btn: string) => {
    if (
      state.userData?.org?.subscribed &&
      btn === SUBSCRIPTION_PLAN.PREMIUM &&
      state.userData?.is_agency
    ) {
      toastifyError('You are already subscribed');
    } else if (btn === SUBSCRIPTION_PLAN.PREMIUM) {
      subscriptionRef?.current?.openModal();
    }
  };
  const listInvoice = async () => {
    try {
      let res: any = await Models.subscription.subscriptionInvoice({});
      
      const invoiceData: any = [];
      for (let invoice of res.data) {
        if(state.userData?.is_agency){
          invoiceData.push({
            created_at: invoice.created,
            url: invoice.hosted_invoice_url,
            invoice_number: invoice.number,
            quantity: invoice.lines.data[0].quantity,
            amount: invoice.total / 100,
          });
        }
        else{

          invoiceData.push({
            created_at: invoice.created,
            url: invoice.hosted_invoice_url,
            invoice_number: invoice.number,
            quantity: invoice.lines.data[0].quantity,
            amount: invoice.total / 100,
          });
        }
      }
      setState({ invoiceData });
    } catch (error) {
      toastifyError(error);
    }
  };

  //Hooks
  useEffect(() => {
    if (!_.isEmpty(state.userData)) {
      // setState({ trigger: !state.trigger });
      subscriptionProduct();
      // if (state.userData?.org.subscribed) {
      getSubscription();
      // }
      listInvoice();
    }
  }, [state.userData]);

  useEffect(() => {
    if (
      !_.isEmpty(state.userData) &&
      !_.isEmpty(state.productData) &&
      !_.isEmpty(sessionID)
    ) {
      if (state.userData.is_agency) {
        addSubscription();
      } else {
        addCheckout();
      }
    }
  }, [state.userData, sessionID, state.productData]);

  useEffect(() => {
    if (!_.isEmpty(state.userData) && !_.isEmpty(state.productData)) {
      billingDetailsList(state.productData);
    }
  }, [state.userData, state.productData]);

  useEffect(() => {
    getUser();
    // invoiceSubscription()
  }, []);

  return (
    <div className="offer_template_container">
      <div className="header">
        <ToggleHeader
          data={tabData}
          onChange={(item: string) =>
            setState({ active: item, no_of_value: 1 })
          }
          active={state.active}
        />
      </div>
      <div className="offer_template_wrapper">
        {state.active === OFFER_TEMPLATE.BILLING && (
          <div className="billing_container">
            <div className="billing_wrapper">
              <div className="billing_details">
                <div className="billing_header">Billing Details</div>
                <div className="billing_details_list">
                  <OfferListComponent
                    titleArray={billingDetails}
                    data={state.billingDetailsList}
                    // loadMore={loadMore}
                    // hasMore={state.loadMore}
                    // actionArray={actionArray}
                    loading={state.loading}
                  />
                </div>
                <div className="billing_create_date">
                  {!_.isEmpty(state.userData) &&
                    `Current billing period: ${
                         isoToDateConvert(state.userData.org?.[state.userData?.org?.plan]?.created_at)
                    }`}
                </div>
                {state.userData?.is_agency &&
                state.userData?.org?.plan === SUBSCRIPTION_PLAN.PREMIUM &&
                state.userData?.org?.subscribed ? (
                  <div className="sub_plan_cancel">
                    <div className="update_plan">
                      <PrimaryButton
                        onClick={() =>
                          setState({ active: OFFER_TEMPLATE.PLAN })
                        }
                        text={'Update Plan'}
                        className={'save_btn'}
                      />
                    </div>
                    <div className="or">or</div>
                    <div
                      className="cancel_subscription"
                      onClick={() => {
                        cancelRef?.current?.openModal();
                      }}>
                      Cancel Subscription
                    </div>
                    <CancelSubscriptionModal
                      ref={cancelRef}
                      onClick={()=>getUser(true)}
                    />
                  </div>
                ) : (
                  <div className="update_plan">
                    <PrimaryButton
                      onClick={() => setState({ active: OFFER_TEMPLATE.PLAN })}
                      text={'Update Plan'}
                      className={'save_btn'}
                    />
                  </div>
                )}
              </div>

              {!_.isEmpty(state.invoiceData) && (
                <div className="billing_details">
                  <div className="billing_header">Invoices</div>
                  <div className="billing_invoice_list">
                    <OfferListComponent
                      titleArray={invoiceDetails}
                      data={state.invoiceData}
                      type="invoiceDetails"
                      // loadMore={loadMore}
                      // hasMore={state.loadMore}
                      // actionArray={actionArray}
                      loading={state.loading}
                    />
                  </div>
                </div>
              )}

              {state.userData?.org?.subscribed &&
                state.userData?.is_agency &&
                !_.isEmpty(state.upcomingBill.toString()) && (
                  <div className="billing_details">
                    <div className="bill_summary">
                      <div className="billing_header">Next bill summary</div>
                      <div className="billing_create_date">
                        {`upcoming billing period: 
                    ${unixTimeToDateConvert(state.upcomingBill, true)}`}
                      </div>
                    </div>
                    {/* <div className="billing_details_list">
                  <OfferListComponent
                  // titleArray={titleData}
                  // data={
                  //   location?.location_list.length > 0
                  //     ? location?.location_list
                  //     : []
                  // }
                  // loadMore={loadMore}
                  // hasMore={state.loadMore}
                  // actionArray={actionArray}
                  // loading={state.loading}
                  />
                </div> */}
                    {/* <div className="update_plan">
              <PrimaryButton
                onClick={{}}
                text={'Pay Now'}
                className={'save_btn'}
              />
            </div> */}
                  </div>
                )}
            </div>
          </div>
        )}
        {state.active === OFFER_TEMPLATE.PLAN && (
          <div className="subscription_container">
            <div className="subscription_wrapper">
              <div className="subscription_slider">
                <div className="slider_header_value">
                  <div className="slider_name">
                    {`Number of
                     ${state.userData.is_agency ? 'users' : 'requisitions'}`}
                  </div>
                  <div className="slider_value">{state.no_of_value}</div>
                </div>
                <div className="slider_wrapper">
                  <SingleSlider
                    min={1}
                    max={100}
                    onChange={(value: number) =>
                      setState({ no_of_value: value })
                    }
                  />
                </div>
              </div>
              <div className="subscription_plan_container">
                {subscription_plan.map((item: any, index: number) => {
                  let plan: boolean = item.isPlan;
                  return (
                    <div
                      className={`subscription_plan_wrapper ${
                        plan && 'sub_plan_active'
                      }`}
                      key={index}>
                      <div className="subscription_plan_header">
                        <div
                          className={`subscription_plan_icon  ${
                            plan && 'plan_bg'
                          }`}>
                          <img src={item.icon} height={28} width={28} />
                        </div>
                        <div className="subscription_plan_content">
                          <div
                            className={`company_plan ${plan && 'plan_color'}`}>
                            {item.company}
                          </div>
                          <div className={`plan_name ${plan && 'plan_color'}`}>
                            {item.name}
                          </div>
                        </div>
                        {plan && index === 1 && (
                          <div className="plan_popular">Popular</div>
                        )}
                      </div>
                      <div
                        className={`subscription_desc ${plan && 'plan_color'}`}>
                        {item.description}
                      </div>
                      <div className="subscription_amount">
                        <>
                          <div className={`sub_amount ${plan && 'plan_color'}`}>
                            {_.isNumber(item.amount)
                              ? `₹${state.no_of_value * item.amount}`
                              : item.amount}
                          </div>
                          {state.userData.is_agency && item.monthly && (
                            <div
                              className={`sub_month ${plan && 'plan_color'}`}>
                              /monthly
                            </div>
                          )}
                        </>
                      </div>
                      <div className="subscription_plan">
                        <div className={`plan_header ${plan && 'plan_color'}`}>
                          What’s included
                        </div>
                        <div className="sub_many_plan">
                          {item.plan.map((name: any, index: number) => (
                            <div className="sub_plan" key={index}>
                              <img
                                src={
                                  plan
                                    ? Assets.tick_active
                                    : Assets.tick_inactive
                                }
                                height={20}
                                width={20}
                              />
                              <div
                                className={`plan_name ${plan && 'plan_color'}`}>
                                {name}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        className={`subscription_btn ${
                          SUBSCRIPTION_PLAN.ENTERPRISE === item.name.toLowerCase() &&
                          'contact_us'
                        }`}>
                        <PrimaryButton
                          onClick={() => handleSubscription(item.btn)}
                          text={`${item.pay}`}
                          styleText={plan && Colors.btnGradientText}
                          className={`button ${plan && 'plan_bg '}`}
                        />
                      </div>
                      <SubscriptionModal
                        ref={subscriptionRef}
                        onClick={
                          state.userData?.is_agency
                            ? createSubscription
                            : createCheckout
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferTemplate;
