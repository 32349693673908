import instance from 'utils/axios.utils';
import { uploadFile } from 'utils/functions.utils';

const skills = {
  createSkills: (data: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'skills/create_skills';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getManySkills: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'skills/get_many_skills';
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
 
};

export default skills;
