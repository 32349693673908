import { GET_USER, USER_LIST } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  auth: {},
  userList: [],
};

const userReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        auth: { ...state.auth, ...action.payload },
      };
    case USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
