import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { NetworkStatus, SUBSCRIPTION_PLAN } from 'utils/constant.utils';
import { toastify, toastifyError, useQuery, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  Input,
  PrimaryButton,
  Dropdown,
  Tabs,
  CheckBox,
  Validation,
} from 'utils/imports.utils';
import { getUserDetails, setAppRoute, testDispatch } from 'utils/redux.utils';
import './signup.screen.scss';

const Signup = (props: any) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({
    checked: false,
    agency_name: false,
    email: '',
    password: '',
    confirm_password: '',
    is_agency: false,
    company: '',
    company_email: '',
    company_size: '',
    visible: false,
    confirm_password_visible: false,
    errorArray: [],
    link: '/login'
  });

  //Hooks
  useEffect(() => {
    if (is_agency) {
      setState({ is_agency:true })
    }
    if (redirect) {
      setState({ link:`/login?redirect=${redirect}` })
    }
  }, []);
  const navigate = useNavigate();
  let query = useQuery();
  let redirect:any = query.get('redirect')
  let url = decodeURIComponent(redirect)
  let params = new URLSearchParams(url.split('?').pop());
  let is_agency = params.get('is_agency')
  // Network req

  const createOrganization = async () => {
    try {
      if (state.company_email.length > 0) {
        const isUserExists: any = await checkIfUserExists();
        if (!isUserExists) {
          if (
            state.company.length > 0 &&
            state.company_size.length > 0 &&
            state.password.length >= 8 &&
            state.password === state.confirm_password
          ) {
            let domain = state.company_email.split('@').pop();
            const body = {
              name: state.company,
              domain,
              type: state.company_size,
              is_agency: state.is_agency,
              plan:SUBSCRIPTION_PLAN.BASIC,
              [SUBSCRIPTION_PLAN.BASIC]:{
                quantity:1000,
                total_quantity:1000
              }
            };
            const res: any = await Models.organization.createOrganization(body);
            if (res) {
              await handleSignup(res.data._id);
            }
          } else if (state.company_email === '') {
            toastifyError('Enter work email');
          } else if (state.company === '') {
            toastifyError('Enter company name');
          } else if (state.company_size === '') {
            toastifyError('Select company size');
          } else if (state.password !== state.confirm_password) {
            toastify('Passwords do not match');
          } else if (state.password.length < 8) {
            toastify('Password must be atleast 8 characters');
          }
        }
      }
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const handleSignup = async (org_id: string) => {
    try {
      if (state.password !== state.confirm_password) {
        toastify('Passwords do not match');
      } else if (state.company_email.length > 0 && state.password.length > 0) {
        const body = {
          email: state.company_email,
          password: state.password,
          is_agency: state.is_agency,
          org: org_id,
        };
        const res: any = await Models.auth.signup(body);
        getUserDetails(res?.data);
        await localStorage.setItem('token', res.token);
        await localStorage.setItem('user_id', res.data._id);
        await localStorage.setItem(
          'user',
          JSON.stringify({
            user_id: res.data._id,
            full_name: `${res.data.first_name} ${res.data.last_name}`,
            role: res.data.role,
            email: res.data.email,
          }),
        );
        await localStorage.setItem('org', JSON.stringify(res.data.org));
        setAppRoute({ email: res?.data?.email });
        if (redirect) {
          window.location.href = `${redirect}`;
        } else {
          window.location.href = '/create_profile';
        }
      } else if (state.company_email.length == 0) {
        toastifyError('Enter your email');
      } else {
        toastifyError('Enter your password');
      }
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const checkIfUserExists = async () => {
    try {
      if (state.company_email.length > 0) {
        const res: any = await Models.auth.userExists({
          email: state.company_email,
        });
        if (res.status == NetworkStatus.SUCCESS) {
          toastify('User already exists');
          return true;
        } else {
          return false;
        }
      }
    } catch (error: any) {
      console.log('error', error);
    }
  };

  //Logic
  const data = [
    { label: '1 - 10', value: 'Small' },
    { label: '11 - 100', value: 'Medium' },
    { label: '101 - 500', value: 'Large' },
    { label: '500+', value: 'Enterprise' },
  ];
  const tabData = ['Hiring for myself', 'Hiring for clients'];
  const handleSelectTab = (value: string) => {
    if (value == 'Hiring for myself') {
      setState({ is_agency: false });
    } else {
      setState({ is_agency: true });
    }
    setState({ agency_name: value });
  };

  return (
    <div className="signup_container">
      <div className="signup_wrapper">
        <div className="signup_container_logo">
          <img
            src={Assets.logo}
            height={100}
            width={100}
            alt="sidebar logo"
            className="sidebar_logo"
          />
        </div>
        {/* <div className="welcome">Welcome 👋</div> */}
        <div className="create_an_account">Create an Account</div>
        <div className="inputs">
          <div className="input_heading">
            Work Email<span className="required_field">*</span>
          </div>
          <div className="input_box">
            <Input
              type={'text'}
              onChange={(company_email) => setState({ company_email })}
              value={state.company_email}
              name={'email'}
            />
          </div>
          <div className="input_heading">
            Password<span className="required_field">*</span>
          </div>
          <div className="input_box">
            <Input
              name={'password'}
              onChange={(password) => setState({ password })}
              value={state.password}
              type={state.visible ? 'text' : 'password'}
              icon={state.visible ? Assets.eye_slash : Assets.eye}
              iconOnPress={() => setState({ visible: !state.visible })}
              error={state.errorArray}
            />
          </div>
          <div className="input_heading">
            Confirm Password<span className="required_field">*</span>
          </div>
          <div className="input_box">
            <Input
              type={state.confirm_password_visible ? 'text' : 'password'}
              onChange={(confirm_password) => setState({ confirm_password })}
              value={state.confirm_password}
              name={'comfirm_password'}
              icon={
                state.confirm_password_visible ? Assets.eye_slash : Assets.eye
              }
              iconOnPress={() =>
                setState({
                  confirm_password_visible: !state.confirm_password_visible,
                })
              }
            />
          </div>
          <div className="input_heading">Company name</div>
          <div className="input_box">
            <Input
              type={'text'}
              onChange={(company) => setState({ company })}
              value={state.company}
              name={'company'}
            />
          </div>
          <div className="input_heading">Company size</div>
          <div className="input_box">
            <Dropdown
              data={data}
              setActive={(company_size: any) =>
                setState({ company_size: company_size.value })
              }
            />
          </div>
          {!redirect && <div className="hiring">
            <Tabs
              data={tabData}
              selected={(value: string) => {
                handleSelectTab(value);
              }}
            />
          </div>}
          <div
            className="terms_and_condition"
            onClick={() => setState({ checked: !state.checked })}>
            <CheckBox
              checked={state.checked}
              onPress={() => setState({ checked: !state.checked })}
            />
            {/* <div
              className={`${state.checked && 'checked'} checkbox_wrapper`}
              onClick={() => setState({ checked: !state.checked })}>
              {state.checked && (
                <img
                  src={Assets.tick}
                  alt="image"
                  className={`input_icon ${props.icon_class}`}
                />
              )}
            </div> */}
            <div className="text">Accept Terms and Condition</div>
          </div>
          <div className="sign_up">
            <PrimaryButton
              text={'Sign Up'}
              disabled={state.checked ? false : true}
              onClick={createOrganization}
              className={'sign_up_btn'}
            />
          </div>
          <div className="footer">
            <div>Already have an Account?</div>
            <Link to={state.link} className="login">
              Login
            </Link>
          </div>
          <div className="policy_text">
            <a
              href="https://www.askhugo.io/privacy-policy"
              className="login"
              target={'_blank'}>
              View Privacy Policy
            </a>
          </div>
          <div className="policy_text">
            <a
              href="https://www.askhugo.io/terms-and-conditions"
              target={'_blank'}
              className="login">
              View Terms and Condition
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
