import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import {
  admin_home_data,
  admin_settings_data,
  agency_home_data,
  agency_settings,
  UserRole,
  user_settings_data,
  super_admin_data,
} from 'utils/constant.utils';
import { checkAgency, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  Navbutton,
  Colors,
  EmailResume,
  SupportEmail,
} from 'utils/imports.utils';
import { getUserDetails, testDispatch } from 'utils/redux.utils';
import './sidebar.ui.scss';

const Sidebar = (props: any) => {
  // Redux
  const userState = useSelector((state: any) => state.user);
  // State
  const [state, setState] = useSetState({ hide: false });

  //Hooks
  useEffect(() => {
    // getActiveRoute();
    getUser()
  }, []);
  const getUser = async () => {
    let userId = localStorage.getItem('user_id');
    try {
      const res: any = await Models.auth.viewUser(userId);
      // dispatch
      getUserDetails(res.data);
    } catch (error: any) {
      if (error == 'invalid signature'|| error == 'Invalid token') {
        localStorage.clear();
        window.location.href = '/login';
      }
      console.log(error);
    }
  };
  const navigate = useNavigate();
  const resumeModalRef: any = useRef();
  // Network req

  //Logic
  const getActiveRoute = () => {
    let route = window.location.href.split('/').pop();
    if (window.location.href.includes('settings')) {
      return `/settings/${route}`;
    } else {
      return `/${route}`;
    }
  };

  const getSidebarData = () => {
    let route = window.location.href;
    if (route.includes('settings')) {
         if (userState.auth?.role == UserRole.ADMIN) {
        if (userState.auth?.is_agency) {
          return agency_settings;
        } else {
          return admin_settings_data;
        }
      } else {
        return user_settings_data;
      }
    } else {
      // if (userState.auth?.role == UserRole.ADMIN) {
      //   return admin_home_data;
      // } else {
      //   return admin_home_data;
      // }
      if(userState.auth?.role == UserRole.SUPER_ADMIN){
        return super_admin_data;
      }
     else if (userState.auth?.role == UserRole.ADMIN) {
        if (checkAgency()) {
          return agency_home_data;
        } else {
          return admin_home_data;
        }
      } else {
        return admin_home_data;
      }
    }
  };

const navigateTo = () => {
  if(checkAgency()){
    navigate('/requisition_board')
  }else{
    navigate('/home')
  }
}

  return (
    <div
      className={`${state.hide && 'sidebar_hide_container'} sidebar_container`}>
      <div className="sidebar_content">
        <div className={`${state.hide && 'sidebar_icon_hide'} sidebar_header`}>
          {/* {!state.hide && ( */}
          <img src={Assets.logo} alt="sidebar logo" className="sidebar_logo" />
          {/* )} */}
          <img
            src={state.hide ? Assets.burger_icon_2 : Assets.burger_icon_1}
            alt="burger icon"
            onClick={() => {
              setState({ hide: !state.hide });
              props.onHide(!state.hide);
            }}
            style={state.hide ? { paddingTop: '10px' } : { paddingTop: '0px' }}
            className="sidebar_hamburger_icon"
          />
        </div>
        <div className="sidebar_heading">
          <div
            className="sidebar_active_route"
            style={
              state.hide
                ? { justifyContent: 'center' }
                : { justifyContent: 'flex-start' }
            }
            onClick={() => {
              navigateTo()
            }}>
            {getActiveRoute().includes('settings') && (
              <img
                src={Assets.back}
                onClick={() => {
                  navigateTo()
                }}
                className="sidebar_back_icon"
              />
            )}
            {!state.hide && getActiveRoute().includes('settings') && 'Settings'}
          </div>
        </div>
        <div className="sidebar_element_container">
          {getSidebarData().map((item: any) => (
            <Link
              to={item.route}
              className={`${
                getActiveRoute() == item.route.toLowerCase() ? 'active_tab' : ''
              } sidebar_element`}
              onClick={() => setState({ active_tab: item.name })}>
              <div
                className={`${
                  (state.hide && 'sidebar_hide_icon') || ''
                } sidebar_icon`}>
                <img
                  src={
                    getActiveRoute() == item.route.toLowerCase()
                      ? item.active_icon
                      : item.inactive_icon
                  }
                  height={20}
                  width={20}
                  alt="icon"
                />
              </div>
              {!state.hide && (
                <div
                  className={`${
                    getActiveRoute() == item.route.toLowerCase()
                      ? 'element_name_active'
                      : ''
                  } sidebar_element_name`}>
                  {item.name}
                </div>
              )}
            </Link>
          ))}
          {/* </div> */}
        </div>
        {!getActiveRoute().includes('settings') && (
          <div className="support_element_container">
            <div className="email_element">
              <div
                className={`${
                  state.hide ? 'email_support_hide_icon' : 'email_support_icon'
                }`}
                onClick={() => resumeModalRef?.current?.openModal()}>
                {/* <a href="mailto:support@askhugo.zohodesk.in" target="_blank"> */}
                <img
                  src={Assets.support_email}
                  height={20}
                  width={20}
                  alt="icon"
                />
                {/* </a> */}
              </div>
              {!state.hide && (
                <div
                  className={`sidebar_element_name`}
                  onClick={() => resumeModalRef?.current?.openModal()}>
                  {/* <a href="mailto:support@askhugo.zohodesk.in" target="_blank"> */}
                  Email Support
                  {/* </a> */}
                </div>
              )}
            </div>
          </div>
        )}
        <SupportEmail ref={resumeModalRef} />
      </div>
    </div>
  );
};

export default Sidebar;
