import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { isTemplateMiddle } from 'typescript';
import {
  addCommasToCurrency,
  checkAgency,
  getDropdownData,
  getDropdownValue,
  mergeArrayWithoutDuplicates,
  phoneNumberCheck,
  toastify,
  useSetState,
} from 'utils/functions.utils';
import {
  Tag,
  Assets,
  Attachment,
  Categories,
  Funnel,
  Dropdown,
  PrimaryButton,
  InviteModal,
  KanbanBoard,
  UploadResumeModal,
  Models,
  Loader,
  EmailResume,
  FileUpload,
  DeletePop,
  Pop,
  BulkUploadResumeModal,
  MultiSelectDropdown,
  Colors,
  ApprovalModal,
  ApprovalListComponent,
} from 'utils/imports.utils';
import '../job-requisition/requisition.screen.scss';
import { useParams } from 'react-router-dom';
import { toastifyError, useQuery } from 'utils/functions.utils';
import { getAllCandidates, getRecquisitionById, getUserDetails } from '../../utils/redux.utils';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import _ from 'lodash';
import {
  agency_candidate_stage,
  candidate_stage,
  CANDIDATE_STATUS,
  REQ_APPROVAL,
  REQUISITION_STATUS,
  RESUME_PARSER,
  SOURCE,
  SUBSCRIPTION_FEATURES,
  SUBSCRIPTION_PLAN,
} from 'utils/constant.utils';

const Requisition = () => {
  const navigate = useNavigate();
  const query = useQuery();
  // redux
  const requisition = useSelector((state: any) => state.recquisition);
  const candidateState = useSelector((state: any) => state.candidate);
  const userState = useSelector((state: any) => state.user.auth);
  const modalRef: any = useRef();
  const uploadModalRef: any = useRef();
  const approvalRejectRef: any = useRef();
  const bulkUploadModalRef: any = useRef();
  // state
  const [state, setState] = useSetState({
    disposition: '',
    board_data: '',
    summary: {},
    loading: false,
    limit: 50,
    skip: 0,
    loadMore: true,
    applied: true,
    screening: true,
    interviewing: true,
    offered: true,
    joined: true,
    stage: '',
    selectedCandidates: [],
    agencies_list: [],
    agency: [],
    modal: false,
    cloning: false,
    emptyEmail: [],
    fileCount: 0,
    approvalUser: '',
    planName: '',
    subscribed: false,
      });
  let req_id: any = query?.get('id');
  const resumeModalRef: any = useRef();
  const inputFileRef: any = useRef();
  const ApprovalSequential = [
    { label: 'User', value: 'label', width: '35%' },
    { label: 'Priority', value: 'priority', width: '10%' },
    { label: 'Status', value: 'status', width: '20%' },
    { label: 'Reason', value: 'reason', width: '30%' },
  ];
  const ApprovalParallel = [
    { label: 'User', value: 'label', width: '45%' },
    { label: 'Status', value: 'status', width: '20%' },
    { label: 'reason', value: 'reason', width: '30%' },
  ];

  // Network
  const recquisitionById = async () => {
    try {
      setState({ loading: true });
      const body = {
        requisition_id: req_id,
      };
      const response: any = await Models.jobRecquisition.getRecquisitionById(
        body,
      );
      // dispatch
      if (response.data?.subscription?.subscribed) {
        setState({
          planName: response.data.subscription.plan_name,
          subscribed: response.data.subscription.subscribed,
                  });
      }
      getRecquisitionById(response.data);
      // getManyCandidates();
      getReqSummary();
      let agencies_list: any = [];
      if (response.data.agencies?.length > 0) {
        response.data.agencies.map((item: any) =>
          agencies_list.push({ label: item.name, value: item._id }),
        );
        setState({ agency: agencies_list });
      }
      setState({ loading: false });
    } catch (error) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const changeReqStatus = async (status: any) => {
    try {
      let body = {
        requisition_id: req_id,
        status: status.value,
      };
      let requisitionData = requisition?.recquisition_by_id;
      requisitionData.status = status.value;
            const res: any = await Models.jobRecquisition.editRequisition(body);
      getRecquisitionById(requisitionData);
      setState({ skip: 0 });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getManyCandidates = async (stage?: any) => {
    try {
      let body: any = {
        requisition_id: req_id,
        limit: state.limit,
        skip: 0,
        stage,
      };
      let agency = localStorage.getItem('agency');
      if (agency) {
        body.agency = agency;
      }
      const res: any = await Models.candidateModel.getManyCandidates(body);
      if (res.data.totalDocs === res.data.docs.length) {
        setState({ [stage.toLowerCase()]: false });
      } else {
        setState({ [stage.toLowerCase()]: true });
      }
      //dispatch
      getAllCandidates(res.data.docs);
      return res.data.docs;
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getReqSummary = async () => {
    try {
      let query: any = {
        requisition_id: req_id,
      };
      let agency = localStorage.getItem('agency');
      if (agency) {
        query.agency = agency;
      }
      const res: any = await Models.candidateModel.getSummary(query);
      setState({ summary: res?.data });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const loadMoreCandidates = async (stage: string) => {
    try {
      let body = {
        requisition_id: req_id,
        limit: state.limit,
        skip: state.skip + 50,
        stage,
      };
      const res: any = await Models.candidateModel.getManyCandidates(body);
      let data = mergeArrayWithoutDuplicates(
        candidateState?.candidate_list,
        res?.data.docs,
      );
      let board_data: any = state.board_data;
      let index = _.findIndex(board_data.columns, { title: stage });
      board_data.columns[index].cards = [
        ...board_data.columns[index].cards,
        ...res.data.docs,
      ];
      if (res.data.totalDocs === board_data.columns[index].cards.length) {
        setState({ [stage.toLowerCase()]: false });
      } else {
        setState({ [stage.toLowerCase()]: true });
      }
      setState({ skip: state.skip + 50, board_data });
      //dispatch
      getAllCandidates(data);
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const handleFilterCandidates = (data: any) => {
    let applied: any = _.filter(data, (item: any) => {
      if (item.stage == CANDIDATE_STATUS.APPLIED) {
        return true;
      }
    });
    let screening: any = _.filter(data, (item: any) => {
      if (
        item.stage == CANDIDATE_STATUS.SCREENING ||
        item.stage == CANDIDATE_STATUS.REQUEST_FOR_SCREENING_AVAILABILITY ||
        item.stage == CANDIDATE_STATUS.CONFIRM_SCREENING_AVAILABILITY ||
        item.stage == CANDIDATE_STATUS.LONGLIST ||
        item.stage == CANDIDATE_STATUS.SHORTLIST
      ) {
        return true;
      }
    });
    let interviewing: any = _.filter(data, (item: any) => {
      if (
        item.stage == CANDIDATE_STATUS.CONFIRM_INTERVIEW_AVAILABILITY ||
        item.stage == CANDIDATE_STATUS.REQUEST_FOR_INTERVIEW_AVAILABILITY ||
        item.stage == CANDIDATE_STATUS.INTERVIEWING
      ) {
        return true;
      }
    });
    let offered: any = _.filter(data, (item: any) => {
      if (
        item.stage == CANDIDATE_STATUS.OFFERED ||
        item.stage == CANDIDATE_STATUS.OFFER_ACCEPTED ||
        item.stage == CANDIDATE_STATUS.OFFER_DECLINED
      ) {
        return true;
      }
    });
    let joined: any = _.filter(data, (item: any) => {
      if (item.stage == CANDIDATE_STATUS.JOINED) {
        return true;
      }
    });
    let reject: any = _.filter(data, (item: any) => {
      if (
        item.stage == CANDIDATE_STATUS.REJECT ||
        item.stage == CANDIDATE_STATUS.INTERVIEW_REJECT ||
        item.stage == CANDIDATE_STATUS.SCREEN_REJECT ||
        item.stage == CANDIDATE_STATUS.ON_HOLD ||
        item.stage == CANDIDATE_STATUS.OFFER_DECLINED ||
        item.stage == CANDIDATE_STATUS.OFFER_WITHDRAWN
      ) {
        return true;
      }
    });
    let submitted: any = _.filter(data, (item: any) => {
      if (
        item.stage == CANDIDATE_STATUS.SUBMITTED ||
        item.stage == CANDIDATE_STATUS.TO_BE_SUBMITTED
      ) {
        return true;
      }
    });
    if (!userState.is_agency) {
      screening = [...screening, ...submitted];
    }
    if (userState.is_agency) {
      let board_data = {
        columns: [
          {
            id: 1,
            title: 'Applied',
            cards: applied,
          },
          { id: 2, title: 'Screening', cards: screening },
          { id: 3, title: 'Submitted', cards: submitted },
          { id: 4, title: 'Interviewing', cards: interviewing },
          { id: 5, title: 'Offered', cards: offered },
          { id: 6, title: 'Joined', cards: joined },
          { id: 7, title: 'Others', cards: reject },
        ],
      };
      setState({ board_data });
    } else {
      let board_data = {
        columns: [
          {
            id: 1,
            title: 'Applied',
            cards: applied,
          },
          { id: 2, title: 'Screening', cards: screening },
          { id: 3, title: 'Interviewing', cards: interviewing },
          { id: 4, title: 'Offered', cards: offered },
          { id: 5, title: 'Joined', cards: joined },
          { id: 6, title: 'Others', cards: reject },
        ],
      };
      setState({ board_data });
    }
  };

  const promises = async () => {
    try {
      let requests: any = [];
      if (userState.is_agency) {
        for (let i = 0; i < agency_candidate_stage.length; i++) {
          let data = await getManyCandidates(agency_candidate_stage[i]);
          requests = [...data, ...requests];
        }
      } else {
        for (let i = 0; i < candidate_stage.length; i++) {
          let data = await getManyCandidates(candidate_stage[i]);
          requests = [...data, ...requests];
        }
      }
      handleFilterCandidates(requests);
    } catch (error) {
      console.log('error', error);
      toastifyError('Unable to fetch candidate list');
    }
  };

  const cloneRequisition = async () => {
    try {
      setState({ modal: false, cloning: true });
      let is_agency = userState.is_agency || false;
      const body: any = {
        requisition_id: req_id,
        is_agency: is_agency,
      };
      let planName: string =
        state.planName.toLowerCase() || SUBSCRIPTION_PLAN.BASIC;
      let check: any =
        _.toNumber(requisition?.recquisition_by_id?.number_of_vacancies) <=
        userState?.org?.[planName]?.quantity;
      if (state.subscribed) {
        if (userState?.org?.subscribed) {
          if(!is_agency || planName === SUBSCRIPTION_PLAN.BASIC) {
          if (check) {
            body.subscription = {
              plan_name: planName,
              subscribed: userState?.org?.subscribed,
            };
          } else {
            let error = `You have only ${userState?.org?.[planName]?.quantity} vacancies`;
            toastifyError(error);
            setState({ cloning: false });
            return;
          }
          }
        } else {
          let error = `Please update your plan`;
          toastifyError(error);
          setState({ cloning: false });
          return;
        }
      } else if (planName === SUBSCRIPTION_PLAN.BASIC && !check) {
        let error = `You have only ${userState?.org?.[planName]?.quantity} vacancies`;
        if (userState?.org?.[planName]?.quantity <= 0) {
          error = `Please update your plan`;
        }
        toastifyError(error);
        setState({ cloning: false });
        return;
      }
       
      // setState({ cloning: false });
      // return;
      const response: any = await Models.jobRecquisition.cloneRequisition(body);
      toastify('Requisition cloned');
      navigate(`/requisition?id=${response.data._id}`);
      setState({ modal: false, cloning: false });
      getUser()
    } catch (error) {
      toastifyError(error);
    }
  };

  useEffect(() => {
    recquisitionById();
    // getManyCandidates();
    getAllAgency();
    promises();
  }, [req_id]);

  const disposition_list = ['Open', 'On hold', 'Cancelled', 'Filled'];

  const kanbanLoadMoreStatus = (status: string) => {
    if (state[status.toLowerCase()]) {
      return true;
    }
  };

  const filterCandidates = async (stage, filter) => {
    try {
      let body: any = {
        requisition_id: req_id,
        limit: state.limit,
        skip: 0,
        stage,
        filter,
      };
      const res: any = await Models.candidateModel.getManyCandidates(body);
      if (res.data.totalDocs === res.data.docs.length) {
        setState({ [stage.toLowerCase()]: false });
      } else {
        setState({ [stage.toLowerCase()]: true });
      }
      let board_data = state.board_data;
      board_data.columns.map((item) => {
        if (item.title === stage) {
          item.cards = res.data.docs;
        }
        return item;
      });
      setState({ board_data });
      return res.data.docs;
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const resumeParser = async (file: string) => {
    try {
      setState({ parsing: true, emptyEmail: [] });
      let agency: any = localStorage.getItem('agency');
      let body = {
        url: file,
        type: RESUME_PARSER.AFFINDA,
        job_description: requisition.recquisition_by_id.job_description_file,
      };
      const res: any = await Models.resume.parseResume(body);
      let skills_value: any = [];
      let resumeBody: any = {
        requisition_id: requisition.recquisition_by_id._id,
        job_description_identifier: res.data.job_description_identifier,
        status: CANDIDATE_STATUS.APPLIED,
        uploaded_by: userState._id,
      };
      // if (agency!=='') {
      //   resumeBody.agency=agency
      // }
      res.data.parsed_resume?.data?.skills?.map((skill: any) =>
        skills_value.push({ label: skill.name, value: skill.name }),
      );
      let employers: any[] = [];
      if (res.data.parsed_resume?.data?.workExperience?.length > 0) {
        res.data.parsed_resume?.data?.workExperience.map((item: any) =>
          employers.push({
            employer: item.organization,
            job_title: item.jobTitle,
            start_date: item.dates?.startDate,
            end_date: item.dates?.endDate,
          }),
        );
        setState({ employers, employerLength: employers.length });
      }
      resumeBody.resume = {
        email:
          res.data.parsed_resume?.data?.emails?.length > 0
            ? res.data.parsed_resume?.data?.emails[0]
            : '',
        phone: !_.isEmpty(res.data.parsed_resume?.data?.phoneNumbers[0])
          ? phoneNumberCheck(res.data.parsed_resume?.data?.phoneNumbers[0])
          : '',
        resume: file,
        first_name: res.data.parsed_resume?.data?.name?.first,
        last_name: res.data.parsed_resume?.data?.name?.last,
        address: res.data.parsed_resume?.data?.location?.formatted,
        total_experience: res.data.parsed_resume?.data?.totalYearsExperience,
        dob: res.data.parsed_resume?.data?.dateOfBirth,
        pincode: res.data.parsed_resume?.data?.location?.postalCode,
        skills: skills_value,
        employers,
        state: res.data.parsed_resume?.data?.location?.state,
        city: res.data.parsed_resume?.data?.location?.city,
        country: res.data.parsed_resume?.data?.location?.country,
        resume_identifier: res.data.parsed_resume.meta.identifier,
        bulk_upload: true,
        raw_text: res.data.parsed_resume.data?.rawText,
      };
      if (agency && agency.length > 0) {
        resumeBody.resume.source = SOURCE.AGENCY;
        resumeBody.agency = agency;
      } else {
        resumeBody.resume.source = SOURCE.REFERRAL;
      }
      setState({
        candidate_name: `${res.data.parsed_resume?.data?.name?.first} ${res.data.parsed_resume?.data?.name?.last}`,
      });
      await bulkUploadResume(resumeBody);
      setState({ parsing: false });
    } catch (error) {
      setState({ parsing: false });
      toastifyError(error);
    }
  };
  const uploadResume = (resume_file: string) => {
    let fileName = resume_file.split('.').pop();
    if (fileName === 'pdf' || fileName === 'doc' || fileName === 'docx') {
      resumeParser(resume_file);
    }
    setState({
      resume_file,
    });
  };

  const bulkUploadResume = async (body: any) => {
    try {
      let res: any = await Models.candidateModel.uploadBulkResume(body);
      let data = await getManyCandidates(CANDIDATE_STATUS.APPLIED);
      let board_data = state.board_data;
      board_data.columns.map((item) => {
        if (item.title === CANDIDATE_STATUS.APPLIED) {
          item.cards = data;
        }
        return item;
      });
      setState({ board_data });
      toastify('Candidate uploaded');
          } catch (error: any) {
      toastifyError(error.message);
      if (error.data) {
        let data: any = state.emptyEmail;
        data.push(error.data);
        // data.map((item)=>{item.resume.uploaded=false;return item;})
        setState({ emptyEmail: data });
        bulkUploadModalRef.current.openModal();
      }
    }
  };

  const getAllAgency = async () => {
    try {
      const res: any = await Models.agency.getAllAgency({
        limit: 100,
        skip: 0,
        invite_accepted: true,
      });
      let agencies_list: any = [];
      res.data.docs.map((item: any) =>
        agencies_list.push({ label: item.name, value: item._id }),
      );
      setState({ agencies_list });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const handleApprovalAccept = async () => {
    try {
      let body: any = {
        requisition_id: req_id,
        user_id: userState._id,
      };
      const res: any = await Models.jobRecquisition.requisitionApproval(body);
      setState({ approvalUser: false });
      const response: any = await Models.jobRecquisition.getRecquisitionById(
        body,
      );
      // dispatch
      getRecquisitionById(response.data);
      toastify('Requisition approved');
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const handleApproval = async () => {
    let approvalData: any = requisition.recquisition_by_id.approval_user;
    let index = _.findIndex(approvalData, (e: any) => {
      return e.user.value === userState._id;
    });
    if (approvalData[index]?.approval === true) {
      setState({ approvalUser: false });
    } else if (approvalData[index]?.approval === false) {
      setState({ approvalUser: false });
    } else if (_.isEmpty(approvalData[index]?.approval)) {
      if (
        requisition.recquisition_by_id.approval_type === REQ_APPROVAL.SEQUENTIAL
      ) {
        if (index === 0) {
          setState({ approvalUser: true });
        } else if (approvalData[index - 1]?.approval) {
          setState({ approvalUser: true });
        } else {
          setState({ approvalUser: false });
        }
      } else if (
        requisition.recquisition_by_id.approval_type === REQ_APPROVAL.PARALLEL
      ) {
        setState({ approvalUser: true });
      }
    }
  };

  const getUser = async () => {
    let userId = localStorage.getItem('user_id');
    try {
      const res: any = await Models.auth.viewUser(userId);
      // dispatch
      getUserDetails(res.data);
    } catch (error: any) {
      if (error == 'invalid signature' || error == 'Invalid token') {
        localStorage.clear();
        window.location.href = '/login';
      }
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      requisition?.recquisition_by_id.approval_required &&
      !_.isEmpty(userState)
    ) {
      handleApproval();
          }
    // if (userState?.org?.subscribed && !userState?.is_agency) {
    //   setState({ subscribed: userState.org.subscribed });
    // }
  }, [userState, requisition]);
  
  if (state.loading) return <Loader />;
  return (
    <section className="requisition-container">
      <div className="requisition-content-block">
        {state.approvalUser && (
          <div className="approval_user_wrapper">
            <div className="approval_user_content">{`${requisition?.recquisition_by_id.created_by.first_name} ${requisition?.recquisition_by_id.created_by.last_name} (${requisition?.recquisition_by_id.created_by.email}) sent you an approval request for requisition - ID JR-${requisition?.recquisition_by_id?.id}`}</div>
            <div className="approval_user_btn">
              <PrimaryButton
                text={'Reject'}
                onClick={() => {
                  approvalRejectRef.current.openModal();
                }}
                backgroundColor={Colors.secondaryBtnColor}
                color={Colors.primaryDarkColor}
                width={'140px'}
              />
              <PrimaryButton
                text={'Accept'}
                onClick={handleApprovalAccept}
                width={'140px'}
              />
              <ApprovalModal ref={approvalRejectRef} type={'approvalReject'} />
            </div>
          </div>
        )}
        <div className="requisition_header_container">
          <div className="requisition_header_wrapper">
            <div
              className="requisition_header_icon"
              onClick={() => navigate('/requisition_board')}>
              <img src={Assets.back} alt="back arrow" className="back_icon" />
            </div>
            <div className="requisition_header_text">Job Requisition</div>
          </div>
          <div className="requisition_header_buttons">
            {!checkAgency() && (
              <div className="requisition_header_dropdown">
                <div className="requisition_dropdown_heading">
                  Job requisition status
                </div>
                <div className="requisition_status_dropdown">
                  <Dropdown
                    disabled={
                      requisition?.recquisition_by_id.approval_required &&
                      requisition?.recquisition_by_id?.status ===
                        REQUISITION_STATUS.DRAFT
                        ? true
                        : false
                    }
                    data={getDropdownData(disposition_list)}
                    value={[
                      {
                        label: requisition?.recquisition_by_id?.status,
                        value: requisition?.recquisition_by_id?.status,
                      },
                    ]}
                    setActive={(status: any) => changeReqStatus(status)}
                  />
                </div>
              </div>
            )}
            {!checkAgency() && (
              <div className="requisition_button_container">
                <PrimaryButton
                  text={'Search Resume'}
                  icon={Assets.search_white}
                  className={'job_req_header_button'}
                  onClick={() => {
                    navigate('/resume_search');
                  }}
                />
                {state.cloning ? (
                  <div style={{ width: '80px' }}>
                    <Loader />
                  </div>
                ) : (
                  <PrimaryButton
                    // disabled={state.subscribed ? true : false}
                    text={'Clone Requisition'}
                    // icon={
                    //      state.subscribed
                    //     ? Assets.copy_white_inactive
                    //     : Assets.copy_white
                    // }
                     icon={
                         Assets.copy_white
                    }
                    className={'job_req_header_button'}
                    onClick={() => {
                      setState({ modal: true });
                    }}
                  />
                )}
                <PrimaryButton
                  onClick={() =>
                    navigate(
                      `/post_job?req_id=${requisition?.recquisition_by_id?._id}`,
                    )
                  }
                  text={'Post Job'}
                  icon={
                    requisition?.recquisition_by_id.open_vacancies > 0
                      ? Assets.post_job
                      : ''
                  }
                  className={'job_req_header_button'}
                  disabled={
                    requisition?.recquisition_by_id.open_vacancies > 0
                      ? false
                      : true
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="content-block">
          <div className="card">
            <div className="canditate_block">
              <div className="content_block">
                <div className="intro-id">
                  <h4 className="title">
                    Requisition ID: JR-{requisition?.recquisition_by_id?.id}
                  </h4>
                  {!checkAgency() &&
                  !requisition?.recquisition_by_id.approval_required ? (
                    <div
                      className="edit-requisition"
                      onClick={() =>
                        navigate(
                          `/new_job_requisition?id=${requisition?.recquisition_by_id?._id}`,
                        )
                      }>
                      <img src={Assets.edit} />
                      <div className="edit_req_title">Edit Requisition</div>
                    </div>
                  ) : !checkAgency() &&
                    _.every(
                      requisition?.recquisition_by_id.approval_user,
                      (e: any) => {
                        return requisition?.recquisition_by_id.approval_type ===
                          REQ_APPROVAL.SEQUENTIAL
                          ? _.isEmpty(e.reason)
                          : !_.every(
                              requisition?.recquisition_by_id.approval_user,
                              (e: any) => {
                                return !_.isEmpty(e.reason);
                              },
                            );
                      },
                    ) ? (
                    <div
                      className="edit-requisition"
                      onClick={() =>
                        navigate(
                          `/new_job_requisition?id=${requisition?.recquisition_by_id?._id}`,
                        )
                      }>
                      <img src={Assets.edit} />
                      <div className="edit_req_title">Edit Requisition</div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="data_wrapper">
                  {!_.isEmpty(requisition?.recquisition_by_id?.open_date) && (
                    <div className="open_data">
                      <h6 className="title">Open date</h6>
                      <div className="para">
                        {moment(
                          requisition?.recquisition_by_id?.open_date,
                        ).format('DD-MM-YYYY')}
                      </div>
                    </div>
                  )}
                  <div className="close_data">
                    <h6 className="title">Target close date</h6>
                    <div className="para">
                      {moment(
                        requisition?.recquisition_by_id?.target_close_date,
                      ).format('DD-MM-YYYY')}
                    </div>
                  </div>
                </div>
                <div className="job_description_row">
                  <div className="job_description_column">
                    <h6 className="title">Job Title</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.job_title}
                    </p>
                  </div>
                  <div className="job_description_column">
                    <h6 className="title">Job Type</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.job_type}
                    </p>
                  </div>
                  <div className="job_description_column">
                    <h6 className="title">Job Role Type</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.job_role_type}
                    </p>
                  </div>
                  {requisition?.recquisition_by_id?.salary_min &&
                    requisition?.recquisition_by_id?.salary_max && (
                      <div className="job_description_column">
                        <h6 className="title">Salary Range</h6>
                        <p className="para">{`
                    ${addCommasToCurrency(
                      requisition?.recquisition_by_id?.salary_min,
                    )} - ${addCommasToCurrency(
                          requisition?.recquisition_by_id?.salary_max,
                        )} ${
                          requisition?.recquisition_by_id?.salary_currency
                            ? `(${requisition?.recquisition_by_id?.salary_currency})`
                            : ''
                        }`}</p>
                      </div>
                    )}
                </div>
                <div className="job_description_row">
                  {/* <div className={'job_description_column'}>
                    <h6 className="title">Job location</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.job_location}
                    </p>
                  </div> */}
                  <div className={'job_description_column flexTwo'}>
                    <h6 className="title">Job location</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.work_location?.address}
                    </p>
                  </div>
                </div>
                <div className="job_description_row flexTwo">
                  <div className="job_description_column">
                    <h6 className="title">Departments</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.department?.name}
                    </p>
                  </div>
                  <div className="job_description_column">
                    <h6 className="title">Business Unit</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.business_unit?.name}
                    </p>
                  </div>
                  <div className="job_description_column">
                    <h6 className="title">No of Vacancies</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.number_of_vacancies}
                    </p>
                  </div>
                  <div className="job_description_column">
                    <h6 className="title">Available vacancies</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.open_vacancies}
                    </p>
                  </div>
                </div>
                <div className="job_description_row">
                  <div className="job_description_column">
                    <h6 className="title">Headcount type</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.headcount_type}
                    </p>
                  </div>
                  <div className="job_description_column">
                    <h6 className="title">Remote working</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.remote_working}
                    </p>
                  </div>
                  <div className="job_description_column">
                    <h6 className="title">Min Experience Per Year</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.min_experience} years
                    </p>
                  </div>
                  <div className="job_description_column">
                    <h6 className="title">Max Experience Per Year</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.max_experience} years
                    </p>
                  </div>
                  {/* <div className="job_description_column">
                    <h6 className="title">Bonus payout</h6>
                    <p className="para">
                      {requisition?.recquisition_by_id?.bonus_payout_value}
                    </p>
                  </div> */}
                </div>
                {userState.is_agency && (
                  <div className="job_description_row flexTwo">
                    {!_.isEmpty(requisition?.recquisition_by_id?.poc) && (
                      <div className="job_description_column">
                        <h6 className="title">POC</h6>
                        <p className="para">
                          {requisition?.recquisition_by_id?.poc}
                        </p>
                      </div>
                    )}
                    {!_.isEmpty(
                      requisition?.recquisition_by_id?.account_manager,
                    ) && (
                      <div className="job_description_column">
                        <h6 className="title">Account Manger</h6>
                        <p className="para">
                          {
                            requisition?.recquisition_by_id?.account_manager
                              ?.first_name
                          }
                          {
                            requisition?.recquisition_by_id?.account_manager
                              ?.last_name
                          }
                        </p>
                      </div>
                    )}
                    {!_.isEmpty(
                      requisition?.recquisition_by_id?.client?.client_name,
                    ) && (
                      <div className="job_description_column">
                        <h6 className="title">Client</h6>
                        <p className="para">
                          {requisition?.recquisition_by_id?.client?.client_name}
                        </p>
                      </div>
                    )}
                    {!_.isEmpty(
                      requisition?.recquisition_by_id?.payment_terms?.name,
                    ) && (
                      <div className="job_description_column">
                        <h6 className="title">Payment Terms</h6>
                        <p className="para">
                          {requisition?.recquisition_by_id?.payment_terms?.name}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="image_block">
                <Funnel
                  applicationNumber={state.summary.applied}
                  // longlistedNumber={700}
                  joined={state.summary.joined}
                  interviewedNumber={state.summary.interviewing}
                  offerNumber={state.summary.offered}
                  screening={state.summary.screening}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="content-block job_description_block">
          <div className="job_description_row">
            <div
              className={
                'job_description_column flexFour job_description_column'
              }>
              <h6 className="title">Job description</h6>
              <h6 className="para">
                {parse(`${requisition?.recquisition_by_id?.job_description}`)}
              </h6>
            </div>
          </div>
        </div>
        <div className="job_description_skills_container">
          <div className=" skills_block">
            <div className="skills_hiring_interviewer_wrapper">
              <div className="skills_behaviour_wrapper">
                <div className="job_description_row skills_container">
                  <div className="job_description_column flexFour">
                    <h6 className="title" style={{ marginBottom: '0px' }}>
                      Skills
                    </h6>
                    <div className="job_description_skills_wrapper">
                      {requisition?.recquisition_by_id?.skills?.map((item) => {
                        return <Tag text={item} className={'tag_color'} />;
                      })}
                    </div>
                  </div>
                </div>
                <div className="job_description_row behaviour_container">
                  <div className="job_description_column flexFour">
                    <h6 className="title" style={{ marginBottom: '0px' }}>
                      Behaviors/Competency
                    </h6>
                    <div className="job_description_behaviours_wrapper">
                      {requisition?.recquisition_by_id?.behaviours?.map(
                        (item) => {
                          return <Tag text={item} className="tag_color" />;
                        },
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="skills_behaviour_wrapper">
                <div className="job_description_row behaviour_container">
                  <div className="job_description_column flexFour">
                    <h6 className="title" style={{ marginBottom: '0px' }}>
                      Recruiter
                    </h6>
                    <div className="job_description_skills_wrapper">
                      {requisition?.recquisition_by_id?.recruiter?.map(
                        (item) => {
                          return (
                            <Tag
                              text={`${item.first_name} ${item.last_name}`}
                              profile_picture={
                                item.profile_picture
                                  ? item.profile_picture
                                  : Assets.user_placeholder_white
                              }
                            />
                          );
                        },
                      )}
                    </div>
                  </div>
                </div>
                {!checkAgency() && userState.is_agency ? (
                  ''
                ) : (
                  <div className="job_description_row skills_container">
                    <div className="job_description_column flexFour">
                      <h6 className="title" style={{ marginBottom: '0px' }}>
                        Hiring Team
                      </h6>
                      <div className="job_description_skills_wrapper">
                        {requisition?.recquisition_by_id?.hiring_team?.map(
                          (item) => {
                            return (
                              <Tag
                                text={`${item.first_name} ${item.last_name}`}
                                profile_picture={
                                  item.profile_picture
                                    ? item.profile_picture
                                    : Assets.user_placeholder_white
                                }
                              />
                            );
                          },
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {!checkAgency() && userState.is_agency ? (
                ''
              ) : (
                <div className="skills_behaviour_wrapper">
                  <div className="job_description_row behaviour_container">
                    <div className="job_description_column flexFour">
                      <h6 className="title" style={{ marginBottom: '0px' }}>
                        Interviewer
                      </h6>
                      <div className="job_description_skills_wrapper">
                        {requisition?.recquisition_by_id?.interviewer?.map(
                          (item) => {
                            return (
                              <Tag
                                text={`${item.first_name} ${item.last_name}`}
                                profile_picture={
                                  item.profile_picture
                                    ? item.profile_picture
                                    : Assets.user_placeholder_white
                                }
                              />
                            );
                          },
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {requisition?.recquisition_by_id.approval_required && (
          <div className="create_approval_container">
            <div className="approval_view_title">Requisition Approval Team</div>
            <div className="approval_team_wrapper">
              <div className="approval_team_header">
                <div className="approval_team_title">
                  {requisition?.recquisition_by_id.approval_type}
                </div>
              </div>
              <div className="approval_view_list">
                <ApprovalListComponent
                  data={requisition?.recquisition_by_id.approval_user}
                  titleArray={
                    requisition?.recquisition_by_id.approval_type ===
                    REQ_APPROVAL.SEQUENTIAL
                      ? ApprovalSequential
                      : ApprovalParallel
                  }
                  type="approvalView"
                  loading={state.loading}
                />
              </div>
            </div>
          </div>
        )}

        <div className="content-block req_kanban_board_wrapper">
          <div className="card">
            <div className="attachment_container">
              <div className="attachment_block">
                <h4 className="title">Candidates</h4>
                <div className="cta_block">
                  <div className="upload_cta">
                    <PrimaryButton
                      icon={Assets.email_active}
                      text={'Email Resumes'}
                      onClick={() => resumeModalRef?.current.openModal()}
                    />
                  </div>
                  {/* {subscriptionPlan(
                    state.planName,
                    SUBSCRIPTION_FEATURES.BULKUPLOADRESUME,
                  ) && ( */}
                    <div className="upload_cta">
                      {state.parsing ? (
                        <Loader />
                      ) : (
                        <FileUpload
                                                  ref={inputFileRef}
                          folder={'resume'}
                          multiple
                          fileCount={(fileCount) => {
                            setState({ fileCount });
                          }}
                          onChange={(resume_file) => uploadResume(resume_file)}
                          accept_types={'.doc, .docx,.pdf'}>
                          <PrimaryButton
                            icon={Assets.uploadImage}
                            text={'Upload'}
                            onClick={() => inputFileRef?.current?.openUpload()}
                          />
                        </FileUpload>
                      )}
                    </div>
                  {/* )} */}
                </div>
              </div>
            </div>
            <div className="listed_container">
              {state.board_data && (
                <KanbanBoard
                  data={state.board_data}
                  loadMore={(column) => {
                    setState({ stage: column.title });
                    loadMoreCandidates(column.title);
                  }}
                  hasMore={(column) => kanbanLoadMoreStatus(column.title)}
                  getSelectedCandidates={(selectedCandidates: any) => {
                    setState({ selectedCandidates });
                  }}
                  candidatesIds={(candidatesIds: any) => {
                    setState({ candidatesIds });
                  }}
                  filter={(stage, filter) => {
                    setState({ filter });
                    if (stage === 'Others') {
                      filterCandidates('Reject', filter);
                    } else {
                      filterCandidates(stage, filter);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <EmailResume
          ref={resumeModalRef}
          resume={state.selectedCandidates}
          agency={userState.is_agency}
          candidatesIds={state.candidatesIds}
          callback={promises}
        />
        <Pop
          text={'Are you sure you want to clone this Requisition?'}
          onClick={cloneRequisition}
          leftBtnText={'Cancel'}
          rightBtnText={'Clone'}
          onCancel={() => setState({ modal: false })}
          open={state.modal}
        />
        {!_.isEmpty(state.emptyEmail) && (
          <BulkUploadResumeModal
            data={state.emptyEmail}
            ref={bulkUploadModalRef}
            fileCount={state.fileCount}
            onChange={() => {
              promises(), setState({ emptyEmail: [] });
            }}
          />
        )}
      </div>
    </section>
  );
};

export default Requisition;
