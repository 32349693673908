import React, { useEffect } from 'react';
import {
  addCommasToCurrency,
  mergeArrayWithoutDuplicates,
  numberFromCurrency,
  removeCommasFromCurrency,
  useSetState,
} from 'utils/functions.utils';
import {
  DeletePop,
  Dropdown,
  Loader,
  Models,
  Navbutton,
  Radio,
  Validation,
} from 'utils/imports.utils';
import './payment_terms.screen.scss';
import { Assets } from '../../utils/imports.utils';
import { Input, PrimaryButton } from '../../utils/imports.utils';
import { toastifyError } from 'utils/functions.utils';
import ListComponent from 'common_components/ui/list/list.component';
import { IEditPaymentTerms, IPaymentTerms } from 'interfaces/common.interface';
import _, { capitalize } from 'lodash';
const PaymentTerms = (props: any) => {
  //sample data
  const titleData = [
    { label: 'Name', value: 'name', width: '30%' },
    { label: 'Payment Type', value: 'payment_type', width: '20%' },
    { label: 'Due Days', value: 'due_days', width: '20%' },
  ];

  const paymentType = [
    { label: 'Fixed', value: 'fixed' },
    { label: 'Percentage', value: 'percentage' },
    { label: 'Range', value: 'range' },
  ];

  // State
  const [state, setState] = useSetState({
    name: '',
    paymentType: { label: 'Fixed', value: 'fixed' },
    clientAllData: [],
    commission: 0,
    dueDays: 0,
    modal: false,
    paymentTermsData: {},
    paymentTermsAllData: [],
    errorArray: [],
    skip: 0,
    data_limit: 50,
    loadMore: true,
    loading: false,
    isLoading: false,
    isRadio: ['fixed'],
    commError: false,
    clientData: '',
    commissionSlabs: [{ max_salary: '', min_salary: '', percentage: '' }],
    paymentTerms_id: '',
  });

  //  hooks
  useEffect(() => {
    getAllPaymentTerms();
    getAllClient();
  }, []);

  // Network req

  const getAllPaymentTerms = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.paymentTerms.getAllPaymentTerms({
        limit: state.data_limit,
        skip: 0,
      });
      if (res.data.totalDocs === res?.data?.docs.length) {
        setState({ loadMore: false });
      } else {
        setState({ loadMore: true });
      }
      setState({ paymentTermsAllData: res.data.docs, loading: false, skip: 0 });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getAllClient = async () => {
    try {
      const res: any = await Models.client.getAllClient({
        limit: state.data_limit,
        skip: 0,
      });
      let clientList: any = [];

      res.data?.docs.map((item: any) =>
        clientList.push({
          label: item.client_name,
          value: item._id,
        }),
      );
      setState({ clientAllData: clientList, skip: 0 });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const loadMorePaymentTerms = async (skip: number) => {
    try {
      const res: any = await Models.paymentTerms.getAllPaymentTerms({
        limit: state.data_limit,
        skip,
      });
      let data = mergeArrayWithoutDuplicates(
        state.paymentTermsData,
        res?.data.docs,
      );
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      setState({ paymentTermsAllData: data });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const createPaymentTerms = async () => {
    try {
      setState({
        isLoading: true,
      });
      let body: IPaymentTerms = {
        name: state.name,
        payment_type: state.paymentType.value,
        due_days: parseInt(state.dueDays),
        client: state.clientData.value,
      };
      if (state.paymentType.label === 'Range') {
        setState({ commError: true });
        let commissionSlabs: any = [];
        for (let commission_slab of state.commissionSlabs) {
          let max_salary: any = removeCommasFromCurrency(
            commission_slab.max_salary,
          );
          let min_salary: any = removeCommasFromCurrency(
            commission_slab.min_salary,
          );
          commissionSlabs.push({
            max_salary: max_salary,
            min_salary: min_salary,
            percentage: parseInt(commission_slab.percentage),
          });
        }
        body.commission_slabs = commissionSlabs;
        await Validation.paymentTermsSlabsSchema.validate(body, {
          abortEarly: false,
        });
      } else {
        body.commission = removeCommasFromCurrency(state.commission.toString());
        await Validation.paymentTermsSchema.validate(body, {
          abortEarly: false,
        });
      }
      const res: any = await Models.paymentTerms.createPaymentTerms(body);
      await getAllPaymentTerms();
      setState({
        name: '',
        paymentType: { label: 'Fixed', value: 'fixed' },
        commission: 0,
        dueDays: 0,
        isLoading: false,
        commError: false,
        clientData: '',
        commissionSlabs: [{ max_salary: '', min_salary: '', percentage: '' }],
      });
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner || [],
        isLoading: false,
      });
      setTimeout(() => {
        setState({ errorArray: [], commError: false });
      }, 3000);
    }
  };

  const editPaymentTerms = async () => {
    try {
      setState({
        isLoading: true,
      });
      let body: IEditPaymentTerms = {
        name: state.name,
        payment_type: state.paymentType.value,
        due_days: parseInt(state.dueDays),
        client: state.clientData.value,
        payment_terms_id: state.paymentTerms_id,
      };
      if (state.paymentType.label === 'Range') {
        setState({ commError: true });
        let commissionSlabs: any = [];
        for (let commission_slab of state.commissionSlabs) {
          let max_salary: any;
          max_salary = removeCommasFromCurrency(
            commission_slab.max_salary.toString(),
          );
          let min_salary: any = removeCommasFromCurrency(
            commission_slab.min_salary.toString(),
          );
          commissionSlabs.push({
            max_salary: max_salary,
            min_salary: min_salary,
            percentage: commission_slab.percentage,
            _id: commission_slab._id,
          });
        }
        body.commission_slabs = commissionSlabs;
        await Validation.editPaymentTermsSlabsSchema.validate(body, {
          abortEarly: false,
        });
      } else {
        body.commission = removeCommasFromCurrency(state.commission.toString());
        await Validation.editPaymentSchema.validate(body, {
          abortEarly: false,
        });
      }

      const res: any = await Models.paymentTerms.editPaymentTermsById(body);
      await getAllPaymentTerms();
      setState({
        name: '',
        paymentType: { label: 'Fixed', value: 'fixed' },
        commission: 0,
        dueDays: 0,
        clientData: '',
        commissionSlabs: [{ max_salary: '', min_salary: '', percentage: '' }],
        isLoading: false,
        commError: false,
      });
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner || [],
        isLoading: false,
      });
      setTimeout(() => {
        setState({ errorArray: [], commError: false });
      }, 3000);
    }
  };

  const deletePaymentTerms = async () => {
    try {
      const body = {
        payment_terms_id: state.paymentTermsData._id,
      };
      const res: any = await Models.paymentTerms.deletePaymentTermsById(body);
      setState({ paymentTermsData: '', modal: false });
      await getAllPaymentTerms();
    } catch (error: any) {
      toastifyError(error);
    }
  };
  const getPaymentTerms = async (id: any) => {
    try {
      const body = {
        payment_terms_id: id,
      };
      const res: any = await Models.paymentTerms.getPaymentTerms(body);
      setState({
        name: res.data.name,
        paymentTerms_id: res.data._id,
        commissionSlabs: res.data.commission_slabs,
        commission: res.data.commission,
        paymentType: {
          label: capitalize(res.data.payment_type),
          value: res.data.payment_type,
        },
        dueDays: res.data.due_days,
        clientData: {
          label: capitalize(res.data.client.client_name),
          value: res.data.client._id,
        },
      });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  let actionArray = {
    style: { width: '30%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div style={{ display: 'flex', gap: '20px' }}>
              <Navbutton
                icon={Assets.edit}
                onClick={() => getPaymentTerms(item._id)}
              />
              <Navbutton
                icon={Assets.trash}
                onClick={() =>
                  setState({ paymentTermsData: item, modal: true })
                }
              />
            </div>
          );
        },
      },
    ],
  };

  const commAddHandler = () => {
    let data: any = state.commissionSlabs;
    data.push({ max_salary: '', min_salary: '', percentage: '' });
    setState({ commissionSlabs: data });
  };

  const commUpdateHandler = (item, index) => {
    let data: any = state.commissionSlabs;

    if (numberFromCurrency(item.min_salary) || item.min_salary === '') {
      data[index].min_salary = item.min_salary.replaceAll(',', '');
    }
    if (numberFromCurrency(item.max_salary) || item.max_salary === '') {
      data[index].max_salary = item.max_salary.replaceAll(',', '');
    }
    if (item.percentage || item.percentage === '') {
      data[index].percentage = item.percentage;
    }
    setState({ commissionSlabs: data });
  };

  const commDeleteHandler = (index) => {
    let data: any = state.commissionSlabs;
    data.splice(index, 1);
    setState({ commissionSlabs: data });
  };
  const commErrorHandler = (data, index?: any) => {
    if (
      state.commissionSlabs[0]?.max_salary === '' &&
      state.commissionSlabs[0]?.min_salary === '' &&
      state.commissionSlabs[0]?.percentage === '' &&
      data === 'commissionSlabs'
    ) {
      return 'provide atleast one commissionSlabs';
    } else if (
      state.commissionSlabs[index]?.max_salary === '' &&
      data === 'max_salary'
    ) {
      return 'Enter Minimum Salary';
    } else if (
      state.commissionSlabs[index]?.min_salary === '' &&
      data === 'min_salary'
    ) {
      return 'Enter Maximum Salary';
    } else if (
      state.commissionSlabs[index]?.percentage === '' &&
      data === 'percentage'
    ) {
      return 'Enter Percentage';
    }
  };
  const loadMore = () => {
    setState({ skip: state.skip + 50 });
    loadMorePaymentTerms(state.skip + 50);
  };

  return (
    <div className="payment_terms_container">
      <div className="create_payment_terms_wrapper">
        <div className="payment_terms_heading">Payment Terms</div>
        <div className="payment_terms_flex_container">
          <div className="payment_terms_wrapper">
            <div className="payment_terms_input_wrapper">
              <div className="payment_terms_label">
                Name<span className="required_field">*</span>
              </div>
              <Input
                value={state.name}
                type="text"
                name="name"
                error={state.errorArray}
                onChange={(value) => setState({ name: value })}
              />
            </div>
            <div className="payment_terms_input_wrapper">
              <div className="payment_terms_label">Payment Type</div>
              <div className="radio_container">
                {paymentType.map((item: any, index: number) => (
                  <Radio
                    name="payment_type"
                    data={item}
                    isSelected={state.paymentType.value === item.value}
                    setActive={(type: any) => {
                      setState({
                        paymentType: type,
                        commission: 0,
                        commissionSlabs: [
                          { max_salary: '', min_salary: '', percentage: '' },
                        ],
                      });
                    }}
                    key={index}
                  />
                ))}
              </div>
            </div>
            {state.paymentType.label === 'Range' ? (
              <>
                <div className="payment_terms_input_wrapper">
                  <div className="payment_terms_label">
                    Commission Slabs<span className="required_field">*</span>
                  </div>
                  <div className="comm_container">
                    {state.commissionSlabs?.map((item: any, index: number) => {
                      return (
                        <div className="comm_wrapper" key={index}>
                          <div className="comm_min_salary">
                            <div className="comm_label">Min Salary</div>
                            <Input
                              value={addCommasToCurrency(item.min_salary)}
                              type="text"
                              name="commissionSlabs.min_salary"
                              onChange={(value) =>
                                commUpdateHandler({ min_salary: value }, index)
                              }
                            />
                            {state.commError && (
                              <div className="input_field_error">
                                {commErrorHandler('min_salary', index)}
                              </div>
                            )}
                          </div>
                          <div className="comm_max_salary">
                            <div className="comm_label">Max Salary</div>
                            <Input
                              value={addCommasToCurrency(item.max_salary)}
                              type="text"
                              name={'commissionSlabs.max_salary'}
                              onChange={(value) =>
                                commUpdateHandler({ max_salary: value }, index)
                              }
                            />
                            {state.commError && (
                              <div className="input_field_error">
                                {commErrorHandler('max_salary', index)}
                              </div>
                            )}
                          </div>
                          <div className="comm_percentage">
                            <div className="comm_label">Percentage</div>
                            <Input
                              value={item.percentage.toString()}
                              type="number"
                              name={'commissionSlabs.percentage'}
                              onChange={(value) =>
                                commUpdateHandler({ percentage: value }, index)
                              }
                            />
                            {state.commError && (
                              <div className="input_field_error">
                                {commErrorHandler('percentage', index)}
                              </div>
                            )}
                          </div>

                          <div className="comm_nav_button_wrapper">
                            {state.commissionSlabs?.length !== 1 && (
                              <Navbutton
                                icon={Assets.trash}
                                onClick={() => commDeleteHandler(index)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {state.pocError && (
                    <div className="input_field_error">
                      {commErrorHandler('poc')}
                    </div>
                  )}
                </div>
                <div className="comm_add_button">
                  <PrimaryButton
                    className={'comm_commission_button'}
                    text="Add Commission Slabs"
                    icon={Assets.add}
                    onClick={commAddHandler}
                  />
                </div>
              </>
            ) : (
              <div className="payment_terms_input_wrapper">
                <div className="payment_terms_label">
                  Commission<span className="required_field">*</span>
                </div>
                <Input
                  value={
                    state?.paymentType?.label === 'Percentage'
                      ? state.commission
                      : addCommasToCurrency(state.commission)
                  }
                  type="text"
                  name={'commission'}
                  error={state.errorArray}
                  onChange={(value) => {
                    if (state?.paymentType?.label === 'Percentage') {
                      setState({ commission: value });
                    } else {
                      let commission = addCommasToCurrency(
                        value.replaceAll(',', ''),
                      );
                      if (numberFromCurrency(commission) || value === '') {
                        setState({ commission });
                      }
                    }
                  }}
                />
              </div>
            )}
            <div className="payment_terms_input_wrapper">
              <div className="payment_terms_label">
                Client<span className="required_field">*</span>
              </div>
              <Dropdown
                data={state.clientAllData}
                setActive={(value: any) => {
                  setState({
                    clientData: value,
                  });
                }}
                value={state.clientData}
                name={'client'}
                error={state.errorArray}
              />
            </div>
            <div className="payment_terms_input_wrapper">
              <div className="payment_terms_label">
                Due Days<span className="required_field">*</span>
              </div>
              <Input
                value={state.dueDays}
                type="number"
                name={'due_days'}
                error={state.errorArray}
                onChange={(value) => {
                  setState({ dueDays: value });
                }}
              />
            </div>
            <div className="btn-input_wrapper">
              {state.isLoading ? (
                <Loader />
              ) : (
                <PrimaryButton
                  onClick={
                    state.paymentTerms_id.length > 0
                      ? editPaymentTerms
                      : createPaymentTerms
                  }
                  text={'Submit'}
                  className={'payment_terms_button'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="payment_terms_table_wrapper">
        <ListComponent
          titleArray={titleData}
          data={
            state.paymentTermsAllData.length > 0
              ? state.paymentTermsAllData
              : []
          }
          actionArray={actionArray}
          hasMore={state.loadMore}
          loadMore={loadMore}
          loading={state.loading}
        />
      </div>
      <DeletePop
        text={'Are you sure you want to delete this payment terms?'}
        onClick={deletePaymentTerms}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  );
};

export default PaymentTerms;
