import instance from 'utils/axios.utils';
const chatGptModal = {
  chatGptCreate: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'chat_gpt/create_job_description';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
}

export default chatGptModal