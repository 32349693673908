import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  mergeArrayWithoutDuplicates,
  toastifyError,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Dropdown,
  Input,
  Assets,
  Tag,
  // Slider,
  ResumeList,
  CheckBox,
  Slider,
  PrimaryButton,
  Loader,
} from 'utils/imports.utils';
import './resume_search.screen.scss';
import { getDataFromDropdown } from '../../utils/functions.utils';
import { getResumeList } from '../../utils/redux.utils';
import _ from 'lodash';
import {
  currency,
  gender,
  notice_period,
  REQUISITION_STATUS,
  willing_to_relocate,
} from 'utils/constant.utils';
import { useDebouncedCallback } from 'use-debounce';
const ResumeSearch = (props: any) => {
  // Redux
  const resume = useSelector((state: any) => state.resume);

  // State
  const [state, setState] = useSetState({
    minimum_experience: 0,
    maximum_experience: 0,
    search: '',
    gender: '',
    salary_currency: '',
    current_salary: '',
    relocation: '',
    notice_period: '',
    city: '',
    current_employer: '',
    previous_employer: '',
    checkbox: false,
    total_results: false,
    data: [],
    value: {},
    willing_to_relocate: {},
    city_list: [],
    req_list: [],
    limit: 20,
    skip: 0,
    loadMore: true,
    loading: true,
    totalDocs: 0
  });

  const debounced = useDebouncedCallback((search) => {
    setState({ search });
  }, 500);
  
  //Hooks
  useEffect(() => {
    fetchResumeList();
    setState({ skip: 0, loadMore: true });
  }, [
    state.search,
    state.gender,
    state.salary_currency,
    state.current_salary,
    state.minimum_experience,
    state.maximum_experience,
    state.notice_period,
    state.city,
    state.willing_to_relocate?.value,
  ]);

  useEffect(() => {
    getRecquisitionLists()
    getCityListByStates()
  }, []);

  // Network req

  const fetchResumeList = async () => {
    try {
      setState({ loading: true });
      const body = {
        search: state.search,
        gender: state.gender?.value,
        salary_currency: state.salary_currency?.value,
        current_salary: state.current_salary,
        minimum_experience: state.minimum_experience,
        maximum_experience: state.maximum_experience,
        notice_period: state.notice_period?.value,
        city: state.city?.value,
        // skills: state.skills,
        // remote_work_preference: state.remote_work_preference,
        willing_to_relocate: state.willing_to_relocate?.value,
        limit: state.limit,
        skip: state.skip,
      };
      const res: any = await Models.resume.getAllResume(body);
      if (res.data?.totalDocs === res.data?.docs?.length) {
        setState({ loadMore: false });
      } else {
        setState({ loadMore: true });
      }
      setState({ loading: false, skip: 0, totalDocs: res.data.totalDocs });
      // dispatch
      getResumeList(res.data.docs);
    } catch (error: any) {
      setState({ loading: false });
      // toastifyError(error);
    }
  };

  const loadMoreResumeList = async (skip) => {
    try {
      const body = {
        search: state.search,
        gender: state.gender?.value,
        salary_currency: state.salary_currency?.value,
        current_salary: state.current_salary,
        minimum_experience: state.minimum_experience,
        maximum_experience: state.maximum_experience,
        notice_period: state.notice_period?.value,
        city: state.city?.value,
        // skills: state.skills,
        // remote_work_preference: state.remote_work_preference,
        willing_to_relocate: state.willing_to_relocate?.value,
        limit: state.limit,
        skip,
      };
      const res: any = await Models.resume.getAllResume(body);
      let data = mergeArrayWithoutDuplicates(
        resume.resume_list,
        res?.data?.docs,
      );
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      setState({ loading: false });
      // dispatch
      getResumeList(data);
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  //Logic

  const handleSelect = (item) => {
    let data: any = state.data;
    const index = _.findIndex(data, (id) => {
      return id == item._id;
    });
    if (index !== -1) {
      data.splice(index, 1);
      setState({ data });
    } else {
      data.push(item._id);
      setState({ data });
    }
  };

  const handleSelectAllData = () => {
    if (state.data.length === resume.resume_list?.length) {
      setState({ data: [], total_results: false });
    } else {
      let ids: any = [];
      resume.resume_list?.map((item: any) => ids.push(item._id));
      setState({ data: ids, total_results: true });
    }
  };

  const loadMore = () => {
    setState({ skip: state.skip + 20 });
    loadMoreResumeList(state.skip + 20);
  };

  const setCheckbox = (item: any) => {
    if (
      _.findIndex(state.data, (id) => {
        return id == item._id;
      }) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getRecquisitionLists = async () => {
    try {
      const res: any = await Models.jobRecquisition.getNewRecquisition({
        limit: 500,
        skip: 0,
        status: REQUISITION_STATUS.OPEN,
      });
      // dispatch
      let req_list: any = [];
      res.data.docs.map((item: any) =>
      req_list.push({ label: `${item?.job_title} Jr-${item.id}`, value: item._id }),
      );
      setState({ req_list });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getCityListByStates = async (country?: string, stateIso?: string) => {
    try {
      const res: any = await Models.country.getCitiesByCountry(
        "IN",
        'TN',
      );
      let city_list: any = [];
      res?.map((item: any) =>
        city_list.push({
          label: item.name,
          value: item.name,
        }),
      );
      setState({ city_list });
    } catch (error) {}
  };

  return (
    <div className="resume_search_container">
      <div className="resume_search_heading">Resume Search</div>
      <div className="resume_search_screen_container">
        <div className="resume_search_wrapper">
          <div className="resume_search_search_container">
            <div className="resume_search_input_heading">Search</div>
            <Input
              onChange={(search: any) => {
                debounced(search)
              }}
              type={'text'}
              // value={state.search}
              name={'resume search'}
              icon={Assets.search_gray}
            />
          </div>
          <div className="resume_search_gender_container">
            <div className="resume_search_input_heading">Gender</div>
            <Dropdown
              clear
              value={state.gender}
              onChange={(gender: any) => {
                setState({
                  gender,
                });
              }}
              data={getDropdownData(gender)}
            />
          </div>
          <div className="resume_search_currency_container">
            <div className="resume_search_currency">
              <div className="resume_search_input_heading">Currency</div>
              <Dropdown
                clear
                value={state.salary_currency}
                onChange={(salary_currency: any) => {
                  setState({
                    salary_currency,
                  });
                }}
                data={getDropdownData(currency)}
              />
            </div>
            <div className="resume_search_current_compensation">
              <div className="resume_search_input_heading">
                Current Compensation
              </div>
              <Input
                onChange={(current_salary: any) => {
                  setState({
                    current_salary,
                  });
                }}
                type={'number'}
                value={state.current_salary}
                name={'current_salary'}
              />
            </div>
          </div>
          <div className="resume_search_experience_container">
            <div className="resume_search_experience_header">
              <div className="resume_search_input_heading">Experience</div>
              <div className="resume_search_experience_count">
                {state.minimum_experience} to {state.maximum_experience} years
              </div>
            </div>
            <Slider
              min={0}
              max={40}
              minValue={state.minimum_experience}
              maxValue={state.maximum_experience}
              onInput={(e) => {
                setState({
                  minimum_experience: e.minValue,
                  maximum_experience: e.maxValue,
                });
              }}
            />
          </div>
          <div className="resume_search_relocation_container">
            <div className="resume_search_input_heading">
              Open to Relocation
            </div>
            <Dropdown
              clear
              value={state.willing_to_relocate}
              onChange={(willing_to_relocate: any) => {
                setState({
                  willing_to_relocate,
                });
              }}
              data={getDropdownData(willing_to_relocate)}
            />
          </div>
          <div className="resume_search_notice_container">
            <div className="resume_search_input_heading">
              Current Notice Period
            </div>
            <Dropdown
              clear
              value={state.notice_period}
              onChange={(notice_period: any) => {
                setState({
                  notice_period,
                });
              }}
              data={notice_period}
            />
          </div>
          <div className="resume_search_city_container">
            <div className="resume_search_input_heading">City</div>
            <Dropdown
              value={state.city}
              onChange={(city: any) => {
                setState({
                  city,
                });
              }}
              clear
              data={state.city_list}
            />
          </div>
          {/* <div className="resume_search_currentemp_container">
            <div className="resume_search_input_heading">Current Employer</div>
            <div className="resume_search_currentemp_wrapper">
              <Dropdown
                onChange={(current_employer: any) => {
                  setState({
                    current_employer,
                  });
                }}
                data={[]}
                name="current_employer"
                value={state.current_employer}
              />
            </div>
          </div> */}
          {/* <div className="resume_search_previousemp_container">
            <div className="resume_search_input_heading">Previous Employer</div>
            <div className="resume_search_previousemp_wrapper">
              <Dropdown
                onChange={(previous_employer: any) => {
                  setState({
                    previous_employer,
                  });
                }}
                data={[]}
                name="previous_employer"
                value={state.previous_employer}
              />
            </div>
          </div> */}
        </div>
        <div className="resume_search_table_container">
          <div
            className="resume_search_table_check_box"
            onClick={() => handleSelectAllData()}>
            <CheckBox checked={state.total_results} />
            <div className="resume_list_table_checkbox_header">
              {state.totalDocs} results found
            </div>
          </div>
          {state.loading ? (
            <Loader />
          ) : (
            resume.resume_list?.map((item: any, index: number) => {
              return (
                <ResumeList
                  onClick={() => handleSelect(item)}
                  data={item}
                  profile={item?.profile_picture}
                  checked={setCheckbox(item)}
                  requisitions={state.req_list}
                />
              );
            })
          )}
          {state.loadMore && (
            <div className="resume_search_load_more_wrapper">
              <PrimaryButton
                text={'Load more'}
                onClick={loadMore}
                className="resume_search_load_more_btn"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeSearch;
