import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Functions,
  Models,
  Input,
  PrimaryButton,
  Assets,
} from 'utils/imports.utils';
import './error.scss'

const ErrorScreen = (props: any) => {
  return (
    <div className="error_container">
      <div className="error_wrapper">
        <div className="error_container_logo">
          <img src={Assets.logo} height={100} width={100} alt="sidebar logo" className="sidebar_logo" />
        </div>
        <div className="error_heading">Not Authorized</div>
        {/* <div className="sub_heading">Enter your email ID to recieve OTP</div> */}
        <Link to={'/requisition_board'} className="back_to_login">
          Back To Home
        </Link>
      </div>
    </div>
  );
};

export default ErrorScreen;
