import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toastify, toastifyError, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  PrimaryButton,
  Assets,
} from 'utils/imports.utils';
import { setAppRoute, testDispatch } from 'utils/redux.utils';
import './forget_password.screen.scss';

const ForgetPassword = (props: any) => {
  // Redux
  const testState = useSelector((state: any) => state.app_state);
  // State
  const [state, setState] = useSetState({ email: '' });

  //Hooks
  useEffect(() => {}, []);
  const navigate = useNavigate();
  // Network req
  const sendOtp = async () => {
    try {
      if (state.email?.length > 0) {
        const body = { email: state.email };
        const res: any = await Models.auth.sendOtp(body);
        toastify('Otp has been sent to your email id');
        navigate('/otp');
      } else {
        toastifyError('Enter your email');
      }
      //dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className="forget_password_container">
      <div className="forget_password_wrapper">
        <div className="forget_password_container_logo">
          <img src={Assets.logo} height={100} width={100} alt="sidebar logo" className="sidebar_logo" />
        </div>
        <div className="forget_password_heading">Forget Password</div>
        <div className="sub_heading">Enter your email ID to recieve OTP</div>
        <div className="inputs">
          <div className="input_heading">
            Work Email<span className="required_field">*</span>
          </div>
          <div className="input_box">
            <Input
              type={'text'}
              onChange={(email) => setState({ email })}
              value={state.email}
              name={'email'}
            />
          </div>
        </div>
        <div className="continue">
          <PrimaryButton
            text={'Continue'}
            className={'button'}
            onClick={() => {
              setAppRoute({ email: state.email });
              sendOtp();
            }}
          />
        </div>
        <Link to={'/login'} className="back_to_login">
          Back To Login
        </Link>
      </div>
    </div>
  );
};

export default ForgetPassword;
