export const TEST_ACTION = 'TEST_ACTION';
export const CONVERSATION = 'CONVERSATION';
export const GET_USER = 'GET_USER';
export const APP_ROUTE = 'APP_ROUTE';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const GET_AGENCIES_LIST = 'GET_AGENCIES_LIST';
export const USER_LIST = 'USER_LIST';
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_BUSINESS_UNITS = 'GET_BUSINESS_UNITS';
export const GET_JOB_RECQUISITION_LIST = 'GET_JOB_RECQUISITION_LIST';
export const GET_POST_JOB = 'GET_POST_JOB';
export const GET_RECQUISITION_BY_ID = 'GET_RECQUISITION_BY_ID';
export const GET_RESUME_LIST = 'GET_RESUME_LIST';
export const GET_CANDIDATE_LIST = 'GET_CANDIDATE_LIST';
export const GET_SUMMARY = 'GET_SUMMARY';
export const GET_CANDIDATE = 'GET_CANDIDATE';
export const GET_CANDIDATE_HISTORY = 'GET_CANDIDATE_HISTORY';
export const GET_INTERVIEW = 'GET_INTERVIEW';
export const GET_MANY_INTERVIEW = 'GET_MANY_INTERVIEW';
export const GET_MANY_FEEDBACK = 'GET_MANY_FEEDBACK';
export const GET_FEEDBACK_DUES = 'GET_FEEDBACK_DUES';
export const GET_MANY_NOTES = 'GET_MANY_NOTES';
export const GET_CANDIDATE_DASHBOARD = 'GET_CANDIDATE_DASHBOARD'
