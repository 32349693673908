import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { toastify, useSetState } from 'utils/functions.utils';
import {
  Navbutton,
  Assets,
  PrimaryButton,
  Input,
  Dropdown,
  TextArea,
  Models,
} from 'utils/imports.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './approval_modal.ui.scss';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRecquisitionById } from 'utils/redux.utils';

const ApprovalModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();
  const navigate = useNavigate();
  const requisition = useSelector((state: any) => state.recquisition);
  const userState = useSelector((state: any) => state.user.auth);
  //Redux
  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    email: '',
    userList: [],
  });

  const handledropdown = () => {
    let result = _.differenceWith(
      props.data,
      props.approvalData,
      (obj1: any, obj2: any) => {
        return obj2.user.value === obj1.value;
      },
    );
    setState({ userList: result });
  };

  useEffect(() => {
    handledropdown();
  }, [state.isModalOpen]);

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  const handleApprovalUser = () => {
    if (!_.isEmpty(state.email)) {
      props.onClick(state.email);
      setState({ isModalOpen: false, email: '' });
    } else {
      setState({
        errorArray: [{ path: 'email', message: 'Please enter email' }],
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 1000);
    }
  };

  const handleRejectApproval = async () => {
    if (!_.isEmpty(state.reject)) {
      let index = _.findIndex(
        requisition.recquisition_by_id.approval_user,
        (e: any) => {
          return e.user.value.toString() === userState._id.toString();
        },
      );
      let data = requisition.recquisition_by_id.approval_user;
      data[index].reason = state.reject;
      data[index].approval = false;
      let query: any = {
        requisition_id: requisition.recquisition_by_id._id,
        approval_user: data,
      };
      await Models.jobRecquisition.editRequisition(query);
      let body: any = {
        requisition_id: requisition.recquisition_by_id._id,
      };
      const response: any = await Models.jobRecquisition.getRecquisitionById(
        body,
      );
      // dispatch
      getRecquisitionById(response.data);
      toastify('Requisition rejected');
      setState({ isModalOpen: false, reject: '' });
      await Models.jobRecquisition.rejectedReqApproval(body);
      // navigate('/requisition_board')
    } else {
      setState({
        errorArray: [{ path: 'reject', message: 'Please enter reason' }],
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 1000);
    }
  };
  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'approvalModal',
        }}>
        {props.type === 'approvalUser' ? (
          <div className="approval_team_container">
            <div className="approval_wrapper">
              <div className="approval_header">
                <div className="approval_heading">Add Member</div>
                <Navbutton
                  icon={Assets.black_close}
                  onClick={() => {
                    setState({ isModalOpen: false });
                  }}
                />
              </div>
              <div className="approval_email">
                <div className="approval_subheading">Email</div>
                <span className="required_field">*</span>
              </div>
              <Dropdown
                data={state.userList}
                onChange={(email: any) => {
                  setState({ email });
                }}
                value={state.email}
                name={'email'}
                error={state.errorArray}
              />
              <div className="approval_send_button">
                <PrimaryButton
                  text={'Submit'}
                  className={'send_approval_button'}
                  onClick={handleApprovalUser}
                  width={'150px'}
                />
              </div>
            </div>
          </div>
        ) : props.type === 'approvalReject' ? (
          <div className="approval_reject_container">
            <div className="approval_wrapper">
              <div className="approval_header">
                <div className="approval_heading">Reason to Reject</div>
                <Navbutton
                  icon={Assets.black_close}
                  onClick={() => {
                    setState({ isModalOpen: false });
                  }}
                />
              </div>
              <div className="approval_reject_notes">
                <TextArea
                  onChange={(e) => setState({ reject: e.target.value })}
                  name={'reject'}
                  value={state.feedback}
                  className="reject_notes"
                  error={state.errorArray}
                  height={'100px'}
                />
              </div>
              <div className="approval_send_button">
                <PrimaryButton
                  text={'Submit'}
                  className={'send_reject_button'}
                  onClick={handleRejectApproval}
                  width={'150px'}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </Modal>
    </div>
  );
});

export default ApprovalModal;
