import SmtpForm from 'components/smtp_integration/smtp_form';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { CALENDAR, UserRole } from 'utils/constant.utils';
import { toastifyError, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  Assets,
  PrimaryButton,
  Validation,
  CheckBox,
} from 'utils/imports.utils';
import { getUserDetails } from 'utils/redux.utils';
import connectSocket from 'utils/socket.utils';
import './integration.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

let socket:any
const Integrations = (props: any) => {
  // Redux
  const UserState = useSelector((state: any) => state.user.auth)
  const navigate = useNavigate();
  // State
  const [state, setState] = useSetState({
    getSmtpDetails: {},
  });

  //Hooks
  useEffect(() => {
    getSmtpDetails();
  }, []);

  useEffect(() => {
    socket =  connectSocket();
    socket.on('integrated', (data) => {
    getUser(data)
    });
  }, []);


  const modalRef: any = useRef();
  // Network req
  const getUser = async (data) => {
    let userId = localStorage.getItem('user_id');
    try {
      setState({ loading: true });
      const res: any = await Models.auth.viewUser(userId);
      // dispatch
      getUserDetails({...res.data,...data});
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleClick = async () => {
    try {
      let userId = localStorage.getItem('user_id');
      const res: any = await Models.auth.googleAuth();
      window.open(`${res.data}`, 'mywindow', 'height=500,width=500');
    } catch (error: any) {
      toastifyError(error);
      console.log('error', error);
    }
  };

  const removeIntegration = async (body) => {
    try {
      const res: any = await Models.auth.removeIntegration(body);
      getUserDetails(res.data);
      toastifyError('Calendar integration disconnected');
    } catch (error) {
      toastifyError(error);
      console.log('error', error);
    }
  };

  const handleOutlookIntegration = async () => {
    try {
      let userId = localStorage.getItem('user_id');
      const res: any = await Models.auth.microsoftAuth();
      window.open(`${res.data}`, 'mywindow', 'height=500,width=500');
    } catch (error: any) {
      toastifyError(error);
      console.log('error', error);
    }
  };

  const getSmtpDetails = async () => {
    try {
      let user: any = localStorage.getItem('user_id');
      const res: any = await Models.smtp.getSmtp({ user });
      setState({ smtpDetails: res.data });
    } catch (error) {
      // toastifyError(error);
      console.log('error', error);
    }
  };
  const saveUserDetails = async (integration) => {
    try {
      let userId = localStorage.getItem('user_id');
      const body = {
        user_id: userId,
        default_calendar:integration,
        default_integration:integration
      };
      const res: any = await Models.auth.editUser(body);
      getUserDetails(res.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const equestIntergration = async () => {
    try {
      const res: any = await Models.equest.equestAuth({});
      window.open(res.data);
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const equestAuthRemoved = async () => {
    try {
      const res: any = await Models.equest.removeEquestAuth({});
      getUserDetails(res.data);
      toastifyError('Equest integration disconnected');
    } catch (error: any) {
      toastifyError(error);
    }
  };

  return (
    <div className="integration_container">
      <div className="integration_wrapper">
        {!UserState?.org?.subscribed && (
          <div className="integration_subscribe">
            <img src={Assets.info_icon} height={32} width={32} />
            <div className="subscribe_text">
              Subscription required for integrating third-party apps and tools.
            </div>
            <div
              className="subscribe_link"
              onClick={() => navigate(`/settings/payment_settings`)}>
              View Plan
            </div>
          </div>
        )}
        <div className="integration_heading">Integrations</div>
        <div className="integrations_wrapper">
          <div className="google_integration_wrapper">
            <div className="google_icon_wrapper">
              <img
                src={Assets.google_icon}
                className="google_icon"
                alt=""
              />
            </div>
            <div className="google_integration_heading">Google</div>
            <div className="google_integrate_button_wrapper">
              <PrimaryButton
                onClick={handleClick}
                text={
                  UserState.google_calender_integration
                    ? 'Integrated'
                    : 'Integrate'
                }
                className={'button'}
                disabled={
                  !UserState?.org?.subscribed
                    ? true
                    : UserState.google_calender_integration && true
                }
                icon={
                  UserState.google_calender_integration ||
                  !UserState?.org?.subscribed
                    ? Assets.calender_integrated
                    : Assets.calender_inactive
                }
              />
            </div>
            {UserState.google_calender_integration && (
              <div
                className="google_integration_disconnect"
                onClick={() =>
                  removeIntegration({
                    google_calender_integration: false,
                    default_calendar: null,
                    default_integration: null,
                    google_mail_integration: false,
                  })
                }>
                Disconnect
              </div>
            )}
          </div>
          <div className="microsoft_integration_wrapper">
            <div className="microsoft_icon_wrapper">
              <img
                src={Assets.outlook_calendar}
                className="microsoft_icon"
                alt=""
              />
            </div>
            <div className="microsoft_integration_heading">Microsoft</div>
            <div className="microsoft_integrate_button_wrapper">
              <PrimaryButton
                onClick={handleOutlookIntegration}
                text={
                  UserState.microsoft_calender_integration
                    ? 'Integrated'
                    : 'Integrate'
                }
                className={'button'}
                disabled={
                  !UserState?.org?.subscribed
                    ? true
                    : UserState.microsoft_calender_integration && true
                }
                icon={
                  UserState.microsoft_calender_integration ||
                  !UserState?.org?.subscribed
                    ? Assets.calender_integrated
                    : Assets.calender_inactive
                }
              />
            </div>
            {UserState.microsoft_calender_integration && (
              <div
                className="microsoft_integration_disconnect"
                onClick={() =>
                  removeIntegration({
                    microsoft_calender_integration: false,
                    default_calendar: null,
                    default_integration: null,
                    outlook_mail_integration: false,
                  })
                }>
                Disconnect
              </div>
            )}
          </div>
          {/* {UserState.role == UserRole.ADMIN && ( */}
          <div className="email_integration_wrapper">
            <div className="email_icon_wrapper">
              <img
                src={Assets.email_integration}
                className="email_icon"
                alt=""
              />
            </div>
            <div className="email_integration_heading">Email</div>
            <div className="email_integrate_button_wrapper">
              <PrimaryButton
                disabled={!UserState?.org?.subscribed && true}
                onClick={() => modalRef.current.openModal()}
                text={'Integrate'}
                className={'button'}
                icon={
                  !UserState?.org?.subscribed
                    ? Assets.calender_integrated
                    : Assets.calender_inactive
                }
              />
            </div>
            <div
              className="microsoft_integration_disconnect"
              onClick={() => {}}></div>
          </div>
          <div className="equest_integration_wrapper">
            <div className="equest_icon_wrapper">
              <img src={Assets.equest_logo} className="equest_icon" alt="" />
            </div>
            <div className="equest_integration_heading">equest</div>
            <div className="equest_integrate_button_wrapper">
              <PrimaryButton
                onClick={equestIntergration}
                text={
                  UserState.equest_calender_integration
                    ? 'Integrated'
                    : 'Integrate'
                }
                className={'button'}
                disabled={
                  !UserState?.org?.subscribed
                    ? true
                    : UserState.equest_integration && true
                }
                icon={
                  UserState.equest_integration || !UserState?.org?.subscribed
                    ? Assets.calender_integrated
                    : Assets.calender_inactive
                }
              />
            </div>
            {UserState.equest_integration && (
              <div
                className="equest_integration_disconnect"
                onClick={equestAuthRemoved}>
                Disconnect
              </div>
            )}
          </div>
        </div>

        {UserState.google_calender_integration &&
          UserState.microsoft_calender_integration && (
            <div className="default_calendar_container">
              <div className="heading">Set Default Integration</div>
              <div
                className="default_calender_google"
                onClick={() => saveUserDetails(CALENDAR.GOOGLE)}>
                <div className="radio_btn">
                  <img
                    src={
                      UserState.default_calendar === CALENDAR.GOOGLE
                        ? Assets.radio_checked
                        : Assets.radio_unchecked
                    }
                    height={20}
                    width={20}
                  />
                </div>
                <div className="calendar_text">Google</div>
              </div>
              <div
                className="default_calender_google"
                onClick={() => saveUserDetails(CALENDAR.OUTLOOK)}>
                <div className="radio_btn">
                  <img
                    src={
                      UserState.default_calendar === CALENDAR.OUTLOOK
                        ? Assets.radio_checked
                        : Assets.radio_unchecked
                    }
                    height={20}
                    width={20}
                  />
                </div>
                <div className="calendar_text">Outlook</div>
              </div>
            </div>
          )}
      </div>
      <SmtpForm ref={modalRef} data={state.smtpDetails} />
    </div>
  );
};

export default Integrations;
