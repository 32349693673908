import moment from 'moment';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './candidate_history.scss';

interface ICandidateHistory {
  data?: any;
}

const CandidateHistory = (props: ICandidateHistory) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);
  const navigate = useNavigate();
  // Network req

  //Logic

  return (
    <div className="candidate_history_container">
      <div className="candidate_history_content_wrapper">
        <div className="candidate_history_content">
          <div>
            <div
              className="candidate_history_headings"
              onClick={() => {
                navigate(`/requisition?id=${props.data.requisition._id}`);
              }}>
              Requisition
            </div>
            <div className="candidate_role_content">
              {props.data?.requisition?.job_title}
            </div>
          </div>
          <div>
            <div className="candidate_history_headings">Status</div>
            <div className="candidate_role_content">{props.data.stage}</div>
          </div>
        </div>
        <div className="candidate_history_content">
          <div>
            <div
              className="candidate_history_headings"
              onClick={() => {
                navigate(`/requisition?id=${props.data.requisition._id}`);
              }}>
              Requisition ID
            </div>
            <div className="candidate_role_content">
              JR - {props.data?.requisition?.id}
            </div>
          </div>
          <div>
            <div className="candidate_history_headings">Applied Date</div>
            <div className="candidate_role_content">
              {moment(props.data.applied_date).format('DD-MM-YYYY')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateHistory;
