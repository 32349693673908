import instance from 'utils/axios.utils';
import { uploadFile } from 'utils/functions.utils';

const agency = {
  createAgency: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'agency/create_agency';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getAllAgency: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'agency/get_many_agency';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  agencyDeleteById: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'agency/delete_agency';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
         if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear()
              window.location.href = '/login';
              reject("");
            }else{
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editAgency: (data:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'agency/edit_agency';
      instance()
        .post(url,data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  inviteAgency: (data:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'agency/invite_agency';
      instance()
        .post(url,data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getOrgs: (data:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'agency/get_many_org';
      instance()
        .post(url,data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default agency;
