import React, { useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { useSetState } from 'utils/functions.utils';
import { Assets, Colors } from 'utils/imports.utils';
// import * as chartDataLabels from 'chartjs-plugin-labels'
import './pie_chart.scss';
const PieChart = (props: any) => {
  const [state, setState] = useSetState({ display: false });
  const chartRef: any = useRef();
  const saveCanvas = () => {
    const link: any = document.createElement('a');
    link.href = chartRef?.current?.chartInstance?.toBase64Image();
    link.download = 'chart.jpeg';
    link.click();
    link.parentNode.removeChild(link);
  };
  return (
    <div>
      <div className="line_chart_wrapper"></div>
      {props.genderData.sum > 0 ? (
        <>
          <Pie
            ref={chartRef}
            // plugins={[chartDataLabels]}
            data={props.data}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: true,
                position: 'bottom',
              },
              plugins: {
                labels: {
                  render: 'value',
                  fontColor: ['white', 'white', 'white'],
                  precision: 1,
                  display: false,
                },
              },
            }}
          />

          {/* <div className="legend_details_wrapper">
            {Object.keys(props.genderData).map((key) => (
              <div className="legend_details">
                <div
                  className="diversity_color"
                  style={{ backgroundColor: '#27C69E' }}></div>
                <div className="diversity_text">
                  {key}% {props.genderData[key]}
                </div>
              </div>
            ))}
          </div> */}

          {/* {<div className="legend_details_wrapper">
            <div className="legend_details">
              <div
                className="diversity_color"
                style={{ backgroundColor: '#27C69E' }}></div>
              <div className="diversity_text">
                {props.genderData.percentageOfMale}% Male
              </div>
            </div>
            <div className="legend_details">
              <div
                className="diversity_color"
                style={{ backgroundColor: Colors.activeTabColor }}></div>
              <div className="diversity_text">
                {props.genderData.percentageOfFemale}% Female
              </div>
            </div>
            <div className="legend_details">
              <div
                className="diversity_color"
                style={{ backgroundColor: Colors.inputHeadingColor }}></div>
              <div className="diversity_text">
                {props.genderData.percentageOfOthers}% Undisclosed
              </div>
            </div>
          </div>} */}
        </>
      ) : (
        <div className="empty_text">No data found</div>
      )}
      <div style={{ position: 'absolute', top: '22px', right: '20px' }}>
        <img onClick={() => saveCanvas()} src={Assets.csv} />
      </div>
    </div>
  );
};

export default PieChart;
