import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import DatePicker from 'react-date-picker';
import './date_picker.ui.scss';
import moment from 'moment';
import Assets from 'imports/assets.import';

const DatePickerComponent = (props: any) => {
  // Redux

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req

  //Logic
  const testLogic = () => {};

  return (
    <div className={`${props.className || ''} date_picker_wrapper`}>
      <DatePicker
        autoFocus={false}
        onChange={(date: any) => {
          props.onChange(new Date(date));
        }}
        calendarIcon={<img src={Assets.calendar_icon} height={25} width={25} />}
        clearIcon={<img src={Assets.close_black} height={25} width={25} />}
        value={props.value || ''}
        {...props}
      />
      {props.error &&
        props.error.map(
          (error: any) =>
            props.name === error?.path && (
              <div className="input_field_error">{error.message}</div>
            ),
        )}
    </div>
  );
};

export default DatePickerComponent;
