import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useQuery, useSetState } from 'utils/functions.utils';
import {
  DatePickerComponent,
  PrimaryButton,
  SingleSlider,
} from 'utils/imports.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './org_modal.ui.scss';
import _ from 'lodash';

const OrgModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();
  let query = useQuery();
  //Redux
  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    errorArray: [],
    start_date: '',
    end_date: '',
    no_of_value: 0,
  });

  // Network req

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  //Logic
  useEffect(() => {
    if (props.data.plan === 'trial' && !_.isEmpty(props.data)) {
      if (props.title === 'Agencies') {
        setState({
          start_date: new Date(props.data.trial?.start_date),
          end_date: new Date(props.data.trial?.end_date),
        });
      } else {
        setState({ no_of_value: props.data.trial.quantity });
      }
    } else {
      setState({ no_of_value: 1, start_date: new Date(), end_date: '' });
    }
  }, [props.data, state.isModalOpen]);

  const handleClick = () => {
    // let errorData = '';
    // if (props.title === 'Agencies') {
    //   errorData = !_.isDate(state.end_date)
    //     ? 'Please select End Date'
    //     : state.end_date <= state.start_date
    //     ? 'Check start date and end date'
    //     : '';
    // } else if (
    //   props.data.trial.quantity > state.no_of_value &&
    //   props.data.plan === 'trial'
    // ) {
    //   errorData = `Please select a quantity above ${props.data.trial.quantity}`;
    // }

    // if (!_.isEmpty(errorData)) {
    //   setState({
    //     errorArray: [
    //       {
    //         path: 'end_date',
    //         message: errorData,
    //       },
    //     ],
    //   });
    //   setTimeout(() => {
    //     setState({
    //       errorArray: [],
    //     });
    //   }, 1000);
    //   return;
    // }

    props.onClick(
      props.title === 'Agencies'
        ? {
            trialEndDate: state.end_date,
            trailStartDate: state.start_date,
          }
        : { vacancies_count: state.no_of_value },
    );
  };

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'orgModalAgency',
        }}>
        <div className="org_modal_container">
          <div className="org_modal_wrapper">
            <div className="invite_header">Edit {props.title}</div>
            <div className="invite_heading">Trial</div>
            {props.title === 'Agencies' ? (
              <div className="trial_agencies">
                <div style={{ width: '220px' }}>
                  <div className="invite_subheading">Start Date</div>
                  <DatePickerComponent
                    onChange={(value: any) => {
                      setState({ start_date: value });
                    }}
                    value={state.start_date}
                    name="start_date"
                  />
                </div>
                <div style={{ width: '220px' }}>
                  <div className="invite_subheading">End Date</div>
                  <DatePickerComponent
                    onChange={(value: any) => {
                      setState({ end_date: value });
                    }}
                    value={state.end_date}
                    name="end_date"
                    error={state.errorArray}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="invite_subheading">Vacancies Count</div>
                <div className="count_wrapper">
                  <SingleSlider
                    min={_.isNumber(props.data?.trial?.quantity)?props.data.trial.quantity:1}
                    max={1000}
                    onChange={(value: number) =>
                      setState({ no_of_value: value })
                    }
                    defaultValue={state.no_of_value}
                    name="end_date"
                    error={state.errorArray}
                  />
                </div>
              </>
            )}
            <div className="invite_send_button">
              <PrimaryButton
                onClick={() =>
                  setState({ isModalOpen: false, prompt: props.data })
                }
                text={'Cancel'}
                className={'delete_btn'}
              />
              <PrimaryButton
                text={'Save'}
                className={'send_invite_button'}
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default OrgModal;
