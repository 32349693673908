import React, { useEffect, useRef, useImperativeHandle } from 'react';
import {
  addCommasToCurrency,
  getDataFromDropdown,
  getDropdownData,
  getDropdownValue,
  getExperience,
  isGenderBiased,
  mergeTemplates,
  removeCommasFromCurrency,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  Assets,
  Tag,
  Dropdown,
  MultiSelectDropdown,
  TextEditor,
  PrimaryButton,
  DatePickerComponent,
  FileUpload,
  Validation,
  ConfirmationPop,
  Radio,
  ApprovalModal,
  ApprovalListComponent,
  TextArea,
} from 'utils/imports.utils';
import './create_requisition.screen.scss';
import {
  getBusinessUnitList,
  getDepartmentList,
  getRecquisitionById,
  getUserDetails,
  getUserList,
} from '../../utils/redux.utils';
import { getAllWorkLocations } from '../../utils/redux.utils';
import { toastify, uploadFile, toastifyError } from 'utils/functions.utils';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  behaviorData,
  currency,
  DropdownStatus,
  explanations,
  feminine_coded_words,
  headcount_type,
  job_location,
  job_role_type,
  job_types,
  masculine_coded_words,
  possible_codings,
  remote_work_preference,
  REQ_APPROVAL,
  REQUISITION_STATUS,
  skills,
  SUBSCRIPTION_PLAN,
  subscriptionPlan,
} from 'utils/constant.utils';
import moment from 'moment';
import {
  ICreateRequisition,
  IEditRequisition,
} from 'interfaces/common.interface';
import { useSelector } from 'react-redux';
import DraggableComponent from 'common_components/ui/dragable/draggable.componet';
const CreateRequisition = (props: any) => {
  // Redux
  const userState = useSelector((state: any) => state.user.auth);
  const approvalType = [
    { label: 'Sequential Approval', value: 'Sequential' },
    { label: 'Parallel Approval', value: 'Parallel' },
  ];
  const sequentialApproval = [{label:"",value:"",width:"5%"},{ label: 'User', value: 'user', width: '40%' },{ label: 'Priority', value: 'priority', width: '30%' }];
  const editSequentialApproval = [{ label: 'User', value: 'user', width: '45%' },{ label: 'Priority', value: 'priority', width: '30%' }];
  const parallelApproval = [{ label: 'User', value: 'user', width: '75%' }];
  // reference
  // State
  const [state, setState] = useSetState({
    job_title: '',
    job_type: { label: 'Permanent', value: 'Permanent' },
    job_role: { label: 'Full Time', value: 'Full Time' },
    salary_currency: '',
    min_salary: null,
    max_salary: null,
    job_location: '',
    work_location: '',
    no_of_vancanies: 1,
    headcount_type: '',
    bonus_payout_type: '',
    bonus_payout_value: null,
    remote_working: '',
    target_close_date: null,
    min_yr_of_exp: '',
    max_yr_of_exp: '',
    job_req_desc: '',
    job_description: '',
    job_description_template: '',
    skills: [],
    behaviors: [],
    hiring: [],
    hiring_team: [],
    errorArray: [],
    work_location_value: [],
    departments: [],
    business_units: [],
    department_value: [],
    business_unit_value: [],
    skills_value: [],
    hiring_team_value: [],
    behavior_value: [],
    recruiter: [],
    interviewer: [],
    recruiter_data: [],
    interviewer_data: [],
    shouldBlockNavigation: false,
    navigate: false,
    userList: [],
    addedList: [],
    job_description_file: '',
    type: '',
    userArray: [],
    skills_data: [],
    loading: true,
    clientsData: [],
    clientValue: '',
    paymentTermsData: [],
    paymentTermsValue: '',
    accountManagerData: [],
    accountManagerValue: { label: '', value: '' },
    clientAllData: [],
    pocData: [],
    pocValue: '',
    approvalRequired: false,
    approvalType: { label: 'Sequential Approval', value: 'Sequential' },
    approvalParallel: [],
    approvalSequential: [],
    approvalError: [],
    editApprovalParallel: [],
    editApprovalSequential: [],
    subscribed: false,
    existVacancies:0,
    planName:'',
    reqSubscribed: false,
    approval_desc:"",
    email_templates:[],
    templates:[]
  });

  //Hooks
  const query = useQuery();
  const navigate: any = useNavigate();
  const location = useLocation();
  let req_id: any = query.get('id');
  const wrapperRef: any = useRef();
  const targetRef: any = useRef();
  const approvalRef: any = useRef();
  const pdfRef: any = useRef();

  useEffect(() => {
    wrapperRef?.current?.scrollTo(0, 0);
    getUserLists();
    getAllLocation();
    getAllDeparment();
    getAllBusinessUnits();
    getSkillsData();
    getAllClients();
    fetchEmailTemplates()
    // getAllPaymentTerms()
    if (req_id?.length > 0) {
      recquisitionById();
    }
  }, []);

  const handleWindowClose = (e) => {
    e.preventDefault();
    return (e.returnValue = '');
  };
  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [window]);

  // useEffect(() => {
  //   if (userState?.org?.subscribed) {
  //     setState({ subscribed: userState.org.subscribed });
  //   }
  // }, [userState]);

  // Network req
  const getUserLists = async () => {
    try {
      let body: any = { limit: 200 };
      const res: any = await Models.auth.getUserList(body);
      let nameArray: any[] = [];
      res.data?.docs?.map((item) => {
        nameArray.push({
          value: item?._id,
          label: `${item?.first_name} ${item?.last_name} - ${item?.email}`,
        });
      });
      setState({
        hiring_team: nameArray,
        recruiter_data: nameArray,
        interviewer_data: nameArray,
        userList: nameArray,
        userArray: nameArray,
        accountManagerData: nameArray
      });
      //dispatch
      getUserList(res.data);
    } catch (error: any) {
      // toastifyError(error);
    }
  };
  const fetchEmailTemplates = async () => {
    try {
      const res: any = await Models.email.getAllEmail({limit:200});
      const templates = mergeTemplates(res.data.docs)
      let email_templates: any = [];
      templates.map((item: any) =>
        email_templates.push({
          label: item.name,
          value: item.body,
        }),
      );
      setState({
        email_templates,
        templates,
      });
    } catch (error: any) {
      toastifyError(error);
    }
  };
  const getAllLocation = async () => {
    try {
      const res: any = await Models.workLocation.getAllLocation({ limit: 200 });
      let workLocationArray: any[] = [];
      res.data.docs.map((item) => {
        workLocationArray.push({
          value: item?._id,
          label: item?.nickname,
        });
      });
      setState({ work_location: workLocationArray });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const getAllClients = async () => {
    try {
      const res: any = await Models.client.getAllClient();
      let clientsData: any = [];
      res.data.docs.map((item: any) => {
        clientsData.push({
          label: item?.client_name,
          value: item?._id,
        });
      });
      setState({ clientsData, clientAllData: res.data.docs });
    } catch (error: any) {
      toastifyError(error);
    }
  };
  const getAllPaymentTerms = async (body) => {
    try {
      let query: any = {
        client: body
      }
      const res: any = await Models.paymentTerms.getAllPaymentTerms(query);
      let paymentTermsData: any = [];
      res.data.docs.map((item: any) => {
        paymentTermsData.push({
          label: item?.name,
          value: item?._id,
        });
      });
      setState({ paymentTermsData });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  // create JobReq

  const createJobRecquisition = async (type?: string) => {
    try {
      let stage:any=type
      const body: ICreateRequisition = {
        job_title: state.job_title,
        job_type: state.job_type?.value,
        job_role_type: state.job_role.value,
        work_location: state.work_location_value?.value,
        number_of_vacancies: state.no_of_vancanies,
        headcount_type: state.headcount_type.value,
        min_experience: state.min_yr_of_exp?.value,
        max_experience: state.max_yr_of_exp?.value,
        open_vacancies: state.no_of_vancanies,
      };
      
      if (state.no_of_vancanies <= 0) {
        let error = `Add at least 1 vacancy`;
        toastifyError(error);
        setState({ createReqLoader: false });
        return;
      }
      let planName = userState?.org?.plan;
            if (
        (userState.is_agency && planName !== SUBSCRIPTION_PLAN.BASIC) ||
        _.toNumber(state.no_of_vancanies) <=
          userState?.org?.[planName]?.quantity
      ) {
        if (userState?.org?.subscribed) {
          body.subscription = {
            plan_name: planName,
            subscribed: userState?.org?.subscribed,
          };
        }
      } else {
        let error = `You have only ${userState?.org?.[planName]?.quantity} vacancies`;
        if (userState?.org?.[planName]?.quantity <= 0) {
          error = `please update your plan`;
        }
        toastifyError(error);
        setState({ createReqLoader: false });
        return;
      }
      if (userState.is_agency && planName === SUBSCRIPTION_PLAN.BASIC) {
        if (
          _.toNumber(state.no_of_vancanies) >
          userState?.org?.[planName]?.quantity
        ) {
          let error = `You have only ${userState?.org?.[planName]?.quantity} vacancies`;
          if (userState?.org?.[planName]?.quantity <= 0) {
            error = `please update your plan`;
          }
          toastifyError(error);
          setState({ createReqLoader: false });
          return;
        }
      }
      if (state.job_req_desc.length > 0) {
        body.job_req_desc = state.job_req_desc;
      }
      if (state.salary_currency.value?.length > 0) {
        body.salary_currency = state.salary_currency?.value;
      }
      if (state.min_salary !== null && state.min_salary.toString().length > 0) {
        body.salary_min = removeCommasFromCurrency(state.min_salary);
      }
      if (state.max_salary !== null && state.max_salary.toString().length > 0) {
        body.salary_max = removeCommasFromCurrency(state.max_salary);
      }
      if (state.remote_working?.label?.length > 0) {
        body.remote_working = state.remote_working?.label;
      }
      if (state.job_description.length > 0) {
        body.job_description = state.job_description;
      }
      if (
        state.target_close_date !== null &&
        state.target_close_date.toString().length > 0
      ) {
        body.target_close_date = state.target_close_date;
      }
      if (state.skills_value.length > 0) {
        body.skills = getDataFromDropdown(state.skills_value);
      }
      if (state.behavior_value.length > 0) {
        body.behaviours = getDataFromDropdown(state.behavior_value);
      }
      if (state.skills_value.length > 0) {
        body.skills = getDataFromDropdown(state.skills_value);
      }
      if (!_.isEmpty(state.hiring_team_value)) {
        body.hiring_team = getDataFromDropdown(state.hiring_team_value);
      }
      if (state.interviewer.length > 0) {
        body.interviewer = getDataFromDropdown(state.interviewer);
      }
      if (state.recruiter.length > 0) {
        body.recruiter = getDataFromDropdown(state.recruiter);
      }
      if (state.department_value?.value?.length > 0) {
        body.department = state.department_value?.value;
      }
      if (state.business_unit_value?.value?.length > 0) {
        body.business_unit = state.business_unit_value?.value;
      }
      if (userState.is_agency) {
        if (state.pocValue?.value?.length > 0) {
          body.poc = state.pocValue.value;
        }
        if (state.clientValue?.value?.length > 0) {
          body.client = state.clientValue.value;
        }
        if (state.paymentTermsValue?.value?.length > 0) {
          body.payment_terms = state.paymentTermsValue.value;
        }
        if (state.accountManagerValue?.value?.length > 0) {
          body.account_manager = state.accountManagerValue.value;
        }
        if(userState?.org?.subscribed){
          body.subscription = {
            plan_name: userState?.org?.plan,
            subscribed: userState?.org?.subscribed,
          };
        }
      }
      if (state.approvalRequired) {
        body.approval_required = state.approvalRequired;
        body.approval_type=state.approvalType.value
        body.approval_desc=state.approval_desc
        if(!_.isEmpty(state.approvalSequential)){
          body.approval_user=state.approvalSequential
        }
       else if(!_.isEmpty(state.approvalParallel)){
          body.approval_user=state.approvalParallel
        }
      }
      //   if (state.job_location_value?.value?.length > 0) {
      //     body.job_location = state.job_location_value?.value;
      //   }
      if (userState.is_agency) {
        await Validation.agencyRequisitionSchema.validate(body, { abortEarly: false });
      } else {
        await Validation.requisitionSchema.validate(body, { abortEarly: false });
      }

      if (
        state.approvalRequired &&
        (_.isEmpty(body.approval_desc) || _.isEmpty(body.approval_user))
      ) {
        let errorData: any = [];
        if (_.isEmpty(body.approval_desc)) {
          errorData.push('Approval Desc');
        }
        if (_.isEmpty(body.approval_user)) {
          errorData.push('Approval User');
        }

        setState({ approvalError: errorData });
        setTimeout(() => {
          setState({ approvalError: [] });
        }, 1000);
      } else {
        setState({ createReqLoader: true });
        const res: any = await Models.jobRecquisition.createNewRecquisition(
          body,
        );

         // subscription plan
         if (!userState.is_agency || planName === SUBSCRIPTION_PLAN.BASIC) {
           let quantity: number =
             userState?.org?.[userState?.org?.plan]?.quantity -
             _.toNumber(state.no_of_vancanies);
           let query: any = {
             [userState?.org?.plan]: {
               total_quantity:
                 userState?.org?.[userState?.org?.plan]?.total_quantity,
               quantity,
               created_at: userState?.org?.[userState?.org?.plan]?.created_at,
             },
           };
           if (
             userState?.org?.plan !== SUBSCRIPTION_PLAN.BASIC &&
             quantity <= 0
           ) {
             query.plan = SUBSCRIPTION_PLAN.BASIC;
             query.subscribed = false;
           }
           await Models.organization.editOrganization(query);
         }

        if (state.approvalRequired) {
          stage = REQUISITION_STATUS.DRAFT;
        }
        if (stage) {
          toastify('Requisition saved as draft');
        } else {
          toastify('Requisition created');
        }
        // if(state.subscribed && !userState.is_agency){
        //   editOrganization()
        // }
        navigate('/requisition_board');
        getUser()
        if (res.data.approval_required) {
          await Models.jobRecquisition.requisitionApproval({
            requisition_id: res.data._id,
          });
        }

        setState({
          job_title: '',
          job_type: '',
          job_role: '',
          salary_currency: '',
          min_salary: null,
          max_salary: null,
          job_location_value: '',
          work_location: '',
          no_of_vancanies: null,
          headcount_type: '',
          bonus_payout_type: '',
          bonus_payout_value: null,
          remote_working: '',
          target_close_date: '',
          min_yr_of_exp: '',
          max_yr_of_exp: '',
          job_req_desc: '',
          job_description: '',
          job_description_template: '',
          skills_value: [],
          behavior_value: [],
          hiring_team_value: [],
          errorArray: [],
          work_location_value: [],
          department_value: [],
          business_unit_value: [],
          recruiter: [],
          interviewer: [],
          recruiter_data: [],
          interviewer_data: [],
          createReqLoader: false,
          poc: '',
          clientsData: [],
          clientValue: '',
          paymentTermsData: [],
          paymentTermsValue: '',
          pocData: [],
          pocValue: '',
          accountManagerData: [],
          accountManagerValue: '',
          approvalRequired: false,
          approvalType: {},
          reqSubscribed: false,
          existVacancies:0,
          planName:''
        });
      }
      // await Validation.requisitionSchema.validate(body, { abortEarly: false });
    } catch (error: any) {
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
        createReqLoader: false
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  const getAllDeparment = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.department.getAllDepartment({ limit: 200 });
      let departments: any = [];
      res.data.docs.map((item: any) => {
        departments.push({
          label: item?.name,
          value: item?._id,
        });
      });
      setState({ loading: false });
      getDepartmentList(res.data);
      setState({ departments });
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const getAllBusinessUnits = async () => {
    try {
      const res: any = await Models.business.getAllBusinessUnits({ limit: 200 });
      let business_units: any = [];
      res?.data?.docs.map((item: any) =>
        business_units.push({
          label: item?.name,
          value: item?._id,
        }),
      );
      getBusinessUnitList(res?.data?.docs);
      setState({ business_units });
    } catch (error: any) {
      setState({ loading: false });
      toastifyError(error);
    }
  };

  const recquisitionById = async () => {
    try {
      const body = {
        requisition_id: req_id,
      };
      const response: any = await Models.jobRecquisition.getRecquisitionById(
        body,
      );
      let hiring_team: any = [];
      let recruiter: any = [];
      let interviewer: any = [];
      response.data.hiring_team.map((item) => {
        hiring_team.push({
          value: item._id,
          label: `${item?.first_name} ${item?.last_name}`,
        });
      });
      response?.data?.recruiter?.map((team: any) =>
        recruiter.push({
          label: `${team?.first_name} ${team?.last_name}`,
          value: team?._id,
        }),
      );
      response?.data?.interviewer?.map((team: any) =>
        interviewer.push({
          label: `${team?.first_name} ${team?.last_name}`,
          value: team?._id,
        }),
      );
      let remote_working: any = [];
      if (response?.data.remote_working) {
        remote_working.push({ label: 'Yes', value: true });
      } else {
        remote_working.push({ label: 'No', value: false });
      }
      setState({
        job_title: response?.data?.job_title,
        job_type: getDropdownValue(response?.data?.job_type),
        job_role: getDropdownValue(response?.data?.job_role_type),
        salary_currency: getDropdownValue(response?.data?.salary_currency),
        min_salary: addCommasToCurrency(response?.data?.salary_min),
        max_salary: addCommasToCurrency(response?.data?.salary_max),
        // job_location_value: [
        //   {
        //     label: response?.data?.job_location,
        //     value: response?.data?.job_location,
        //   },
        // ],
        work_location_value: getDropdownData([
          response?.data?.work_location?.address,
        ]),
        no_of_vancanies: _.toNumber(response?.data?.number_of_vacancies) || 1,
        existVacancies:_.toNumber(response?.data?.number_of_vacancies),
        headcount_type: getDropdownData([response?.data?.headcount_type]),
        bonus_payout: getDropdownData([response?.data?.bonus_payout_type]),
        bonus_payout_value: response?.data?.bonus_payout_value,
        remote_working,
        target_close_date: response?.data?.target_close_date
          ? new Date(response?.data?.target_close_date)
          : new Date(),
        min_yr_of_exp: getDropdownData([response?.data?.min_experience]),
        max_yr_of_exp: getDropdownData([response?.data?.max_experience]),
        job_req_desc: response?.data?.job_req_desc,
        job_description: response?.data?.job_description || '',
        job_description_template: '',
        skills_value: getDropdownData(response?.data?.skills),
        behavior_value: getDropdownData(response?.data?.behaviours),
        hiring_team_value: hiring_team,
        errorArray: [],
        department_value: [
          {
            label: response?.data?.department?.name,
            value: response?.data?.department?._id,
          },
        ],
        business_unit_value: [
          {
            label: response?.data?.business_unit?.name,
            value: response?.data?.business_unit?._id,
          },
        ],
        recruiter,
        interviewer,
        job_description_file: response.data.job_description_file,
        type: response.data.status,
        clientValue: {
          label: response?.data?.client?.client_name,
          value: response?.data?.client?._id,
        },
        accountManagerValue: {
          label: !_.isEmpty(response?.data?.account_manager?._id)
            ? `${response?.data?.account_manager?.first_name} ${response?.data?.account_manager?.last_name} - ${response?.data?.account_manager?.email}`
            : '',
          value: !_.isEmpty(response?.data?.account_manager?._id)
            ? response?.data?.account_manager?._id
            : '',
        },
      });
      if (response?.data?.client?._id) {
        getClientData(response?.data?.client?._id)
        getAllPaymentTerms(response?.data?.client?._id)
        setState({
          pocValue: {
            label: response?.data?.poc,
            value: response?.data?.poc,
          },
          paymentTermsValue: {
            label: response?.data?.payment_terms?.name,
            value: response?.data?.payment_terms?._id,
          },
        })
      }
      if (response?.data?.approval_required) {
        {
          setState({
            approvalRequired: response?.data?.approval_required,
            approvalType: {
              label: response?.data?.approval_type,
              value: response?.data?.approval_type,
            },
            approval_desc: response?.data?.approval_desc
          });
        }
        if (response?.data?.approval_type === REQ_APPROVAL.SEQUENTIAL) {
          setState({
            approvalSequential: response?.data?.approval_user,editApprovalSequential:response?.data?.approval_user
          });
        } else if (response?.data?.approval_type === REQ_APPROVAL.PARALLEL) {
          setState({
            approvalParallel: response?.data?.approval_user, editApprovalParallel: response?.data?.approval_user,
          });
        }
      }
    if (!_.isEmpty(response?.data?.subscription)) {
      setState({ planName: response?.data?.subscription?.plan_name,reqSubscribed:response?.data?.subscription?.subscribed });
    }
      // dispatch
      getRecquisitionById(response.data);
    } catch (error) {
      toastifyError(error);
    }
  };
  const editRequisition = async () => {
    try {
      setState({ editReqLoader: true });
      const body: any = {
        requisition_id: req_id,
        job_title: state.job_title,
        job_type: state.job_type?.value,
        job_role_type: state.job_role.value,
        salary_currency: state.salary_currency.value,
        job_location: state.job_location_value?.value,
        work_location: state.work_location_value?.value,
        number_of_vacancies: state.no_of_vancanies,
        headcount_type: state.headcount_type.value,
        remote_working: state.remote_working?.value,
        target_close_date: state.target_close_date,
        min_experience: state.min_yr_of_exp?.value,
        max_experience: state.max_yr_of_exp?.value,
        job_req_desc: state.job_req_desc,
        // job_description: state.job_description,
        skills: getDataFromDropdown(state.skills_value),
        behaviours: getDataFromDropdown(state.behavior_value),
        hiring_team: getDataFromDropdown(state.hiring_team_value),
        department: state.department_value?.value,
        business_unit: state.business_unit_value?.value,
        recruiter: getDataFromDropdown(state.recruiter),
        interviewer: getDataFromDropdown(state.interviewer),
      };
      if(state.no_of_vancanies<=0){
        let error = `Add at least 1 vacancy`;
        toastifyError(error);
        setState({ editReqLoader: false });
        return;
      }
      if (state.min_salary) {
        body.salary_min = removeCommasFromCurrency(state.min_salary)
      }
      if (state.max_salary) {
        body.salary_max = removeCommasFromCurrency(state.max_salary)
      }
      if (state.job_description.length > 0) {
        body.job_description = state.job_description;
      }
      if (userState.is_agency) {
        if (state.pocValue?.value?.length > 0) {
          body.poc = state.pocValue.value;
        }
        if (state.clientValue?.value?.length > 0) {
          body.client = state.clientValue.value;
        }
        if (state.paymentTermsValue?.value?.length > 0) {
          body.payment_terms = state.paymentTermsValue.value;
        }
        if (state.accountManagerValue?.value?.length > 0) {
          body.account_manager = state.accountManagerValue.value;
        }
      }
      if (state.approvalRequired) {
        body.approval_required = state.approvalRequired;
        body.approval_type = state.approvalType.value;
        body.approval_desc=state.approval_desc
        if (!_.isEmpty(state.approvalSequential)) {
          body.approval_user = state.approvalSequential;
        } else if (!_.isEmpty(state.approvalParallel)) {
          body.approval_user = state.approvalParallel;
        }
      }
      let query: any = {};
      let planName: string = subscriptionPlan.includes(state.planName)?userState.org.plan:SUBSCRIPTION_PLAN.BASIC
      let quantity = userState.org[planName].quantity;
        if (state.existVacancies < _.toNumber(state.no_of_vancanies)) {
          if(state.reqSubscribed&& state.planName !== SUBSCRIPTION_PLAN.BASIC ) {  
            if(!userState.org.subscribed){
             let error = `please update your plan`;
             toastifyError(error);
             setState({ editReqLoader: false });
             return;
            }
         } 
         if(  !userState?.is_agency  ||
            planName === SUBSCRIPTION_PLAN.BASIC){
              if (quantity > 0) {
                quantity = _.toNumber(state.no_of_vancanies) - state.existVacancies;
                if (quantity <= userState?.org?.[planName]?.quantity) {
                  quantity = userState?.org?.[planName]?.quantity - quantity;
                  if (
                    userState?.org?.subscribed &&
                    quantity <= 0 &&
                    !userState.is_agency 
                  ) {
                    query.subscribed = false;
                    query.plan = SUBSCRIPTION_PLAN.BASIC;
                  }
                } else {
                  let error = `You have only ${userState?.org?.[planName]?.quantity} vacancies`;
                  toastifyError(error);
                  setState({ editReqLoader: false });
                  return;
                }
              } else {
                let error = `please update your plan`;
                toastifyError(error);
                setState({ editReqLoader: false });
                return;
              }
            }
        } else if (state.existVacancies > _.toNumber(state.no_of_vancanies)) {
          quantity += state.existVacancies - _.toNumber(state.no_of_vancanies);
          if (userState.org[planName].quantity <= 0) {
            query.subscribed = true;
            query.plan = planName;
          }
        }
        query[planName] = {
          total_quantity: userState?.org?.[planName]?.total_quantity,
          quantity,
          created_at: userState?.org?.[planName]?.created_at,
        };
      
      // setState({ editReqLoader: false });
      // return;
      if (state.type == REQUISITION_STATUS.DRAFT) {
        const res: any = await Models.jobRecquisition.editDraft(body);
      } else {
        const res: any = await Models.jobRecquisition.editRequisition(body);
      }
      if ( quantity !== userState.org[planName].quantity) {
        if(!userState.is_agency || planName===SUBSCRIPTION_PLAN.BASIC){
          await Models.organization.editOrganization(query)
        }
      }
      setState({ editReqLoader: false });
      toastify('Requisition edited');
      navigate(`/requisition?id=${req_id}`);
      getUser()
    } catch (error) {
      setState({ editReqLoader: false });
      toastifyError(error);
    }
  };
  const createRequisitionDraft = async () => {
    try {
      setState({ draftLoader: true });
      const body: any = {};
      if (state.job_title.length > 0) {
        body.job_title = state.job_title;
      }
      if (state.job_type.value?.length > 0) {
        body.job_type = state.job_type?.value;
      }
      if (state.job_role.value?.length > 0) {
        body.job_role_type = state.job_role?.value;
      }
      if (state.work_location_value.value?.length > 0) {
        body.work_location = state.work_location_value?.value;
      }
      body.number_of_vacancies = state.no_of_vancanies;
      body.open_vacancies = state.no_of_vancanies;
      if (state.headcount_type.value?.length > 0) {
        body.headcount_type = state.headcount_type?.value;
      }
      if (state.headcount_type.value?.length > 0) {
        body.min_experience = state.min_yr_of_exp?.value;
      }
      if (state.headcount_type.value?.length > 0) {
        body.max_experience = state.max_yr_of_exp?.value;
      }
      if (state.job_req_desc.length > 0) {
        body.job_req_desc = state.job_req_desc;
      }
      if (state.salary_currency.value?.length > 0) {
        body.salary_currency = state.salary_currency?.value;
      }
      if (state.min_salary !== null && state.min_salary.toString().length > 0) {
        body.salary_min = removeCommasFromCurrency(state.min_salary);
      }
      if (state.max_salary !== null && state.max_salary.toString().length > 0) {
        body.salary_max = removeCommasFromCurrency(state.max_salary);
      }
      if (state.remote_working?.label?.length > 0) {
        body.remote_working = state.remote_working?.label;
      }
      if (state.job_description?.length > 0) {
        body.job_description = state.job_description;
      }
      if (
        state.target_close_date !== null &&
        state.target_close_date.toString()?.length > 0
      ) {
        body.target_close_date = state.target_close_date;
      }
      if (state.skills_value?.length > 0) {
        body.skills = getDataFromDropdown(state.skills_value);
      }
      if (state.behavior_value?.length > 0) {
        body.behaviours = getDataFromDropdown(state.behavior_value);
      }
      if (state.skills_value?.length > 0) {
        body.skills = getDataFromDropdown(state.skills_value);
      }
      if (!_.isEmpty(state.hiring_team_value)) {
        body.hiring_team = getDataFromDropdown([state.hiring_team_value]);
      }
      if (state.interviewer?.length > 0) {
        body.interviewer = getDataFromDropdown(state.interviewer);
      }
      if (state.recruiter?.length > 0) {
        body.recruiter = getDataFromDropdown(state.recruiter);
      }
      if (state.department_value?.value?.length > 0) {
        body.department = state.department_value?.value;
      }
      if (state.business_unit_value?.value?.length > 0) {
        body.business_unit = state.business_unit_value?.value;
      }
      if (userState.is_agency) {
        if (state.pocValue?.value?.length > 0) {
          body.poc = state.pocValue.value;
        }
        if (state.clientValue?.value?.length > 0) {
          body.client = state.clientValue.value;
        }
        if (state.paymentTermsValue?.value?.length > 0) {
          body.payment_terms = state.paymentTermsValue.value;
        }
        if (state.accountManagerValue?.value?.length > 0) {
          body.account_manager = state.accountManagerValue.value;
        }
      }
      if (state.approvalRequired) {
        body.approval_required = state.approvalRequired;
        body.approval_type = state.approvalType.value;
        body.approval_desc=state.approval_desc
        if (!_.isEmpty(state.approvalSequential)) {
          body.approval_user = state.approvalSequential;
        }
        if (!_.isEmpty(state.approvalParallel)) {
          body.approval_user = state.approvalParallel;
        }
      }


      let planName = userState?.org?.plan;

      if (
        (userState.is_agency && planName !== SUBSCRIPTION_PLAN.BASIC) ||
        _.toNumber(state.no_of_vancanies) <=
          userState?.org?.[planName]?.quantity
      ) {
        if (userState?.org?.subscribed) {
          body.subscription = {
            plan_name: planName,
            subscribed: userState?.org?.subscribed,
          };
        }
      } else {
        let error = `You have only ${userState?.org?.[planName]?.quantity} vacancies`;
        if (userState?.org?.[planName]?.quantity <= 0) {
          error = `please update your plan`;
        }
        toastifyError(error);
        setState({ draftLoader: false });
        return;
      }
      
      if (userState.is_agency && planName === SUBSCRIPTION_PLAN.BASIC) {
        if (
          _.toNumber(state.no_of_vancanies) >
          userState?.org?.[planName]?.quantity
        ) {
          let error = `You have only ${userState?.org?.[planName]?.quantity} vacancies`;
          if (userState?.org?.[planName]?.quantity <= 0) {
            error = `please update your plan`;
          }
          toastifyError(error);
          setState({ draftLoader: false });
          return;
        }
      }
      //   if (state.job_location_value?.value?.length > 0) {
      //     body.job_location = state.job_location_value?.value;
      //   }
      // setState({
      //   draftLoader: false,
      // });
      // return
      body.status = REQUISITION_STATUS.DRAFT;
      const response: any = await Models.jobRecquisition.createDraft(body);

              // subscription plan
              if(!userState.is_agency || planName === SUBSCRIPTION_PLAN.BASIC){
                let quantity: number =
                userState?.org?.[userState?.org?.plan]?.quantity -
                _.toNumber(state.no_of_vancanies);
              let query: any = {
                [userState?.org?.plan]: {
                  total_quantity:
                    userState?.org?.[userState?.org?.plan]?.total_quantity,
                  quantity,
                  created_at: userState?.org?.[userState?.org?.plan]?.created_at,
                },
              };
              if (
                userState?.org?.plan !== SUBSCRIPTION_PLAN.BASIC &&
                quantity <= 0
              ) {
                query.plan= SUBSCRIPTION_PLAN.BASIC;
                query.subscribed = false;
              }
                await Models.organization.editOrganization(query);
              }
      toastify('Requisition saved as draft');
      navigate('/requisition_board');
      setState({
        job_title: '',
        job_type: '',
        job_role: '',
        salary_currency: '',
        min_salary: null,
        max_salary: null,
        job_location_value: '',
        work_location: '',
        no_of_vancanies: null,
        headcount_type: '',
        bonus_payout_type: '',
        bonus_payout_value: null,
        remote_working: '',
        target_close_date: '',
        min_yr_of_exp: '',
        max_yr_of_exp: '',
        job_req_desc: '',
        job_description: '',
        job_description_template: '',
        skills_value: [],
        behavior_value: [],
        hiring_team_value: [],
        errorArray: [],
        work_location_value: [],
        department_value: [],
        business_unit_value: [],
        recruiter: [],
        interviewer: [],
        recruiter_data: [],
        interviewer_data: [],
        draftLoader: true,
        poc: '',
        clientsData: [],
        clientValue: '',
        paymentTermsData: [],
        paymentTermsValue: '',
        accountManagerData: [],
        accountManagerValue: '',
        editApprovalParallel: [],
        editApprovalSequential: [],
        reqSubscribed: false,
        existVacancies:0,
        planName:''
      });
    } catch (error) {
      setState({
        draftLoader: false,
      });
      toastifyError(error);
    }
  };

  const getSkillsData = async () => {
    try {
      const res: any = await Models.skills.getManySkills();
      setState({ skills_data: res.data.skills });
    } catch (error: any) {
      toastifyError(error);
    }
  };
  const getClientData = async (body: any) => {
    try {
      setState({ paymentTermsValue: "", pocValue: "" });
      let res: any = await Models.client.getClientById({ client_id: body })
      let pocData: any = []
      res.data.poc.map((item: any) => {
        pocData.push({
          label: item?.email,
          value: item?.email,
        });
      });
      setState({ pocData });
    } catch (error: any) {
      toastifyError(error);
    }
  };

 

  // const getClientData = (body) => {
  //   setState({ paymentTermsValue: "", pocValue: "" });
  //   let data: any = _.find(state.clientAllData, { _id: body });
  //   let pocData: any = []
  //   data.poc.map((item: any) => {
  //     pocData.push({
  //       label: item?.email,
  //       value: item?.email,
  //     });
  //   });
  //   setState({ pocData });
  // };

  //Logic
  const removeEmailFromDropdown = (data: any) => {
    let newData = data.map((item: any) => {
      item.label = item.label.split('-')[0];
      return item;
    });
    return newData;
  };

  const removeSelectedUser = (newOption?: any) => {
    if (newOption.action == DropdownStatus.REMOVE_VALUE) {
      let newUserList: any = state.userList;
      newUserList.push(newOption.removedValue);
      setState({
        userList: newUserList,
      });
    } else {
      let index: any = _.findIndex(state.addedList, {
        value: newOption.option.value,
      });
      if (index == -1) {
        let newUserList: any = _.filter(state.userList, (item: any) => {
          return item.value !== newOption.option.value;
        });
        setState({
          userList: newUserList,
        });
      }
    }
  };

  const removeUserFromList = (option: any) => {
    let index: any = _.findIndex(state.userArray, {
      value: option,
    });
    if (index !== -1) {
      let newUserList: any = _.filter(state.userArray, (item: any) => {
        return item.value !== option;
      });
      setState({
        userList: newUserList,
      });
    }
  };
  const getUser = async () => {
    let userId = localStorage.getItem('user_id');
    try {
      const res: any = await Models.auth.viewUser(userId);
      // dispatch
      getUserDetails(res.data);
    } catch (error: any) {
      if (error == 'invalid signature'|| error == 'Invalid token') {
        localStorage.clear();
        window.location.href = '/login';
      }
      console.log(error);
    }
  };
  let actionArray = {
    style: { width: '25%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div
              className="remove_icon"
              onClick={() => {
                let data: any = [];
                if (state.approvalType.value === REQ_APPROVAL.SEQUENTIAL) {
                  data = state.approvalSequential;
                  let index = _.findIndex(data, (e: any) => {
                    return e.user.value === item.user.value;
                  });
                  data.splice(index, 1);
                  data.forEach((element, index) => {
                    data[index].priority = index + 1;
                  });
                  setState({ approvalSequential: data });
                } else if (state.approvalType.value === REQ_APPROVAL.PARALLEL) {
                  data = state.approvalParallel;
                  let index = _.findIndex(data, (e: any) => {
                    return e.user.value === item.user.value;
                  });
                  data.splice(index, 1);
                  setState({ approvalParallel: data });
                }
              }}>
              <img src={Assets.trash} />
            </div>
          );
        },
      },
    ],
  };
  const approvalTeam = (data: any) => {
    let query: any = {
      user: data,
    };
    if (state.approvalType.value === REQ_APPROVAL.SEQUENTIAL) {
      query.priority = state.approvalSequential.length + 1;
      setState({
        approvalSequential: [...state.approvalSequential, query],
      });
    } else if (state.approvalType.value === REQ_APPROVAL.PARALLEL) {
      setState({
        approvalParallel: [...state.approvalParallel, query],
      });
    }
  };

 

  const editApproval = (data:string) => {
    if(data === "approvalList") {
    return (
      _.size(state.editApprovalSequential) === _.size(state.approvalSequential)
    );}
    else if(data === "editApprovalShow") {
      if(!_.isEmpty(state.editApprovalSequential)){
        return true
      }
      else if(!_.isEmpty(state.approvalParallel)){
        return true
      }
      else{
        return false
      }
    }
  };

  const addSubjectFromTemplate = (template) => {
    console.log('template',template);
    
    setState({
      approval_desc: template?.value,
    });
  };
  // console.log('state.job_desc',state.approval_desc);
  
  return (
    <div className="new_job_container" ref={wrapperRef}>
      <div className="new_job_header">
        <div className="new_job_header_text">
          {req_id?.length > 0 ? 'Edit' : 'New'} Requisition
        </div>
        <div className="new_job_header_buttons">
          {req_id?.length > 0 ? (
            <>
              <PrimaryButton
                text={'Close'}
                icon={Assets.close_black}
                className={'header_button gray_button'}
                onClick={() => {
                  navigate(`/requisition?id=${req_id}`);
                }}
              />
              <PrimaryButton
                text={'Save'}
                icon={Assets.save}
                loading={state.editReqLoader}
                className={'header_button'}
                onClick={() => editRequisition()}
              />
            </>
          ) : (
            <div className='draft_btn'>
              <PrimaryButton
                text={'Save as draft'}
                // disabled={userState.org?.subscribed?true:false}
                // icon={userState.org?.subscribed?Assets.save_inactive:Assets.save}
                icon={Assets.save}
                loading={state.draftLoader}
                onClick={() => {
                  createRequisitionDraft();
                }}
                className={'save_draft_btn'}
              />
              <PrimaryButton
                onClick={() => createJobRecquisition()}
                text={'Submit'}
                loading={state.createReqLoader}
                icon={Assets.big_tick}
                className={'header_button'}
              />
            </div>
          )}
        </div>
      </div>
      <div className="new_job_wrapper">
        <div className="new_job_details_container">
          <div className="new_job_heading">Job details</div>
          <div className="new_job_title_container">
            <div className="new_job_job_title">
              <div className="new_job_input_heading">
                Job Title<span className="required_field">*</span>
              </div>
              <Input
                onChange={(job_title: string) => {
                  setState({ job_title: job_title });
                }}
                type={'text'}
                value={state.job_title}
                name={'job_title'}
                error={state.errorArray}
              />
            </div>
            <div className="new_job_job_type new_job_type_wrapper">
              <div className="new_job_input_heading">
                Job Type<span className="required_field">*</span>
              </div>
              <Dropdown
                onChange={(job_type: any) => {
                  setState({
                    job_type: job_type,
                  });
                }}
                data={getDropdownData(job_types)}
                name="job_type"
                value={state.job_type}
                error={state.errorArray}
              />
            </div>
            <div className="new_job_job_role new_job_job_type">
              <div className="new_job_input_heading">
                Job Role Type<span className="required_field">*</span>
              </div>
              <Dropdown
                onChange={(job_role: any) => {
                  setState({
                    job_role: job_role,
                  });
                }}
                data={getDropdownData(job_role_type)}
                name="job_role_type"
                value={state.job_role}
                error={state.errorArray}
              />
            </div>
            <div className="new_job_salary_currency new_job_job_type">
              <div className="new_job_input_heading">Salary Currency</div>
              <Dropdown
                onChange={(salary_currency: any) => {
                  setState({
                    salary_currency: salary_currency,
                  });
                }}
                data={getDropdownData(currency)}
                value={state.salary_currency}
                error={state.errorArray}
                name="salary_currency"
              />
            </div>
            <div className="new_job_salary_min">
              <div className="new_job_input_heading">Min Salary Per Year</div>
              <Input
                onChange={(min_salary: any) => {
                  let salary_min = addCommasToCurrency(
                    min_salary.replaceAll(',', ''),
                  );
                  setState({ min_salary: salary_min, salaryMin: min_salary });
                }}
                type={'text'}
                value={state.min_salary}
                name={'salary_min'}
                error={state.errorArray}
              />
            </div>
            <div className="new_job_salary_min">
              <div className="new_job_input_heading">Max Salary Per Year</div>
              <Input
                onChange={(max_salary: any) => {
                  let salary_max = addCommasToCurrency(
                    max_salary.replaceAll(',', ''),
                  );
                  setState({
                    max_salary: salary_max,
                    salaryMax: max_salary,
                  });
                }}
                type={'text'}
                value={state.max_salary}
                name={'salary_max'}
                error={state.errorArray}
              />
            </div>
          </div>
          <div className="new_job_location_container">
            <div className="new_job_work_location">
              <div className="new_job_input_heading">
                Work Location Address<span className="required_field">*</span>
              </div>
              <Dropdown
                onChange={(work_location_value: any) => {
                  setState({
                    work_location_value,
                  });
                }}
                data={state.work_location}
                value={state.work_location_value}
                name={'work_location'}
                error={state.errorArray}
              />
            </div>
            <div className="new_job_job_role new_job_job_department">
              <div className="new_job_input_heading">Department</div>
              <Dropdown
                onChange={(department_value: any) => {
                  setState({
                    department_value,
                  });
                }}
                data={state.departments}
                name="department"
                value={state.department_value}
                error={state.errorArray}
              />
            </div>
            <div className="new_job_job_role new_job_job_business_unit">
              <div className="new_job_input_heading">Business unit</div>
              <Dropdown
                onChange={(business_unit_value: any) => {
                  setState({
                    business_unit_value,
                  });
                }}
                data={state.business_units}
                name="business_unit"
                value={state.business_unit_value}
                error={state.errorArray}
              />
            </div>
            <div className="new_job_numberof_vacancies">
              <div className="new_job_input_heading">
                Number Of Vacancies<span className="required_field">*</span>
              </div>
              <Input
                onChange={(no_of_vancanies: any) => {
                  setState({ no_of_vancanies: no_of_vancanies });
                }}
                type={'number'}
                value={state.no_of_vancanies}
                name={'number_of_vacancies'}
                error={state.errorArray}
              />
            </div>
          </div>
          <div className="new_job_vacancies_container">
            <div className="new_job_headcount_type">
              <div className="new_job_input_heading">
                Headcount Type<span className="required_field">*</span>
              </div>
              <Dropdown
                onChange={(headcount_type: any) => {
                  setState({
                    headcount_type: headcount_type,
                  });
                }}
                data={getDropdownData(headcount_type)}
                value={state.headcount_type}
                error={state.errorArray}
                name="headcount_type"
              />
            </div>
            <div className="new_job_remote_working">
              <div className="new_job_input_heading">Remote Working</div>
              <Dropdown
                onChange={(remote_working: any) => {
                  setState({
                    remote_working,
                  });
                }}
                data={getDropdownData(remote_work_preference)}
                value={state.remote_working}
                error={state.errorArray}
                name={'remote_working'}
              />
            </div>
            <div className="new_job_target_date">
              <div className="new_job_input_heading">Target Close Date</div>
              <DatePickerComponent
                onChange={(target_close_date: string) => {
                  setState({ target_close_date: target_close_date });
                }}
                value={state.target_close_date}
                name={'target_close_date'}
                error={state.errorArray}
              />
            </div>
            <div className="new_job_min_experience">
              <div className="new_job_input_heading">
                Min Experience<span className="required_field">*</span>
              </div>
              <Dropdown
                onChange={(min_yr_of_exp) => {
                  setState({
                    min_yr_of_exp,
                  });
                }}
                data={getDropdownData(getExperience())}
                name="min_experience"
                value={state.min_yr_of_exp}
                error={state.errorArray}
              />
            </div>
            <div className="new_job_max_experience">
              <div className="new_job_input_heading">
                Max Experience<span className="required_field">*</span>
              </div>
              <Dropdown
                onChange={(max_yr_of_exp) => {
                  setState({
                    max_yr_of_exp: max_yr_of_exp,
                  });
                }}
                data={getDropdownData(getExperience('max'))}
                name="max_experience"
                value={state.max_yr_of_exp}
                error={state.errorArray}
              />
            </div>
          </div>
        </div>
        <div className="new_job_description_container">
          <div className="job_description_gender_decoder_wrapper">
            <div className="job_description_editor">
              <div className="new_job_heading">Job Description</div>
              <div className="new_job_description_editor" ref={targetRef}>
                <TextEditor
                  onChange={(job_description: string) =>
                    setState({ job_description })
                  }
                  name="job_description"
                  error={state.errorArray}
                  value={state.job_description}
                />
              </div>
            </div>
            <div className="new_job_description_wrapper">
              <div className="new_job_description_template">
                <div className="new_job_heading margin_bottom">
                  Gender Decoder
                </div>
                {state.job_description !== '' &&
                  state.job_description !== '<p><br></p>' && (
                    <div className="new_job_tips_container">
                      <div className="new_job_tips_wrapper">
                        {state.job_description !== '' &&
                          state.job_description !== '<p><br></p>' && (
                            <div className="new_job_description_text">
                              Your job description looks
                              <span className="gender_heading">
                                {
                                  isGenderBiased(state.job_description)
                                    .genderStrength.strength
                                }
                              </span>
                            </div>
                          )}
                        <br />
                        <div className="new_job_description_text">
                          {state.job_description !== '' &&
                            state.job_description !== '<p><br></p>' &&
                            isGenderBiased(state.job_description).genderStrength
                              .reason}
                        </div>
                        {state.job_description !== '' &&
                          state.job_description !== '<p><br></p>' &&
                          isGenderBiased(state.job_description).genderData.map(
                            (genderData: any) => {
                              return (
                                genderData.data.length > 0 && (
                                  <div className="new_job_description_text gender_title">
                                    {genderData.gender}:
                                    {genderData.data.map((codedWords: any) => {
                                      return (
                                        <div
                                          style={{
                                            display: 'flex',
                                          }}>
                                          <div className="new_job_description_text gender_coded_words">
                                            {codedWords[0]} :
                                          </div>
                                          <div className="new_job_description_text gender_coded_words">
                                            {codedWords[1]}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )
                              );
                            },
                          )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="new_job_skills_container">
            <div className="new_job_heading">Skills</div>
            <div className="new_job_skills_wrapper">
              <MultiSelectDropdown
                data={getDropdownData(state.skills_data)}
                onChange={(data: any) => {
                  setState({
                    skills_value: data,
                  });
                }}
                value={state.skills_value}
                name="skills"
                error={state.errorArray}
              />
            </div>
          </div>
          <div className="new_job_skills_container">
            <div className="new_job_heading">Behaviors</div>
            <div className="new_job_skills_wrapper">
              <MultiSelectDropdown
                data={getDropdownData(behaviorData)}
                onChange={(behavior_value: any) => {
                  setState({
                    behavior_value,
                  });
                }}
                value={state.behavior_value}
                name="behaviours"
                error={state.errorArray}
              />
            </div>
          </div>
        </div>
        <div className="hiring_team_wrapper">
          <div className="new_job_heading">Hiring Team</div>
          {userState.is_agency ? (
            <>
              <div className="new_job_skills_container">
                <div className="new_job_heading">Recruiter</div>
                <div className="new_job_skills_wrapper">
                  <Dropdown
                    isMulti
                    data={state.userList}
                    onChange={(data: any, newOption?: any) => {
                      removeSelectedUser(newOption);
                      setState({
                        recruiter: data,
                      });
                    }}
                    value={state.recruiter}
                    name="recruiter"
                    error={state.errorArray}
                  />
                </div>
              </div>
              <div className="new_job_client_container">
                <div className="new_job_account_manager">
                  <div className="new_job_input_heading">Account Manger</div>
                  <Dropdown
                    onChange={(accountManagerValue: any) => {
                      setState({
                        accountManagerValue,
                      });
                    }}
                    data={state.accountManagerData}
                    name="account_manager"
                    value={state.accountManagerValue}
                    error={state.errorArray}
                  />
                </div>
                <div className="new_job_client">
                  <div className="new_job_input_heading">Client</div>
                  <Dropdown
                    onChange={(clientValue: any) => {
                      getAllPaymentTerms(clientValue.value);
                      getClientData(clientValue.value);
                      setState({
                        clientValue,
                      });
                    }}
                    data={state.clientsData}
                    name="client"
                    value={state.clientValue}
                    error={state.errorArray}
                  />
                </div>
                <div className="new_job_payment_terms">
                  <div className="new_job_input_heading">Payment Terms</div>
                  <Dropdown
                    onChange={(paymentTermsValue: any) => {
                      setState({
                        paymentTermsValue,
                      });
                    }}
                    data={state.paymentTermsData}
                    name="payment_terms"
                    value={state.paymentTermsValue}
                    error={state.errorArray}
                  />
                </div>
                <div className="new_job_poc">
                  <div className="new_job_input_heading">Poc</div>
                  <Dropdown
                    onChange={(pocValue: any) => {
                      setState({
                        pocValue,
                      });
                    }}
                    data={state.pocData}
                    name="poc"
                    value={state.pocValue}
                    error={state.errorArray}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="new_job_skills_container">
                <div className="new_job_heading">Hiring Manager</div>
                <div className="new_job_skills_wrapper">
                  <Dropdown
                    data={state.userList}
                    isMulti
                    onChange={(data: any, newOption?: any) => {
                      if (newOption.option) {
                        setState({
                          hiring_team_value: [newOption.option],
                        });
                      } else {
                        setState({
                          hiring_team_value: [],
                        });
                      }
                    }}
                    value={state.hiring_team_value}
                    name="hiring_team"
                    error={state.errorArray}
                  />
                </div>
              </div>
              <div className="new_job_skills_container">
                <div className="new_job_heading">Recruiter</div>
                <div className="new_job_skills_wrapper">
                  <Dropdown
                    isMulti
                    data={state.userList}
                    onChange={(data: any, newOption?: any) => {
                      removeSelectedUser(newOption);
                      setState({
                        recruiter: data,
                      });
                    }}
                    value={state.recruiter}
                    name="recruiter"
                    error={state.errorArray}
                  />
                </div>
              </div>
              <div className="new_job_skills_container">
                <div className="new_job_heading">Interviewer</div>
                <div className="new_job_skills_wrapper">
                  <Dropdown
                    isMulti
                    data={state.userList}
                    onChange={(data: any, newOption?: any) => {
                      removeSelectedUser(newOption);
                      setState({
                        interviewer: data,
                      });
                    }}
                    value={state.interviewer}
                    name="interviewer"
                    error={state.errorArray}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={`${
            editApproval('editApprovalShow') &&
            !_.isEmpty(req_id) &&
            'create_approval_container'
          } ${_.isEmpty(req_id) && 'create_approval_container'}`}>
          {!_.isEmpty(req_id) && editApproval('editApprovalShow') ? (
            <div className="approval_header">
              <img
                src={state.approvalRequired ? Assets.checked : Assets.uncheck}
                alt="no check_icon"
                height={16}
                width={16}
              />
              <div className="approval_title">
                Create Requisition with Approval
              </div>
            </div>
          ) : _.isEmpty(req_id) ? (
            <div
              className="approval_header"
              onClick={() =>
                setState({
                  approvalRequired: !state.approvalRequired,
                })
              }>
              <img
                src={state.approvalRequired ? Assets.checked : Assets.uncheck}
                alt="no check_icon"
                height={16}
                width={16}
              />
              <div className="approval_title">
                Create Requisition with Approval
              </div>
            </div>
          ) : (
            ''
          )}
          {state.approvalRequired && (
            <>
              <div className="radio_container">
                {_.isEmpty(req_id) ? (
                  approvalType.map((item: any, index: number) => (
                    <Radio
                      name="approval_type"
                      data={item}
                      isSelected={state.approvalType.value === item.value}
                      setActive={(type: any) => {
                        setState({
                          approvalType: type,
                        });
                      }}
                      key={index}
                    />
                  ))
                ) : (
                  <div className="approval_type_title">
                    {state.approvalType.value} Approval
                  </div>
                )}
              </div>
              <div className="approval_team_wrapper">
                <div className="approval_team_header">
                  <div className="approval_title">Approval Member<span className="required_field">*</span></div>
                  {_.isEmpty(req_id) && (
                    <PrimaryButton
                      text={'Add Member'}
                      icon={Assets.add}
                      className={'header_button'}
                      onClick={() => {
                        approvalRef.current.openModal();
                        // navigate(`/requisition?id=${req_id}`);
                      }}
                      width={'140px'}
                    />
                  )}
                </div>
                <ApprovalModal
                  ref={approvalRef}
                  data={state.userArray}
                  type={'approvalUser'}
                  onClick={approvalTeam}
                  approvalData={
                    state.approvalType.value === REQ_APPROVAL.SEQUENTIAL
                      ? state.approvalSequential
                      : state.approvalParallel
                  }
                />
                <div className="approval_team_list">
                  {state.approvalType.value === REQ_APPROVAL.SEQUENTIAL &&
                    (req_id?.length > 0 ? (
                      <>
                        <ApprovalListComponent
                          data={state.editApprovalSequential}
                          titleArray={
                            editApproval('approvalList')
                              ? editSequentialApproval
                              : sequentialApproval
                          }
                          type="parallelApproval"
                          loading={state.loading}
                          // show='sequentialApproval'
                          show={'sequentialApproval'}
                        />
                        {/* <DraggableComponent
                            data={state.approvalSequential}
                            editApproval={state.editApprovalSequential}
                            titleArray={sequentialApproval}
                            actionArray={actionArray}
                            refresh={(approvalSequential) =>
                              setState({ approvalSequential })
                            }
                          /> */}
                      </>
                    ) : (
                      <DraggableComponent
                        data={state.approvalSequential}
                        titleArray={sequentialApproval}
                        loading={state.loading}
                        actionArray={actionArray}
                        refresh={(approvalSequential) =>
                          setState({ approvalSequential })
                        }
                      />
                    ))}
                  {state.approvalType.value === REQ_APPROVAL.PARALLEL &&
                    (req_id?.length > 0 ? (
                      <>
                        <ApprovalListComponent
                          data={state.approvalParallel}
                          editApproval={state.editApprovalParallel}
                          titleArray={parallelApproval}
                          type="parallelApproval"
                          loading={state.loading}
                          show="parallelApproval"
                          actionArray={actionArray}
                        />
                      </>
                    ) : (
                      <ApprovalListComponent
                        data={state.approvalParallel}
                        titleArray={parallelApproval}
                        type="parallelApproval"
                        loading={state.loading}
                        actionArray={actionArray}
                      />
                    ))}
                  {state.approvalError.includes("Approval User") && (
                    <div className="input_field_error">Please add member</div>
                  )}
                </div>
              </div>
                    <div className="job_description_editor">
              <div className="new_job_heading">Approval Description<span className="required_field">*</span></div>
              <div className="new_job_description_editor" >
                <TextEditor
                  onChange={(e: any) =>
                    setState({ approval_desc:e })
                  }
                  name="approval_desc"
                  error={state.errorArray}
                  value={state.approval_desc}
                  disabled={!_.isEmpty(req_id)}
                />
                 {state.approvalError.includes("Approval Desc") && (
                    <div className="input_field_error">Please Enter Approval Description</div>
                  )}
                     {/* <div className="email_resume_name_container">
              <div className="email_resume_name_label">Email templates</div>
              <Dropdown
                data={state.email_templates}
                value={state.email_template}
                setActive={(email_template: any) => {
                  addSubjectFromTemplate(email_template);
                }}
              />
            </div> */}
              </div>
            </div>
            </>
          )}
        </div>
        <div className="req_btn_wrapper">
          {req_id?.length > 0 ? (
            <>
              <PrimaryButton
                text={'Close'}
                icon={Assets.close_black}
                className={'header_button gray_button'}
                onClick={() => {
                  navigate(`/requisition?id=${req_id}`);
                }}
              />
              <PrimaryButton
                text={'Save'}
                icon={Assets.save}
                className={'header_button'}
                loading={state.editReqLoader}
                onClick={() => editRequisition()}
              />
            </>
          ) : (
            <div className="draft_btn">
              <PrimaryButton
                text={'Save as draft'}
                // disabled={userState.org?.subscribed?true:false}
                // icon={userState.org?.subscribed?Assets.save_inactive:Assets.save}
                icon={Assets.save}
                loading={state.draftLoader}
                onClick={() => createRequisitionDraft()}
                className={'save_draft_btn'}
              />
           
              <PrimaryButton
                onClick={createJobRecquisition}
                loading={state.createReqLoader}
                text={'Submit'}
                icon={Assets.big_tick}
                className={'header_button'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateRequisition;
