import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toastify, toastifyError, useQuery, useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  PrimaryButton,
  Assets,
} from 'utils/imports.utils';
import { setAppRoute, testDispatch } from 'utils/redux.utils';
import './invite.screen.scss';

const Invite = (props: any) => {
  // Redux
  const testState = useSelector((state: any) => state.app_state);
  // State
  const [state, setState] = useSetState({ email: '' });
    let query = useQuery();
    let redirect:any = query.get('redirect')
    let is_agency = query.get('is_agency')
    let agency = query.get('agency')
    let org = query.get('org')
    let domain = query.get('domain')

    //Hooks
  useEffect(() => {
    let token = localStorage.getItem('token');
    let organization:any = localStorage.getItem('org')
    organization = JSON.parse(organization);
    if (!_.isEmpty(organization) && domain !== organization?.domain) {
      localStorage.clear();
      window.location.href = `/signup?redirect=${encodeURIComponent(redirect)}`;
    } else {
      inviteOrg();
    }
  }, []);
  const navigate = useNavigate();
  // Network req
  const editAgency = async (body: any) => {
    try {
      const res: any = await Models.agency.inviteAgency(body);
      let redirect=`/create_profile?is_agency=${is_agency}`
      window.location.href = `/signup?redirect=${encodeURIComponent(redirect)}`
      //dispatch
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const inviteOrg = async () => {
    try {
      let res: any = await Models.auth.inviteOrganization({ org_id: org });
      setState({ org_data: res.data });
    } catch (error) {
      toastifyError(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className="invite_container">
      <div className="invite_wrapper">
        <div className="invite_container_logo">
          <img
            src={Assets.logo}
            height={100}
            width={100}
            alt="sidebar logo"
            className="sidebar_logo"
          />
        </div>
        <div className="invite_heading">Accept invite</div>
        <div className="sub_heading">
          You have been invited to join {state.org_data?.name}
        </div>
        {/* <div className="inputs">
          <div className="input_heading">
            Work Email<span className="required_field">*</span>
          </div>
          <div className="input_box">
            <Input
              type={'text'}
              onChange={(email) => setState({ email })}
              value={state.email}
              name={'email'}
            />
          </div>
        </div> */}
        <div className="invite_button_wrapper">
          <PrimaryButton
            text={'Accept'}
            className={'invite_button'}
            width={'250px'}
            onClick={() => {
              editAgency({ agency_id: agency, invite_accepted: true });
            }}
          />
          <PrimaryButton
            text={'Decline'}
            width={'250px'}
            className={'invite_button'}
            onClick={() => {
              editAgency({ agency_id: agency, invite_accepted: false });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Invite;
