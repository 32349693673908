import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  PrimaryButton,
  Dropdown,
  CandidateInfo,
  Input,
  TextEditor
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './create_new_offer.screen.scss';

interface ICreateNewOffer {
  text?: String;
}

const CreateNewOffer = (props: ICreateNewOffer) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};

  const candidateValue = {
    Full_name: 'John Doe',
    Position_Applied_For :'Software Developer',
    Email: 'john123@gmail.com',
    Location: 'chennai',
    Gender: 'Male',
    Relocation: 'yes',
    Contact: '0987654321',
    Compensation: '$145,000,000',
    Resume_Source: 'Email',
  };

  return (
    <div className="new_offer_container">
      <div className="new_offer_header">
        <div className="new_offer_header_wrapper">
          <div className="new_offer_header_back">
            <img src={Assets.back} />
          </div>
          <div className="new_offer_header_text">Create New Offer</div>
        </div>
        <div className="new_offer_header_buttons">
          <PrimaryButton
            text={'Close'}
            icon={Assets.black_close}
            className={'header_button gray_button'}
          />
          <PrimaryButton
            text={'Save Draft'}
            icon={Assets.save}
            className={'header_button'}
          />
          <PrimaryButton
            text={'Submit For Approval'}
            icon={Assets.big_tick}
            className={'header_button'}
          />
        </div>
      </div>
      <div className='new_offer_container1'>
          <div className='new_offer_details_container'>
              <div className='new_offer_subheading'>Offer Details</div>
              <div className='new_offer_details_wrapper'>
                  <div className='new_offer_details_content'>
                      <div className='new_offer_details_heading'>Job Title</div>
                      <div className='new_offer_details_value'>Software Developer</div>
                  </div>
                  <div className='new_offer_details_content'>
                      <div className='new_offer_details_heading'>Job Type</div>
                      <div className='new_offer_details_value'>Permanant</div>
                  </div>
                  <div className='new_offer_details_content'>
                      <div className='new_offer_details_heading'>Job Role Type</div>
                      <div className='new_offer_details_value'>Full Time</div>
                  </div>
                  <div className='new_offer_details_content'>
                      <div className='new_offer_details_heading'>Salary Range</div>
                      <div className='new_offer_details_value'>$45,000 to $65,000</div>
                  </div>
                  <div className='new_offer_details_content'>
                      <div className='new_offer_details_heading'>Job Location</div>
                      <div className='new_offer_details_value'>Software Engineer</div>
                  </div>
                  <div className='new_offer_details_content'>
                      <div className='new_offer_details_heading'>Work Location Address</div>
                      <div className='new_offer_details_value'>14 Wall Street, Manhattan, New York City, NY, USA</div>
                  </div>
                  <div className='new_offer_details_content'>
                      <div className='new_offer_details_heading'>Bonus Payout</div>
                      <div className='new_offer_details_value'>N/A</div>
                  </div>
                  <div className='new_offer_details_content'>
                      <div className='new_offer_details_heading'>Remote Working</div>
                      <div className='new_offer_details_value'>No</div>
                  </div>
                  <div className='new_offer_salary_container'>
                      <div className='new_offer_salary_wrapper'>
                          <div className='new_offer_input_heading'>Current Salary</div>
                          <div className='new_offer_salary_dropdown'>
                              <Dropdown data={""} />
                          </div>
                      </div>
                      <div className='new_offer_salary_wrapper'>
                      <div className='new_offer_input_heading'>Expected Salary</div>
                          <div className='new_offer_salary_dropdown'>
                              <Dropdown data={""} />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className='new_offer_candidateinfo_container'>
              <CandidateInfo data={candidateValue} />
          </div>
      </div>
      <div className="new_offer_container2">
          <div className='new_offer_subheading'>Offer Letter</div>
          <div className="new_offer_letter_wrapper">
            <div className="new_offer_editor">
              <TextEditor
                onChange={(content: string) => setState({ content })}
              />
            </div>
            <div className="new_offer_template">
              <div className="new_offer_templates_heading">
                Offer Templates
              </div>
              <Dropdown data={''} />
              <div className="new_offer_tips_container">
                <div className="new_offer_tips_wrapper">
                  <div className="new_offer_heading margin_bottom">
                    Tips offer Description
                  </div>
                  <div className="new_offer_description_text">
                    Your job description looks gender biased. Click on tips
                    below to apply them to your job description.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default CreateNewOffer;
