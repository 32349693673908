import instance from 'utils/axios.utils';
import { uploadFile } from 'utils/functions.utils';

const workLocation = {
  createWorkLocation: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'location/create_location';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getLocationById: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = '/location/get_location';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getAllLocation: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'location/get_many_location';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteLocationById: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'location/delete_location';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getLocationList: (body: any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'location/public/get_many_location';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message === 'Invalid token' ||
              error.response.data.message === 'invalid signature'
            ) {
              localStorage.clear();
              window.location.href = '/login';
              reject('');
            } else {
              reject(error.response.data.message);
            }
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default workLocation;
