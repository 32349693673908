import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Colors, Functions, Models } from 'utils/imports.utils';
import 'react-circular-progressbar/dist/styles.css';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import './circular_progress.scss';

const CircularProgress = (props: any) => {
  // Redux

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  //Logic
  //sample_data

  return (
    <div>
      <CircularProgressbarWithChildren
        value={props.value}
        styles={buildStyles({
          pathTransitionDuration: 0.5,
          pathColor: Colors.gradientButtonColor,
          trailColor: Colors.inputBackground,
        })}
        strokeWidth={10}
        {...props}>
        <div className="circular_progress_value">{props.percentage}</div>
        <div className="circular_progress_text">{props.title}</div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircularProgress;
