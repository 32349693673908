import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  useSetState,
  getDataFromDropdown,
  toastifyError,
  getDropdownValue,
  toastify,
} from 'utils/functions.utils';
import {
  Models,
  Navbutton,
  Assets,
  Input,
  Tag,
  Dropdown,
  PrimaryButton,
  FileUpload,
  TextEditor,
  Attachment,
  MultiSelectDropdown,
  Loader,
} from 'utils/imports.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './support_email.component.scss';
import { useNavigate } from 'react-router-dom';
import { getAllEmailTemplates } from 'utils/redux.utils';
import _ from 'lodash';

const SupportEmail = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();
  const inputFileRef: any = useRef();
  //Redux
  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    email: '',
    from: '',
    recipients: [],
    errorArray: [],
    attachments: [],
    email_templates: [],
    email_template: '',
    to: '',
    cc: '',
    bcc: '',
    toRecipients: [],
    ccRecipients: [],
    bccRecipients: [],
    error: false,
    subject:'',
    html:''
  });

  //Hooks
  useEffect(() => {
    if (props.resume) {
      let attachments: any = [];
      // attachments.push(props.resume);
      setState({ attachments: [...attachments, ...props.resume] });
    }
    // addRecipient();
  }, [props.resume?.length]);
  // Network req

  useEffect(() => {
    setState({ toRecipients: getDropdownData(props.recipients) });
  }, [props?.recipients?.length]);

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({
        isModalOpen: false,
        email: '',
        from: '',
        recipients: [],
        errorArray: [],
        attachments: [],
        email_templates: [],
        email_template: '',
        to: '',
        cc: '',
        bcc: '',
        toRecipients: [],
        ccRecipients: [],
        bccRecipients: [],
      });
    },
  }));

  const sendEmail = async () => {
    try {
      if (state.subject.length > 0 && state.email) {
        setState({ loading: true });
        let body = {
          email: ['support@askhugo.zohodesk.in'],
          subject: state.subject,
          html: state.email,
          attachments: state.attachments,
        };
        const res: any = await Models.auth.sendSupportEmail(body);
        toastify('Email sent');
        setState({ isModalOpen: false, loading: false });
      } else {
        toastifyError('Enter all the fields');
      }
    } catch (error) {
      setState({ loading: false });
      console.log('error', error);
      toastifyError(error);
    }
  };

  //Logic

  const handleUploadAttachments = (files: any) => {
    let attachments: any = state.attachments;
    attachments.push(files);
    setState({
      attachments,
    });
  };

  const removeAttachments = (index: number) => {
    let attachments: any = state.attachments;
    attachments.splice(index, 1);
    setState({ attachments });
  };

  const addRecipient = () => {
    let recipients: any = [];
    if (state.toRecipients.length > 0) {
      state.toRecipients.map((email: any) => recipients.push(email.value));
    }
    if (state.ccRecipients.length > 0) {
      state.ccRecipients.map((email: any) => recipients.push(email.value));
    }
    if (state.bccRecipients.length > 0) {
      state.bccRecipients.map((email: any) => recipients.push(email.value));
    }
    setState({
      recipients,
    });
    return recipients;
  };

  const addSubjectFromTemplate = (template) => {
    const index = _.findIndex(state.templates, { name: template.label });
    let email_templates = state.templates;
    setState({
      subject: email_templates[index].subject,
      email_template: template,
      email: template?.value,
    });
  };

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'email_resume_modal_wrapper',
        }}>
        <div className="email_resume_container">
          <div className="email_resume_header">
            <div className="email_resume_heading">New Message</div>
            <Navbutton
              icon={Assets.black_close}
              onClick={() => {
                setState({ isModalOpen: false });
              }}
            />
          </div>
          {/* <div className="email_resume_name_container">
              <div className="email_resume_name_label">To</div>
              <MultiSelectDropdown
                type="email"
                data={[]}
                onChange={(data: any) => {
                  setState({
                    toRecipients: data,
                  });
                }}
                value={state.toRecipients}
                name="toRecipients"
                error={state.errorArray}
                placeholder="Enter recipients"
              />
            </div> */}
          {/* <div className="email_recipients_wrapper">
              <div
                className="email_recipient"
                onClick={() => setState({ showCC: !state.showCC })}>
                Cc
              </div>
              <div
                className="email_recipient"
                onClick={() => setState({ showBcc: !state.showBcc })}>
                Bcc
              </div>
            </div> */}
          {/* {state.showCC && (
              <div className="email_resume_name_container">
                <div className="email_resume_name_label">Cc</div>
                <MultiSelectDropdown
                  type="email"
                  data={[]}
                  onChange={(data: any) => {
                    setState({
                      ccRecipients: data,
                    });
                  }}
                  value={state.ccRecipients}
                  name="ccRecipients"
                  error={state.errorArray}
                  placeholder="Enter recipients"
                />
              </div>
            )}
            {state.showBcc && (
              <div className="email_resume_name_container">
                <div className="email_resume_name_label">Bcc</div>
                <MultiSelectDropdown
                  type="email"
                  data={[]}
                  onChange={(data: any) => {
                    setState({
                      bccRecipients: data,
                    });
                  }}
                  value={state.bccRecipients}
                  name="bccRecipients"
                  error={state.errorArray}
                  placeholder="Enter recipients"
                />
              </div>
            )} */}
          <div className="email_resume_name_container">
            <div className="email_resume_name_label">Subject</div>
            <Input
              onChange={(subject: String) => {
                setState({
                  subject,
                });
              }}
              type="text"
              value={state.subject}
              name="subject"
            />
          </div>
          {/* <div className="email_resume_name_container">
              <div className="email_resume_name_label">Email templates</div>
              <Dropdown
                data={state.email_templates}
                value={state.email_template}
                setActive={(email_template: any) => {
                  addSubjectFromTemplate(email_template);
                }}
              />
            </div> */}
          <div className="email_resume_text_edtior">
            <TextEditor
              onChange={(email: any) => setState({ email })}
              error={state.errorArray}
              name={'email'}
              value={state.email}
            />
          </div>
          <div className="email_resume_attachment_wrapper">
            {state.attachments.length > 0 &&
              state.attachments.map((item: string, index: number) => (
                <div className="email_resume_attachment">
                  <Attachment
                    data={{ fileName: item.split('_')?.pop() }}
                    onClick={() => removeAttachments(index)}
                  />
                </div>
              ))}
          </div>
          <div className="email_resume_button_wrapper">
            {state.loading ? (
              <Loader />
            ) : (
              <>
                <FileUpload
                  ref={inputFileRef}
                  multiple
                  folder="interview_attachment"
                  onChange={(attachments: any) => {
                    handleUploadAttachments(attachments);
                  }}>
                  <Navbutton
                    icon={Assets.attachment}
                    onClick={() => {
                      inputFileRef?.current?.openUpload();
                    }}
                  />
                </FileUpload>
                <PrimaryButton
                  text={'Send'}
                  className="email_resume_btn"
                  onClick={sendEmail}
                />
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
});
export default SupportEmail;
