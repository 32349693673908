import { Assets } from '../../../utils/imports.utils';
import './radio.ui.scss';

interface IRadioProps{
  data?:any;
  isSelected?:boolean;
  setActive?:any;
  name?:string;
}
const Radio = (props:IRadioProps) => {
  return (
    <>
      <div className="radio_icon_wrapper" onClick={()=>props.setActive(props.data)}>
        <img
          src={props.isSelected ? Assets.radio_active : Assets.radio_inactive}
          height={15}
          width={15}
          className="checked-icon"
          alt="no_radio"
        />
        {props.data && <div className="radio_label">{props.data.label}</div>}
      </div>
    </>
  );
};

export default Radio;
