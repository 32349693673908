import ListComponent from 'common_components/ui/list/list.component';
import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
  getDropdownData,
  mergeArrayWithoutDuplicates,
  useSetState,
} from 'utils/functions.utils';
import {
  Functions,
  Models,
  Input,
  PrimaryButton,
  Assets,
  Dropdown,
  DeletePop,
  Validation,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './work_locations.screen.scss';
import { toastify, uploadFile, toastifyError } from 'utils/functions.utils';
import Notiflix from 'notiflix';
import { getAllWorkLocations } from '../../utils/redux.utils';
import _ from 'lodash';

const WorkLocation = (props: any) => {
  // Redux
  const location = useSelector((state: any) => state.location);
  // State
  const [state, setState] = useSetState({
    location_name: '',
    address: '',
    city: '',
    pincode: '',
    state: '',
    country: '',
    data: [],
    errorArray: [],
    modal: false,
    countryList: [],
    stateList: [],
    countryCode: '',
    cityList: [],
    skip: 0,
    limit: 10,
    loadMore: true,
    loading: false,
  });

  //Hooks
  useEffect(() => {
    getAllLocation();
    getCountriesList();
  }, []);

  const handleWindowClose = (e) => {
    e.preventDefault();
    return (e.returnValue = '');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [window]);

  //Logic

  // sample_data
  const titleData = [
    { label: 'Nick Name', value: 'nickname', width: '20%' },
    { label: 'Full address', value: 'full_address', width: '55%' },
  ];

  //  get location

  const getAllLocation = async () => {
    try {
      setState({ loading: true });
      const res: any = await Models.workLocation.getAllLocation({
        skip: 0,
        limit: state.limit,
      });
      res.data.docs.map(
        (item: any) =>
          // (item.full_address = `${item.address} ${item.city} ${item.pincode} ${item.state} ${item.country}`),
          (item.full_address = item.address),
      );
      if (res.data.totalDocs === res?.data?.docs?.length) {
        setState({ loadMore: false });
      }
      getAllWorkLocations(res?.data?.docs);
      setState({ data: res?.data?.docs, loading: false, skip: 0 });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  const loadMoreLocation = async (skip: number) => {
    try {
      const res: any = await Models.workLocation.getAllLocation({
        skip,
        limit: state.limit,
      });
      res.data.docs.map(
        (item: any) =>
          (item.full_address = `${item.address} ${item.city} ${item.pincode} ${item.state} ${item.country}`),
      );
      let data = mergeArrayWithoutDuplicates(
        location?.location_list,
        res?.data.docs,
      );
      if (res.data.totalDocs === data.length) {
        setState({ loadMore: false });
      }
      getAllWorkLocations(data);
      setState({ data: res?.data?.docs });
    } catch (error: any) {
      toastifyError(error);
    }
  };

  // create Method

  const handleCreateLocation = async () => {
    try {
      const body: any = {
        nickname: state.location_name,
        address: state.address,
        city: state.city.label,
        state: state.state.value,
        country: state.country.label,
        pincode: state.pincode
      };
      // if (state.city?.label?.length > 0) {
      //   body.city = state.city.label;
      // }
      // if (state.state?.value?.length > 0) {
      //   body.state = state.state.value;
      // }
      // if (state.county?.label?.length > 0) {
      //   body.country = state.country.label;
      // }
      // if (state.pincode.length > 0) {
      //   body.pincode = state.pincode;
      // }
      
      await Validation.workLocationSchema.validate(body, {
        abortEarly: false,
      });
      const res: any = await Models.workLocation.createWorkLocation(body);
      setState({
        location_name: '',
        address: '',
        city: '',
        pincode: '',
        state: '',
        country: '',
      });
      await getAllLocation();
      toastify('Work location added');
    } catch (error: any) {
      toastifyError(error);
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: [] });
      }, 3000);
    }
  };

  // delete
  const deleteWorkLocationById = async () => {
    try {
      const body = {
        location_id: state.data._id,
      };
      const res: any = await Models.workLocation.deleteLocationById(body);
      await getAllLocation();
      setState({ modal: false });
    } catch (error: any) {}
  };

  const getCountriesList = async () => {
    try {
      const res: any = await Models.country.getAllCountries();
      let countryList: any = [];
      res?.map((country: any) =>
        countryList.push({
          label: country.name,
          value: country.iso2,
        }),
      );
      setState({ countryList });
    } catch (error) {}
  };

  const getStatesListByCountry = async (isoCode: string) => {
    try {
      const res: any = await Models.country.getStatesOfCountry(isoCode);
      // console.log('res', res);
      let stateList: any = [];
      res?.map((item: any) =>
        stateList.push({
          label: item.name,
          value: item.name,
        }),
      );
      setState({ stateList, allStates: res });
    } catch (error) {}
  };

  const getCityListByStates = async (country: string, state_code: string) => {
    let index = _.findIndex(state.allStates, { name: state_code });
    let stateIso = state.allStates[index].iso2;
    try {
      const res: any = await Models.country.getCitiesByCountry(
        country,
        stateIso,
      );
      let cityList: any = [];
      res?.map((item: any) =>
        cityList.push({
          label: item.name,
          value: item.name,
        }),
      );
      setState({ cityList });
    } catch (error) {}
  };

  const loadMore = () => {
    setState({ skip: state.skip + 10 });
    loadMoreLocation(state.skip + 10);
  };

  //sample data
  let actionArray = {
    style: { width: '25%' },
    actions: [
      {
        component: (item: any) => {
          return (
            <div style={{}}>
              <PrimaryButton
                text={'Delete location'}
                className={'list_component_action_btn'}
                icon={Assets.trash}
                onClick={() => {
                  setState({ data: item, modal: true });
                  // deleteWorkLocationById(item);
                }}
              />
            </div>
          );
        },
      },
    ],
  };

  return (
    <div className="work_locations_container">
      <div className="work_location_agencies_container">
        <div className="agencies_container_wrapper">
          <div className="input_block">
            <div className="agencies_heading">Work location</div>
            <div className="work_location_input_wrapper">
              <div className="input_heading">
                Location Nick Name<span className="required_field">*</span>
              </div>
              <div className="input_area">
                <Input
                  onChange={(value) => {
                    setState({ location_name: value });
                  }}
                  type={'text'}
                  error={state.errorArray}
                  value={state.location_name}
                  name={'nickname'}
                />
              </div>
              <div className="input_heading">
                Address<span className="required_field">*</span>
              </div>
              <div className="input_area">
                <Input
                  onChange={(value) => {
                    setState({ address: value });
                  }}
                  type={'text'}
                  error={state.errorArray}
                  value={state.address}
                  name={'address'}
                />
              </div>

              <div className="input_heading">Pincode<span className="required_field">*</span></div>
              <div className="input_area">
                <Input
                  onChange={(value) => {
                    setState({ pincode: value });
                  }}
                  type={'text'}
                  value={state.pincode}
                  name={'pincode'}
                  error={state.errorArray}
                />
              </div>
              <div className="input_heading">Country<span className="required_field">*</span></div>
              <div className="input_area">
                <Dropdown
                  data={state.countryList}
                  setActive={(value: any) => {
                    getStatesListByCountry(value?.value);
                    setState({
                      country: value,
                      country_code: value?.value,
                    });
                  }}
                  value={state.country}
                  name={'country'}
                  error={state.errorArray}
                />
              </div>
              <div className="input_heading">State<span className="required_field">*</span></div>
              <div className="input_area">
                <Dropdown
                  data={state.stateList}
                  setActive={(value: any) => {
                    getCityListByStates(state.country_code, value.value);
                    setState({ state: value });
                  }}
                  value={state.state}
                  name={'state'}
                  error={state.errorArray}
                />
              </div>
              <div className="input_heading">City<span className="required_field">*</span></div>
              <div className="input_area">
                <Dropdown
                  data={state.cityList}
                  setActive={(city: any) => {
                    setState({ city });
                  }}
                  value={state.city}
                  name={'city'}
                  error={state.errorArray}
                />
              </div>
              <div className="save_button">
                <PrimaryButton
                  onClick={handleCreateLocation}
                  text={'Add location'}
                  className={'save'}
                />
              </div>
            </div>
          </div>
          <div className="table_block">
            <ListComponent
              titleArray={titleData}
              data={
                location?.location_list.length > 0
                  ? location?.location_list
                  : []
              }
              loadMore={loadMore}
              hasMore={state.loadMore}
              actionArray={actionArray}
              loading={state.loading}
            />
          </div>
        </div>
      </div>
      <DeletePop
        text={'Are you sure you want to delete this work location?'}
        onClick={deleteWorkLocationById}
        onCancel={() => setState({ modal: false })}
        open={state.modal}
      />
    </div>
  );
};

export default WorkLocation;
