import { GET_BUSINESS_UNITS } from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = { business_units: [] };

const AppReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_BUSINESS_UNITS:
      return {
        ...state,
        business_units: action.payload,
      };
    default:
      return state;
  }
};

export default AppReducer;
